import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  IonList,
  IonButton,
  IonInput,
  IonTitle,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
  IonText,
  IonChip,
  useIonActionSheet,
  IonNote,
} from "@ionic/react";

import {
  close,
  closeCircleOutline,
  checkmarkCircleOutline,
  checkmarkOutline,
  trashBin,
  closeOutline,
  trash,
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";

interface ConversationOutcomeDetailProps {
  session: Session;
  conversationOutcome: Models.ConversationOutcome;
  onDismiss: () => void;
}

const ConversationOutcomeDetail: React.FC<ConversationOutcomeDetailProps> =
  observer(({ session, conversationOutcome, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [present, dismiss] = useIonActionSheet();

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const onDeleteOutcome = () => {
      if (formState.isValid && conversationOutcome && conversationOutcome.id) {
        conversationOutcome.isDeleted = true;
        conversationOutcome
          .save()
          .then(() => onDismiss())
          .catch((error) => {
            console.error("Error deleting Conversation Outcome.", error);
          });
      }
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && conversationOutcome) {
        if (conversationOutcome.id == null) {
          conversationOutcome.name = data.name.trim();
          conversationOutcome.category = data.category;
        }
        try {
          conversationOutcome.tag = data.tag.trim();
          await conversationOutcome.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save Conversation Outcome");
          console.error("Error saving Conversation Outcome.", error);
        }
      }
    };

    const categories = session.conversationOutcomesSettings?.categories;

    if (!conversationOutcome || !categories) {
      return <IonProgressBar color="tertiary" type="indeterminate" />;
    }

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  if (formState.isDirty) {
                    present({
                      buttons: [
                        {
                          text: "Discard Changes",
                          role: "destructive",
                          handler: () => onDismiss(),
                        },
                        { text: "Keep Editing", role: "cancel" },
                      ],
                      header: "Are you sure you want to discard your changes?",
                      mode: "ios",
                    });
                  } else {
                    onDismiss();
                  }
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Conversation Outcome</IonTitle>
            <IonButtons slot="end">
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={(_) => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeleteOutcome}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {conversationOutcome.id ? (
                    <IonButton
                      onClick={(_) => setIsDeleting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    shape="round"
                    expand="block"
                    size="large"
                    fill="solid"
                    color="light"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {conversationOutcome ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList lines="none" className="ion-no-padding">
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "5px" }}
                >
                  BASIC DETAILS
                </IonListHeader>
                <IonItem key="name" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Name
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={conversationOutcome.name}
                    name="name"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                      validate: (data) => {
                        const searchString = data.toLowerCase().trim();
                        const found =
                          session.conversationOutcomes?.data?.filter(
                            (existing) =>
                              existing.id !== conversationOutcome.id &&
                              existing.name?.toLowerCase().trim() ===
                              searchString
                          ) ?? [];
                        return found.length === 0
                          ? true
                          : "Name must be unique";
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        disabled={conversationOutcome.id != null}
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Customer Reference"
                      ></IonInput>
                    )}
                  />

                  {showError("name")}
                </IonItem>
                <IonItem key="category" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Category
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={conversationOutcome.category}
                    name="category"
                    rules={{
                      required: true,
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonSelect
                        className="input-field"
                        disabled={conversationOutcome.id != null}
                        value={value}
                        okText="Okay"
                        cancelText="Dismiss"
                        onIonChange={onChange}
                        onBlur={onBlur}
                      >
                        {categories.map((category) => (
                          <IonSelectOption value={category} key={category}>
                            {category}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />

                  {showError("category")}
                </IonItem>
                <IonItem key="tag" lines="none" className="ion-padding-bottom" style={{ marginTop: "15px" }}>
                  <IonLabel position="stacked" color="medium">
                    Tag
                  </IonLabel>
                  
                  <Controller
                    control={control}
                    defaultValue={conversationOutcome.tag ?? null}
                    name="tag"
                    rules={{
                      required: false,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                      pattern: {
                        value: /^[0-9a-z-]*$/,
                        message:
                          "Only lowercase alphanumeric characters allowed.",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Customer Reference"
                      ><IonText color="medium" style={{ paddingLeft: "8px" }}>#</IonText></IonInput>
                    )}
                  />

                  {showError("tag")}
                </IonItem>
              </IonList>
            </form>
          ) : (
            <div>Conversation Outcome not found</div>
          )}
        </IonContent>
      </IonPage>
    );
  });

export default ConversationOutcomeDetail;
