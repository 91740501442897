import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonImg,
  IonText,
} from "@ionic/react";

import { close, personAdd } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";

interface AddParticipantButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const AddParticipantButton: React.FC<AddParticipantButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);
    const [searchText, setSearchText] = useState("");

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const workerProfiles = session.workerProfiles?.data?.filter(
      (workerProfile) =>
        workerProfile.id !== conversation?.ownerId
        && conversation?.participants?.data?.findIndex(participant => participant.id === workerProfile.id) === -1
        && workerProfile.user && workerProfile.user.loaded && workerProfile.user.data && workerProfile.user.data.isEnabled
        && (workerProfile.name ?? "").trim() !== ""
        && (workerProfile.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())
    );

    return (
      <>
        <IonButton
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => set_showModal(true)}
          disabled={conversation === undefined || conversation === null}
          hidden={
            !(
              conversation?.status === "active" &&
              (
                conversation.ownerId === session.currentUser?.workerProfileId
                || session.currentUser?.role === "admin"
              )
            )
          }
        >
          <IonIcon icon={personAdd} />
        </IonButton>

        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={(e) => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Add Participant</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonSearchbar
              color="light"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
            <IonList lines="none">
              {workerProfiles && workerProfiles.length > 0
                ? workerProfiles.map((item) => (
                  <IonItem
                    button
                    key={item.id}
                    onClick={() => {
                      conversation
                        ?.addParticipant(item.id!)
                        .then(() => set_showModal(false));
                    }}
                  >
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end"
                    >
                      {item.avatar
                        ? <AnimatedImg alt="" src={item.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{item.name}</IonLabel>
                  </IonItem>
                ))
                : (searchText ?? "") !== "" && (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-center">
                      <IonImg
                        style={{
                          maxWidth: "250px",
                          margin: "auto",
                          marginTop: "69px",
                        }}
                        src={
                          (searchText ?? "") === ""
                            ? "/assets/images/artboard007.png"
                            : "/assets/images/artboard008.png"
                        }
                      />
                      <p>
                        <strong>
                          <IonText
                            color="dark"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {(searchText ?? "") === ""
                              ? ""
                              : " No Search Results"}
                          </IonText>
                        </strong>
                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default AddParticipantButton;
