import firebase from "firebase/app";
import { Session } from "../../session";

import { WorkItem } from "./WorkItem";

export interface WorkItemData {
    hasUnreadInternalMessages: boolean | null;
    hasUnreadNotes: boolean | null;
    unreadMessages: boolean | null;
    lastContactDate: firebase.firestore.Timestamp | null;
    lastInternalMessageReadAt: firebase.firestore.Timestamp | null;
    lastInternalMessageReceivedAt: firebase.firestore.Timestamp | null;
    lastInternalMessageSenderId: string | null;
    lastMessageDirection: "in" | "out" | null;
    lastMessageReadAt: firebase.firestore.Timestamp | null;
    lastNoteReadAt: firebase.firestore.Timestamp | null;
    lastNoteSenderId: string | null;
    conversationId: string | null;
    status: "active" | "inactive" | "new" | null;
}

export const workItemDefaultValue = {
    hasUnreadInternalMessages: null,
    hasUnreadNotes: null,
    unreadMessages: null,
    lastContactDate: null,
    lastInternalMessageReadAt: null,
    lastInternalMessageReceivedAt: null,
    lastInternalMessageSenderId: null,
    lastMessageDirection: null,
    lastMessageReadAt: null,
    lastNoteReadAt: null,
    lastNoteSenderId: null,
    conversationId: null,
    status: null,
} as WorkItemData;

export const workItemDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: WorkItemData) {
            return {
                hasUnreadInternalMessages: data.hasUnreadInternalMessages,
                hasUnreadNotes: data.hasUnreadNotes,
                unreadMessages: data.unreadMessages,
                lastContactDate: data.lastContactDate,
                lastInternalMessageReadAt: data.lastInternalMessageReadAt,
                lastInternalMessageReceivedAt: data.lastInternalMessageReceivedAt,
                lastInternalMessageSenderId: data.lastInternalMessageSenderId,
                lastMessageDirection: data.lastMessageDirection,
                lastMessageReadAt: data.lastMessageReadAt,
                lastNoteReadAt: data.lastNoteReadAt,
                lastNoteSenderId: data.lastNoteSenderId,
                conversationId: data.conversationId,
                status: data.status,
            }
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<WorkItemData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                hasUnreadInternalMessages: data.hasUnreadInternalMessages ?? false,
                hasUnreadNotes: data.hasUnreadNotes ?? false,
                unreadMessages: data.unreadMessages ?? false,
                lastContactDate: data.lastContactDate ?? null,
                lastInternalMessageReadAt: data.lastInternalMessageReadAt ?? null,
                lastInternalMessageReceivedAt: data.lastInternalMessageReceivedAt ?? null,
                lastInternalMessageSenderId: data.lastInternalMessageSenderId ?? null,
                lastMessageDirection: data.lastMessageDirection ?? "in",
                lastMessageReadAt: data.lastMessageReadAt ?? null,
                lastNoteReadAt: data.lastNoteReadAt ?? null,
                lastNoteSenderId: data.lastNoteSenderId ?? null,
                conversationId: snapshot.id ?? null,
                status: data.status ?? null
            } as WorkItemData;
        },
    }
};

export const workItemConverter = (session: Session) => {
    return {
        toFirestore: function (workItem: WorkItem) {
            return workItem.data ?
                {
                    hasUnreadInternalMessages: workItem.data.hasUnreadInternalMessages,
                    hasUnreadNotes: workItem.data.hasUnreadNotes,
                    unreadMessages: workItem.data.unreadMessages,
                    lastContactDate: workItem.data.lastContactDate,
                    lastInternalMessageReadAt: workItem.data.lastInternalMessageReadAt,
                    lastInternalMessageReceivedAt: workItem.data.lastInternalMessageReceivedAt,
                    lastInternalMessageSenderId: workItem.data.lastInternalMessageSenderId,
                    lastMessageDirection: workItem.data.lastMessageDirection,
                    lastMessageReadAt: workItem.data.lastMessageReadAt,
                    lastNoteReadAt: workItem.data.lastNoteReadAt,
                    lastNoteSenderId: workItem.data.lastNoteSenderId,
                    conversationId: workItem.data.conversationId,
                    status: workItem.data.status,
                } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<WorkItemData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return WorkItem.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    hasUnreadInternalMessages: data.hasUnreadInternalMessages ?? false,
                    hasUnreadNotes: data.hasUnreadNotes ?? false,
                    unreadMessages: data.unreadMessages ?? false,
                    lastContactDate: data.lastContactDate ?? null,
                    lastInternalMessageReadAt: data.lastInternalMessageReadAt ?? null,
                    lastInternalMessageReceivedAt: data.lastInternalMessageReceivedAt ?? null,
                    lastInternalMessageSenderId: data.lastInternalMessageSenderId ?? null,
                    lastMessageDirection: data.lastMessageDirection ?? "in",
                    lastMessageReadAt: data.lastMessageReadAt ?? null,
                    lastNoteReadAt: data.lastNoteReadAt ?? null,
                    lastNoteSenderId: data.lastNoteSenderId ?? null,
                    conversationId: snapshot.id ?? null,
                    status: data.status ?? null
                }
            );
        },
    }
};