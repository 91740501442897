import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonItem,
  IonLabel,
  IonAlert,
  IonImg,
  IonText,
  useIonModal,
  IonButtons,
  useIonToast,
} from "@ionic/react";

import { logoGoogle, logoFacebook, close, arrowBack } from "ionicons/icons";

import { SessionContext } from "../../session";
import ForgotPassword from "./components/ForgotPassword";

const debug = false;

const LoginPage: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  if (debug) console.log("Component: Render - UserLogin");

  useEffect(() => {
    if (debug) console.log("Component: Activate - UserLogin");
    return function dispose() {
      if (debug) console.log("Component: Dispose - UserLogin");
    };
  }, []);

  const [presentToast, dismissToast] = useIonToast();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showForgotPasswordAlert, setShowForgotPasswordAlert] = useState(false);
  const [email, setEmail] = useState("");

  const { control, handleSubmit, formState, reset, errors } = useForm({
    mode: "onChange",
  });

  const session = useContext(SessionContext);

  const [presentForgotPassword, dismissForgotPassword] = useIonModal(
    ForgotPassword,
    {
      session: session,
      onSuccess: () => {
        dismissForgotPassword();
        presentToast({
          //buttons: [{ text: 'hide', handler: () => dismissToast() }],
          message: "An email with instructions to reset your password has been sent to your email address",
          duration: 4000,
          //onDidDismiss: () => {},
          //onWillDismiss: () => {},
        })
      },
      onCancel: () => dismissForgotPassword(),
    }
  );

  if (!session) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    try {
      const useIdentityService = true;
      await session.signInWithEmailAndPassword(data.email, data.password, useIdentityService);
      localStorage.setItem("vrz--engage-instance-id", session.instanceId);
      reset();
    } catch (error: any) {
      setErrorMessage((
        error.code === "auth/invalid-email"
          ? "Invalid Email Address"
          : error.code === "auth/user-disabled"
            ? "User is disabled"
            : error.code === "auth/user-not-found" || error.code === "auth/wrong-password"
              ? "Invalid user email address or password"
              : error.message
      ));
      setShowErrorToast(true);
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="">
          <IonButtons slot="start">
            <IonButton
              href="/"
              hidden={
                typeof window?.location?.href?.match(
                  /^(https:\/\/)?((app.)?engage.)?([^.]+)\.voyagernetz\.us/
                )?.[4] === "string"
              }
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          color="medium"
          isOpen={showErrorToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={2000}
        />
        <IonImg
          src="/apple-touch-icon.png"
          style={{
            minWidth: "80px",
            maxWidth: "80px",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        />
        <IonTitle style={{ textAlign: "center" }}>VoyagerNetz Engage</IonTitle>
        <p style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>Sign into your instance.</p>
        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 5 }}>
          <IonGrid fixed style={{ maxWidth: "340px" }}>
            <IonRow>
              <IonCol>
                <IonItem
                  lines="none"
                  style={{
                    border: "1px solid var(--ion-color-light)",
                    borderRadius: "10px",
                  }}
                >
                  <Controller
                    control={control}
                    name="instanceId"
                    defaultValue={session.instanceId}
                    rules={{
                      required: true,
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        disabled
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="instance"
                      />
                    )}
                  />
                  <IonText color="medium">.voyagernetz.us</IonText>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol>
                <IonItem
                  lines="none"
                  style={{
                    border: "1px solid var(--ion-color-light)",
                    borderRadius: "10px",
                  }}
                >
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="email"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Email"
                        onKeyUp={(e) => {
                          if (formState.isValid && e.key === "Enter")
                            handleSubmit(onSubmit)();
                        }}
                      />
                    )}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem
                  lines="none"
                  style={{
                    border: "1px solid var(--ion-color-light)",
                    borderRadius: "10px",
                  }}
                >
                  <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="password"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Password"
                        onKeyUp={(e) => {
                          if (formState.isValid && e.key === "Enter")
                            handleSubmit(onSubmit)();
                        }}
                      />
                    )}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem
                  color="clear"
                  button
                  lines="none"

                >
                  <IonLabel
                    slot="end"
                    onClick={() => presentForgotPassword()}
                  >
                    <div className="ion-text-end">
                      <p>Forgot your password?</p>
                    </div>
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  color="primary"
                  type="submit"
                  disabled={!formState.isValid || formState.isSubmitting}
                  expand="block"
                >
                  {formState.isSubmitting ? "Signing in..." : "Sign in"}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow hidden style={{ marginTop: "20px", marginBottom: "10px" }}>
              <IonCol>
                <IonLabel>
                  <p className="ion-text-center">Or sign in with</p>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow hidden>
              <IonCol>
                <IonButton
                  shape="round"
                  color="light"
                  disabled={formState.isSubmitting}
                  expand="block"
                  onClick={() => {
                    session
                      .signInWithGoogle()
                      .then(() => {
                        localStorage.setItem(
                          "vrz--engage-instance-id",
                          session.instanceId
                        );
                        reset();
                        //history.push("/");
                      })
                      .catch(function (error) {
                        // Handle Errors here.
                        //var errorCode = error.code;
                        var errorMessage = error.message;
                        // ...
                        setErrorMessage(errorMessage);
                        setShowErrorToast(true);
                      });
                  }}
                >
                  <IonIcon icon={logoGoogle} slot="start"></IonIcon>
                  Google
                </IonButton>
              </IonCol>
              <IonCol hidden>
                <IonButton
                  disabled
                  shape="round"
                  color="light"
                  expand="block"
                  onClick={() => {
                    session
                      .signInWithFacebook()
                      .then(() => {
                        localStorage.setItem(
                          "vrz--engage-instance-id",
                          session.instanceId
                        );
                        reset();
                        //history.push("/");
                      })
                      .catch(function (error) {
                        // Handle Errors here.
                        //var errorCode = error.code;
                        var errorMessage = error.message;
                        // ...
                        setErrorMessage(errorMessage);
                        setShowErrorToast(true);
                      });
                  }}
                >
                  <IonIcon icon={logoFacebook} slot="start"></IonIcon>
                  Facebook
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow hidden style={{ marginTop: "20px", marginBottom: "20px" }}>
              <IonCol>
                <IonLabel onClick={() => history.push("/signup")}>
                  <p className="ion-text-center">
                    Don't have an account? <strong>Sign up here.</strong>
                  </p>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
});

export default LoginPage;
