import firebase from "firebase/app";
import { Session } from "../../session";

import { Participant } from "./Participant";

export interface ParticipantData {
    workerProfileId: string | null;
}

export const participantDefaultValue = {
    workerProfileId: null,
} as ParticipantData;

export const participantDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: ParticipantData) {
            return {
                workerProfileId: data.workerProfileId,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<ParticipantData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                workerProfileId: data.workerProfileId ?? snapshot.id,
            } as ParticipantData;
        },
    }
};

export const participantConverter = (session: Session) => {
    return {
        toFirestore: function (participant: Participant) {
            return participant.data ? {
                workerProfileId: participant.data.workerProfileId,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<ParticipantData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return Participant.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    workerProfileId: data.workerProfileId ?? snapshot.id,
                }
            );
        },
    }
};