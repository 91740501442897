import firebase from "firebase/app";

import { Note } from "./Note";

import { Session } from "../../session";

export interface NoteData {
    body: string | null;
    createdOn: firebase.firestore.Timestamp | null;
    workerProfileId: string | null;
}

export const noteDefaultValue = {
    body: null,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    workerProfileId: null,
} as NoteData;

export const noteDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: NoteData) {
            return {
                body: data.body,
                createdOn: data.createdOn,
                workerProfileId: data.workerProfileId
            }
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<NoteData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                body: data.body ?? null,
                createdOn: data.createdOn ?? null,
                workerProfileId: data.workerProfileId ?? null
            } as NoteData;
        },
    }
};

export const noteConverter = (session: Session) => {
    return {
        toFirestore: function (note: Note) {
            return note.data ? {
                body: note.data.body,
                createdOn: note.data.createdOn,
                workerProfileId: note.data.workerProfileId
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<NoteData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return Note.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    body: data.body ?? null,
                    createdOn: data.createdOn ?? null,
                    workerProfileId: data.workerProfileId ?? null
                }
            );
        },
    }
};