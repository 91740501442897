import React from "react";
import { observer } from "mobx-react-lite";
import { LinkIt, urlRegex, emailRegex } from "react-linkify-it";
import moment from "moment";

import { IonIcon } from "@ionic/react";
import {
  alertCircleOutline,
  chatboxEllipses,
  chatbubbleOutline,
  checkmarkCircleOutline,
  checkmarkDoneOutline,
  checkmarkOutline,
  globeOutline,
  logoFacebook,
  logoWhatsapp,
  personAdd,
  personAddOutline,
  personRemoveOutline,
  radioButtonOff,
  radioButtonOn,
  timeOutline,
} from "ionicons/icons";

import * as Models from "../models";

export function fileSizeString(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function relativeDateString(
  date: Date | null | undefined,
  includeTime = true
) {
  if (!date) return "";

  const momentDate = moment(date);

  const isThisYear = momentDate.isBetween(
    moment().startOf("year"),
    moment().endOf("year"),
    "day",
    "()"
  );
  const isThisMonth = momentDate.isBetween(
    moment().startOf("month"),
    moment().endOf("month")
  );
  const isYesterday = momentDate.isBetween(
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day")
  );
  const isToday = momentDate.isBetween(
    moment().startOf("day"),
    moment().endOf("day")
  );

  const dateString = isToday
    ? includeTime
      ? momentDate.format("h:mm A")
      : "Today"
    : isYesterday && !includeTime
    ? "Yesterday"
    : isThisYear
    ? momentDate.format(includeTime ? "MMM D, h:mm A" : "MMM D")
    : momentDate.format(includeTime ? "MMM D YYYY, h:mm A" : "MMM D YYYY");

  return dateString;
}

export function getWindowDimensions() {
  var viewportwidth;
  var viewportheight;
  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth != "undefined") {
    viewportwidth = window.innerWidth;
    viewportheight = window.innerHeight;
  }
  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement != "undefined" &&
    typeof document.documentElement.clientWidth != "undefined" &&
    document.documentElement.clientWidth !== 0
  ) {
    viewportwidth = document.documentElement.clientWidth;
    viewportheight = document.documentElement.clientHeight;
  }
  // older versions of IE
  else {
    viewportwidth = document.getElementsByTagName("body")[0].clientWidth;
    viewportheight = document.getElementsByTagName("body")[0].clientHeight;
  }
  return {
    width: viewportwidth,
    height: viewportheight,
  };
}

/*type UnknownArrayOrObject = unknown[] | Record<string, unknown>;
export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  );
};*/

export function newId(): string {
  // Alphanumeric characters
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}

export function getId(path: string) {
  const pathElements = path.substring(path.startsWith("/") ? 1 : 0).split("/");
  return pathElements.length % 2 == 0
    ? pathElements[pathElements.length - 1]
    : null;
}

export const hashCode = (s: string) => {
  let h = 0,
    l = (s || "").length;
  for (let i = 0; i < l; ++i) {
    h = ((h << 5) - h + s.charCodeAt(i)) | 0;
  }
  return h;
};

export const getChannelIcon = (channel: string) => {
  switch (channel.toLowerCase()) {
    case Models.messageChannels.WHATSAPP:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={logoWhatsapp}
        />
      );
    case Models.messageChannels.SMS:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={chatboxEllipses}
        />
      );
    case Models.messageChannels.DEBUG:
      return <></>;
    case Models.messageChannels.MESSENGER:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={logoFacebook}
        />
      );
    case Models.messageChannels.WEBCHAT:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={globeOutline}
        />
      );
    case Models.messageChannels.DEBUGINCOMING:
      return <></>;
  }
};

export const getMessageStatusIcon = (status: string) => {
  switch (status.toLowerCase()) {
    case Models.messageStatuses.PENDING:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={timeOutline}
        />
      );
    case Models.messageStatuses.PUBLISHED:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={timeOutline}
        />
      );
    case Models.messageStatuses.SENDING:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={timeOutline}
        />
      );
    case Models.messageStatuses.SUBMITTED:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkOutline}
        />
      );
    case Models.messageStatuses.QUEUED:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkOutline}
        />
      );
    case Models.messageStatuses.SENT:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkOutline}
        />
      );
    case Models.messageStatuses.DELIVERED:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkDoneOutline}
        />
      );
    case Models.messageStatuses.FAILED:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={alertCircleOutline}
          color="danger"
        />
      );
    case Models.messageStatuses.READ:
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkDoneOutline}
        />
      );

    default:
      return <></>;
  }
};

export const getConversationHistoryEntryIcon = (triggerName: string) => {
  switch (triggerName.toLowerCase()) {
    case "conversation.accept":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={radioButtonOn}
        />
      );
    case "conversation.close":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={checkmarkCircleOutline}
        />
      );
    case "conversation.create":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={radioButtonOff}
        />
      );
    case "conversation.participants.add":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={personAddOutline}
        />
      );
    case "conversation.participants.leave":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={personRemoveOutline}
        />
      );
    case "conversation.participants.remove":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={personRemoveOutline}
        />
      );
    case "conversation.transfer.request":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={"/icons/swap-horizontal-circle-outline.svg"}
        />
      );
    case "conversation.transfer.accept":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={"/icons/swap-horizontal-circle-outline.svg"}
        />
      );
    case "conversation.transfer.reject":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={"/icons/swap-horizontal-circle-outline.svg"}
        />
      );
    case "conversation.transfer.cancel":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={"/icons/swap-horizontal-circle-outline.svg"}
        />
      );
    case "conversation.messages.in":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={chatbubbleOutline}
        />
      );
    case "conversation.messages.out":
      return (
        <IonIcon
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
          icon={chatbubbleOutline}
        />
      );
  }
};

export const LinkifyIt: React.FC = observer(({ children }) => {
  return (
    <LinkIt
      component={(match, key) => (
        <a
          style={{ color: "inherit" }}
          href={/^www\./.exec(match) ? `http://${match}` : match}
          key={key}
          target="_blank"
          rel="noreferrer"
        >
          {match}
        </a>
      )}
      regex={urlRegex}
    >
      <LinkIt
        component={(match, key) => (
          <a
            style={{ color: "inherit" }}
            href={`mailto:${match}`}
            key={key}
            target="_blank"
            rel="noreferrer"
          >
            {match}
          </a>
        )}
        regex={emailRegex}
      >
        <>{children}</>
      </LinkIt>
    </LinkIt>
  );
});
