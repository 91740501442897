import firebase from "firebase/app";
import { Session } from "../../session";

import { ConversationOutcomesSettings } from "./ConversationOutcomesSettings";

export interface ConversationOutcomesSettingsData {
  categories: Record<string, string> | null;
}

export const conversationOutcomesSettingsDefaultValue = {
  categories: null,
} as ConversationOutcomesSettingsData;

export const conversationOutcomesSettingsDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: ConversationOutcomesSettingsData) {
      return {
        categories: data.categories,
      }
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomesSettingsData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        categories: data.categories ?? null,
      } as ConversationOutcomesSettingsData;
    },
  }
};

export const conversationOutcomesSettingsConverter = (session: Session) => {
  return {
    toFirestore: function (conversationOutcomesSettings: ConversationOutcomesSettings) {
      return conversationOutcomesSettings.data
        ? {
          categories: conversationOutcomesSettings.data.categories,
        } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomesSettingsData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return ConversationOutcomesSettings.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          categories: data.categories ?? null,
        }
      );
    },
  }
};