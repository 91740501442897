import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";
import { 
  checkmarkOutline, 
  close, 
  closeOutline,
  trash 
} from "ionicons/icons";

import * as Models from "../../../models";

interface ConversationOutcomeCategoryDetailProps {
  category: string;
  conversationOutcomesSettings: Models.ConversationOutcomesSettings;
  onDismiss: () => void;
}

const ConversationOutcomeDetail: React.FC<ConversationOutcomeCategoryDetailProps> =
  observer(({ category, conversationOutcomesSettings, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [present, dismiss] = useIonActionSheet();

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    useEffect(() => {
      reset();
    }, [category, reset]);

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const onSubmit = async (data: any) => {
      if (
        formState.isValid &&
        conversationOutcomesSettings &&
        data?.category &&
        conversationOutcomesSettings.categoryAdd(data.category)
      ) {
        try {
          await conversationOutcomesSettings.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save Conversation Outcome Settings");
          console.error("Error saving Conversation Outcome Settings.", error);
        }
      }
    };

    const onDeleteCategory = async () => {
      if (category) {
        const isRemovable = await conversationOutcomesSettings.categoryRemove(category);

        if (isRemovable) {
          conversationOutcomesSettings
            .save()
            .then(() => onDismiss())
            .catch(error => {
              console.error("Error deleting Category.", error);
            });
        } else {
          alert("Unable to delete category, as it is in use.");
          onDismiss();
        }
      }
    };

    return (
      <IonPage>
        <IonHeader
          translucent
          //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  if (formState.isDirty) {
                    present({
                      buttons: [
                        {
                          text: "Discard Changes",
                          role: "destructive",
                          handler: () => onDismiss(),
                        },
                        { text: "Keep Editing", role: "cancel" },
                      ],
                      header: "Are you sure you want to discard your changes?",
                      mode: "ios",
                    });
                  } else {
                    onDismiss();
                  }
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Conversation Outcome Category</IonTitle>
            <IonButtons slot="end">
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={(_) => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeleteCategory}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {category ? (
                    <IonButton
                      onClick={(_) => setIsDeleting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    shape="round"
                    expand="block"
                    size="large"
                    fill="solid"
                    color="light"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList lines="none" className="ion-no-padding">
              <IonListHeader hidden color="light" style={{ paddingTop: "5px" }}>
                BASIC DETAILS
              </IonListHeader>
              <IonItem key="name" lines="none" className="ion-padding-bottom">
                <IonLabel position="stacked" color="medium">
                  Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={category}
                  name="category"
                  rules={{
                    required: {
                      value: true,
                      message: "A category is required.",
                    },
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters long",
                    },
                    validate: (value) => {
                      if (!value.trim()) {
                        return "A non-blank category is required.";
                      }

                      return conversationOutcomesSettings.categoryExists(value)
                        ? "Category already exists."
                        : true;
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      disabled={!!category}
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                      //placeholder="Customer Reference"
                    />
                  )}
                />

                {showError("category")}
              </IonItem>
            </IonList>
          </form>
        </IonContent>
      </IonPage>
    );
  });

export default ConversationOutcomeDetail;
