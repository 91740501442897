import firebase from "firebase/app";

import { makeAutoObservable } from "mobx";
import { Session } from "../../session";

import { ConversationOutcome } from "./ConversationOutcome";
import { conversationOutcomeConverter } from "./ConversationOutcomeData";

export class ConversationOutcomes {
  private mSession: Session;
  private mCollectionRef: firebase.firestore.CollectionReference<ConversationOutcome>;
  private mQuery: firebase.firestore.Query<ConversationOutcome> | null;
  private mPath: string;
  private mData: ConversationOutcome[] | null | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;

    this.mCollectionRef = this.session.firebase
      .firestore()
      .collection(path)
      .withConverter(conversationOutcomeConverter(session));

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  public get session() {
    return this.mSession;
  }

  public get collectionRef() {
    return this.mCollectionRef;
  }

  public get query() {
    return this.mQuery;
  }
  public set query(newValue: firebase.firestore.Query<ConversationOutcome> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  public get path() {
    return this.mPath;
  }

  public get data() {
    if (this.mData) return this.mData.filter((item) => item.data?.isDeleted !== true ?? true);
    return this.mData;
  }
  protected set data(newValue: ConversationOutcome[] | null | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get()
        .then((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }
}