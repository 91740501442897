import firebase from "firebase/app";

import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
  IReactionDisposer,
  reaction,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";
import { Conversation } from "../Conversations";
import { WorkerProfile } from "../WorkerProfiles";
import { WorkItemData, workItemDataConverter, workItemDefaultValue } from "./WorkItemData";

const __cache: Record<string, WorkItem> = {};

export class WorkItem {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mExists: boolean | undefined;
  private mData: WorkItemData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mLastInternalMessageSender: WorkerProfile | null | undefined;
  private mLastNoteSender: WorkerProfile | null | undefined;
  private mLastNoteSenderDisposer: IReactionDisposer | null | undefined;
  private mConversation: Conversation | null | undefined;
  private mConversationDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: WorkItemData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mExists = undefined;
    this.mData = data ? data : (this.mId == null ? workItemDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(
      this,
      "lastInternalMessageSender",
      this.subscribeLastInternalMessageSender
    );
    onBecomeUnobserved(
      this,
      "lastInternalMessageSender",
      this.unsubscribeLastInternalMessageSender
    );
    onBecomeObserved(this, "lastNoteSender", this.subscribeLastNoteSender);
    onBecomeUnobserved(this, "lastNoteSender", this.unsubscribeLastNoteSender);
    onBecomeObserved(this, "conversation", this.subscribeConversation);
    onBecomeUnobserved(this, "conversation", this.unsubscribeConversation);
  }

  static createCacheInstance(session: Session, path: string, data?: WorkItemData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new WorkItem(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: WorkItem) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get exists() {
    return this.mExists;
  }
  private set exists(newValue: boolean | undefined) {
    this.mExists = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: WorkItemData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.exists === undefined;
  }

  public get loaded() {
    return this.exists !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(workItemDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = (snapshot.exists) ? snapshot.data() : undefined;
          this.data = data;
          this.exists = snapshot.exists;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(workItemDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<WorkItemData>) => {
          const data = (snapshot.exists) ? snapshot.data() : undefined;
          this.data = data;
          this.exists = snapshot.exists;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(workItemDataConverter(this.session))
            .add(this.data).then((doc) => {
              this.path = doc.path;
              WorkItem.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(workItemDataConverter(this.session))
            .set(this.data, { merge: true }).then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  public get hasUnreadInternalMessages(): boolean | null | undefined {
    if (this.data) return this.data.hasUnreadInternalMessages;
    return undefined;
  }

  public get hasUnreadNotes(): boolean | null | undefined {
    if (this.data) return this.data.hasUnreadNotes;
    return undefined;
  }

  public get hasUnreadMessages(): boolean | null | undefined {
    if (this.data) return this.data.unreadMessages;
    return undefined;
  }

  public get lastContactDate(): Date | null | undefined {
    if (this.data) return this.data.lastContactDate?.toDate() ?? null;
    return undefined;
  }

  public get lastInternalMessageReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastInternalMessageReadAt?.toDate() ?? null;
    return undefined;
  }
  public set lastInternalMessageReadAt(newValue: Date | null | undefined) {
    if (newValue && this.exists) {
      this.session.firebase
        .firestore()
        .doc(`${this.mPath}`)
        .update("lastInternalMessageReadAt", firebase.firestore.Timestamp.fromDate(newValue));
    }
  }

  public get lastInternalMessageReceivedAt(): Date | null | undefined {
    if (this.data) return this.data.lastInternalMessageReceivedAt?.toDate() ?? null;
    return undefined;
  }

  public get lastMessageDirection(): "in" | "out" | null | undefined {
    if (this.data) return this.data.lastMessageDirection;
    return undefined;
  }
  public get lastMessageReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastMessageReadAt?.toDate() ?? null;
    return undefined;
  }
  public set lastMessageReadAt(newValue: Date | null | undefined) {
    if (newValue && this.exists) {
      this.session.firebase
        .firestore()
        .doc(`${this.mPath}`)
        .update("lastMessageReadAt", firebase.firestore.Timestamp.fromDate(newValue));
    }
  }

  public get lastNoteReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastNoteReadAt?.toDate() ?? null;
    return undefined;
  }
  public set lastNoteReadAt(newValue: Date | null | undefined) {
    if (newValue && this.exists) {
      this.session.firebase
        .firestore()
        .doc(`${this.mPath}`)
        .update("lastNoteReadAt", firebase.firestore.Timestamp.fromDate(newValue));
    }
  }

  public get lastInternalMessageSenderId(): string | null | undefined {
    if (this.data) return this.data.lastInternalMessageSenderId;
    return undefined;
  }

  public get lastInternalMessageSender(): WorkerProfile | null | undefined {
    return this.mLastInternalMessageSender;
  }
  public subscribeLastInternalMessageSender = () => {
    when(
      () => this.lastInternalMessageSenderId !== undefined,
      () => {
        if (
          this.mLastInternalMessageSender === undefined &&
          this.lastInternalMessageSenderId !== undefined
        ) {
          runInAction(() => {
            this.mLastInternalMessageSender = this.lastInternalMessageSenderId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.lastInternalMessageSenderId}`
              )
              : null;
            this.mLastInternalMessageSender?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeLastInternalMessageSender = () => {
    this.mLastInternalMessageSender?.unsubscribe();
    this.mLastInternalMessageSender = undefined;
  };

  public get lastNoteSenderId(): string | null | undefined {
    if (this.data) return this.data.lastNoteSenderId;
    return undefined;
  }

  public get lastNoteSender(): WorkerProfile | null | undefined {
    return this.mLastNoteSender;
  }
  public subscribeLastNoteSender = async () => {
    when(
      () => this.lastNoteSenderId !== undefined,
      () => {
        const initLastNoteSender = () => {
          if (this.mLastNoteSender && this.mLastNoteSender.id != this.lastNoteSenderId) {
            this.unsubscribeLastNoteSender();
          }

          if (this.mLastNoteSender === undefined && this.lastNoteSenderId !== undefined) {
            this.mLastNoteSender = this.lastNoteSenderId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.lastNoteSenderId}`
              )
              : null;
            this.mLastNoteSender?.subscribe();

            this.mLastNoteSenderDisposer = reaction(
              () => this.lastNoteSenderId,
              (userId) => initLastNoteSender()
            );
          }
        };

        initLastNoteSender();
      }
    );
  };
  public unsubscribeLastNoteSender = () => {
    if (this.mLastNoteSenderDisposer) this.mLastNoteSenderDisposer();
    this.mLastNoteSender?.unsubscribe();
    this.mLastNoteSender = undefined;
  };

  public get conversationId(): string | null | undefined {
    if (this.data) return this.data.conversationId;
    return undefined;
  }

  public get conversation(): Conversation | null | undefined {
    return this.mConversation;
  }
  private set conversation(newValue: Conversation | null | undefined) {
    this.mConversation = newValue;
  }
  public subscribeConversation = () => {
    when(
      () => this.conversationId !== undefined,
      () => {
        const initConversation = () => {
          if (this.conversation && this.conversation.id != this.conversationId) {
            this.unsubscribeConversation();
          }

          if (this.conversation === undefined && this.conversationId !== undefined) {
            this.conversation = this.conversationId
              ? Conversation.createCacheInstance(
                this.session,
                `conversations/${this.conversationId}`
              )
              : null;
            this.conversation?.subscribe();

            this.mConversationDisposer = reaction(
              () => this.conversationId,
              (userId) => initConversation()
            );
          }
        };

        initConversation();
      }
    );
  };
  public unsubscribeConversation = () => {
    if (this.mConversationDisposer) this.mConversationDisposer();
    this.mConversation?.unsubscribe();
    this.mConversation = undefined;
  };

  public get status(): "active" | "inactive" | "new" | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  public markMessagesAsRead() {
    return new Promise<void>((resolve, reject) => {
      if (this.exists) {
        this.session.firebase
          .firestore()
          .doc(`${this.mPath}`)
          .update("unreadMessages", false)
          .then(() => resolve(undefined))
      } else resolve();
    });
  }

  public markInternalMessagesAsRead() {
    return new Promise<void>((resolve, reject) => {
      if (this.exists) {
        this.session.firebase
          .firestore()
          .doc(`${this.mPath}`)
          .update("hasUnreadInternalMessages", false)
          .then(() => resolve(undefined))
      } else resolve();
    });
  }

  public markNotesAsRead() {
    return new Promise<void>((resolve, reject) => {
      if (this.exists) {
        this.session.firebase
          .firestore()
          .doc(`${this.mPath}`)
          .update("hasUnreadNotes", false)
          .then(() => resolve(undefined))
      } else resolve();
    });
  }
}



