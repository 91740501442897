import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonNote,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";

import {
  close,
  personCircle,
  saveOutline,
  personOutline,
  checkmarkOutline,
} from "ionicons/icons";

import * as Models from "../../../models";

import { Controller, useForm } from "react-hook-form";
import { SessionContext } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";
import NewConversationButton from "../../Conversations/components/NewConversationButton";

interface CustomerButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
  buttonType: string;
}

const CustomerButton: React.FC<CustomerButtonProps> = observer(
  ({ parent, conversation, buttonType }) => {
    const [showModal, set_showModal] = useState(false);
    const [profile, setProfile] = useState<string | null>("engage");
    const [present, dismiss] = useIonActionSheet();

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    useEffect(() => {
      reset();
    }, [showModal, reset]);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && conversation && conversation.customer) {
        try {
          await conversation.customer.update({
            externalReference: data.customerReference,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            notes: data.notes,
          });
          reset();
          set_showModal(false);
        } catch (error) {
          alert("Unable to save customer information");
          console.error("Error updating customer information.", error);
        }
      }
    };

    const isOwner =
      conversation?.ownerId === session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status === "active";
    const workItemState = isActive
      ? conversation?.requestTransfer &&
        conversation?.requestTransfer.targetOwnerId ===
        session.currentUser?.workerProfileId
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !==
          session.currentUser?.workerProfileId &&
          isOwner
          ? "outgoing"
          : "assigned"
      : "completed";

    return (
      <>
        {buttonType === "display" && (
          <>
            <IonAvatar
              slot="start"
              style={{
                //zIndex: 1,
                //marginLeft: "-10px",
                //border: "1px solid #FFFFFF",
                height: "35px",
                width: "35px",
                minWidth: "35px",
                minHeight: "35px",
              }}
              onClick={() => set_showModal(true)}
            >
              {conversation?.customer?.avatar ? (
                <AnimatedImg
                  alt=""
                  src={conversation?.customer?.avatar ?? undefined}
                />
              ) : (
                <IonIcon
                  style={{
                    //border: "1px solid #FFFFFF",
                    height: "35px",
                    width: "35px",
                    minHeight: "35px",
                    minWidth: "35px",
                  }}
                  //className="ion-margin-end"
                  icon={personCircle}
                />
              )}
            </IonAvatar>

            <IonLabel slot="start" onClick={() => set_showModal(true)}>
              <h2>
                <b>
                  &nbsp;
                  {conversation?.customer?.displayName}
                </b>
              </h2>
            </IonLabel>
          </>
        )}

        {buttonType === "button" && (
          <IonButton
            slot="end"
            shape="round"
            expand="block"
            size="large"
            fill="solid"
            color="light"
            onClick={() => set_showModal(true)}
            disabled={conversation === undefined || conversation === null}
          >
            <IonIcon icon={personOutline} />
          </IonButton>
        )}
        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar
              color={workItemState === "completed" ? "tertiary" : "tertiary"}
            >
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    if (formState.isDirty) {
                      present({
                        buttons: [
                          {
                            text: "Discard Changes",
                            role: "destructive",
                            handler: () => set_showModal(false),
                          },
                          { text: "Keep Editing", role: "cancel" },
                        ],
                        header:
                          "Are you sure you want to discard your changes?",
                        mode: "ios",
                      });
                    } else {
                      set_showModal(false);
                    }
                  }}
                >
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Contact Info</IonTitle>

              <IonButtons slot="end">
                <IonButton
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    !formState.isValid ||
                    formState.isSubmitting ||
                    !formState.isDirty
                  }
                  shape="round"
                  expand="block"
                  size="large"
                  fill="solid"
                  color="light"
                >
                  <IonIcon hidden icon={checkmarkOutline} />
                  {formState.isSubmitting ? "Saving..." : "Save"}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent color="light">
            <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 18 }}>
              <IonList
                lines="none"
                //style={{ marginTop: "20px" }}
                className="ion-no-padding"
              >
                <IonItem color="light">
                  <IonGrid>
                    <IonRow style={{ paddingTop: "5px" }}>
                      <IonCol>
                        <IonAvatar
                          style={{
                            height: "100px",
                            width: "100px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {conversation?.customer?.avatar ? (
                            <AnimatedImg
                              alt=""
                              src={conversation?.customer?.avatar ?? undefined}
                            />
                          ) : (
                            <IonIcon
                              color="medium"
                              style={{
                                //border: "1px solid #FFFFFF",
                                height: "100px",
                                width: "100px",
                                minHeight: "100px",
                                minWidth: "100px",
                              }}
                              //className="ion-margin-end"
                              icon={personCircle}
                            />
                          )}
                        </IonAvatar>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{ paddingTop: "0px", paddingBottom: "20px" }}
                    >
                      <IonCol>
                        <IonLabel className="ion-text-center">
                          <h2>
                            <strong>
                              {conversation?.customer?.displayName}
                            </strong>
                          </h2>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>

                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "0px" }}
                ></IonListHeader>
                {conversation?.customer?.mobileNumber && (<>
                  <NewConversationButton session={session} displayAsItem defaults={{ phoneNumber: conversation?.customer?.mobileNumber }} onDismiss={() => set_showModal(false)} />
                  <IonItem color="light" style={{ paddingTop: "0px", height: "20px" }}>
                    <strong></strong>
                  </IonItem>
                </>
                )}

                <IonItem
                  lines="none"
                  key="customerReference"
                  style={{ marginTop: "5px" }}
                  className="ion-padding-bottom"
                >
                  <IonLabel position="stacked" color="medium">
                    Contact Reference
                  </IonLabel>
                  <Controller
                    control={control}
                    name="customerReference"
                    defaultValue={conversation?.customer?.externalReference}
                    //rules={{
                    //required: true,
                    //minLength: {
                    //  value: 2,
                    //  message: "Must be at least 2 characters long",
                    //  },
                    //}}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Customer Reference"
                      ></IonInput>
                    )}
                  />
                  {showError("customerReference")}
                </IonItem>
                <IonListHeader color="light" style={{ paddingTop: "0px" }}>
                  <strong></strong>
                </IonListHeader>
                <IonItem
                  lines="none"
                  style={{ marginTop: "0px" }}
                  key="firstName"
                >
                  <IonLabel position="stacked" color="medium">
                    First Name
                  </IonLabel>
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue={conversation?.customer?.firstName}
                    //rules={{
                    //required: true,
                    //minLength: {
                    //  value: 2,
                    //  message: "Must be at least 2 characters long",
                    //  },
                    //}}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder={
                          conversation?.customer?.providerProfileFirstName || ""
                        }
                      />
                    )}
                  />

                  {showError("firstName")}
                </IonItem>
                <IonItem lines="none" key="lastName">
                  <IonLabel position="stacked" color="medium">
                    Last Name
                  </IonLabel>
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue={conversation?.customer?.lastName}
                    //rules={{
                    //  required: true,
                    //  minLength: {
                    //    value: 2,
                    //    message: "Must be at least 2 characters long",
                    //  },
                    //}}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder={
                          conversation?.customer?.providerProfileLastName || ""
                        }
                      />
                    )}
                  />

                  {showError("lastName")}
                </IonItem>
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "0px" }}
                ></IonListHeader>
                <IonItem style={{ marginTop: "0px" }} key="email" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Email
                  </IonLabel>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue={conversation?.customer?.email}
                    //rules={{
                    //  required: true,
                    //  minLength: {
                    //    value: 2,
                    //    message: "Must be at least 2 characters long",
                    //  },
                    //}}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        type="email"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Last Name"
                      />
                    )}
                  />
                  {showError("email")}
                </IonItem>
                <IonItem key="mobile">
                  <IonLabel position="stacked" color="medium">
                    Mobile
                  </IonLabel>
                  <IonInput
                    className="input-field"
                    disabled
                    value={conversation?.customer?.mobileNumber}
                  ></IonInput>
                </IonItem>
                <IonItem key="facebook" className="ion-padding-bottom">
                  <IonLabel position="stacked" color="medium">
                    Facebook
                  </IonLabel>
                  <IonInput
                    className="input-field"
                    disabled
                    value={conversation?.customer?.facebookProfileId}
                  ></IonInput>
                </IonItem>
                <IonListHeader
                  color="light"
                  style={{ paddingTop: "0px" }}
                ></IonListHeader>
                <IonItem style={{ marginTop: "0px" }} key="notes" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Notes
                  </IonLabel>
                  <Controller
                    control={control}
                    name="notes"
                    defaultValue={conversation?.customer?.notes}
                    //rules={{
                    //  required: true,
                    //  minLength: {
                    //    value: 2,
                    //    message: "Must be at least 2 characters long",
                    //  },
                    //}}
                    render={({ onBlur, onChange, value }) => (
                      <IonTextarea
                        onIonChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        rows={10}
                      //placeholder="Last Name"
                      />
                    )}
                  />
                  {showError("email")}
                </IonItem>
              </IonList>
              <IonItem lines="none" color="light" key="submit">
                <IonButton
                  hidden
                  type="submit"
                  style={{ height: "36px", margin: "auto" }}
                  color="dark"
                  shape="round"
                  fill="solid"
                  disabled={
                    !formState.isValid ||
                    formState.isSubmitting ||
                    !formState.isDirty
                  }
                >
                  <IonIcon icon={saveOutline}></IonIcon>&nbsp;Save changes
                </IonButton>
              </IonItem>
            </form>
          </IonContent>
          <IonFooter className="ion-no-border" hidden>
            <IonToolbar color="secondary">
              <IonButtons slot="end" className="ion-margin-start">
                <IonButton
                  type="submit"
                  style={{ height: "36px" }}
                  color="light"
                  shape="round"
                  fill="solid"
                  disabled={
                    !formState.isValid ||
                    formState.isSubmitting ||
                    !formState.isDirty
                  }
                >
                  <IonIcon icon={saveOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </>
    );
  }
);

export default CustomerButton;
