import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";
import { isPlatform } from "@ionic/react";

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  useEffect(() => {
    if (isPlatform("capacitor")) {
      App.addListener("appUrlOpen", (data: any) => {
        // url: https://vrz.page.link/slug
        const slug = data.url.split(".link").pop();
        if (slug) {
          history.push(slug);
        }
      });
    }
  }, []);

  return null;
};

export default AppUrlListener;