import firebase from "firebase/app";
import { Session } from "../../session";

import { WorkerProfile } from "./WorkerProfile";

export interface WorkerProfileData {
    name: string | null;
    userId: string | null;
}

export const workerProfileDefaultValue = {
    name: null,
    userId: null,
} as WorkerProfileData;

export const workerProfileDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: WorkerProfileData) {
            return {
                name: data.name,
                userId: data.userId,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<WorkerProfileData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                name: data.name ?? null,
                userId: data.userId ?? null,
            } as WorkerProfileData;
        },
    }
};

export const workerProfileConverter = (session: Session) => {
    return {
        toFirestore: function (workerProfile: WorkerProfile) {
            return workerProfile.data ? {
                name: workerProfile.data.name,
                userId: workerProfile.data.userId,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<WorkerProfileData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return WorkerProfile.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    name: data.name ?? null,
                    userId: data.userId ?? null,
                }
            );
        },
    }
};