import React, { useContext } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";

import {
  IonChip,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonText,
} from "@ionic/react";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import { ConversationHistoryEntry } from "../../../models/ConversationHistoryEntries";
import {
  getConversationHistoryEntryIcon,
  relativeDateString,
} from "../../../utils/Helpers";

interface ConversationHistoryProps {
  conversation: Models.Conversation | null | undefined;
}

export const ConversationHistory: React.FC<ConversationHistoryProps> = observer(
  ({ conversation }) => {
    const session = useContext(SessionContext);

    if (!session || !session.formats) {
      return <></>;
    }

    const historyEntries = conversation?.conversationHistoryEntries?.data
      ?.slice()
      .reduce((groups: Record<string, ConversationHistoryEntry[]>, item) => {
        if (item.createdOn) {
          const dateString = moment(item.createdOn).format("YYYY-MM-DD");

          if (!groups[dateString]) {
            groups[dateString] = [];
          }

          groups[dateString].push(item);
        }

        return groups;
      }, {});

    return (
      <IonList lines="none">
        {historyEntries &&
          Object.keys(historyEntries)
            //.reverse()
            .map((key) => (
              <>

                <div
                  className="ion-padding-top"
                  style={{
                    //width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    //position: "sticky",
                    marginTop: "15px",
                    //zIndex: 999999,
                  }}
                >
                  <IonChip>
                    {relativeDateString(moment(key).toDate(), false)}
                  </IonChip>
                </div>
                {historyEntries[key].map((entry) => (
                  <IonItem
                    key={entry.id}
                    className="ion-align-items-center"
                    style={{ marginTop: "10px" }}
                  >
                    <IonText color="medium" slot="start">
                      <span
                        style={{
                          //textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        {entry.createdOn
                          ? moment(entry.createdOn).format("h:mm A")
                          : null}
                      </span>
                    </IonText>

                    <IonText color="dark" style={{ fontSize: "14px" }}>
                      <p>{entry.displayText}</p>
                      {entry.message && entry.message.data?.direction == "in" && <IonText color="medium" style={{ fontSize: "11px" }}><p>Received: {moment(entry.message.data?.receivedOn?.toDate()).format("h:mm A")}</p></IonText>}
                    </IonText>

                    <IonText color="dark" slot="end">
                      <span
                        style={{
                          //border: "1px solid var(--ion-color-dark)",
                          borderRadius: "20px",
                          width: "36px",
                          height: "30px",
                          //textAlign: "center",
                          fontSize: "23px",
                          marginLeft: "10px",
                          marginRight: "10px"
                        }}
                      >
                        {getConversationHistoryEntryIcon(entry.trigger ?? "")}
                      </span>
                    </IonText>
                  </IonItem>
                ))}
              </>
            ))}
      </IonList>
    );
  }
);
