import React from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonSkeletonText,
  IonText,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";

import * as Models from "../../../models";
import AnimatedImg from "../../../components/AnimatedImg";

interface UsersListItemProps {
  user: Models.User;
  onSelectItem: (item: Models.User) => void;
}

const UsersListItem: React.FC<UsersListItemProps> = observer(
  ({ user, onSelectItem }) => {
    const location = useLocation();

    if (user && user.loaded) {
      return (
        <IonItem
          //color={location.pathname == `/users/${user.id}` ? "light" : ""}
          //routerLink={`/users/${user.id}`}
          detail={false}
          onClick={() => onSelectItem(user)}
          button
        >
          <IonAvatar
            style={{ height: "50px", width: "50px" }}
            className="ion-margin-end"
          >
            {user?.avatar
              ? <AnimatedImg alt="" src={user?.avatar ?? undefined} />
              : undefined
            }
          </IonAvatar>
          <IonLabel>
            <h2>{user.fullName}</h2>
            <IonText color="medium">
              <p>{user.email}</p>
            </IonText>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
          </h2>
        </IonLabel>
      </IonItem>
    );
  }
);

export default UsersListItem;
