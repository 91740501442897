import firebase from "firebase/app";

import { Photo } from "@capacitor/camera";

import { Session } from "../../session";
import { FileAttachment } from "../common";

export const FileDatastore = {
    create: (session: Session, path: string, dataUrl: string, contentType: string) => {
        return new Promise((resolve: (path: string) => void, reject: (error: any) => void) => {
            if (dataUrl && contentType) {
                session.firebase
                    .storage()
                    .ref()
                    .child(path)
                    .putString(dataUrl, "data_url", {
                        contentType: contentType,
                    })
                    .then(() => resolve(path))
                    .catch((error) => reject(error));
            } else {
                reject("no data specified")
            }
        });
    },
}