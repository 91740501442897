import React from "react";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
} from "@ionic/react";

import {
  handRight
} from "ionicons/icons";

interface UnauthenticatedPageProps {
}

const UnauthenticatedPage: React.FC<UnauthenticatedPageProps> = observer(
  () => {
    return (
      <IonPage>
        <IonContent fullscreen={true}>
          <IonCard>
            <IonCardHeader>
              <IonItem>
                <IonAvatar slot="start">
                  <img src={handRight} alt="Stop" />
                </IonAvatar>
                <IonLabel>
                  Unauthorized
                </IonLabel>
              </IonItem>
            </IonCardHeader>
            <IonCardContent>
              Oops, looks like you've ended up where you should not have.
              <br />
              Follow <IonRouterLink href="/" routerDirection="back">this link</IonRouterLink> to safety.
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
);

export default UnauthenticatedPage;
