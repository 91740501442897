import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItemDivider,
  IonList,
} from "@ionic/react";

import { close, chatboxEllipses, readerOutline } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";

interface StandardResponseButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
  onSelectStandardResponse: (standardResponse: Models.StandardResponse) => void;
}

const StandardResponseButton: React.FC<StandardResponseButtonProps> = observer(
  ({ parent, conversation, onSelectStandardResponse }) => {
    const [showModal, set_showModal] = useState(false);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }
    
    const responses = session.standardResponses?.data?.reduce(
      (groups: Record<string, Models.StandardResponse[]>, item) => {
        if (item.category) {
          const groupName = item.category;

          if (!groups[groupName]) {
            groups[groupName] = [];
          }

          groups[groupName].push(item);
        }

        return groups;
      },
      {}
    );

    return (
      <>
        <IonButton
          style={{ position: "absolute", bottom: "19px", right: "4px", borderRadius: "5px" }}
          fill="clear"
          color="dark"
          shape="round"
          size="small"
          onClick={() => set_showModal(true)}
          //disabled={conversation === undefined || conversation === null}
          hidden={
            !(
              conversation?.status == "active" &&
              conversation.ownerId == session.currentUser?.workerProfileId
            )
          }
        >
          <IonIcon icon={"/icons/chatbox-quote-outline.svg"} />
        </IonButton>

        <IonModal animated isOpen={showModal} swipeToClose onDidDismiss={(e) => set_showModal(false)}>
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Standard Responses</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList lines="full" className="ion-no-padding">
              {responses &&
                Object.keys(responses).map((key) => (
                  <div key={key}>
                    <IonItemDivider mode="ios" color="light" sticky>
                      {key}
                    </IonItemDivider>
                    {responses[key].map((item) => (
                      <IonItem
                        button
                        key={item.id}
                        onClick={() => {
                          onSelectStandardResponse(item);
                          set_showModal(false);
                        }}
                      >
                        <IonLabel>
                          <h2>{item.name}</h2>
                          <p>{item.text}</p>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </div>
                ))}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default StandardResponseButton;
