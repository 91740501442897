import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";

import {
  close,
  closeOutline,
  checkmarkOutline,
  trash,
  peopleCircle,
  peopleCircleOutline,
  remove,
  personRemove,
  add
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AddUserButton from "./AddUserButton";
import moment from "moment";
import AnimatedImg from "../../../components/AnimatedImg";
import { Button } from "../../../components/Button";

interface ToggleChangeEventDetail {
  value: boolean;
  checked: boolean;
}
interface GroupDetailProps {
  session: Session;
  segment: string;
  group: Models.Group;
  onDismiss: () => void;
}
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GroupDetail_segment_users: React.FC<GroupDetailProps> = observer(
  ({ session, segment, group, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const { control, formState, errors, handleSubmit, watch } = useForm({
      mode: "onChange",
    });

    const [present, dismiss] = useIonActionSheet();

    const users = group?.users?.data
      ? group?.users?.data
        .slice()
        .sort((a, b) =>
          (a.workerProfile?.name ?? "") > (b.workerProfile?.name ?? "")
            ? 1
            : -1
        )
      : null;
    const usersCount = users?.length ?? 0;

    const phoneNumbers = session.phoneNumbers?.data;

    /// Handlers ---
    const onDeleteGroup = () => {
      // Validation
      if (!session.currentUser) {
        console.warn('You need to be logged in to perform this action.');
        return;
      }

      if (session.currentUser?.role !== "admin") {
        console.warn('Logged in group does not have the required permissions to perform this action.');
        return;
      }

      // Perform action
      group
        .delete()
        .then(() => onDismiss())
        .catch(error => console.error('Error deleting group.', error));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && group) {
        const acceptPeriod = toTimeSeconds(data.conversationAcceptPeriodSeconds);
        const replyPeriod = toTimeSeconds(data.messageResponsePeriodSeconds);

        group.name = data.name.trim();
        group.phoneNumberId = data.phoneNumberId;
        group.greetingMessage = data.greetingMessage;
        group.noAgentAvailableMessage = data.noAgentAvailableMessage;
        group.slowConversationAcceptMessage = data.slowConversationAcceptMessage;
        group.conversationAcceptPeriodSeconds = acceptPeriod;
        group.messageResponsePeriodSeconds = replyPeriod;

        try {
          await group.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save group.");
          console.log("Error saving group.", error);
        }
      }
    };
    /// --- handlers

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const toTimeSeconds = (timeString: string) => {
      if (!isNaN(timeString as any)) {
        return parseInt(timeString);
      }

      const parts = (timeString || "0:0:0")
        .split(":")
        .map(part => parseInt(part));
      return (parts[0] * 60 * 60) + (parts[1] * 60) + parts[2];
    }

    const toTimeString = (totalSeconds: number) => {
      const hours = Math.floor(totalSeconds / (60 * 60));
      totalSeconds -= (hours * 60 * 60);

      const minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= (minutes * 60);

      return moment(`${hours}:${minutes}:${totalSeconds}`, "h:m:s").format("HH:mm:ss");
    }

    return (
      <IonContent hidden={segment !== "users"} fullscreen color="light">
        {group ? (
          <>
            {group.id && (
              <IonList lines="full" class="ion-no-margin ion-no-padding">
                <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                  <IonLabel><strong>USERS</strong></IonLabel>
                  <IonButtons>
                    <AddUserButton session={session} group={group} />
                  </IonButtons>
                </IonListHeader>
                {users && users.length > 0
                  ? users.map((user) => (
                    <IonItem key={user.id}>
                      <IonAvatar
                        style={{ height: "40px", width: "40px" }}
                        className="ion-margin-end"
                      >
                        {user?.avatar
                          ? <AnimatedImg alt="" src={user.workerProfile?.avatar ?? undefined} />
                          : undefined
                        }
                      </IonAvatar>
                      <IonLabel>
                        <span>
                          {user.workerProfile?.name ?? "Loading..."}
                        </span>
                      </IonLabel>

                      {user.userGroups?.loaded && user.userGroups.data
                        ? (
                          <IonChip
                            color="dark"
                            onClick={() => {
                              present({
                                buttons: [
                                  {
                                    text: "Group Admin",
                                    handler: async () => { await user.updateGroup(group.id!, "admin") },
                                  },
                                  {
                                    text: "User",
                                    handler: async () => { await user.updateGroup(group.id!, "user") },
                                  },
                                  { text: "Cancel", role: "cancel" },
                                ],
                                header: `Change role of ${user.fullName} to`,
                                mode: "ios",
                              });
                            }}
                          >
                            {user.userGroups.data.find(e => e.id === group.id)?.role === "admin" ? "Group Admin" : "User"}
                          </IonChip>
                        ) : undefined
                      }
                      <Button
                        hidden={
                          !(
                            session.currentUser?.role === "admin"
                          )
                        }
                        shape="round"
                        fill="solid"
                        color="dark"
                        onClick={() =>
                          user.removeGroup(group!.id!)
                        }
                        style={{ height: "25px" }}
                      >
                        <IonIcon icon={remove} />
                      </Button>
                    </IonItem>
                  )) : (
                    <IonItem><IonLabel className="ion-text-center" color="medium">No users added.</IonLabel></IonItem>
                  )}
              </IonList>
            )}
          </>
        ) : (
          <div>Group not found</div>
        )}
      </IonContent>
    );
    /// --- render
  }
);
