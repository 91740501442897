import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";

import CustomerListItem from "./components/CustomerListItem";
import { SessionContext } from "../../session";
import { createSwipeBackGesture } from "../../utils/gestures/swipeBackGesture";

const CustomersPage: React.FC = observer(() => {
  const session = useContext(SessionContext);
  const pageRef = useRef<HTMLElement>()

  useEffect(() => {
    if (pageRef.current) {
      const swipeBackGesture = createSwipeBackGesture(
        pageRef.current,
        () => { return true; }, // canStartHandler
        () => { }, // onStartHandler
        (step: number) => { }, // onMoveHandler
        (shouldComplete: boolean, step: number, dur: number) => { } // onEndHandler
      );
      swipeBackGesture.enable();
    }
  }, [pageRef.current])

  if (!session) {
    return <></>;
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar
        //color="primary"
        >
          <IonTitle>Customers</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Customers</IonTitle>
          </IonToolbar>
        </IonHeader>*/}

        <IonProgressBar
          hidden={!session.customers?.loading}
          type="indeterminate"
        />

        <IonList hidden={!session.customers?.loaded}>
          {session.customers?.loaded &&
            session.customers?.data?.map((item) => (
              <CustomerListItem key={item.id} customer={item} />
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default CustomersPage;
