import React, { useContext, useEffect, useRef } from "react";
import {
  IonIcon,
  IonItem,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonListHeader,
  IonFooter,
  IonText,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useLocation, useRouteMatch } from "react-router";

import {
  chatbubblesOutline,
  checkmarkCircleOutline,
  download,
  logoFacebook,
  peopleOutline,
  peopleCircleOutline,
  keypadOutline,
} from "ionicons/icons";

import { SessionContext } from "../../session";
import { createSwipeBackGesture } from "../../utils/gestures/swipeBackGesture";

const AdminPage: React.FC = observer(() => {
  const location = useLocation();
  const match = useRouteMatch();

  const pageRef = useRef<HTMLElement>()

  useEffect(() => {
    if (pageRef.current) {
      const swipeBackGesture = createSwipeBackGesture(
        pageRef.current,
        () => { return true; }, // canStartHandler
        () => { }, // onStartHandler
        (step: number) => { }, // onMoveHandler
        (shouldComplete: boolean, step: number, dur: number) => { } // onEndHandler
      );
      swipeBackGesture.enable();
    }
  }, [pageRef.current])

  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {(session.windowSize === "small") ? (
              <></>
            ) : (
              <IonMenuButton hidden menu="start" autoHide={false} />
            )}
          </IonButtons>
          <IonTitle>Admin</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <IonList lines="none" className="ion-no-padding">
          <IonListHeader color="light" style={{ paddingTop: "5px" }}>
            <strong>GENERAL</strong>
          </IonListHeader>
          <IonItem
            color={
              location.pathname === `${match.path}/settings` ? "primary" : ""
            }
            routerLink={`${match.path}/settings`}
          >
            <IonLabel>Conversations</IonLabel>
            <IonIcon slot="start" icon={chatbubblesOutline} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonItem
            color={location.pathname === `${match.path}/users` ? "primary" : ""}
            routerLink={`${match.path}/users`}
          >
            <IonLabel>Users</IonLabel>
            <IonIcon slot="start" icon={peopleOutline} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonItem
            color={location.pathname === `${match.path}/groups` ? "primary" : ""}
            routerLink={`${match.path}/groups`}
          >
            <IonLabel>Groups</IonLabel>
            <IonIcon slot="start" icon={"/icons/groups-outline.svg"} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          
          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong>CONVERSATION OUTCOMES</strong>
          </IonListHeader>
          <IonItem
            color={
              location.pathname === `${match.path}/outcomes` ? "primary" : ""
            }
            routerLink={`${match.path}/outcomes`}
          >
            <IonLabel>Outcomes</IonLabel>
            <IonIcon slot="start" icon={checkmarkCircleOutline} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonItem
            color={
              location.pathname === `${match.path}/outcomecategories`
                ? "primary"
                : ""
            }
            routerLink={`${match.path}/outcomecategories`}
          >
            <IonLabel>Outcome Categories</IonLabel>
            <IonIcon slot="start" icon={undefined} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong>STANDARD RESPONSES</strong>
          </IonListHeader>
          <IonItem
            color={
              location.pathname === `${match.path}/responses` ? "primary" : ""
            }
            routerLink={`${match.path}/responses`}
          >
            <IonLabel>Responses</IonLabel>
            <IonIcon slot="start" icon={"/icons/chatbox-quote-outline.svg"} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonItem
            color={
              location.pathname === `${match.path}/responsecategories`
                ? "primary"
                : ""
            }
            routerLink={`${match.path}/responsecategories`}
          >
            <IonLabel>Response Categories</IonLabel>
            <IonIcon slot="start" icon={undefined} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong>CONTACT DETAILS</strong>
          </IonListHeader>
          <IonItem
            color={location.pathname === `${match.path}/channels/phoneNumbers` ? "primary" : ""}
            routerLink={`${match.path}/channels/phoneNumbers`}
          >
            <IonLabel>Phone Numbers</IonLabel>
            <IonIcon slot="start" icon={keypadOutline} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonItem
            color={
              location.pathname === `${match.path}/channels/facebookPages`
                ? "primary"
                : ""
            }
            routerLink={`${match.path}/channels/facebookPages`}
          >
            <IonLabel>Facebook Pages</IonLabel>
            <IonIcon slot="start" icon={logoFacebook} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong>EXPORT</strong>
          </IonListHeader>
          <IonItem
            color={
              location.pathname === `${match.path}/exportCompletedConversations`
                ? "primary"
                : ""
            }
            routerLink={`${match.path}/exportCompletedConversations`}
          >
            <IonLabel>Completed Conversations</IonLabel>
            <IonIcon slot="start" icon={download} className="ion-no-margin" style={{ marginRight: "12px" }} />
          </IonItem>
          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong></strong>
          </IonListHeader>
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar color="light">
          <div className="ion-text-start ion-padding">
            <IonLabel>
              <IonText style={{ fontSize: "1.0em" }} color="dark"><strong>VoyagerNetz Engage</strong> Pleiades</IonText><br />
              <div style={{ paddingTop: "3px" }} />
              <IonText style={{ fontSize: "0.8em" }} color="dark">{session.instanceVersionDisplayText}</IonText><br />
              <IonText style={{ fontSize: "0.6em" }} color="medium">Build {session.instanceVersion}</IonText>
            </IonLabel>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
});

export default AdminPage;
