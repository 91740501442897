import React from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";

import { ConversationOutcome } from "../../../models/ConversationOutcomes";

interface ConversationOutcomesListItemProps {
  conversationOutcome: ConversationOutcome;
  onSelectItem: (item: ConversationOutcome) => void;
}

const ConversationOutcomesListItem: React.FC<ConversationOutcomesListItemProps> =
  observer(({ conversationOutcome, onSelectItem }) => {
    const location = useLocation();

    if (conversationOutcome && conversationOutcome.loaded) {
      return (
        <IonItem
          onClick={() => onSelectItem(conversationOutcome)}
          detail={false}
          button
        >
          <IonLabel>
            <h2>{conversationOutcome.name}</h2>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
          </h2>
        </IonLabel>
      </IonItem>
    );
  });

export default ConversationOutcomesListItem;
