import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonSearchbar,
  isPlatform,
  useIonModal,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";

import { add, arrowBack } from "ionicons/icons";

import GroupsListItem from "./components/GroupsListItem";
import GroupDetail from "./components/GroupDetail";

import * as Models from "../../models";
import { SessionContext } from "../../session";

const GroupsPage: React.FC = observer(() => {
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState<Models.Group>();

  const handleDismiss = () => {
    dismiss();
  };

  const session = useContext(SessionContext);

  const [present, dismiss] = useIonModal(GroupDetail, {
    session: session,
    group: selectedItem,
    onDismiss: handleDismiss,
  });

  if (!session) {
    return <></>;
  }

  const groups = session.groups?.data?.filter(
    (group) =>
      ((group.name ?? "").trim() !== "" &&
        (group.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())) ||
      ((group.name ?? "").trim() !== "" &&
        (group.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase()))
  );

  return (
    <IonPage>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {session.windowSize === "small" ? (
              <>
                {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                <IonButton routerLink="/admin">
                  <IonIcon icon={arrowBack} />
                </IonButton>
              </>
            ) : (
              <></>
            )}
          </IonButtons>
          <IonTitle>Groups</IonTitle>
          <IonButtons slot="end">
            <IonButton
              shape="round"
              expand="block"
              size="large"
              fill="solid"
              color="light"
              onClick={() => {
                setSelectedItem(new Models.Group(session, "groupProfiles"));
                present({ cssClass: "modal-create-view" });
              }}
            >
              <IonIcon icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonProgressBar hidden={!session.groups?.loading} type="indeterminate" />
        <IonSearchbar
          color="light"
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        <IonList>
          {groups && groups.length > 0
            ? groups.map((item) => (
                <GroupsListItem
                  key={item.id}
                  group={item}
                  onSelectItem={(item) => {
                    setSelectedItem(item);
                    present({ cssClass: "modal-edit-view" });
                  }}
                />
              ))
            : (
                <IonItem style={{}} lines="none">
                  <IonLabel className="ion-text-center">
                    <IonImg
                      style={{
                        maxWidth: "250px",
                        margin: "auto",
                        marginTop: "69px",
                      }}
                      src={
                        (searchText ?? "") === ""
                          ? "/assets/images/artboard005.png"
                          : "/assets/images/artboard008.png"
                      }
                    />
                    <p>
                      <strong>
                        <IonText
                          color="dark"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          {(searchText ?? "") === ""
                            ? ""
                            : " No Search Results"}
                        </IonText>
                      </strong>
                    </p>
                    <p
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <IonText
                        color="medium"
                        style={{
                          fontSize: "16px",
                        }}
                      ></IonText>
                    </p>
                  </IonLabel>
                </IonItem>
              )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default GroupsPage;
