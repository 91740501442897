import React, { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonItem,
  IonLabel,
  IonAlert,
  IonImg,
  IonText,
  IonSpinner,
  IonButtons,
} from "@ionic/react";

interface InstancePageProps {
  onSelectInstance: (instanceId: string) => Promise<void>;
}

const InstancePage: React.FC<InstancePageProps> = observer(
  ({ onSelectInstance }) => {
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isBuys, setIsBusy] = useState(false);

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    const onSubmit = async (data: any) => {
      try {
        setIsBusy(true);

        await onSelectInstance(data.instanceId);
      } catch (error) {
        reset();
      }
    };
    return (
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar color="">
            <IonButtons slot="start"></IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonToast
            color="medium"
            isOpen={showErrorToast}
            onDidDismiss={() => setShowErrorToast(false)}
            message={errorMessage}
            duration={2000}
          />

          <IonImg
            src="/apple-touch-icon.png"
            style={{
              minWidth: "80px",
              maxWidth: "80px",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          />
          <IonTitle style={{ textAlign: "center" }}>VoyagerNetz Engage</IonTitle>
          <p style={{ textAlign: "center", marginLeft: "30px", marginRight: "30px" }}>Please enter your instance name.</p>
          <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 5 }}>
            <IonGrid fixed style={{ maxWidth: "340px" }}>
              <IonRow>
                <IonCol>
                  <IonItem
                    lines="none"
                    style={{
                      border: "1px solid var(--ion-color-light)",
                      borderRadius: "10px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="instanceId"
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ onBlur, onChange, value }) => (
                        <IonInput
                          onIonInput={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="instance"
                        />
                      )}
                    />
                    <IonText color="medium">.voyagernetz.us</IonText>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol>
                  <IonButton
                    color="primary"
                    shape="round"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!formState.isValid || formState.isSubmitting}
                    expand="block"
                  >
                    {formState.isSubmitting ? <IonSpinner /> : "Next"}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonPage>
    );
  }
);

export default InstancePage;
