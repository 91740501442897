import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonFooter,
  IonTextarea,
} from "@ionic/react";

import { close, swapHorizontalOutline } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import { Button } from "../../../components/Button";

interface TransferConversationButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
  workerProfile: Models.WorkerProfile | null | undefined;
}

const TransferConversationButton: React.FC<TransferConversationButtonProps> =
  observer(({ parent, conversation, workerProfile }) => {
    const [showModal, set_showModal] = useState(false);
    const [reason, setReason] = useState("");

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    return (
      <>
        <IonButton
          hidden={
            !(
              conversation?.status === "active"
              && (
                conversation.ownerId === session.currentUser?.workerProfileId
                || session.currentUser?.role === "admin"
              )
              && conversation.ownerId !== workerProfile?.id
              && conversation?.transferToWorkerProfileId !== workerProfile?.id
            )
          }
          //slot="end"
          shape="round"
          fill="solid"
          color="dark"
          onClick={() => {
            setReason("");
            set_showModal(true);
          }}
          disabled={
            conversation === undefined
            || conversation === null
            || conversation?.transferToWorkerProfileId === workerProfile?.id
          }
          style={{ height: "30px", width: "50px" }}
        >
          <IonIcon icon={swapHorizontalOutline} />
        </IonButton>

        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={(e) => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>
                Transfer Conversation to {workerProfile?.name}
              </IonTitle>

              <IonButtons slot="end"></IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonTextarea
              spellcheck={true}
              autocapitalize="on"
              style={{ height: "100%" }}
              placeholder="Enter reason for transfer here..."
              value={reason}
              rows={100}
              onIonChange={(e) => {
                setReason(e.detail.value!);
              }}
            ></IonTextarea>
          </IonContent>
          <IonFooter>
            <IonToolbar color="secondary">
              <IonButtons slot="end">
                <Button
                  shape="round"
                  expand="block"
                  size="large"
                  fill="solid"
                  color="light"
                  onClick={() =>
                    conversation
                      ?.transferToParticipant(workerProfile?.id!, reason)
                      .then(() => set_showModal(false))
                  }
                >
                  <IonIcon icon={swapHorizontalOutline} slot="start" />
                  Transfer
                </Button>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </>
    );
  });

export default TransferConversationButton;
