import firebase from "firebase/app";
import { Session } from "../../session";

import { ConversationHistoryEntry } from "./ConversationHistoryEntry";

export interface ConversationHistoryEntryItem {
  ref: any | null;
  text: string | null;
};

export interface ConversationHistoryEntryArgs {
  actor: {
    active: ConversationHistoryEntryItem | null;
    passive: ConversationHistoryEntryItem | null;  
  }
  conversation: ConversationHistoryEntryItem | null;
  message: ConversationHistoryEntryItem | null;
  outcome: ConversationHistoryEntryItem | null;
  transfer: ConversationHistoryEntryItem | null;
}

export interface ConversationHistoryEntryData {
  createdOn: firebase.firestore.Timestamp | null;
  trigger: string | null;
  data: ConversationHistoryEntryArgs
};

export const conversationHistoryEntryDefaultValue = {
  createdOn: null,
  trigger: null,
  data: {
    actor: {
      active: null,
      passive: null
    },
    conversation: null,
    message: null,
    outcome: null,
    transfer: null
  } as ConversationHistoryEntryArgs
} as ConversationHistoryEntryData;

export const conversationHistoryEntryDataConverter = (session: Session) => ({
  toFirestore: (data: ConversationHistoryEntryData) => ({
    createdOn: data.createdOn,
    trigger: data.trigger,
    data: {
      actor: {
        active: data.data.actor.active,
        passive: data.data.actor.passive
      },
      conversation: data.data.conversation,
      message: data.data.message,
      outcome: data.data.outcome,
      transfer: data.data.transfer
    }
  }),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationHistoryEntryData>,
    options: firebase.firestore.SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    return {
      createdOn: data.createdOn ?? null,
      trigger: data.trigger ?? null,
      data: {
        actor: {
          active: data.data.actor.active ?? null,
          passive: data.data.actor.passive ?? null,
        },
        conversation: data.data.conversation ?? null,
        message: data.data.message ?? null,
        outcome: data.data.outcome ?? null,
        transfer: data.data.transfer ?? null,
      }
    } as ConversationHistoryEntryData;
  }
});

export const conversationHistoryEntryConverter = (session: Session) => ({
  toFirestore: (conversationHistoryEntry: ConversationHistoryEntry) => (
    conversationHistoryEntry.data ? {
      createdOn: conversationHistoryEntry.data.createdOn,
      trigger: conversationHistoryEntry.data.trigger,
      data: {
        actor: {
          active: conversationHistoryEntry.data.data.actor.active,
          passive: conversationHistoryEntry.data.data.actor.passive
        },
        conversation: conversationHistoryEntry.data.data.conversation,
        message: conversationHistoryEntry.data.data.message,
        outcome: conversationHistoryEntry.data.data.outcome,
        transfer: conversationHistoryEntry.data.data.transfer,
      }
    } : {}
  ),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationHistoryEntryData>,
    options: firebase.firestore.SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    return ConversationHistoryEntry.createCacheInstance(
      session,
      snapshot.ref.path, 
      {
        createdOn: data.createdOn ?? null,
        trigger: data.trigger ?? null,
        data: {
          actor: {
            active: data.data.actor.active ?? null,
            passive: data.data.actor.passive ?? null,
          },
          conversation: data.data.conversation ?? null,
          message: data.data.message ?? null,
          outcome: data.data.outcome ?? null,
          transfer: data.data.transfer ?? null,
        }
      } as ConversationHistoryEntryData
    )
  }
});