import firebase from "firebase/app";
import { Session } from "../../session";

import { PhoneNumber } from "./PhoneNumber";

export interface PhoneNumberData {
    name: string | null;
    phone: string | null;
    groupId: string | null;
};

export const phoneNumberDefaultValue = {
    name: null,
    phone: null,
} as PhoneNumberData;

export const phoneNumberDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: PhoneNumberData) {
            return {
                name: data.name,
                phone: data.phone,
                groupId: data.groupId,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<PhoneNumberData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                name: data.name ?? null,
                phone: data.phone ?? null,
                groupId: data.groupId ?? null,
            } as PhoneNumberData;
        },
    };
};

export const phoneNumberConverter = (session: Session) => {
    return {
        toFirestore: function (phoneNumber: PhoneNumber) {
            return phoneNumber.data ? {
                name: phoneNumber.data.name,
                phone: phoneNumber.data.phone,
                groupId: phoneNumber.data.groupId,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<PhoneNumberData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return PhoneNumber.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    name: data.name ?? null,
                    phone: data.phone ?? null,
                    groupId: data.groupId ?? null,
                }
            );
        },
    };
};
