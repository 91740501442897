import React, { useContext, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonText,
  IonChip,
  IonAvatar,
} from "@ionic/react";
import {
  close,
  people,
  closeOutline,
  checkmarkOutline,
  personRemove,
  swapHorizontalOutline,
} from "ionicons/icons";

import AddParticipantButton from "./AddParticipantButton";
import TransferConversationButton from "./TransferConversationButton";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";
import { Button } from "../../../components/Button";

interface ParticipantsButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const ParticipantsButton: React.FC<ParticipantsButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);
    const modalRef = useRef<HTMLIonModalElement | null>(null);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const owner = conversation?.owner;
    const group = conversation?.group;
    const participants = conversation?.participants?.data
      ? conversation?.participants?.data
        .slice()
        .sort((a, b) =>
          (a.workerProfile?.name ?? "") > (b.workerProfile?.name ?? "")
            ? 1
            : -1
        )
      : null;
    const participantsCount = participants?.length ?? 0;

    return (
      <>
        <IonChip
          slot="end"
          dir="rtl"
          onClick={() => set_showModal(true)}
          disabled={conversation === undefined || conversation === null}
          style={{ height: "38px" }}
        >
          <IonLabel color="light">
            <b>{participantsCount}</b>&nbsp;
          </IonLabel>

          {participants
            ?.filter(
              (participant) =>
                participant.workerProfileId !== conversation?.ownerId
            )
            .slice(0, 4)
            .map((participant) => (
              <IonAvatar
                key={participant.id}
                //slot="end"
                style={{
                  zIndex: "-1",
                  marginLeft: "-5px",
                  border: "1px solid white",
                  height: "20px",
                  width: "20px",
                }}
              >
                {participant.workerProfile?.avatar
                  ? <AnimatedImg alt="" src={participant.workerProfile?.avatar ?? undefined} />
                  : undefined
                }
              </IonAvatar>
            ))}

          <IonAvatar
            //slot="end"
            style={{
              border: "1px solid #FFFFFF",
              height: "26px",
              width: "26px",
            }}
          //className="ion-margin-end"
          >
            {owner?.avatar
              ? <AnimatedImg alt="" src={owner?.avatar ?? undefined} />
              : undefined
            }
          </IonAvatar>
          <IonAvatar
            className="border-radius-0"
            style={{
              border: "1px solid #FFFFFF",
              height: "26px",
              width: "26px",
              marginLeft: "5px"
            }}
          //className="ion-margin-end"
          >
            {group?.avatar
              ? <AnimatedImg alt="" src={group?.avatar ?? undefined} />
              : undefined
            }
          </IonAvatar>
        </IonChip>

        <IonButton
          hidden
          shape="round"
          //expand="block"
          //size="large"
          fill="solid"
          color="light"
          onClick={() => set_showModal(true)}
          disabled={conversation === undefined || conversation === null}
        >
          <IonIcon icon={people} />
          <IonText hidden={participants?.length === 1}>
            &nbsp;{participants?.length}
          </IonText>
        </IonButton>

        <IonModal ref={modalRef} animated isOpen={showModal} swipeToClose onDidDismiss={(e) => set_showModal(false)}>
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Participants</IonTitle>

              <IonButtons slot="end">
                <AddParticipantButton
                  parent={modalRef.current}
                  conversation={conversation}
                />
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList lines="full">
              {participants &&
                participants.map((participant) => (
                  <IonItem key={participant.id}>
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end"
                    >
                      {participant.workerProfile?.avatar
                        ? <AnimatedImg alt="" src={participant.workerProfile?.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>
                      <span>
                        {participant.workerProfile?.name ?? "Loading..."}
                        {participant.workerProfile?.id === conversation?.ownerId
                          ? (
                            <IonText color="medium"> - Owner</IonText>
                          ) : participant.workerProfile?.id === conversation?.transferToWorkerProfileId
                            ? (
                              <IonText color="medium">
                                {" "} - Pending transfer request
                              </IonText>
                            ) : (
                              <></>
                            )
                        }
                      </span>
                    </IonLabel>

                    <TransferConversationButton
                      parent={modalRef.current}
                      conversation={conversation}
                      workerProfile={participant.workerProfile}
                    />

                    <IonChip
                      hidden={
                        !(
                          conversation?.status === "active" &&
                          conversation?.requestTransfer &&
                          participant.workerProfile?.id === conversation?.requestTransfer?.targetOwnerId &&
                          (
                            conversation?.requestTransfer?.targetOwnerId === session.currentUser?.workerProfileId
                            || conversation.ownerId === session.currentUser?.workerProfileId
                            || session.currentUser?.role === "admin"
                          )
                        )
                      }
                      color="dark"
                      style={{ height: "40px" }}
                    >
                      <IonIcon icon={swapHorizontalOutline} title="Transfer" />
                      <Button
                        hidden={
                          !(
                            conversation
                            && conversation.requestTransfer
                            && session.currentUser
                            && participant.workerProfile
                            && conversation.status === "active"
                            && participant.workerProfile.id === session.currentUser?.workerProfileId
                            && conversation.requestTransfer.targetOwnerId === session.currentUser.workerProfileId
                          )
                        }
                        onClick={(_) => conversation?.declineIncomingTransfer()}
                        color="danger"
                        fill="solid"
                        shape="round"
                      >
                        <IonIcon icon={closeOutline} title="Decline" />
                      </Button>
                      <Button
                        hidden={
                          !(
                            conversation
                            && conversation.requestTransfer
                            && session.currentUser
                            && participant.workerProfile
                            && conversation.status === "active"
                            && participant.workerProfile.id === session.currentUser?.workerProfileId
                            && conversation.requestTransfer.targetOwnerId === session.currentUser.workerProfileId
                          )
                        }
                        shape="round"
                        fill="solid"
                        color="success"
                        onClick={() => conversation?.acceptIncomingTransfer()}
                      >
                        <IonIcon icon={checkmarkOutline} title="Accept" />
                      </Button>
                      <Button
                        hidden={
                          !(
                            participant.workerProfile?.id !== session.currentUser?.workerProfileId &&
                            (
                              conversation?.ownerId === session.currentUser?.workerProfileId
                              || session.currentUser?.role === "admin"
                            )
                          )
                        }
                        shape="round"
                        fill="solid"
                        color="danger"
                        onClick={() => conversation?.cancelTransfer()}
                      >
                        <IonIcon icon={closeOutline} title="Cancel" />
                      </Button>
                    </IonChip>

                    <Button
                      hidden={
                        !(
                          conversation?.status === "active" &&
                          (
                            conversation?.ownerId === session.currentUser?.workerProfileId
                            || session.currentUser?.role === "admin"
                          )
                          && conversation?.ownerId !== participant.workerProfile?.id
                          && conversation?.requestTransfer?.targetOwnerId !== participant.workerProfile?.id
                        )
                      }
                      shape="round"
                      fill="solid"
                      color="dark"
                      onClick={() =>
                        conversation?.removeParticipant(
                          participant.workerProfile!.id!
                        )
                      }
                      style={{ height: "30px", width: "50px" }}
                    >
                      <IonIcon icon={personRemove} />
                    </Button>
                  </IonItem>
                ))}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default ParticipantsButton;
