import { IonImg } from "@ionic/react";
import AnimatedImg from "./AnimatedImg";
import "./EmptyContainer.css";

interface ContainerProps {
  title: string;
  message: string;
  image?: string;
  maxWidth?: string;
}

const EmptyContainer: React.FC<ContainerProps> = ({
  title,
  message,
  image,
  maxWidth
}) => {
  return (
    <div className="container">
      {image && (
        <IonImg
          src={image}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            minWidth: "80px",
            maxWidth: maxWidth ?? "400px",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        />
      )}
      <strong>{title}</strong>
      <p>{message}</p>
    </div>
  );
};

export default EmptyContainer;
