import React from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";
import moment from "moment";

import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
  IonText,
} from "@ionic/react";

import {
  personCircle,
} from "ionicons/icons";


interface ConversationOutcomeCategoriesListItemProps {
  category: string;
  onSelectItem: (item: string) => void;
}

const ConversationOutcomeCategoriesListItem: React.FC<ConversationOutcomeCategoriesListItemProps> = observer(({ category, onSelectItem }) => {
  const location = useLocation();

  if (category) {
    return (
      <IonItem
        onClick={() => onSelectItem(category) }
        detail={false}
        button
      >
       <IonLabel>
          <h2>{category}</h2>
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonItem>
      <IonAvatar
        slot="start"
        style={{
          height: "50px",
          width: "50px",
        }}
      >
        <IonIcon
          icon={personCircle}
          color="medium"
          style={{ fontSize: "50px" }}
        ></IonIcon>
      </IonAvatar>
      <IonLabel>
        <h2>
          <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
        </h2>
      </IonLabel>
    </IonItem>
  );
});

export default ConversationOutcomeCategoriesListItem;
