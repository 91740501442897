import firebase from "firebase/app";
import { people } from "ionicons/icons";
import {
  IReactionDisposer,
  onBecomeObserved,
  onBecomeUnobserved,
  makeAutoObservable,
  reaction,
  when,
  runInAction,
} from "mobx";
import { Session } from "../../session";
import { getId, hashCode } from "../../utils/Helpers";
import { Files } from "../Files";
import { PhoneNumber } from "../PhoneNumbers";
import { Users } from "../Users";

import { GroupData, groupDataConverter, groupDefaultValue } from "./GroupData";

const __cache: Record<string, Group> = {};

export class Group {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: GroupData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mPhoneNumber: PhoneNumber | null | undefined;
  private mPhoneNumberDisposer: IReactionDisposer | null | undefined;
  private mUsers: Users | null | undefined;
  private mFiles: Files | null | undefined;

  constructor(session: Session, path: string, data?: GroupData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? groupDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "phoneNumber", this.subscribePhoneNumber);
    onBecomeUnobserved(this, "phoneNumber", this.unsubscribePhoneNumber);
    onBecomeObserved(this, "users", this.subscribeUsers);
    onBecomeUnobserved(this, "users", this.unsubscribeUsers);
    onBecomeObserved(this, "files", this.subscribeFiles);
    onBecomeUnobserved(this, "files", this.unsubscribeFiles);
  }

  static createCacheInstance(session: Session, path: string, data?: GroupData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new Group(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: Group) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: GroupData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public async loadAsync() {
    return new Promise<void>((resolve) => {
      when(
        () => this.data !== undefined,
        () => resolve()
      )
    });
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(groupDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(groupDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<GroupData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get name(): string | null | undefined {
    if (this.data) return this.data.name;
    return undefined;
  }
  public set name(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.name = newValue;
    }
  }

  public get tag(): string | null | undefined {
    if (this.data) return this.data.tag;
    return undefined;
  }
  public set tag(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.tag = newValue;
    }
  }

  public get phoneNumberId(): string | null | undefined {
    if (this.data) return this.data.phoneNumberId;
    return undefined;
  }
  public set phoneNumberId(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.phoneNumberId = newValue;
    }
  }

  public get phoneNumberAsync() {
    return new Promise<PhoneNumber | null | undefined>((resolve) => {
      when(
        () => this.phoneNumber !== undefined,
        () => resolve(this.phoneNumber)
      )
    });
  }
  public get phoneNumber(): PhoneNumber | null | undefined {
    return this.mPhoneNumber;
  }
  public subscribePhoneNumber = () => {
    when(
      () => this.phoneNumberId !== undefined,
      () => {
        const initPhoneNumber = () => {
          if (this.mPhoneNumber && this.mPhoneNumber.id !== this.phoneNumberId) {
            this.unsubscribePhoneNumber();
          }

          if (this.mPhoneNumber === undefined && this.phoneNumberId !== undefined) {
            this.mPhoneNumber = this.phoneNumberId
              ? PhoneNumber.createCacheInstance(
                this.session,
                `communication/channels/phoneNumbers/${this.phoneNumberId}`
              )
              : null;
            this.mPhoneNumber?.subscribe();

            this.mPhoneNumberDisposer = reaction(
              () => this.phoneNumberId,
              (groupId) => initPhoneNumber()
            );
          }
        };

        initPhoneNumber();
      }
    );
  };
  public unsubscribePhoneNumber = () => {
    if (this.mPhoneNumberDisposer) this.mPhoneNumberDisposer();
    this.mPhoneNumber?.unsubscribe();
    this.mPhoneNumber = undefined;
  };

  get greetingMessage(): string | null | undefined {
    if (this.data && this.data.messages) {
      return this.data.messages.greeting;
    }
    return undefined;
  }
  public set greetingMessage(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      if (this.data.messages) {
        this.data.messages.greeting = newValue;
      } else {
        this.data.messages = {
          greeting: newValue,
          noAgent: "",
          slowConversationAccept: ""
        }
      }
    }
  }

  get noAgentAvailableMessage(): string | null | undefined {
    if (this.data && this.data.messages) return this.data.messages.noAgent;
    return undefined;
  }
  public set noAgentAvailableMessage(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      if (this.data.messages) {
        this.data.messages.noAgent = newValue;
      } else {
        this.data.messages = {
          greeting: "",
          noAgent: newValue,
          slowConversationAccept: ""
        }
      }
    }
  }

  get slowConversationAcceptMessage(): string | null | undefined {
    return this.data && this.data.messages
      ? this.data.messages.slowConversationAccept
      : undefined;
  }
  public set slowConversationAcceptMessage(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      if (this.data.messages) {
        this.data.messages.slowConversationAccept = newValue;
      } else {
        this.data.messages = {
          greeting: "",
          noAgent: "",
          slowConversationAccept: newValue
        }
      }
    }
  }

  get conversationAcceptPeriodSeconds(): number | null | undefined {
    if (this.data && this.data.tasks) return this.data.tasks.conversationAcceptPeriodSeconds;
    return undefined;
  }
  public set conversationAcceptPeriodSeconds(newValue: number | null | undefined) {
    if (newValue !== undefined && this.data) {
      if (this.data.tasks) {
        this.data.tasks.conversationAcceptPeriodSeconds = newValue;
      } else {
        this.data.tasks = {
          conversationAcceptPeriodSeconds: newValue,
          messageResponsePeriodSeconds: 0,
        }
      }
    }
  }

  get messageResponsePeriodSeconds(): number | null | undefined {
    if (this.data && this.data.tasks) return this.data.tasks.messageResponsePeriodSeconds;
    return undefined;
  }
  public set messageResponsePeriodSeconds(newValue: number | null | undefined) {
    if (newValue !== undefined && this.data) {
      if (this.data.tasks) {
        this.data.tasks.messageResponsePeriodSeconds = newValue;
      } else {
        this.data.tasks = {
          conversationAcceptPeriodSeconds: 0,
          messageResponsePeriodSeconds: newValue,
        }
      }
    }
  }

  public get users(): Users | null | undefined {
    return this.mUsers;
  }
  public subscribeUsers = async () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mUsers === undefined) {
          runInAction(() => {
            this.mUsers = new Users(this.session, `userProfiles`);

            let query = this.mUsers.collectionRef
              .where("groupIds", "array-contains", this.id)
              .orderBy("firstName")
              .orderBy("lastName");
            this.mUsers.query = query;

            this.mUsers.subscribe();
          });
        }
      });
  };
  public unsubscribeUsers = () => {
    this.mUsers?.unsubscribe();
    this.mUsers = undefined;
  };

  public get files(): Files | null | undefined {
    return this.mFiles;
  }
  private set files(newValue: Files | null | undefined) {
    this.mFiles = newValue;
  }
  public subscribeFiles = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.files === undefined && this.id !== undefined) {
          this.files = this.id
            ? new Files(this.session, `${this.path}`)
            : null;
          this.files?.subscribe();
        }
      }
    );
  };
  public unsubscribeFiles = () => {
    this.files?.unsubscribe();
    this.files = undefined;
  };

  public get avatar() {
    if (!(this.files?.data)) {
      return undefined;
    }

    const avatarPath = `${this.path}/avatar.jpg`;
    const avatarUrl = this.files?.data?.find(file => file.path === avatarPath)?.url;
    if (avatarUrl) {
      return avatarUrl;
    }

    if (this.data) {
      const text = this.name?.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('').substring(0, 2) ?? "";
      const foregroundColor = "white";
      const hue = hashCode(this.id || "") % 360;
      const backgroundColor = `hsl(${hue}, 90%, 70%)`;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      if (context != null) {
        // Draw background
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text
        context.font = "bold 100px 'Helvetica Neue'";
        context.fillStyle = foregroundColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL("image/svg");
      } else {
        return null;
      }
    }
    return undefined;
  }

  public delete = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          async () => {
            if (this.id) {
              try {
                if (this.session.currentUser) {
                  const executeCommandArgs = {
                    commandId: "group.delete",
                    commandArgs: {
                      path: this.path,
                    }
                  }

                  const executeCommand = this.session.firebase
                    .functions()
                    .httpsCallable("engage-executeCommand");

                  await executeCommand(executeCommandArgs);

                  resolve();
                } else {
                  reject(new Error("Unable to determine currently logged in group."));
                }
              } catch (error: any) {
                reject(error);
              }
            } else {
              reject("Unable delete the selected group. Try refreshing the group first.")
            }
          }
        );
      }
    });
  };

  public save = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          async () => {
            try {
              if (this.session.currentUser) {
                const executeCommandArgs = {
                  commandId: (!this.id ? "group.create" : "group.update"),
                  commandArgs: {
                    ...(this.id ? { path: this.path } : {}),
                    data: {
                      name: this.name,
                      tag: this.tag,
                      phoneNumberId: this.phoneNumberId,
                      messages: {
                        greeting: this.greetingMessage,
                        noAgent: this.noAgentAvailableMessage,
                        slowConversationAccept: this.slowConversationAcceptMessage
                      },
                      tasks: {
                        conversationAcceptPeriodSeconds: this.conversationAcceptPeriodSeconds,
                        messageResponsePeriodSeconds: this.messageResponsePeriodSeconds
                      }
                    }
                  }
                }

                const executeCommand = this.session.firebase
                  .functions()
                  .httpsCallable("engage-executeCommand");

                await executeCommand(executeCommandArgs);

                resolve();
              } else {
                reject(new Error("Unable to determine currently logged in group."));
              }
            } catch (error: any) {
              reject(error);
            }
          }
        );
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  };

  public uploadAvatar = async (dataUrl: string) => {
    if (this.path) {
      const storageRef = this.session.firebase
        .storage()
        .ref()
        .child(`${this.path}/avatar.jpg`);

      await storageRef.putString(dataUrl, 'data_url');
      this.files?.unsubscribe();
      this.files?.subscribe();
    }
  }
}
