import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonImg,
  IonText,
} from "@ionic/react";

import { add, close, peopleCircle, personAdd } from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";

interface AddGroupButtonProps {
  session: Session,
  parent?: HTMLElement | null;
  user: Models.User | null | undefined;
}

const AddGroupButton: React.FC<AddGroupButtonProps> = observer(
  ({ session, parent, user }) => {
    const [showModal, set_showModal] = useState(false);
    const [searchText, setSearchText] = useState("");

    const groups = session.groups?.data?.filter(
      (group) =>
        !user?.groupIds?.includes(group.id!)
        && (group.name ?? "").trim() !== ""
        && (group.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())
    );

    return (
      <>
        <IonButton
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => set_showModal(true)}
          disabled={user === undefined || user === null}
          hidden={
            !(
              session.currentUser?.role === "admin"
            )
          }
        >
          <IonIcon icon={add} />
        </IonButton>

        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={(e) => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Add Group</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonSearchbar
              color="light"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
            <IonList lines="none">
              {groups && groups.length > 0
                ? groups.map((item) => (
                  <IonItem
                    button
                    key={item.id}
                    onClick={() => {
                      user
                        ?.addGroup(item.id!, "user")
                        .then(() => set_showModal(false));
                    }}
                  >
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end"
                    >
                      {item?.avatar
                        ? <AnimatedImg alt="" src={item?.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{item.name}</IonLabel>
                  </IonItem>
                ))
                : (searchText ?? "") !== "" && (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-center">
                      <IonImg
                        style={{
                          maxWidth: "250px",
                          margin: "auto",
                          marginTop: "69px",
                        }}
                        src={
                          (searchText ?? "") === ""
                            ? "/assets/images/artboard007.png"
                            : "/assets/images/artboard008.png"
                        }
                      />
                      <p>
                        <strong>
                          <IonText
                            color="dark"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {(searchText ?? "") === ""
                              ? ""
                              : " No Search Results"}
                          </IonText>
                        </strong>
                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default AddGroupButton;
