import firebase from "firebase/app";
import { Session } from "../../session";

import { Format } from "./Format";

export interface FormatData {
  displayFormats: Record<string, string>;
};

export const formatDefaultValue = {
  displayFormats: {}
} as FormatData;

export const formatDataConverter = (session: Session) => ({
  toFirestore: (data: FormatData) => ({
    displayFormats: data.displayFormats
  }),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot<FormatData>,
    options: firebase.firestore.SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    return {
      displayFormats: data.displayFormats
    } as FormatData;
  }
});

export const formatConverter = (session: Session) => ({
  toFirestore: (format: Format) => (
    format.data ? {
      displayFormats: format.data.displayFormats ?? null
    } : {}
  ),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot<FormatData>,
    options: firebase.firestore.SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    return Format.createCacheInstance(
      session,
      snapshot.ref.path, 
      {
        displayFormats: data.displayFormats
      } as FormatData
    )
  }
});