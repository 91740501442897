import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonModal,
  IonFooter,
  IonProgressBar,
  IonText,
} from "@ionic/react";

import { arrowBack, create } from "ionicons/icons";

import * as Models from "../../models";
import ProfileDetail from "./components/ProfileDetail";
import { SessionContext } from "../../session";
import { createSwipeBackGesture } from "../../utils/gestures/swipeBackGesture";
import AnimatedImg from "../../components/AnimatedImg";

const ProfilePage: React.FC = observer(() => {
  const history = useHistory();

  const session = useContext(SessionContext);
  /// Handlers ---
  const handleDismiss = () => {
    dismiss();
  };
  /// --- handlers

  const [present, dismiss] = useIonModal(ProfileDetail, {
    session: session,
    profile: session?.currentUser,
    onDismiss: handleDismiss,
  });

  const pageRef = useRef<HTMLElement>()

  useEffect(() => {
    if (pageRef.current) {
      const swipeBackGesture = createSwipeBackGesture(
        pageRef.current,
        () => { return true; }, // canStartHandler
        () => { }, // onStartHandler
        (step: number) => { }, // onMoveHandler
        (shouldComplete: boolean, step: number, dur: number) => { } // onEndHandler
      );
      swipeBackGesture.enable();
    }
  }, [pageRef.current])

  if (!session) {
    return <></>;
  }

  const loading = session.currentUser?.loading ?? true;

  return (
    <IonPage ref={pageRef}>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonTitle>Profile</IonTitle>
          <IonButtons slot="end" hidden={loading}>
            <IonButton
              onClick={() => {
                present({ cssClass: "modal-edit-view" });
              }}
              shape="round"
              expand="block"
              size="large"
              fill="solid"
              color="light"
            >
              Edit
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {loading ? (
          <IonProgressBar color="primary" type="indeterminate" />
        ) : (
          <>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonAvatar
                    style={{
                      height: "130px",
                      width: "130px",
                      marginTop: "40px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {session.currentUser?.workerProfile?.avatar
                      ? <AnimatedImg alt="" src={session.currentUser?.workerProfile?.avatar ?? undefined} />
                      : undefined
                    }
                  </IonAvatar>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: "35px" }}>
                <IonCol>
                  <IonLabel className="ion-text-center">
                    <h1>
                      <strong>
                        {session.currentUser?.workerProfile?.name}
                      </strong>
                    </h1>
                    <p>{session.currentUser?.email}</p>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonList>
              <IonListHeader></IonListHeader>
              <IonItem
                button
                lines="none"
                onClick={() =>
                  session.signOut().then(() => {
                    //history.push("/");
                    localStorage.removeItem("vrz--engage-instance-id");
                    window.location.href = "/";
                  })
                }
              >
                <IonLabel color="primary" className="ion-text-center">
                  Sign Out
                </IonLabel>
              </IonItem>
            </IonList>
          </>
        )}
      </IonContent>

      <IonFooter>
        <IonToolbar color="light">
          <div className="ion-text-start ion-padding">
            <IonLabel>
              <IonText style={{ fontSize: "1.0em" }} color="dark"><strong>VoyagerNetz Engage</strong> Pleiades</IonText><br />
              <div style={{ paddingTop: "3px" }} />
              <IonText style={{ fontSize: "0.8em" }} color="dark">{session.instanceVersionDisplayText}</IonText><br />
              <IonText style={{ fontSize: "0.6em" }} color="medium">Build {session.instanceVersion}</IonText>
            </IonLabel>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
});

export default ProfilePage;
