import firebase from "firebase/app";

import { makeAutoObservable } from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import {
  ConversationOutcomesSettingsData,
  conversationOutcomesSettingsDataConverter,
  conversationOutcomesSettingsDefaultValue
} from "./ConversationOutcomesSettingsData";

const __cache: Record<string, ConversationOutcomesSettings> = {};

export class ConversationOutcomesSettings {
  private mSession: Session;
  private mPath: string;
  private mId: string | null;
  private mData: ConversationOutcomesSettingsData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string, data?: ConversationOutcomesSettingsData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? conversationOutcomesSettingsDefaultValue : undefined);
    this.mSubscriptionCount = 0;
  }

  static createCacheInstance(session: Session, path: string, data?: ConversationOutcomesSettingsData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new ConversationOutcomesSettings(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: ConversationOutcomesSettings) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: ConversationOutcomesSettingsData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationOutcomesSettingsDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationOutcomesSettingsDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<ConversationOutcomesSettingsData>) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(conversationOutcomesSettingsDataConverter(this.session))
            .add(this.data)
            .then((doc) => {
              this.path = doc.path;
              ConversationOutcomesSettings.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(conversationOutcomesSettingsDataConverter(this.session))
            .update(this.data)
            //.set(this.data, { merge: true }) // Merging a set() fails because the map isn't updated
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  get categories(): string[] | null | undefined {
    if (this.data && this.data.categories) return Object.keys(this.data.categories);
    return undefined;
  }

  public categoryAdd(category: string): boolean {
    if (
      category.trim()
      && this.data
      && this.categories
      && this.data.categories
      && !this.categoryExists(category)
    ) {
      this.data.categories[category] = "";
      return true;
    }

    return false;
  }

  public categoryExists(category: string): boolean {
    return Array.isArray(this.categories)
      && this.categories.findIndex(cat => cat.toLocaleLowerCase() === category.toLocaleLowerCase()) !== -1;
  }

  public async categoryRemove(category: string): Promise<boolean> {
    const existingCat = Array.isArray(this.categories)
      && this.categories.find(cat => cat.toLowerCase() === category.trim().toLowerCase());

    if (existingCat) {
      const collection = await this.session.firebase
        .firestore()
        .collection(`${this.mPath}/templateOptions`)
        .where('category', '==', existingCat)
        .get();

      if (this.data
        && this.data.categories
        && this.data.categories[existingCat] !== undefined
      ) {
        if (collection.empty || (collection.docs.filter((outcome) => outcome.data().isDeleted === false).length ?? 0) === 0) {
          delete this.data.categories[existingCat];
          return true;
        }
      }
    }

    return false;
  }
};
