import firebase from "firebase/app";
import { people } from "ionicons/icons";
import {
  IReactionDisposer,
  onBecomeObserved,
  onBecomeUnobserved,
  makeAutoObservable,
  reaction,
  when,
  runInAction,
} from "mobx";
import { Session } from "../../session";
import { getId, hashCode } from "../../utils/Helpers";
import { PhoneNumber } from "../PhoneNumbers";
import { Users } from "../Users";

import { UserGroupData, userGroupDataConverter, userGroupDefaultValue, UserGroupRole } from "./UserGroupData";

const __cache: Record<string, UserGroup> = {};

export class UserGroup {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: UserGroupData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mPhoneNumber: PhoneNumber | null | undefined;
  private mPhoneNumberDisposer: IReactionDisposer | null | undefined;
  private mUsers: Users | null | undefined;

  constructor(session: Session, path: string, data?: UserGroupData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? userGroupDefaultValue : undefined);
    this.mSubscriptionCount = 0;
  }

  static createCacheInstance(session: Session, path: string, data?: UserGroupData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new UserGroup(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: UserGroup) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: UserGroupData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public async loadAsync() {
    return new Promise<void>((resolve) => {
      when(
        () => this.data !== undefined,
        () => resolve()
      )
    });
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userGroupDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userGroupDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<UserGroupData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get role(): UserGroupRole | undefined {
    if (this.data) return this.data.role;
    return undefined;
  }
  public set role(newValue: UserGroupRole | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.role = newValue;
    }
  }

  public delete = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          async () => {
            if (this.id) {
              try {
                if (this.session.currentUser) {
                  const executeCommandArgs = {
                    commandId: "userGroup.delete",
                    commandArgs: {
                      path: this.path,
                    }
                  }

                  const executeCommand = this.session.firebase
                    .functions()
                    .httpsCallable("engage-executeCommand");

                  await executeCommand(executeCommandArgs);

                  resolve();
                } else {
                  reject(new Error("Unable to determine currently logged in userGroup."));
                }
              } catch (error: any) {
                reject(error);
              }
            } else {
              reject("Unable delete the selected userGroup. Try refreshing the userGroup first.")
            }
          }
        );
      }
    });
  };

  public save = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          async () => {
            try {
              if (this.session.currentUser) {
                const executeCommandArgs = {
                  commandId: (!this.id ? "userGroup.create" : "userGroup.update"),
                  commandArgs: {
                    ...(this.id ? { path: this.path } : {}),
                    data: {
                      role: this.role
                    }
                  }
                }

                const executeCommand = this.session.firebase
                  .functions()
                  .httpsCallable("engage-executeCommand");

                await executeCommand(executeCommandArgs);

                resolve();
              } else {
                reject(new Error("Unable to determine currently logged in userGroup."));
              }
            } catch (error: any) {
              reject(error);
            }
          }
        );
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  };
}
