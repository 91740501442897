import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useInView } from "react-intersection-observer";

import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonText,
  IonCol,
} from "@ionic/react";

import { timeOutline } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import { LinkifyIt } from "../../../utils/Helpers";
import AnimatedImg from "../../../components/AnimatedImg";

interface InternalMessagesListItemProps {
  internalMessage: Models.InternalMessage;
  onInView?: (internalMessage: Models.InternalMessage) => void;
}

const InternalMessagesListItem: React.FC<InternalMessagesListItemProps> =
  observer(({ internalMessage, onInView }) => {
    const [ref, inView, entry] = useInView({
      threshold: 0.5,
    });

    useEffect(() => {
      if (inView) {
        if (onInView) {
          onInView(internalMessage);
        }
      }
    }, [inView]);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    if (
      session.currentUser?.workerProfile?.loaded &&
      internalMessage.loaded &&
      internalMessage.sender &&
      internalMessage.sender.loaded
    ) {
      const internalMessageDate = moment(internalMessage.createdOn).format(
        "h:mm A"
      );
      const internalMessageSender =
        internalMessage.sender.id === session.currentUser?.workerProfile?.id
          ? "You"
          : `${internalMessage.sender?.name}`;
      const internalMessageBody = internalMessage.body;

      return (
        <IonRow className={`ion-padding-top`}>
          <IonCol
            style={{
              display: "flex",
              margin: "auto",
              justifyContent:
                internalMessageSender == "You" ? "flex-end" : "flex-start",
            }}
          >
            <IonAvatar
              hidden={internalMessageSender == "You"}
              style={{
                height: "25px",
                width: "25px",
                minHeight: "25px",
                minWidth: "25px",
              }}
            //className="ion-margin-start"
            >
              {internalMessage.sender?.avatar
                ? <AnimatedImg alt="" src={internalMessage.sender?.avatar ?? undefined} />
                : undefined
              }
            </IonAvatar>
            <IonItem
              ref={ref}
              color={internalMessageSender === "You" ? "primary" : "secondary"}
              lines="none"
              style={{
                minWidth: "100px",
                border: "1px",
                borderRadius:
                  internalMessageSender === "You"
                    ? "15px 3px 15px 15px"
                    : "3px 15px 15px 15px",
              }}
              className={
                internalMessageSender != "You"
                  ? "ion-margin-start"
                  : "ion-margin-end"
              }
            >
              <IonLabel className="ion-text-wrap">
                <p>
                  {internalMessageSender !== "You" && (
                    <b>{internalMessageSender}</b>
                  )}
                </p>
                <div style={{ userSelect: "text", WebkitUserSelect: "text", MozUserSelect: "text", msUserSelect: "text" }}>
                  <LinkifyIt>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {internalMessageBody}
                    </p>
                  </LinkifyIt>
                </div>
                <div>
                  <p>
                    <IonText
                      color={internalMessageSender == "You" ? "light" : "dark"}
                    >
                      <span className="ion-text-end ion-float-right ion-align-items-end">
                        {internalMessage.status === "pending" ? (
                          <IonIcon icon={timeOutline} />
                        ) : (
                          <IonText style={{ fontSize: "12px" }}>
                            {internalMessageDate}
                          </IonText>
                        )}
                      </span>
                    </IonText>
                  </p>
                </div>
              </IonLabel>
            </IonItem>
            <IonAvatar
              hidden={internalMessageSender != "You"}
              style={{
                height: "25px",
                width: "25px",
                minHeight: "25px",
                minWidth: "25px",
              }}
            //className="ion-margin-start"
            >
              {internalMessage.sender?.avatar
                ? <AnimatedImg alt="" src={internalMessage.sender?.avatar ?? undefined} />
                : undefined
              }
            </IonAvatar>
          </IonCol>
        </IonRow>
      );
    }

    return <></>;
  });

export default InternalMessagesListItem;
