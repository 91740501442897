import firebase from "firebase/app";

import { IReactionDisposer, makeAutoObservable, onBecomeObserved, onBecomeUnobserved, reaction, when } from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";
import { Group } from "../Groups";

import { FacebookPageData, facebookPageDataConverter, facebookPageDefaultValue } from "./FacebookPageData";

const __cache: Record<string, FacebookPage> = {};

export class FacebookPage {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: FacebookPageData | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;
    private mGroup: Group | null | undefined;
    private mGroupDisposer: IReactionDisposer | null | undefined;

    constructor(session: Session, path: string, data?: FacebookPageData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = getId(this.mPath);
        this.mData = data ? data : (this.mId == null ? facebookPageDefaultValue : undefined);
        this.mSubscriptionCount = 0;

        onBecomeObserved(this, "group", this.subscribeGroup);
        onBecomeUnobserved(this, "group", this.unsubscribeGroup);
    }

    static createCacheInstance(session: Session, path: string, data?: FacebookPageData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        const id = getId(cachePath);
        if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new FacebookPage(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: FacebookPage) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = getId(this.mPath);
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: FacebookPageData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public refetch() {
        if (!this.mSubscription) {
            this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(facebookPageDataConverter(this.session))
                .get()
                .then((snapshot) => {
                    const data = snapshot.data();
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.path}`);
            this.mSubscription = this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(facebookPageDataConverter(this.session))
                .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<FacebookPageData>) => {
                    const data = snapshot.data();
                    this.data = data;
                }
                );
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.path}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    public save = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.data) {
                when(
                    () => this.session.currentUser?.loaded ?? false,
                    async () => {
                        try {
                            if (this.session.currentUser) {
                                const executeCommandArgs = {
                                    commandId: (!this.id ? "facebookPage.create" : "facebookPage.update"),
                                    commandArgs: {
                                        ...(this.id ? { path: this.path } : {}),
                                        data: {
                                            groupId: this.groupId,
                                        }
                                    }
                                }

                                const executeCommand = this.session.firebase
                                    .functions()
                                    .httpsCallable("engage-executeCommand");

                                await executeCommand(executeCommandArgs);

                                resolve();
                            } else {
                                reject(new Error("Unable to determine currently logged in group."));
                            }
                        } catch (error: any) {
                            reject(error);
                        }
                    }
                );
            } else {
                reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
            }
        });
    };

    get category(): string | null | undefined {
        if (this.data) return this.data.category;
        return undefined;
    }

    get name(): string | null | undefined {
        if (this.data) return this.data.name;
        return undefined;
    }

    get status(): string | null | undefined {
        if (this.data) return this.data.status;
        return undefined;
    }

    get pageId(): string | null | undefined {
        if (this.data) return this.data.pageId;
        return undefined;
    }

    public get groupId(): string | null | undefined {
        if (this.data) return this.data.groupId;
        return undefined;
    }
    public set groupId(newValue: string | null | undefined) {
        if (newValue !== undefined && this.data) {
            this.data.groupId = newValue;
        }
    }

    public get group(): Group | null | undefined {
        return this.mGroup;
    }
    public subscribeGroup = () => {
        when(
            () => this.groupId !== undefined,
            () => {
                const initGroup = () => {
                    if (this.mGroup && this.mGroup.id !== this.groupId) {
                        this.unsubscribeGroup();
                    }

                    if (this.mGroup === undefined && this.groupId !== undefined) {
                        this.mGroup = this.groupId
                            ? Group.createCacheInstance(
                                this.session,
                                `groups/${this.groupId}`
                            )
                            : null;
                        this.mGroup?.subscribe();

                        this.mGroupDisposer = reaction(
                            () => this.groupId,
                            (groupId) => initGroup()
                        );
                    }
                };

                initGroup();
            }
        );
    };
    public unsubscribeGroup = () => {
        if (this.mGroupDisposer) this.mGroupDisposer();
        this.mGroup?.unsubscribe();
        this.mGroup = undefined;
    };
}