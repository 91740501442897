import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useInView } from "react-intersection-observer";

import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonText,
  IonCol,
  IonGrid,
  IonList,
  useIonPopover,
  IonPopover,
  IonContent,
} from "@ionic/react";

import {
  alertCircleOutline,
  desktopOutline,
  documentOutline,
  personCircle,
} from "ionicons/icons";

import AnimatedImg from "../../../components/AnimatedImg";

import * as Models from "../../../models";

import {
  fileSizeString,
  getChannelIcon,
  getMessageStatusIcon,
  LinkifyIt,
} from "../../../utils/Helpers";
import { SessionContext } from "../../../session";
import { messageChannels, MessageMedia } from "../../../models";

interface MessageListItemProps {
  conversation: Models.Conversation;
  message: Models.Message;
  onInView?: (message: Models.Message) => void;
}

const MessageListItem: React.FC<MessageListItemProps> = observer(
  ({ conversation, message, onInView }) => {
    const [ref, inView] = useInView({
      threshold: 0.5,
    });

    useEffect(() => {
      if (inView) {
        if (onInView) onInView(message);
      }
    }, [inView]);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

    if (
      message.loaded &&
      //message.files &&
      //message.files.loaded &&
      session.currentUser?.workerProfile?.loaded &&
      ((message.type === "agent" &&
        message.workerProfile &&
        message.workerProfile.loaded) ||
        message.type !== "agent")
    ) {
      const messageDate = moment(message.createdOn).format("h:mm A");
      const messageSender =
        message.type === "agent"
          ? message.workerProfile?.id === session.currentUser?.workerProfile?.id
            ? "You"
            : `${message.workerProfile?.name}`
          : message.type === "system"
            ? "System"
            : "";
      const messageBody = message.body;

      const imageFiles = message.files?.data?.filter(
        (file) =>
          typeof file.metadata.contentType == "string" &&
          file.metadata.contentType.startsWith("image")
      );

      const otherFiles = message.files?.data?.filter(
        (file) =>
          typeof file.metadata.contentType == "string" &&
          !file.metadata.contentType.startsWith("image") &&
          !file.metadata.contentType.startsWith("fallback")
      );

      const fallbackFiles: MessageMedia[] = [];
      if (message.media && Object.keys(message.media).length > 0
      ) {
        Object.keys(message.media)
          .forEach((key) => {
            const meta = message.media && message.media[key];
            if (meta
              && meta.contentType
              && meta.contentType.toLowerCase() === "fallback"
            ) {
              fallbackFiles.push(meta);
            }
          });
      }

      return (
        <IonRow className={`ion-padding-top`}>
          <IonCol
            style={{
              display: "flex",
              margin: "auto",
              justifyContent:
                message.direction === "out" ? "flex-end" : "flex-start",
            }}
            size="12"
          >
            <IonAvatar
              hidden={message.direction === "out"}
              style={{
                height: "30px",
                width: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              {conversation?.customer?.avatar ? (
                <AnimatedImg
                  src={conversation?.customer?.avatar ?? undefined}
                />
              ) : (
                <IonIcon
                  hidden={message.direction === "out"}
                  style={{
                    height: "30px",
                    width: "30px",
                    minHeight: "30px",
                    minWidth: "30px",
                  }}
                  //className="ion-margin-end"
                  icon={personCircle}
                  color="medium"
                />
              )}
            </IonAvatar>

            <IonItem
              ref={ref}
              color={
                messageSender === "You"
                  ? "primary"
                  : message.type === "agent"
                    ? "secondary"
                    : message.type === "system"
                      ? ""
                      : "light"
              }
              lines="none"
              style={{
                minWidth: "100px",
                border: `1px ${message.type === "system"
                  ? "solid var(--ion-color-medium)"
                  : ""
                  }`,
                borderRadius:
                  message.direction === "out"
                    ? "15px 3px 15px 15px"
                    : "3px 15px 15px 15px",
                margin:
                  message.direction === "out"
                    ? "0px 10px 0px 10px"
                    : "0px 10px 0px 10px",
              }}
              className="ion-no-margin"
            >
              <IonLabel
                className="ion-text-wrap"
                color={message.type === "system" ? "medium" : ""}
              >
                <p>
                  {messageSender !== "You" && messageSender !== "System" ? (
                    <b>{messageSender}</b>
                  ) : (
                    <></>
                  )}
                </p>

                {message.files?.loaded &&
                  message.files.data &&
                  message.files.data.length > 0 && (
                    <IonGrid>
                      {imageFiles && imageFiles.length > 0 && (
                        <IonRow style={{ maxWidth: "300px" }}>
                          {imageFiles.map((file) => (
                            <IonCol
                              key={file.id}
                              size={imageFiles.length === 1 ? "12" : "6"}
                            >
                              {file.loaded && (
                                <a
                                  //lines="none"
                                  color={
                                    messageSender === "You"
                                      ? "dark"
                                      : message.type === "agent"
                                        ? "secondary"
                                        : message.type === "system"
                                          ? ""
                                          : "light"
                                  }
                                  style={{ borderRadius: "5px" }}
                                  href={file.url ?? undefined}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AnimatedImg
                                    src={file.data?.url ?? undefined}
                                    style={{
                                      padding: "0px",
                                      //maxWidth: "300px",
                                      //maxHeight: "600px",
                                      borderRadius: "5px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </a>
                              )}
                            </IonCol>
                          ))}
                        </IonRow>
                      )}

                      {fallbackFiles && fallbackFiles.length > 0 && (
                        <IonRow style={{ maxWidth: "300px" }}>
                          {fallbackFiles.map((file) => (
                            <IonCol
                              key={file.name}
                              sizeXs="12"
                              sizeSm={fallbackFiles.length === 1 ? "12" : "6"}
                            >
                              {
                                file.sourceUrl
                                  ? (
                                    <a href={file.sourceUrl} target="_blank" rel="noreferrer">{file.sourceUrl}</a>
                                  ) : (<></>)
                              }
                            </IonCol>
                          ))}
                        </IonRow>
                      )}

                      {otherFiles && otherFiles.length > 0 && (
                        <IonRow style={{ maxWidth: "300px" }}>
                          {otherFiles.map((file) => (
                            <IonCol
                              key={file.id}
                              sizeXs="12"
                              sizeSm={otherFiles.length === 1 ? "12" : "6"}
                            >
                              {file.loaded && (
                                <>
                                  <IonItem
                                    lines="none"
                                    style={{ borderRadius: "5px" }}
                                    href={file.url ?? undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-title={file.metadata.name}
                                  >
                                    <IonIcon
                                      //slot="start"
                                      icon={documentOutline}
                                      className="ion-padding-end"
                                    />
                                    <IonLabel>
                                      <b>{file.metadata.name}</b>
                                      <p>
                                        {fileSizeString(file.metadata.size)}
                                      </p>
                                    </IonLabel>
                                  </IonItem>
                                </>
                              )}
                            </IonCol>
                          ))}
                        </IonRow>
                      )}
                    </IonGrid>
                  )}
                <div
                  style={{
                    userSelect: "text",
                    WebkitUserSelect: "text",
                    MozUserSelect: "text",
                    msUserSelect: "text",
                  }}
                >
                  <LinkifyIt>
                    <p style={{ whiteSpace: "pre-wrap" }}>{messageBody}</p>
                  </LinkifyIt>
                  {
                    fallbackFiles.filter(file => file.error).length > 0
                      ? (
                        <IonItem lines="none" style={{
                          border: "1px solid var(--ion-color-medium)",
                          borderRadius: "5px"
                        }}>
                          <IonIcon size="small" src={alertCircleOutline} slot="end" />
                          <IonLabel className="ion-text-wrap" color="medium" style={{
                            fontSize: "12px",
                            fontStyle: "italic"
                          }}>
                            Shared content from {message.communicationChannel?.provider || "the provider"} is unavailable.
                          </IonLabel>
                        </IonItem>
                      ) : (<></>)
                  }
                </div>
                <div>
                  <p>
                    <IonText
                      color={
                        messageSender === "You"
                          ? "light"
                          : message.type === "agent"
                            ? "dark"
                            : "medium"
                      }
                    >
                      <span
                        className="ion-text-end ion-float-right ion-align-items-end"
                        title={
                          message.status === Models.messageStatuses.FAILED
                            ? message.error?.message ?? ""
                            : ""
                        }
                        onClick={
                          (e: any) => {
                            e.persist();
                            setShowPopover({ showPopover: true, event: e })
                          }}
                      >
                        &nbsp;
                        <IonText
                          style={{ fontSize: "16px" }}
                          hidden={message.direction === "in"}
                        >
                          {getMessageStatusIcon(message.status ?? "")}
                        </IonText>
                        &nbsp;
                        <IonText style={{ fontSize: "16px" }}>
                          {getChannelIcon(
                            message.communicationChannel?.channel ?? ""
                          )}
                        </IonText>
                      </span>
                      <span
                        className="ion-text-end ion-float-right ion-align-items-end"
                        title={
                          message.status === Models.messageStatuses.FAILED
                            ? message.error?.message ?? ""
                            : ""
                        }
                        onClick={
                          (e: any) => {
                            e.persist();
                            setShowPopover({ showPopover: true, event: e })
                          }}
                      >
                        {message.status === Models.messageStatuses.FAILED ? (
                          <>
                            <IonPopover
                              mode="ios"
                              showBackdrop={true}
                              event={popoverState.event}
                              isOpen={popoverState.showPopover}
                              onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                            >
                              <IonContent >

                                <IonLabel>
                                  <IonText color="danger">
                                    <p style={{ padding: "10px" }}>
                                      <IonIcon size="small" src={alertCircleOutline} style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                      }} />
                                      &nbsp;
                                      {message.error?.message ?? ""}
                                    </p>
                                  </IonText>
                                </IonLabel>

                              </IonContent>
                            </IonPopover>
                            <IonText style={{ fontSize: "12px" }}>
                              {messageDate}
                            </IonText>
                            <IonText color="danger" style={{ fontSize: "12px" }}>
                              {" Sending failed"}
                            </IonText>
                          </>
                        ) : (
                          <>
                            <IonText
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {messageDate}
                            </IonText>
                          </>
                        )}
                      </span>
                    </IonText>
                  </p>
                </div>
              </IonLabel>
            </IonItem>
            {message.type === "system" ? (
              <IonAvatar
                hidden={message.direction === "in"}
                style={{
                  height: "22px",
                  width: "22px",
                  minWidth: "22px",
                  minHeight: "22px",
                }}
              >
                <IonIcon
                  hidden={message.direction === "in"}
                  style={{
                    height: "22px",
                    width: "22px",
                    minHeight: "22px",
                    minWidth: "22x",
                  }}
                  //className="ion-margin-end"
                  icon={desktopOutline}
                  color="medium"
                />
              </IonAvatar>
            ) : (
              <IonAvatar
                hidden={message.direction === "in"}
                style={{
                  height: "25px",
                  width: "25px",
                  minWidth: "25px",
                  minHeight: "25px",
                }}
              >
                {message.workerProfile?.avatar
                  ? <AnimatedImg alt="" src={message.workerProfile?.avatar ?? undefined} />
                  : undefined
                }
              </IonAvatar>
            )}
          </IonCol>
        </IonRow>
      );
    }

    return <></>;
  }
);

export default MessageListItem;
