import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonListHeader,
  IonInput,
  IonText,
  useIonActionSheet,
  IonNote,
} from "@ionic/react";

import { close, checkmarkOutline, information } from "ionicons/icons";

import * as Models from "../../../models";

import { Controller, useForm } from "react-hook-form";
import { SessionContext } from "../../../session";
import { ConversationHistory } from "./ConversationHistory";

interface ConversationInfoButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const ConversationInfoButton: React.FC<ConversationInfoButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, setShowModal] = useState(false);

    const [present, dismiss] = useIonActionSheet();

    const { control, errors, formState, handleSubmit, reset } = useForm({
      mode: "onChange",
    });

    useEffect(() => {
      reset();
    }, [showModal, reset]);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && conversation && conversation.id) {
        try {
          await conversation.update({
            externalReference: data.conversationReference,
          });

          reset(
            {},
            {
              errors: false,
              dirtyFields: false,
              isDirty: false,
              isSubmitted: false,
              touched: false,
              isValid: false,
              submitCount: false,
            }
          );
          setShowModal(false);
        } catch (error) {
          console.error("Error setting external reference.", error);
        }
      }
    };

    return (
      <>
        <IonButton
          slot="end"
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => setShowModal(true)}
          disabled={conversation === undefined || conversation === null}
        >
          <IonIcon icon={information} />
        </IonButton>

        <IonModal
          animated={true}
          isOpen={showModal}
          presentingElement={parent || undefined}
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    if (formState.isDirty) {
                      present({
                        buttons: [
                          {
                            text: "Discard Changes",
                            role: "destructive",
                            handler: () => setShowModal(false),
                          },
                          { text: "Keep Editing", role: "cancel" },
                        ],
                        header:
                          "Are you sure you want to discard your changes?",
                        mode: "ios",
                      });
                    } else {
                      setShowModal(false);
                    }
                  }}
                >
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Conversation Info</IonTitle>

              <IonButtons slot="end">
                <IonButton
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    !formState.isValid ||
                    formState.isSubmitting ||
                    !formState.isDirty
                  }
                  shape="round"
                  expand="block"
                  size="large"
                  fill="solid"
                  color="light"
                >
                  <IonIcon hidden icon={checkmarkOutline} />
                  {formState.isSubmitting ? "Saving..." : "Save"}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent color="light">
            <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 18 }}>
              <IonItem lines="none">
                <IonLabel position="stacked" color="medium">
                  Conversation Reference
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={conversation?.externalReference}
                  name="conversationReference"
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonInput={onChange}
                      value={value}
                    />
                  )}
                />
                {showError("conversationReference")}
              </IonItem>
            </form>

            <IonItem lines="none" className="ion-padding-bottom" >
              <IonLabel position="stacked" color="medium">
                Created Date
              </IonLabel>
              <IonText>
                {conversation?.createdOn
                  ? moment(conversation?.createdOn).format("MMM D YYYY, h:mm A")
                  : ""}
              </IonText>
            </IonItem>

            <IonListHeader color="light" style={{ paddingTop: "20px" }}>
              <strong>SERVICE PROVIDER</strong>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel position="stacked" color="medium">
                Name
              </IonLabel>
              <IonText>{conversation?.serviceProvider?.provider}</IonText>
            </IonItem>

            <IonItem lines="none">
              <IonLabel position="stacked" color="medium">
                Channel
              </IonLabel>
              <IonText>{conversation?.serviceProvider?.channel}</IonText>
            </IonItem>

            <IonItem lines="none" className="ion-padding-bottom">
              <IonLabel position="stacked" color="medium">
                Reference
              </IonLabel>
              <IonText>
                {conversation?.communicationChannelFacebookPage?.name ??
                  conversation?.serviceProvider?.facebookId ??
                  conversation?.serviceProvider?.mobileNumber ??
                  ""}
              </IonText>
            </IonItem>
            {/* --- SERVICE PROVIDER --- */}

            <IonListHeader color="light" style={{ paddingTop: "20px" }}>
              <strong>OUTCOME</strong>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel position="stacked" color="medium">
                Name
              </IonLabel>
              <IonText>{conversation?.outcome?.name}</IonText>
            </IonItem>
            <IonItem lines="none" className="ion-padding-bottom">
              <IonLabel position="stacked" color="medium">
                Category
              </IonLabel>
              <IonText>{conversation?.outcome?.category}</IonText>
            </IonItem>
            {/* --- OUTCOME --- */}

            <IonListHeader color="light" style={{ paddingTop: "20px" }}>
              <strong>HISTORY</strong>
            </IonListHeader>
            <IonItem lines="none">
              <ConversationHistory conversation={conversation} />
            </IonItem>
            {/* --- HISTORY --- */}
          </IonContent>
        </IonModal>
      </>
    );
  }
);
export default ConversationInfoButton;
