import firebase from "firebase/app";
import { Session } from "../../session";

import { StandardResponsesSettings } from "./StandardResponsesSettings";

export interface StandardResponsesSettingsData {
    categories: Record<string, string> | null;
}

export const standardResponsesSettingsDefaultValue = {
    categories: null,
} as StandardResponsesSettingsData;

export const standardResponsesSettingsDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: StandardResponsesSettingsData) {
            return {
                categories: data.categories,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<StandardResponsesSettingsData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                categories: data.categories ?? null,
            } as StandardResponsesSettingsData;
        },
    }
};

export const standardResponsesSettingsConverter = (session: Session) => {
    return {
        toFirestore: function (standardResponsesSettings: StandardResponsesSettings) {
            return standardResponsesSettings.data ? {
                categories: standardResponsesSettings.data.categories,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<StandardResponsesSettingsData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return StandardResponsesSettings.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    categories: data.categories ?? null,
                }
            );
        },
    }
};