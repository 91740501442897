import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import * as Models from "../../../models";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { checkmarkOutline, close } from "ionicons/icons";
import { Session } from "../../../session";

import firebase from "firebase/app";

interface ProfileProps {
  session: Session;
  profile: Models.User;
  onDismiss: () => void;
}

const ProfileDetail: React.FC<ProfileProps> = observer(
  ({ session, profile, onDismiss }) => {
    const [changePassword, setChangePassword] = useState(false);

    const provider = new firebase.auth.EmailAuthProvider();
    const emailAndPasswordProfiles = session.firebase
      .auth()
      .currentUser?.providerData.filter(
        (profile) => profile?.providerId == provider.providerId
      );

    const isEmailAndPasswordProviderEnabled = (emailAndPasswordProfiles && emailAndPasswordProfiles.length > 0);

    const { control, handleSubmit, formState, reset, errors, setValue, watch } =
      useForm({
        mode: "onChange",
      });

    const onSubmit = async (data: any) => {
      if (formState.isValid && profile) {
        try {


          profile.firstName = data.firstName.trim();
          profile.lastName = data.lastName.trim();

          if (profile.id === null || (profile.id !== null && (data.password || "") !== "")) {
            try {
              await session.reauthenticationWithEmailAndPassword(profile.email || "", data.currentPassword);
            } catch (error) {
              alert("Current password specified is incorrect!")
              return;
            }

            profile.password = data.password;
          }

          await profile.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save user profile");
          console.error("Error saving user profile.", error);
        }
      }
    };

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Profile</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !formState.isValid ||
                  formState.isSubmitting ||
                  !formState.isDirty
                }
                shape="round"
                expand="block"
                size="large"
                fill="solid"
                color="light"
              >
                <IonIcon hidden icon={checkmarkOutline} />
                {formState.isSubmitting ? "Saving..." : "Save"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {profile ? (
            <IonList lines="none" className="ion-no-padding ion-padding-bottom">
              <IonListHeader hidden color="light" style={{ paddingTop: "5px" }}>
                BASIC DETAILS
              </IonListHeader>

              <IonItem key="firstName" lines="none">
                <IonLabel position="stacked" color="medium">
                  First Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={profile.firstName}
                  name="firstName"
                  rules={{
                    required: true,
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters long",
                    },
                    validate: (data) => {
                      return (data || "").trim().length > 0
                        ? true
                        : "First name cannot be blank";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />

                {showError("firstName")}
              </IonItem>

              <IonItem key="lastName" lines="none" className="ion-padding-bottom">
                <IonLabel position="stacked" color="medium">
                  Last Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={profile.lastName}
                  name="lastName"
                  rules={{
                    required: true,
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters long",
                    },
                    validate: (data) => {
                      return (data || "").trim().length > 0
                        ? true
                        : "Last name cannot be blank";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />

                {showError("lastName")}
              </IonItem>
              <IonListHeader color="light" style={{ paddingTop: "20px" }} hidden={!isEmailAndPasswordProviderEnabled}>
                <strong>SECURITY</strong>
              </IonListHeader>
              {changePassword
                ? (
                  <>
                    <IonItem key="currentPassword" lines="none" hidden={!isEmailAndPasswordProviderEnabled}>
                      <IonLabel color="medium" position="stacked">
                        Current Password
                      </IonLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="currentPassword"
                        rules={{
                          validate: (value) => {
                            return (profile.id !== null && watch("password", "") === "") || (value || "").trim() !== "" || "Required to change password.";
                          },
                        }}
                        render={({ onBlur, onChange, value }) => (
                          <IonInput
                            className="input-field"
                            type="password"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                      />
                      {showError("currentPassword")}
                    </IonItem>
                    <IonItem key="password" lines="none" hidden={!isEmailAndPasswordProviderEnabled}>
                      <IonLabel color="medium" position="stacked">
                        {profile.id === null ? "Password" : "New Password"}
                      </IonLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="password"
                        rules={{
                          validate: (value) => {
                            return (profile.id !== null && (value || "") === "") || (value || "").trim().length >= 8 || "Must be at least 8 characters long.";
                          },
                        }}
                        render={({ onBlur, onChange, value }) => (
                          <IonInput
                            className="input-field"
                            type="password"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                      />
                      {showError("password")}
                    </IonItem>

                    <IonItem key="passwordConfirmation" lines="none" hidden={!isEmailAndPasswordProviderEnabled}>
                      <IonLabel color="medium" position="stacked">
                        Confirm Password
                      </IonLabel>
                      <Controller

                        control={control}
                        defaultValue=""
                        name="passwordConfirmation"
                        rules={{
                          validate: (value) => {
                            return (profile.id !== null && watch("password", "") === "") || (value === watch("password", "")) || "The passwords do not match.";
                          },
                        }}
                        render={({ onBlur, onChange, value }) => (
                          <IonInput
                            className="input-field"
                            type="password"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                      />
                      {showError("passwordConfirmation")}
                    </IonItem>
                  </>
                ) : (
                  <IonButton
                    className="ion-margin-start ion-margin-top"
                    shape="round"
                    onClick={() => setChangePassword(true)}
                    color="dark"
                  >
                    Change Password
                  </IonButton>
              )}
            </IonList>
          ) : (
            <div>Profile not loaded</div>
          )}
        </IonContent>
      </IonPage>
    );
  }
);

export default ProfileDetail;
