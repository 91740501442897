import firebase from "firebase/app";
import { Session } from "../../session";

import { StandardResponse } from "./StandardResponse";

export interface StandardResponseData {
  category: string | null;
  name: string | null;
  tag: string | null;
  text: string | null;
  isDeleted: boolean;
};

export const standardResponseDefaultValue = {
  category: null,
  name: null,
  tag: "",
  text: null,
  isDeleted: false
} as StandardResponseData;

export const standardResponseDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: StandardResponseData) {
      return {
        category: data.category,
        name: data.name,
        tag: data.tag,
        text: data.text,
        isDeleted: data.isDeleted,
      };
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<StandardResponseData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        category: data.category ?? null,
        name: data.name ?? null,
        tag: data.tag ?? null,
        text: data.text ?? null,
        isDeleted: data.isDeleted ?? false,
      } as StandardResponseData;
    },
  }
};

export const standardResponseConverter = (session: Session) => {
  return {
    toFirestore: function (standardResponse: StandardResponse) {
      return standardResponse.data
        ? {
          category: standardResponse.data.category,
          name: standardResponse.data.name,
          tag: standardResponse.data.tag,
          text: standardResponse.data.text,
          isDeleted: standardResponse.data.isDeleted,
        } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<StandardResponseData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return StandardResponse.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          category: data.category ?? null,
          name: data.name ?? null,
          tag: data.tag ?? null,
          text: data.text ?? null,
          isDeleted: data.isDeleted ?? false,
        }
      );
    },
  }
};