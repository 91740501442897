import firebase from "firebase/app";
import {
  makeAutoObservable,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { UserPresenceData, userPresenceDataConverter, userPresenceDefaultValue } from "./UserPresenceData";

const __cache: Record<string, UserPresence> = {};

export class UserPresence {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: UserPresenceData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string, data?: UserPresenceData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data || (this.mId == null ? userPresenceDefaultValue : undefined);
    this.mSubscriptionCount = 0;
  }

  static createCacheInstance(session: Session, path: string, data?: UserPresenceData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new UserPresence(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: UserPresence) {
    if (instance.id === null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session(): Session {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: UserPresenceData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public get modifiedOn(): firebase.firestore.Timestamp | null | undefined {
    if (this.data) return this.data.modifiedOn;
    return undefined;
  }
  public set modifiedOn(newValue: firebase.firestore.Timestamp | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.modifiedOn = newValue;
    }
  }

  public get status(): "offline" | "online" | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }
  public set status(newValue: "offline" | "online" | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.status = newValue;
    }
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userPresenceDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userPresenceDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<UserPresenceData>) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }
}
