import firebase from "firebase/app";

import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
  IReactionDisposer,
  reaction,
} from "mobx";
import { Session } from "../../session";
import { getId, hashCode } from "../../utils/Helpers";

import { Conversations } from "../Conversations";

import { User } from "../Users";
import { WorkItem, WorkItems } from "../WorkItems";
import { WorkerProfileData, workerProfileDataConverter, workerProfileDefaultValue } from "./WorkerProfileData";

const __cache: Record<string, WorkerProfile> = {};

export class WorkerProfile {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: WorkerProfileData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mUser: User | null | undefined;
  private mUserDisposer: IReactionDisposer | null | undefined;
  private mOpenWorkItems: WorkItems | null | undefined;
  private mClosedWorkItems: WorkItems | null | undefined;
  private mOtherOpenWorkItems: WorkItems | null | undefined;
  private mOtherClosedWorkItems: WorkItems | null | undefined;
  private mAvailableConversations: Conversations | null | undefined;
  private mIncomingConversations: Conversations | null | undefined;

  constructor(session: Session, path: string, data?: WorkerProfileData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? workerProfileDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "user", this.subscribeUser);
    onBecomeUnobserved(this, "user", this.unsubscribeUser);
    onBecomeObserved(this, "openWorkItems", this.subscribeOpenWorkItems);
    onBecomeUnobserved(this, "openWorkItems", this.unsubscribeOpenWorkItems);
    onBecomeObserved(this, "closedWorkItems", this.subscribeClosedWorkItems);
    onBecomeUnobserved(this, "closedWorkItems", this.unsubscribeClosedWorkItems);
    onBecomeObserved(this, "otherOpenWorkItems", this.subscribeOtherOpenWorkItems);
    onBecomeUnobserved(this, "otherOpenWorkItems", this.unsubscribeOtherOpenWorkItems);
    onBecomeObserved(this, "otherClosedWorkItems", this.subscribeOtherClosedWorkItems);
    onBecomeUnobserved(this, "otherClosedWorkItems", this.unsubscribeOtherClosedWorkItems);
    onBecomeObserved(this, "availableConversations", this.subscribeAvailableConversations);
    onBecomeUnobserved(this, "availableConversations", this.unsubscribeAvailableConversations);
    onBecomeObserved(this, "incomingConversations", this.subscribeIncomingConversations);
    onBecomeUnobserved(this, "incomingConversations", this.unsubscribeIncomingConversations);
  }

  static createCacheInstance(session: Session, path: string, data?: WorkerProfileData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new WorkerProfile(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: WorkerProfile) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: WorkerProfileData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(workerProfileDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(workerProfileDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<WorkerProfileData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(workerProfileDataConverter(this.session))
            .add(this.data).then((doc) => {
              this.path = doc.path;
              WorkerProfile.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(workerProfileDataConverter(this.session))
            .set(this.data, { merge: true }).then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  public get name(): string | null | undefined {
    if (this.data) return this.data.name;
    return undefined;
  }

  public get avatar() {
    if (this.data) {
      const text = this.name?.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('').substring(0, 2) ?? "";
      const foregroundColor = "white";
      const hue = hashCode(this.userId || "") % 360;
      const backgroundColor = `hsl(${hue}, 90%, 70%)`;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      if (context != null) {
        // Draw background
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text
        context.font = "bold 100px 'Helvetica Neue'";
        context.fillStyle = foregroundColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL("image/svg");
      }
      else {
        return null;
      }
    }
    return undefined;
  }

  public get userId(): string | null | undefined {
    if (this.data) return this.data.userId;
    return undefined;
  }

  public get user(): User | null | undefined {
    return this.mUser;
  }
  public subscribeUser = () => {
    when(
      () => this.userId !== undefined,
      () => {
        const initUser = () => {
          if (this.mUser && this.mUser.id != this.userId) {
            this.unsubscribeUser();
          }

          if (this.mUser === undefined && this.userId !== undefined) {
            this.mUser = this.userId
              ? User.createCacheInstance(
                this.session,
                `userProfiles/${this.userId}`
              )
              : null;
            this.mUser?.subscribe();

            this.mUserDisposer = reaction(
              () => this.userId,
              (userId) => initUser()
            );
          }
        };

        initUser();
      }
    );
  };
  public unsubscribeUser = () => {
    if (this.mUserDisposer) this.mUserDisposer();
    this.mUser?.unsubscribe();
    this.mUser = undefined;
  };

  public get openWorkItems(): WorkItems | null | undefined {
    return this.mOpenWorkItems;
  }
  public subscribeOpenWorkItems = () => {
    when(
      () => this.id !== undefined && (this.user?.loaded ?? false),
      () => {
        if (this.mOpenWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            const allWorkItems = false; //this.user?.role == "admin";
            this.mOpenWorkItems = this.id
              ? new WorkItems(this.session, (allWorkItems ? "*" : `workerProfiles/${this.id}`) + "/activeConversations", 50)
              : null;
            if (this.mOpenWorkItems) {
              let query = this.mOpenWorkItems.collectionRef.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mOpenWorkItems.query = query;
            }
            this.mOpenWorkItems?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeOpenWorkItems = () => {
    this.mOpenWorkItems?.unsubscribe();
    this.mOpenWorkItems = undefined;
  };

  public get myOpenWorkItems(): WorkItem[] | null | undefined {
    return this.openWorkItems?.data?.filter(
      (workItem) =>
        workItem.conversation?.loaded &&
        workItem.conversation?.ownerId === this.id
    );
  }

  public get participatingOpenWorkItems(): WorkItem[] | null | undefined {
    return this.openWorkItems?.data?.filter(
      (workItem) =>
        workItem.conversation?.loaded &&
        workItem.conversation?.ownerId !== this.id
    );
  }

  public get closedWorkItems(): WorkItems | null | undefined {
    return this.mClosedWorkItems;
  }
  public subscribeClosedWorkItems = () => {
    when(
      () => this.id !== undefined && (this.user?.loaded ?? false),
      () => {
        if (this.mClosedWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            const allWorkItems = false; //this.user?.role == "admin";
            this.mClosedWorkItems = this.id
              ? new WorkItems(this.session, (allWorkItems ? "*" : `workerProfiles/${this.id}`) + "/inactiveConversations", 50)
              : null;
            if (this.mClosedWorkItems) {
              let query = this.mClosedWorkItems.collectionRef.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mClosedWorkItems.query = query;
            }
            this.mClosedWorkItems?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeClosedWorkItems = () => {
    this.mClosedWorkItems?.unsubscribe();
    this.mClosedWorkItems = undefined;
  };

  public get otherOpenWorkItems(): WorkItems | null | undefined {
    return this.mOtherOpenWorkItems;
  }
  public subscribeOtherOpenWorkItems = () => {
    when(
      () => this.id !== undefined && (this.user?.loaded ?? false),
      () => {
        if (this.mOtherOpenWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            const isAdmin = false; //this.user?.role === "admin";
            const isGroupAdmin = (this.user?.adminGroupIds?.length ?? 0) > 0;
            this.mOtherOpenWorkItems = (isAdmin || isGroupAdmin) && this.id
              ? new WorkItems(this.session, "*/activeConversations", 50)
              : null;
            if (this.mOtherOpenWorkItems) {
              let query = this.mOtherOpenWorkItems.collectionRef;
              query = !isAdmin && isGroupAdmin ? query.where("groupId", "in", this.session.currentUser?.adminGroupIds ?? [""]) : query;
              query = query.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mOtherOpenWorkItems.query = query;
            }
            this.mOtherOpenWorkItems?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeOtherOpenWorkItems = () => {
    this.mOtherOpenWorkItems?.unsubscribe();
    this.mOtherOpenWorkItems = undefined;
  };

  public get otherClosedWorkItems(): WorkItems | null | undefined {
    return this.mOtherClosedWorkItems;
  }
  public subscribeOtherClosedWorkItems = () => {
    when(
      () => this.id !== undefined && (this.user?.loaded ?? false),
      () => {
        if (this.mOtherClosedWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            const isAdmin = false; //this.user?.role === "admin";
            const isGroupAdmin = (this.user?.adminGroupIds?.length ?? 0) > 0;
            this.mOtherClosedWorkItems = isAdmin && this.id
              ? new WorkItems(this.session, "*/inactiveConversations", 50)
              : null;
            if (this.mOtherClosedWorkItems) {
              let query = this.mOtherClosedWorkItems.collectionRef;
              query = !isAdmin && isGroupAdmin ? query.where("groupId", "in", this.session.currentUser?.adminGroupIds ?? [""]) : query;
              query = query.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mOtherClosedWorkItems.query = query;
            }
            this.mOtherClosedWorkItems?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeOtherClosedWorkItems = () => {
    this.mOtherClosedWorkItems?.unsubscribe();
    this.mOtherClosedWorkItems = undefined;
  };

  public get availableConversations(): Conversations | null | undefined {
    return this.mAvailableConversations;
  }
  public subscribeAvailableConversations = () => {
    when(
      () => this.id !== undefined && this.session.currentUser !== undefined && (this.session.currentUser?.loaded ?? false),
      () => {
        if (
          this.mAvailableConversations === undefined &&
          this.session.currentUser !== undefined &&
          this.id !== undefined
        ) {
          runInAction(() => {
            this.mAvailableConversations = new Conversations(this.session, `conversations`);

            let query = this.mAvailableConversations.collectionRef
              //.where("status", "==", "new")
              .where("ownerId", "==", null)
              .where("isEmulated", "==", false);

            const isAdmin = false; //this.session.currentUser?.role === "admin";
            query = isAdmin ? query : query.where("groupId", "in", this.session.currentUser?.groupIds ?? [""]);
            query.orderBy("createdOn", "asc");
            this.mAvailableConversations.query = query;

            this.mAvailableConversations.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeAvailableConversations = () => {
    this.mAvailableConversations?.unsubscribe();
    this.mAvailableConversations = undefined;
  };

  public get isAvailableConversation(): boolean {
    const count = this.availableConversations?.data?.length ?? 0;
    return count > 0;
  }

  public get incomingConversations(): Conversations | null | undefined {
    return this.mIncomingConversations;
  }
  public subscribeIncomingConversations = () => {
    when(
      () => this.id !== undefined && this.session.currentUser !== undefined && (this.session.currentUser?.loaded ?? false),
      () => {
        if (
          this.mIncomingConversations === undefined &&
          this.session.currentUser !== undefined &&
          this.id !== undefined
        ) {
          runInAction(() => {
            this.mIncomingConversations = new Conversations(this.session, `conversations`);

            let query = this.mIncomingConversations.collectionRef
              .where("ownerId", "!=", this.session.currentUser?.workerProfileId)
              .where("requestTransfer.targetGroupId", "in", this.session.currentUser?.groupIds ?? [""])
              ;

            //.orderBy("createdOn", "asc");
            this.mIncomingConversations.query = query;

            this.mIncomingConversations.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeIncomingConversations = () => {
    this.mIncomingConversations?.unsubscribe();
    this.mIncomingConversations = undefined;
  };

  public acceptAvailableConversation() {
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.isAvailableConversation,
        () => {
          const requestAvailableConversation = this.session.firebase.functions().httpsCallable("requestAvailableConversation");
          requestAvailableConversation(1).then(() => { resolve(undefined) });
        }
      );
    });
  }
}



