import firebase from "firebase/app";
import { Session } from "../../session";

import { UserGroup } from "./UserGroup";

export type UserGroupRole = "admin" | "user";

export interface UserGroupData {
    role: UserGroupRole;
};


export const userGroupDefaultValue = {
    role: "user",
} as UserGroupData;

export const userGroupDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: UserGroupData) {
            return {
                role: data.role,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<UserGroupData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                role: data.role ?? "user",
            } as UserGroupData;
        },
    };
};

export const userGroupConverter = (session: Session) => {
    return {
        toFirestore: function (userGroup: UserGroup) {
            return userGroup.data ? {
                role: userGroup.data.role,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<UserGroupData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return UserGroup.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    role: data.role ?? "user",
                }
            );
        },
    };
};
