

export interface FileData {
    metadata: any | null;
    url: string | null;
}

export const fileDefaultValue = {
    metadata: null,
    url: null,
}