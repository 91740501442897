import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

import { arrowBack, checkmarkOutline } from "ionicons/icons";
import { SessionContext } from "../../session";
import { Prompt, useHistory, useLocation } from "react-router";

const ConversationsSettingsPage: React.FC = observer(() => {
  let location = useLocation();
  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }

  const settings = session.conversationsSettings ?? null;
  const groups = session.groups?.data;

  const { control, handleSubmit, formState, reset, errors } = useForm(
    {
      mode: "onChange",
    }
  );

  useEffect(() => {
    reset();
  }, [location]);

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <IonNote color="danger" mode="md">
        {error.message || "Field Is Required"}
      </IonNote>
    ) : null;
  };

  const onSubmit = async (data: any) => {
    if (formState.isValid && settings) {
      try {
        await settings.update({
          groupId: data.groupId,
        });

        reset({
          groupId: data.groupId,
        });
      } catch (error) {
        alert("Unable to save settings");
        console.error("Error saving Settings.", error);
      }
    }
  };

  if (!settings?.loaded) {
    return <IonProgressBar type="indeterminate" />;
  }

  const toTimeSeconds = (timeString: string) => {
    if (!isNaN(timeString as any)) {
      return parseInt(timeString);
    }

    const parts = (timeString || "0:0:0")
      .split(":")
      .map(part => parseInt(part));
    return (parts[0] * 60 * 60) + (parts[1] * 60) + parts[2];
  }

  const toTimeString = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / (60 * 60));
    totalSeconds -= (hours * 60 * 60);

    const minutes = Math.floor(totalSeconds / 60);
    totalSeconds -= (minutes * 60);

    return moment(`${hours}:${minutes}:${totalSeconds}`, "h:m:s").format("HH:mm:ss");
  }

  return (
    <IonPage>
      <Prompt
        when={formState.isDirty}
        message={location =>
          `Are you sure you want to discard your changes?`
        }
      />
      <IonHeader
        translucent
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {(session.windowSize === "small") ? (
              <>
                {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                <IonButton routerLink="/admin">
                  <IonIcon icon={arrowBack} />
                </IonButton>
              </>
            ) : (
              <></>
            )}
          </IonButtons>
          <IonTitle>Conversations</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={handleSubmit(onSubmit)}
              disabled={
                !formState.isValid ||
                formState.isSubmitting ||
                !formState.isDirty
              }
              shape="round"
              expand="block"
              size="large"
              fill="solid"
              color="light"
            >
              <IonIcon hidden icon={checkmarkOutline} />
              {formState.isSubmitting ? "Saving..." : "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        {settings ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList lines="none" className="ion-no-padding">
              <IonListHeader
                color="light"
                style={{ paddingTop: "40px", paddingBottom: "10px" }}
              >
                <IonLabel>
                  <strong>DEFAULTS</strong>
                  <p style={{ paddingTop: "5px" }}>
                    <IonText color="dark" style={{ fontSize: "12px" }}>
                      This is the default group that will be assigned to new phone numbers.
                    </IonText>
                  </p>
                </IonLabel>
              </IonListHeader>
              <IonItem className="default-element-border" lines="none" style={{ paddingTop: "5px", paddingBottom: "15px" }}>
                <IonLabel position="stacked">Default Group</IonLabel>
                <Controller
                  control={control}
                  defaultValue={settings.groupId}
                  name="groupId"
                  rules={{
                    required: "A default group for the phone number is required.",
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonSelect
                      className="input-field"
                      value={value}
                      onIonChange={onChange}
                      onIonBlur={onBlur}
                      interfaceOptions={{
                        cssClass: "select-alert"
                      }}
                    >
                      {groups && groups.length > 0 && groups.map(group => {
                        return (
                          <IonSelectOption
                            key={group.id}
                            value={group.id}
                          >
                            {group.name}
                          </IonSelectOption>
                        )
                      })}
                    </IonSelect>
                  )}
                />
                {showError("group")}
              </IonItem>
            </IonList>
          </form>
        ) : (
          <div></div>
        )}
      </IonContent>
    </IonPage>
  );
});

export default ConversationsSettingsPage;
