import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Control, Controller, DeepMap, FieldError, FieldValues, FormState, useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import ReactDOMServer from 'react-dom/server';

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";

import {
  close,
  closeOutline,
  checkmarkOutline,
  trash,
  peopleCircle,
  peopleCircleOutline,
  remove,
  personRemove,
  add
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AddUserButton from "./AddUserButton";
import moment from "moment";

interface ToggleChangeEventDetail {
  value: boolean;
  checked: boolean;
}
interface GroupDetailProps {
  session: Session;
  segment: string;
  group: Models.Group;
  onDismiss: () => void;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GroupDetail_segment_info: React.FC<GroupDetailProps> = observer(
  ({ session, segment, group, onDismiss, control, formState, errors }) => {
    const [presentToast, dismissToast] = useIonToast();

    const phoneNumbers = session.phoneNumbers?.data;

    const qrcodeEngageBody = group.tag ? `engage @${group.tag}` : undefined;
    const qrcodeEngageLink = qrcodeEngageBody && group.phoneNumber?.phone ? `sms://${group.phoneNumber?.phone}?&body=${encodeURI(qrcodeEngageBody)}` : undefined;
    const qrcodeEngage = qrcodeEngageBody && group.phoneNumber?.phone ? ReactDOMServer.renderToString(<QRCode id="qrcodeStatus" value={`smsto:${group.phoneNumber?.phone}:${qrcodeEngageBody}`} />) : undefined;
    const qrcodeEngageEncodedString = qrcodeEngage ? Buffer.from(qrcodeEngage).toString('base64') : undefined;
    const qrcodeEngageImgSrc = qrcodeEngageEncodedString ? `data:image/svg+xml;base64,${qrcodeEngageEncodedString}` : undefined;

    /// Handlers ---
    const onDeleteGroup = () => {
      // Validation
      if (!session.currentUser) {
        console.warn('You need to be logged in to perform this action.');
        return;
      }

      if (session.currentUser?.role !== "admin") {
        console.warn('Logged in group does not have the required permissions to perform this action.');
        return;
      }

      // Perform action
      group
        .delete()
        .then(() => onDismiss())
        .catch(error => console.error('Error deleting group.', error));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && group) {
        const acceptPeriod = toTimeSeconds(data.conversationAcceptPeriodSeconds);
        const replyPeriod = toTimeSeconds(data.messageResponsePeriodSeconds);

        group.name = data.name.trim();
        group.tag = data.tag.trim();
        group.phoneNumberId = data.phoneNumberId;
        group.greetingMessage = data.greetingMessage;
        group.noAgentAvailableMessage = data.noAgentAvailableMessage;
        group.slowConversationAcceptMessage = data.slowConversationAcceptMessage;
        group.conversationAcceptPeriodSeconds = acceptPeriod;
        group.messageResponsePeriodSeconds = replyPeriod;

        try {
          await group.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save group.");
          console.log("Error saving group.", error);
        }
      }
    };
    /// --- handlers

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const toTimeSeconds = (timeString: string) => {
      if (!isNaN(timeString as any)) {
        return parseInt(timeString);
      }

      const parts = (timeString || "0:0:0")
        .split(":")
        .map(part => parseInt(part));
      return (parts[0] * 60 * 60) + (parts[1] * 60) + parts[2];
    }

    const toTimeString = (totalSeconds: number) => {
      const hours = Math.floor(totalSeconds / (60 * 60));
      totalSeconds -= (hours * 60 * 60);

      const minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= (minutes * 60);

      return moment(`${hours}:${minutes}:${totalSeconds}`, "h:m:s").format("HH:mm:ss");
    }

    function downloadImage(src: string, fileName: string) {
      const img = new Image();
      img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
      // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
      img.src = src;
      img.onload = () => {
        // create Canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        // create a tag
        const a = document.createElement('a');
        a.download = fileName;
        a.href = canvas.toDataURL('image/png');
        a.click();
      };
    }

    function copyImage(src: string, fileName: string) {
      const img = new Image();
      img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
      // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
      img.src = src;
      img.onload = () => {
        // create Canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        canvas.toBlob(copyToClipboard, 'image/png')
      };
    }

    async function copyToClipboard(pngBlob: any) {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            ['image/png']: pngBlob
          })
        ]);
        console.log("Image copied to clipboard!");
      } catch (error) {
        console.error(error);
      }
    }

    return (
      <IonContent hidden={segment !== "info"} fullscreen color="light">
        {group ? (
          <>
            <IonList lines="none" className="ion-no-padding">

              <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                <strong>BASIC DETAILS</strong>
              </IonListHeader>
              <IonItem key="name" lines="none" style={{ paddingTop: "5px" }}>
                <IonLabel position="stacked" color="medium">
                  Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.name}
                  name="name"
                  rules={{
                    required: {
                      message: "A group name is required.",
                      value: true,
                    },
                    minLength: {
                      message: "Must be at least 2 characters long.",
                      value: 2,
                    },
                    validate: (data) => {
                      const searchString = data.toLowerCase().trim();
                      const found = session.groups?.data?.filter(
                        (existing) =>
                          existing.id !== group.id &&
                          existing.name?.toLowerCase().trim() ===
                          searchString
                      ) ?? [];
                      return found.length === 0
                        ? true
                        : "Name must be unique";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("name")}
              </IonItem>
              <IonItem key="tag" lines="none" style={{ paddingTop: "5px" }}>
                <IonLabel position="stacked" color="medium">
                  Tag
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.tag}
                  name="tag"
                  rules={{
                    required: {
                      message: "A group tag is required.",
                      value: true,
                    },
                    minLength: {
                      message: "Must be at least 3 characters long.",
                      value: 3,
                    },
                    validate: (data) => {
                      if ((data || "").trim().length === 0) {
                        return "Tag is required";
                      }

                      if (!data.match(/^[a-z0-9][a-z0-9-]{1,18}[a-z0-9]$/g)) {
                        return "Tag must be between 3 and 20 lowercase alphanumeric characters long"
                      }

                      const searchString = data.toLowerCase().trim();
                      const found = session.groups?.data?.filter(
                        (existing) =>
                          existing.id !== group.id &&
                          existing.tag?.toLowerCase().trim() === searchString
                      ) ?? [];
                      return found.length === 0
                        ? true
                        : "Tag must be unique";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    >
                      <IonText color="medium" style={{ paddingLeft: "10px" }}>@</IonText>
                    </IonInput>
                  )}
                />
                {renderError("tag")}
              </IonItem>
              <IonItem className="default-element-border" lines="none" style={{ paddingTop: "5px", paddingBottom: "15px" }}>
                <IonLabel position="stacked" color="medium">
                  Default Outbound Phone Number
                </IonLabel>
                <IonNote style={{ marginTop: "7px" }}>
                  The phone number which will be used for new outbound text/SMS conversations related to this group.
                </IonNote>
                <Controller
                  control={control}
                  defaultValue={group.phoneNumberId}
                  name="phoneNumberId"
                  rules={{
                    required: "A default phone number for the group is required.",
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonSelect
                      className="input-field"
                      value={value}
                      onIonChange={onChange}
                      onIonBlur={onBlur}
                      interfaceOptions={{
                        cssClass: "select-alert"
                      }}
                    >
                      {phoneNumbers && phoneNumbers.length > 0 && phoneNumbers.map(phoneNumber => {
                        return (
                          <IonSelectOption
                            key={phoneNumber.id}
                            value={phoneNumber.id}
                          >
                            {phoneNumber.name} ({phoneNumber.phone})
                          </IonSelectOption>
                        )
                      })}
                    </IonSelect>
                  )}
                />
                {renderError("phoneNumberId")}
              </IonItem>
              <IonListHeader color="light" style={{ paddingTop: "40px" }}>
                These are the automated messages that you can use to help you
                manage your conversations. These messages are optional and
                customizable for your needs. If you leave them blank no message
                will be sent.
              </IonListHeader>
              <IonListHeader
                color="light"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <IonLabel>
                  <strong>GREETING MESSAGE</strong>
                  <p style={{ paddingTop: "5px" }}>
                    <IonText color="dark" style={{ fontSize: "12px" }}>
                      This message will be sent automatically when a message is
                      received and a new conversation is created. The message
                      will be sent immediately upon entrance in the Waiting
                      Area.
                    </IonText>
                  </p>
                </IonLabel>
              </IonListHeader>
              <IonItem key="greetingMessage" lines="none" style={{}}>
                <IonLabel position="stacked" color="medium">
                  Message
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.greetingMessage}
                  name="greetingMessage"
                  //rules={{
                  //  required: false,
                  //minLength: {
                  //  value: 2,
                  //  message: "Must be at least 2 characters long",
                  //},
                  //}}
                  render={({ onBlur, onChange, value }) => (
                    <IonTextarea
                      spellcheck={true}
                      autocapitalize="on"
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Type message here..."
                      autoGrow
                    >
                      <IonButton hidden>patch</IonButton>
                    </IonTextarea>
                  )}
                />
                {renderError("greetingMessage")}
              </IonItem>

              {/* GREETING MESSAGE */}
              <IonListHeader
                color="light"
                style={{ paddingTop: "40px", paddingBottom: "10px" }}
              >
                <IonLabel>
                  <strong>DELAYED CONVERSATION ACCEPTANCE MESSAGE</strong>
                  <p style={{ paddingTop: "5px" }}>
                    <IonText color="dark" style={{ fontSize: "12px" }}>
                      This message will be sent automatically when conversations
                      stay in the Waiting Area without being accepted, for the
                      specifed amount of time below.
                    </IonText>
                  </p>
                </IonLabel>
              </IonListHeader>
              <IonItem key="slowConversationAcceptMessage" lines="none">
                <IonLabel position="stacked" color="medium">
                  Message
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.slowConversationAcceptMessage}
                  name="slowConversationAcceptMessage"
                  //rules={{
                  //  required: false,
                  //minLength: {
                  //  value: 2,
                  //  message: "Must be at least 2 characters long",
                  //},
                  //}}
                  render={({ onBlur, onChange, value }) => (
                    <IonTextarea
                      spellcheck={true}
                      autocapitalize="on"
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Type message here..."
                      autoGrow
                    >
                      <IonButton hidden>patch</IonButton>
                    </IonTextarea>
                  )}
                />
                {renderError("slowConversationAcceptMessage")}
              </IonItem>
              {/* SLOW CONVERSATION ENGAGEMENT */}
              <IonItem key="conversationAcceptPeriodSeconds" lines="none">
                <IonLabel position="stacked" color="medium">
                  Time to wait after initial inbound message
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.conversationAcceptPeriodSeconds}
                  name="conversationAcceptPeriodSeconds"
                  rules={{
                    validate: (data) => {
                      const totalSeconds = toTimeSeconds(data);
                      return totalSeconds > 0 || "A waiting time is required.";
                    }
                  }}
                  render={({ onBlur, onChange, value }) => {
                    const timeString = isNaN(value) ? value : toTimeString(value)
                    return (
                      <IonDatetime
                        onIonBlur={onBlur}
                        onIonChange={onChange}
                        displayFormat="HH:mm:ss"
                        value={timeString}
                      />
                    );
                  }}
                />
              </IonItem>
              {renderError("conversationAcceptPeriodSeconds")}
              <IonListHeader
                color="light"
                style={{ paddingTop: "40px", paddingBottom: "10px" }}
              >
                <IonLabel>
                  <strong>DELAYED RESPONSE TO INBOUND MESSAGE</strong>
                  <p style={{ paddingTop: "5px" }}>
                    <IonText color="dark" style={{ fontSize: "12px" }}>
                      This message will be sent automatically when the owner of
                      an assigned conversation has not replied to a received
                      message in the specified amount of time below. When multiple
                      consecutive messages are received, only the first message will
                      be responded to.
                    </IonText>
                  </p>
                </IonLabel>
              </IonListHeader>
              <IonItem key="noAgentAvailableMessage" lines="none">
                <IonLabel position="stacked" color="medium">
                  Message
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.noAgentAvailableMessage}
                  name="noAgentAvailableMessage"
                  //rules={{
                  //  required: false,
                  //minLength: {
                  //  value: 2,
                  //  message: "Must be at least 2 characters long",
                  //},
                  //}}
                  render={({ onBlur, onChange, value }) => (
                    <IonTextarea
                      spellcheck={true}
                      autocapitalize="on"
                      onIonInput={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Type message here..."
                      autoGrow
                    >
                      <IonButton hidden>patch</IonButton>
                    </IonTextarea>
                  )}
                />
                {renderError("noAgentAvailableMessage")}
              </IonItem>
              <IonItem key="messageResponsePeriodSeconds" lines="none">
                <IonLabel position="stacked" color="medium">
                  Time to wait after last inbound message
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={group.messageResponsePeriodSeconds}
                  name="messageResponsePeriodSeconds"
                  rules={{
                    validate: (data) => {
                      const totalSeconds = toTimeSeconds(data);
                      return totalSeconds > 0 || "A waiting time is required.";
                    }
                  }}
                  render={({ onBlur, onChange, value }) => {
                    const timeString = isNaN(value) ? value : toTimeString(value)
                    return (
                      <IonDatetime
                        onIonBlur={onBlur}
                        onIonChange={onChange}
                        displayFormat="HH:mm:ss"
                        value={timeString}
                      />
                    );
                  }}
                />
                {renderError("messageResponsePeriodSeconds")}
              </IonItem>
              {qrcodeEngageLink && qrcodeEngageImgSrc && group.tag
                ? (
                  <>
                    <IonListHeader
                      color="light"
                      style={{ paddingTop: "40px", paddingBottom: "10px" }}
                    >
                      <IonLabel>
                        <strong>ENGAGE WITH GROUP</strong>

                      </IonLabel>
                    </IonListHeader>
                    <IonItem lines="none">
                      <IonLabel position="stacked"></IonLabel>
                      <div style={{ paddingTop: "20px", paddingLeft: "10px" }}>
                        <img style={{ height: "150px", width: "150px" }} src={qrcodeEngageImgSrc} alt="qrcode_engage.svg" onClick={() => downloadImage(qrcodeEngageImgSrc, `${group.tag}_qrcode_engage.png`)} />
                      </div>
                      <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                        <IonButton style={{ width: "100px" }} fill="outline" color="dark" onClick={() => downloadImage(qrcodeEngageImgSrc, `${group.tag}_qrcode_status.png`)}>Download</IonButton>
                        {navigator && navigator.clipboard &&
                          <>
                            <IonButton hidden onClick={() => copyImage(qrcodeEngageImgSrc, `${group.data?.tag}_qrcode_status.png`)}>Copy Image</IonButton>
                            <IonButton style={{ width: "100px" }} fill="outline" color="dark" onClick={async () => {
                              await navigator.clipboard.writeText(qrcodeEngageLink);
                              presentToast({
                                //buttons: [{ text: 'hide', handler: () => dismissToast() }],
                                message: "Link copied!",
                                duration: 1000,
                                //onDidDismiss: () => {},
                                //onWillDismiss: () => {},
                              })
                            }}>Copy Link</IonButton>
                          </>
                        }
                      </div>
                    </IonItem>
                  </>
                ) : undefined
              }
            </IonList>
          </>
        ) : (
          <div>Group not found</div>
        )}
      </IonContent>
    );
    /// --- render
  }
);
