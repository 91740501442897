import { gql, useQuery } from "@apollo/client";
import { IonLabel, IonSpinner, IonText, useIonViewDidEnter } from "@ionic/react";
import { ResponsivePie } from "@nivo/pie";
import { observer } from "mobx-react-lite";
import { hashCode } from "../../../../../utils/Helpers";

const GRAPHQL_QUERY = gql`
  query Query {
    assignedConversations: conversationStatus(id: "active") {
      conversationsCount
    }
    waitingConversations: conversationStatus(id: "new") {
      conversationsCount
    }
  }
`;

interface Query {
  assignedConversations: ConversationStatus;
  waitingConversations: ConversationStatus;
}

interface ConversationStatus {
  conversationsCount: number;
}

const CenteredMetric = (props: {
  dataWithArc: any[];
  centerX: string | number | undefined;
  centerY: string | number | undefined;
}) => {
  let total = 0;
  props.dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <text
      x={props.centerX}
      y={props.centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "52px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};

const OpenConversationsChart = observer(() => {
  const { loading, error, data, refetch } = useQuery<Query>(GRAPHQL_QUERY);

  useIonViewDidEnter(
    () => {
      refetch();
    }
  );

  if (error) {
    console.error("Dashboard Error (OPEN CONVERSATIONS): ", error)
  }

  return (
    <div style={{ height: "400px" }}>
      <IonLabel>
        <IonText color="dark">
          <strong>OPEN CONVERSATIONS</strong>
        </IonText>
      </IonLabel>
      {loading ? (
        <IonLabel>
          <p>
            <IonSpinner />
          </p>
        </IonLabel>
      ) : error ? (
        <IonLabel>Failed to load.</IonLabel>
      ) : !data ? (
        <></>
      ) : (
        <ResponsivePie
          data={[
            {
              id: "Assigned",
              label: "Assigned",
              value: data.assignedConversations.conversationsCount,
              color: `var(--ion-color-secondary)`,
            },
            {
              id: "Waiting",
              label: "Waiting",
              value: data.waitingConversations.conversationsCount,
              color: `var(--ion-color-light)`,
            },
          ]}
          colors={{ datum: "data.color" }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={5}
          activeOuterRadiusOffset={8}
          //borderWidth={1}
          //borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          legends={[]}
          animate={false}
          layers={[
            "arcs",
            "arcLabels",
            "arcLinkLabels",
            "legends",
            CenteredMetric,
          ]}
        />
      )}
    </div>
  );
});

export default OpenConversationsChart;
