import React from "react";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";

import { Customer } from "../../../models/Customers";

interface CustomerListItemProps {
  customer: Customer;
}

const CustomerListItem: React.FC<CustomerListItemProps> = observer(
  ({ customer }) => {
    if (customer && customer.loaded) {
      return (
        <IonItem routerLink={`/customers/${customer.id}`} detail={false}>
          <IonAvatar
            slot="start"
            style={{
              height: "50px",
              width: "50px",
            }}
          >
            <IonIcon
              icon={personCircle}
              color="primary"
              style={{ fontSize: "50px" }}
            ></IonIcon>
          </IonAvatar>
          <IonLabel>
            <h2>
              {customer.displayName}
            </h2>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
          </h2>
        </IonLabel>
      </IonItem>
    );
  }
);

export default CustomerListItem;
