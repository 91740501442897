import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonImg,
  IonText,
} from "@ionic/react";

import { add, close, personAdd } from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";

interface AddUserButtonProps {
  parent?: HTMLElement | null;
  session: Session,
  group: Models.Group | null | undefined;
  displayAsItem?: boolean;
}

const AddUserButton: React.FC<AddUserButtonProps> = observer(
  ({ parent, session, group, displayAsItem }) => {
    const [showModal, set_showModal] = useState(false);
    const [searchText, setSearchText] = useState("");

    const users = session.users?.data?.filter(
      (user) =>
        user.loaded && user.data && user.data.isEnabled
        && group?.users?.data?.findIndex(existingUser => existingUser.id === user.id) === -1
        && (
          (
            (user.firstName ?? "").trim() !== ""
            && (user.firstName ?? "")
              .toUpperCase()
              .includes((searchText ?? "").toUpperCase())
          ) || (
            (user.lastName ?? "").trim() !== ""
            && (
              (user.lastName ?? "")
                .toUpperCase()
                .includes((searchText ?? "").toUpperCase())
            )
          )
        )
    );

    return (
      <>
        {displayAsItem
          ? (
            <IonItem button onClick={() => set_showModal(true)}>
              <IonIcon icon={add} slot="start" color="primary" />
              <IonLabel color="primary">Add User</IonLabel>
            </IonItem>
          )
          : (
            <IonButton
              shape="round"
              expand="block"
              fill="solid"
              color="dark"
              style={{ height: "26px", marginRight: "10px", marginLeft: "auto" }}
              onClick={() => set_showModal(true)}
              disabled={group === undefined || group === null}
              hidden={
                !(
                  session.currentUser?.role === "admin"
                )
              }
            >
              <IonIcon icon={add} slot="start" />
              <IonLabel>Add User</IonLabel>
            </IonButton>
          )}
        <IonModal
          cssClass="modal-popup-view"
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={(e) => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Add User</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonSearchbar
              color="light"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
            <IonList lines="none">
              {users && users.length > 0
                ? users.map((user) => (
                  <IonItem
                    button
                    key={user.id}
                    onClick={() => {
                      user
                        ?.addGroup(group!.id!, "user")
                        .then(() => set_showModal(false));
                    }}
                  >
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end"
                    >
                      {user?.avatar
                        ? <AnimatedImg alt="" src={user?.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{`${user.firstName ?? ""} ${user.lastName ?? ""}`.trim()}</IonLabel>
                  </IonItem>
                ))
                : (searchText ?? "") !== "" && (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-center">
                      <IonImg
                        style={{
                          maxWidth: "250px",
                          margin: "auto",
                          marginTop: "69px",
                        }}
                        src={
                          (searchText ?? "") === ""
                            ? "/assets/images/artboard007.png"
                            : "/assets/images/artboard008.png"
                        }
                      />
                      <p>
                        <strong>
                          <IonText
                            color="dark"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {(searchText ?? "") === ""
                              ? ""
                              : " No Search Results"}
                          </IonText>
                        </strong>
                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default AddUserButton;
