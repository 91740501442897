import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonBadge,
  IonItemDivider,
  IonButtons,
  IonMenuButton,
  isPlatform,
  IonListHeader,
  IonText,
  IonChip,
  IonIcon,
  IonItem,
  IonImg,
  IonSpinner,
} from "@ionic/react";

import WaitingConversationsListItem from "./components/WaitingConversationsListItem";
import WorkItemsListItem from "./components/WorkItemsListItem";

import * as Models from "../../models";
import { SessionContext } from "../../session";
import { closeCircle, closeOutline } from "ionicons/icons";
import EmptyContainer from "../../components/EmptyContainer";
import { relativeDateString } from "../../utils/Helpers";
import NewConversationButton from "./components/NewConversationButton";
import { createSwipeBackGesture } from "../../utils/gestures/swipeBackGesture";

const ConversationsPage: React.FC = observer(() => {
  const history = useHistory();

  const [loadingMore_closed, setLoadingMore_closed] = useState(false);
  const [loadingMore_open, setLoadingMore_open] = useState(false);
  const [segment, setSegment] = useState<string | undefined>("assigned");
  const [showWaiting, setShowWaiting] = useState(false);
  const [showAssigned, setShowAssigned] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);

  const [showMine, setShowMine] = useState(true);
  const [showParticipating, setShowParticipating] =
    useState(true);
  const [showOther, setShowOther] = useState(false);

  const pageRef = useRef<HTMLElement>()

  useEffect(() => {
    if (pageRef.current) {
      const swipeBackGesture = createSwipeBackGesture(
        pageRef.current,
        () => { return true; }, // canStartHandler
        () => { }, // onStartHandler
        (step: number) => { }, // onMoveHandler
        (shouldComplete: boolean, step: number, dur: number) => { } // onEndHandler
      );
      swipeBackGesture.enable();
    }
  }, [pageRef.current])

  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }

  const workerProfile = session.currentUser?.workerProfile?.loaded
    ? session.currentUser?.workerProfile
    : undefined;

  const openWorkItems = workerProfile?.openWorkItems?.loaded
    ? workerProfile?.openWorkItems.data?.concat(
      workerProfile?.otherOpenWorkItems?.loaded
        ? (workerProfile?.otherOpenWorkItems.data || []).filter((otherOpenWorkItem) => {
          if (otherOpenWorkItem.conversation?.status === "active") {
            const isOwner = (otherOpenWorkItem.conversation?.ownerId === session.currentUser?.workerProfile?.id);
            const isParticipant = (otherOpenWorkItem.conversation?.isParticipant && !isOwner);
            const isOther = !isOwner && !isParticipant;

            return isOther;
          }
          return false;
        })
        : []
    ).sort((a, b) => a.data?.lastContactDate && b.data?.lastContactDate && a.data?.lastContactDate > b.data?.lastContactDate ? 1 : 0)
      .reduce(
        (groups: Record<string, Models.WorkItem[]>, item) => {
          let group = "assigned";

          if (item.conversation?.requestTransfer) {
            if (item.conversation.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId) {
              group = "incoming";
            } //else if (
            //  item.conversation.ownerId == session.currentUser?.workerProfileId
            //) {
            //  group = "outgoing";
            //}
          }

          if (!groups[group]) {
            groups[group] = [];
          }

          if (item.conversation?.status === "active") {
            const isOwner = (item.conversation?.ownerId === session.currentUser?.workerProfile?.id);
            const isParticipant = (item.conversation?.isParticipant && !isOwner);
            const isOther = !isOwner && !isParticipant;

            if (
              group !== "assigned" || (
                group === "assigned" && (
                  (showMine && isOwner)
                  || (showParticipating && isParticipant)
                  || (showOther && isOther)
                )
              )
            ) {
              groups[group].push(item);
            }
          }

          if (group === "incoming" || group === "outgoing") {
            groups[group].sort((a, b) =>
              (a.conversation?.requestTransfer?.requestedOn?.toDate() ??
                new Date()) >
                (b.conversation?.requestTransfer?.requestedOn?.toDate() ??
                  new Date())
                ? 1
                : 0
            );
          }

          return groups;
        },
        {}
      )
    : undefined;

  const waitingCount = 0
    + (workerProfile?.availableConversations?.data?.length ?? 0)
    + (workerProfile?.incomingConversations?.data?.length ?? 0)
    + (openWorkItems?.["incoming"]?.length ?? 0);

  const assignedCount = openWorkItems?.["assigned"]
    ? openWorkItems?.["assigned"].filter(
      (workItem) =>
        workItem.conversation?.workItem?.hasUnreadInternalMessages ||
        workItem.conversation?.workItem?.hasUnreadNotes ||
        workItem.conversation?.workItem?.hasUnreadMessages
    ).length ?? 0
    : undefined;

  const lastContactDateWorkItem_open = workerProfile?.openWorkItems?.data?.slice().concat(
    (workerProfile?.otherOpenWorkItems?.data || [])
  ).sort(
    (a, b) => (a.data?.lastContactDate && b.data?.lastContactDate && a.data?.lastContactDate > b.data?.lastContactDate) ? 1 : -1
  )[0];
  const lastContactDate_open = lastContactDateWorkItem_open?.data?.lastContactDate;

  const lastContactDateWorkItem_closed = workerProfile?.closedWorkItems?.data?.slice().concat(
    (workerProfile?.otherClosedWorkItems?.data || [])
  ).sort(
    (a, b) => (a.data?.lastContactDate && b.data?.lastContactDate && a.data?.lastContactDate > b.data?.lastContactDate) ? 1 : -1
  )[0];
  const lastContactDate_closed = lastContactDateWorkItem_closed?.data?.lastContactDate;

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {session.windowSize === "small" ? (
              <></>
            ) : (
              <IonMenuButton hidden menu="start" autoHide={false} />
            )}
          </IonButtons>
          <IonTitle>Conversations</IonTitle>
          <IonButtons slot="end">
            <NewConversationButton session={session} />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            mode="md"
            onIonChange={(e) => {
              const segment = e.detail.value;
              switch (segment) {
                case "waiting":
                  if (!showWaiting) setShowWaiting(true);
                  break;

                case "assigned":
                  if (!showAssigned) setShowAssigned(true);
                  break;

                case "completed":
                  if (!showCompleted) setShowCompleted(true);
                  break;
              }
              setSegment(segment);
            }}
            value={segment}
            scrollable
          >
            <IonSegmentButton value="waiting" layout="icon-end">
              <IonBadge mode="ios" hidden={waitingCount === 0}>
                {waitingCount}
              </IonBadge>
              <IonLabel>Waiting </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="assigned" layout="icon-end">
              <IonBadge mode="ios" hidden={assignedCount === 0}>
                {assignedCount}
              </IonBadge>
              <IonLabel>Assigned</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="completed">
              <IonLabel>Completed</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {showWaiting && (
          <>
            <IonProgressBar
              hidden={
                !(
                  segment === "waiting" &&
                  (workerProfile?.availableConversations?.loading ?? true)
                )
              }
              type="indeterminate"
            />

            <IonList
              hidden={
                !(
                  segment === "waiting" &&
                  (workerProfile?.availableConversations?.loaded ?? false)
                )
              }
              lines="full"
              className="ion-no-padding"
            >
              <IonItem
                style={{}}
                lines="none"
                hidden={
                  (!workerProfile?.availableConversations?.loaded ?? true)
                  || (workerProfile?.availableConversations?.data?.length ?? 0) > 0
                  || (workerProfile?.incomingConversations?.data?.length ?? 0) > 0
                  || (openWorkItems?.["incoming"]?.length ?? 0) > 0
                }
              >
                <IonLabel className="ion-text-center">
                  <IonImg
                    style={{
                      maxWidth: "180px",
                      margin: "auto",
                      marginTop: "69px",
                    }}
                    src="/assets/images/artboard006.png"
                  />
                  <p>
                    <strong>
                      <IonText
                        color="dark"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Conversations yet!
                      </IonText>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <IonText
                      color="medium"
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Check back a bit later.
                    </IonText>
                  </p>
                </IonLabel>
              </IonItem>
              <IonItemDivider mode="ios" hidden={!((workerProfile?.availableConversations?.data?.length ?? 0) > 0)}>
                New
              </IonItemDivider>
              {workerProfile?.availableConversations?.loaded &&
                workerProfile?.availableConversations?.data?.map((item) => (
                  <WaitingConversationsListItem
                    key={item.id}
                    conversation={item}
                    onConversationAccept={(conversation) => {
                      setSegment("assigned");
                      history.push(`/conversations/${conversation.id}`);
                    }}
                  />
                ))}
              <IonItemDivider mode="ios" hidden={!((openWorkItems?.["incoming"]?.length ?? 0) > 0)}>
                Transfer
              </IonItemDivider>
              {openWorkItems
                && openWorkItems["incoming"]
                && openWorkItems["incoming"].length > 0
                && (
                  <>
                    <div hidden style={{ borderTop: "2px solid grey" }} />
                    {openWorkItems["incoming"].map((item) => (
                      <WorkItemsListItem key={item.id} workItem={item} inWaitingSection />
                    ))}
                    <IonItemDivider hidden color="light"></IonItemDivider>
                    <div hidden style={{ borderTop: "2px solid grey" }} />
                  </>
                )
              }
              <IonItemDivider mode="ios" hidden={!((workerProfile?.incomingConversations?.data?.length ?? 0) > 0)}>
                Group Transfer
              </IonItemDivider>
              {workerProfile?.incomingConversations?.loaded &&
                workerProfile?.incomingConversations?.data?.map((item) => (
                  <WaitingConversationsListItem
                    key={item.id}
                    conversation={item}
                    onConversationAccept={(conversation) => {
                      setSegment("assigned");
                      history.push(`/conversations/${conversation.id}`);
                    }}
                  />
                ))}
            </IonList>
          </>
        )}

        {showAssigned && (
          <>
            <IonProgressBar
              hidden={!(segment === "assigned" && openWorkItems === undefined)}
              type="indeterminate"
            />
            <IonList
              hidden={!(segment === "assigned" && openWorkItems !== undefined)}
              lines="full"
              className="ion-no-padding"
            >

              <IonItem lines="full">
                <IonChip
                  color="primary"
                  outline={!showMine}
                  onClick={() => setShowMine((prev) => !prev)}
                  style={
                    showMine
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-primary)",
                      }
                      : {}
                  }
                >
                  Mine
                  <IonIcon
                    hidden={!showMine || true}
                    icon={closeCircle}
                  />
                </IonChip>
                <IonChip
                  color="secondary"
                  outline={!showParticipating}
                  onClick={() => setShowParticipating((prev) => !prev)}
                  style={
                    showParticipating
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-secondary)",
                      }
                      : {}
                  }
                >
                  Participating
                  <IonIcon
                    hidden={!showParticipating || true}
                    icon={closeCircle}
                  />
                </IonChip>
                <IonChip
                  hidden={
                    !(
                      session.currentUser?.role === "admin" || (session.currentUser?.adminGroupIds?.length ?? 0) > 0
                    )
                  }
                  color="medium"
                  outline={!showOther}
                  onClick={() => setShowOther((prev) => !prev)}
                  style={
                    showOther
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-medium)",
                      }
                      : {}
                  }
                >
                  Other
                  <IonIcon
                    hidden={!showOther || true}
                    icon={closeCircle}
                  />
                </IonChip>

              </IonItem>
              <IonItem
                style={{}}
                lines="none"
                hidden={
                  !(
                    openWorkItems
                    && ((openWorkItems["assigned"] && openWorkItems["assigned"].length) ?? 0) === 0
                    && ((openWorkItems["incoming"] && openWorkItems["incoming"].length) ?? 0) === 0
                  )
                }
              >
                <IonLabel className="ion-text-center">
                  <IonImg
                    style={{
                      maxWidth: "180px",
                      margin: "auto",
                      marginTop: "20px",
                    }}
                    src="/assets/images/artboard005.png"
                  />
                  <p>
                    <strong>
                      <IonText
                        color="dark"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Conversations
                      </IonText>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <IonText
                      hidden={!showMine}
                      color="medium"
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Accept a Conversation from the Waiting list.
                    </IonText>
                  </p>
                </IonLabel>
              </IonItem>
              {openWorkItems
                && openWorkItems["incoming"]
                && openWorkItems["incoming"].length > 0
                && (
                  <>
                    <div hidden style={{ borderTop: "2px solid grey" }} />
                    {openWorkItems["incoming"].map((item) => (
                      <WorkItemsListItem key={item.id} workItem={item} />
                    ))}
                    <IonItemDivider hidden color="light"></IonItemDivider>
                    <div hidden style={{ borderTop: "2px solid grey" }} />
                  </>
                )
              }

              {openWorkItems &&
                openWorkItems["assigned"] &&
                openWorkItems["assigned"].map((item) => {
                  return <WorkItemsListItem key={item.id} workItem={item} />;
                })
              }

              {(typeof workerProfile?.openWorkItems?.limit !== "undefined") && (
                <IonItem
                  color="light"
                  button
                  onClick={async (e) => {
                    if (workerProfile?.openWorkItems) {
                      setLoadingMore_open(true);
                      workerProfile.openWorkItems.loadMore();
                      workerProfile.otherOpenWorkItems?.loadMore();
                      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
                      setLoadingMore_open(false);
                    }
                  }}

                >
                  {loadingMore_open
                    ? <IonSpinner color="light" style={{ margin: "auto" }} />
                    : (
                      <IonLabel className="ion-text-center" style={{ paddingBottom: "12px" }}>
                        <p>
                          {`Showing from ${lastContactDate_open && relativeDateString(lastContactDate_open.toDate(), true)}`}
                        </p>
                        <strong>Load more</strong>
                      </IonLabel>
                    )
                  }</IonItem>
              )}
            </IonList>
          </>
        )}

        {showCompleted && (
          <>
            <IonProgressBar
              hidden={
                !(
                  segment === "completed" &&
                  (workerProfile?.closedWorkItems?.loading ?? true)
                )
              }
              type="indeterminate"
            />
            <IonList
              hidden={
                !(
                  segment === "completed" &&
                  (workerProfile?.closedWorkItems?.loaded ?? false)
                )
              }
              lines="full"
              className="ion-no-padding"
            >
              <IonItem lines="full">
                <IonChip
                  color="primary"
                  outline={!showMine}
                  onClick={() => setShowMine((prev) => !prev)}
                  style={
                    showMine
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-primary)",
                      }
                      : {}
                  }
                >
                  Mine
                  <IonIcon
                    hidden={!showMine || true}
                    icon={closeCircle}
                  />
                </IonChip>
                <IonChip
                  color="secondary"
                  outline={!showParticipating}
                  onClick={() => setShowParticipating((prev) => !prev)}
                  style={
                    showParticipating
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-secondary)",
                      }
                      : {}
                  }
                >
                  Participating
                  <IonIcon
                    hidden={!showParticipating || true}
                    icon={closeCircle}
                  />
                </IonChip>
                <IonChip
                  hidden={
                    !(
                      session.currentUser?.role === "admin" || (session.currentUser?.adminGroupIds?.length ?? 0) > 0
                    )
                  }
                  color="medium"
                  outline={!showOther}
                  onClick={() => setShowOther((prev) => !prev)}
                  style={
                    showOther
                      ? {
                        color: "white",
                        backgroundColor: "var(--ion-color-medium)",
                      }
                      : {}
                  }
                >
                  Other
                  <IonIcon
                    hidden={!showOther || true}
                    icon={closeCircle}
                  />
                </IonChip>
              </IonItem>
              {workerProfile?.closedWorkItems?.loaded &&
                workerProfile?.closedWorkItems?.data?.concat(
                  workerProfile?.otherClosedWorkItems?.loaded
                    ? (workerProfile?.otherClosedWorkItems.data || []).filter((otherClosedWorkItem) => {
                      if (otherClosedWorkItem.conversation?.status === "inactive") {
                        const isOwner = (otherClosedWorkItem.conversation?.ownerId === session.currentUser?.workerProfile?.id);
                        const isParticipant = (otherClosedWorkItem.conversation?.isParticipant && !isOwner);
                        const isOther = !isOwner && !isParticipant;

                        return isOther;
                      }
                      return false;
                    })
                    : []
                ).sort((a, b) => a.data?.lastContactDate && b.data?.lastContactDate && a.data?.lastContactDate > b.data?.lastContactDate ? 1 : 0)
                  .map((item) => {
                    const isOwner =
                      item.conversation?.ownerId ===
                      session.currentUser?.workerProfile?.id;
                    const isParticipant =
                      item.conversation?.isParticipant && !isOwner;
                    const isOther = !isOwner && !isParticipant;
                    if (
                      (showMine && isOwner) ||
                      (showParticipating && isParticipant) ||
                      (showOther && isOther)
                    ) {
                      return <WorkItemsListItem key={item.id} workItem={item} />;
                    }
                  })}
              {(typeof workerProfile?.closedWorkItems?.limit !== "undefined") && (
                <IonItem
                  color="light"
                  button
                  onClick={async (e) => {
                    if (workerProfile?.closedWorkItems) {
                      setLoadingMore_closed(true);
                      workerProfile.closedWorkItems.loadMore();
                      workerProfile.otherClosedWorkItems?.loadMore();
                      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
                      setLoadingMore_closed(false);
                    }
                  }}

                >
                  {loadingMore_closed
                    ? <IonSpinner color="light" style={{ margin: "auto" }} />
                    : (
                      <IonLabel className="ion-text-center" style={{ paddingBottom: "12px" }}>
                        <p>
                          {`Showing from ${lastContactDate_closed && relativeDateString(lastContactDate_closed.toDate(), true)}`}
                        </p>
                        <strong>Load more</strong>
                      </IonLabel>
                    )
                  }</IonItem>
              )}
            </IonList>
          </>
        )}
      </IonContent>
    </IonPage>
  );
});

export default ConversationsPage;
