import firebase from "firebase/app";
import { makeAutoObservable } from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { FileData, fileDefaultValue } from "./FileData";

const __cache: Record<string, File> = {};

export class File {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: FileData | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;

    constructor(session: Session, path: string, data?: FileData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = this.mPath;
        this.mData = data ? data : (this.mId == null ? fileDefaultValue : undefined);
        this.mSubscriptionCount = 0;
    }

    static createCacheInstance(session: Session, path: string, data?: FileData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        //const id = getId(cachePath);
        //if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new File(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: File) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = this.mPath;
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: FileData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    refetch() {
        if (!this.mSubscription) {

        }
    }

    subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.mPath}`);
            this.mSubscription = () => { };
        }
    }

    unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.mPath}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    get metadata(): any | null | undefined {
        if (this.data) return this.data.metadata;
        return undefined;
    }

    get url(): string | null | undefined {
        if (this.data) return this.data.url;
        return undefined;
    }
}
