import { registerPlugin } from "@capacitor/core";

export interface FirebasePlugin {
    initialize(options: {
        instanceID: string,
        googleAppID: string,
        gcmSenderID: string,
        apiKey: string,
        projectID: string,
        bundleID: string,
        clientID: string,
        databaseURL: string,
        storageBucket: string,
    }): Promise<{ instanceID: string }>;
}

const Firebase = registerPlugin<FirebasePlugin>("Firebase");

export default Firebase;