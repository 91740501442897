import React, { useEffect, useState } from "react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  IonApp,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonRow,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { Deploy } from "cordova-plugin-ionic";
import * as semver from "semver";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import InstancePage from "./pages/Auth/InstancePage";
import LoginPage from "./pages/Auth/LoginPage";
import Main from "./pages/Main";

import AppUrlListener from "./utils/AppUrlListener";

import { Session, SessionContext } from "./session";
import LoadingContainer from "./components/LoadingContainer";
import ProtectedRoute from "./utils/ProtectedRoute";
import { constructOutline } from "ionicons/icons";
import { when } from "mobx";
import LoadingPage from "./pages/common/LoadingPage";
import { CheckForUpdateResponse } from "cordova-plugin-ionic/dist/IonicCordova";

const App: React.FC = observer(() => {
  const [session, setSession] = useState<Session | null>();
  const [instanceState, setInstanceState] = useState<
    | "NotLoaded"
    | "Preparing"
    | "Configuring"
    | "Initializing"
    | "Downloading"
    | "Extracting"
    | "Loading"
    | "Loaded"
  >("NotLoaded");
  const [instanceDownloadProgress, setInstanceDownloadProgress] = useState(0);
  const [instanceExtractProgress, setInstanceExtractProgress] = useState(0);

  useEffect(() => {
    const instanceId =
      window?.location?.href?.match(
        /^(https:\/\/)?((app.)?engage.)?([^.]+)\.voyagernetz\.us/
      )?.[4] ??
      process.env.REACT_APP_ENGAGE_INSTANCE ??
      localStorage.getItem("vrz--engage-instance-id-reloading") ??
      localStorage.getItem("vrz--engage-instance-id") ??
      null;

    if (instanceId) {
      loadInstance(instanceId).catch((error) => alert(error));
    }
  }, []);

  const loadInstance = (instanceId: string) => {
    return new Promise<void>(async (resolve, reject) => {
      if (instanceId) {
        try {
          setInstanceState("Preparing");

          const firebaseInstance = await Session.getFirebaseInstance(
            instanceId
          );

          const session = new Session(firebaseInstance);
          await session.init();

          await configureInstance(session);

          setSession(session);
          setInstanceState("Loaded");
        } catch (error) {
          reject(error);

          localStorage.removeItem("vrz--engage-instance-id-reloading");
          localStorage.removeItem("vrz--engage-instance-id");

          setInstanceState("NotLoaded");
          reject(error);
        }
      }
    });
  };

  const configureInstance = (session: Session) => {
    return new Promise<void>(async (resolve, reject) => {
      if (session && session.loaded) {
        try {
          setInstanceState("Configuring");
          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 100)
          );

          if (isPlatform("capacitor")) {
            if (session.instanceVersion) {
              const channelName = session.instanceId;
              await Deploy.configure({ channel: channelName });
            } else {
              throw new Error("Instance Version Info not loaded yet.");
            }
          }

          await initializeInstance(session);
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error("Session not loaded yet."));
      }
    });
  };

  const initializeInstance = (session: Session) => {
    return new Promise<void>(async (resolve, reject) => {
      if (session && session.loaded) {
        try {
          setInstanceState("Initializing");
          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 100)
          );

          if (isPlatform("capacitor")) {
            const configuration = await Deploy.getConfiguration();

            const checkForUpdate = (retries: 0 | 1 | 2 | 3) => {
              return new Promise<CheckForUpdateResponse>((resolve, reject) => {
                const attempt = (tried: number) => {
                  Deploy.checkForUpdate()
                    .then((updateResponse) => resolve(updateResponse))
                    .catch((error) => {
                      if (tried > retries) {
                        reject(error);
                      } else {
                        attempt(++tried);
                      }
                    });
                };

                attempt(1);
              });
            };
            const update = await checkForUpdate(3);

            if (update.available) {
              if (update.incompatibleUpdateAvailable) {
                throw new Error(
                  "Instance is not compatible with this version of the app."
                );
              } else {
                setInstanceState("Downloading");
                await new Promise<void>((resolve) =>
                  setTimeout(() => resolve(), 100)
                );
                await Deploy.downloadUpdate((progress) => {
                  setInstanceDownloadProgress(progress ? progress : 0);
                });

                setInstanceState("Extracting");
                await new Promise<void>((resolve) =>
                  setTimeout(() => resolve(), 100)
                );
                await Deploy.extractUpdate((progress) => {
                  setInstanceExtractProgress(progress ? progress : 0);
                });

                if (session?.instanceId) {
                  localStorage.setItem(
                    "vrz--engage-instance-id-reloading",
                    session.instanceId
                  );
                }

                await Deploy.reloadApp();
              }
            } else {
              localStorage.removeItem("vrz--engage-instance-id-reloading");
            }
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error("Session not loaded yet."));
      }
    });
  };

  function handle_onSelectInstance(instanceId: string) {
    return new Promise<void>(async (resolve, reject) => {
      if (instanceId) {
        loadInstance(instanceId).catch((error) => alert(error));
        resolve();
      } else {
        reject(new Error("Instance ID parameter missing"));
      }
    });
  }

  const renderInstanceState = () => {
    switch (instanceState) {
      case "NotLoaded":
        return <InstancePage onSelectInstance={handle_onSelectInstance} />;

      case "Preparing":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message={
              localStorage.getItem("vrz--engage-instance-id-reloading")
                ? "Applying update..."
                : "Preparing..."
            }
            progress={0}
          />
        );

      case "Configuring":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message="Configuring..."
            progress={0}
          />
        );
        break;

      case "Initializing":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message="Checking for updates..."
            progress={0}
          />
        );

      case "Downloading":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message="Downloading latest update..."
            progress={instanceDownloadProgress}
          />
        );

      case "Extracting":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message="Getting update ready..."
            progress={instanceExtractProgress}
          />
        );

      case "Loading":
        return (
          <LoadingContainer
            title="VoyagerNetz Engage"
            message="Loading..."
            progress={0}
          />
        );
        break;

      case "Loaded":
        return (
          session && (
            <IonReactRouter>
              <AppUrlListener />
              <ProtectedRoute
                path="/"
                component={Main}
                loadingComponent={LoadingPage}
                fallbackComponent={LoginPage}
              />
            </IonReactRouter>
          )
        );
    }
  };

  return (
    <SessionContext.Provider value={session}>
      <IonApp>
        {instanceState == "NotLoaded" || instanceState === "Loaded" ? (
          renderInstanceState()
        ) : (
          <IonPage>
            <IonHeader className="ion-no-border">
              <IonToolbar color="">
                <IonButtons slot="start"></IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>{renderInstanceState()}</IonContent>
          </IonPage>
        )}
      </IonApp>
    </SessionContext.Provider>
  );
});

export default App;
