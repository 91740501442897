import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  IonList,
  IonButton,
  IonInput,
  IonTitle,
  IonTextarea,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
  IonText,
  IonChip,
  useIonActionSheet,
} from "@ionic/react";

import {
  close,
  closeCircleOutline,
  checkmarkCircleOutline,
  checkmarkOutline,
  trashBin,
  closeOutline,
  trash,
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";

interface StandardResponseDetailProps {
  session: Session;
  standardResponse: Models.StandardResponse;
  onDismiss: () => void;
}

const StandardResponseDetail: React.FC<StandardResponseDetailProps> = observer(
  ({ session, standardResponse, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [present, dismiss] = useIonActionSheet();

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    const showErrorLabel = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonLabel color="primary">
          <h5>{error.message || "Field Is Required"}</h5>
        </IonLabel>
      ) : null;
    };

    const showErrorItem = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonItem>
          <IonLabel color="primary">
            <h5>{error.message || "Field Is Required"}</h5>
          </IonLabel>
        </IonItem>
      ) : null;
    };

    const onDeleteResponse = () => {
      if (formState.isValid && standardResponse && standardResponse.id) {
        standardResponse.isDeleted = true;
        standardResponse
          .save()
          .then(() => onDismiss())
          .catch((error) => {
            console.error("Error deleting Standard Response.", error);
          });
      }
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && standardResponse) {
        try {
          standardResponse.name = data.name.trim();
          standardResponse.category = data.category;
          standardResponse.tag = data.tag.trim();
          standardResponse.text = data.text;
          await standardResponse.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save Standard Response");
          console.error("Error saving Standard Response.", error);
        }
      }
    };

    const categories = session.standardResponsesSettings?.categories;

    if (!standardResponse || !categories) {
      return <IonProgressBar color="tertiary" type="indeterminate" />;
    }

    return (
      <IonPage>
        <IonHeader
          translucent
          //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  if (formState.isDirty) {
                    present({
                      buttons: [
                        {
                          text: "Discard Changes",
                          role: "destructive",
                          handler: () => onDismiss(),
                        },
                        { text: "Keep Editing", role: "cancel" },
                      ],
                      header: "Are you sure you want to discard your changes?",
                      mode: "ios",
                    });
                  } else {
                    onDismiss();
                  }
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Standard Response</IonTitle>
            <IonButtons slot="end">
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={(_) => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeleteResponse}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {standardResponse.id ? (
                    <IonButton
                      onClick={(_) => setIsDeleting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    color="light"
                    expand="block"
                    fill="solid"
                    shape="round"
                    size="large"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {standardResponse ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList lines="none" className="ion-no-padding ion-padding-bottom">
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "5px" }}
                >
                  BASIC DETAILS
                </IonListHeader>
                <IonItem key="name" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Name
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={standardResponse.name}
                    name="name"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                      validate: (data) => {
                        const searchString = data.toLowerCase().trim();
                        const found =
                          session.standardResponses?.data?.filter(
                            (existing) =>
                              existing.id !== standardResponse.id &&
                              existing.name?.toLowerCase().trim() ===
                                searchString
                          ) ?? [];
                        return found.length === 0
                          ? true
                          : "Name must be unique";
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      ></IonInput>
                    )}
                  />

                  {showErrorLabel("name")}
                </IonItem>
                <IonItem key="category" lines="none" className="ion-padding-bottom">
                  <IonLabel position="stacked" color="medium">
                    Category
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={standardResponse.category}
                    name="category"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonSelect
                        className="input-field"
                        value={value}
                        okText="Okay"
                        cancelText="Dismiss"
                        onIonChange={onChange}
                        onBlur={onBlur}
                      >
                        {categories.map((category) => (
                          <IonSelectOption value={category} key={category}>
                            {category}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />

                  {showErrorLabel("category")}
                </IonItem>
                <IonItem hidden key="tag" lines="none" style={{ marginTop: "15px" }} className="ion-padding-bottom">
                  <IonLabel position="stacked" color="medium">
                    Tag
                  </IonLabel>
                  
                  <Controller
                    control={control}
                    defaultValue={standardResponse.tag}
                    name="tag"
                    rules={{
                      required: false,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                      pattern: {
                        value: /^[0-9a-z-]*$/,
                        message:
                          "Only lowercase alphanumeric characters allowed.",
                      },
                      validate: (value) => {
                        if ((value || "").trim() === "") return true;
                        const searchString = value.toLowerCase().trim();
                        const found =
                          session.standardResponses?.data?.filter(
                            (existing) =>
                              existing.id !== standardResponse.id &&
                              existing.tag?.toLowerCase().trim() ===
                                searchString
                          ) ?? [];
                        return found.length === 0 ? true : "Tag must be unique";
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      ><IonText color="medium" style={{ paddingLeft: "8px" }}>#</IonText></IonInput>
                    )}
                  />
                  {showErrorLabel("tag")}
                </IonItem>
                <IonListHeader color="light" style={{ paddingTop: "15px" }}>
                  <strong>RESPONSE TEXT</strong>
                </IonListHeader>
                <IonItem key="text" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Message
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={standardResponse.text}
                    name="text"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonTextarea
                        spellcheck={true}
                        autocapitalize="on"
                        autoGrow
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      >
                        <IonButton hidden>Hidden AutoGrow height fix</IonButton>
                      </IonTextarea>
                    )}
                  />
                  {showErrorLabel("text")}
                </IonItem>
              </IonList>
            </form>
          ) : (
            <div>Standard Response not found</div>
          )}
        </IonContent>
      </IonPage>
    );
  }
);

export default StandardResponseDetail;
