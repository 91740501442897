import React from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonSkeletonText,
  IonText,
} from "@ionic/react";

import { personCircle, peopleCircle, keypad } from "ionicons/icons";

import * as Models from "../../../models";

interface PhoneNumbersListItemProps {
  phoneNumber: Models.PhoneNumber;
  onSelectItem: (item: Models.PhoneNumber) => void;
}

const PhoneNumbersListItem: React.FC<PhoneNumbersListItemProps> = observer(
  ({ phoneNumber, onSelectItem }) => {
    const location = useLocation();

    if (phoneNumber && phoneNumber.loaded) {
      return (
        <IonItem
          //color={location.pathname == `/phoneNumbers/${phoneNumber.id}` ? "light" : ""}
          //routerLink={`/phoneNumbers/${phoneNumber.id}`}
          detail={false}
          onClick={() => onSelectItem(phoneNumber)}
          button
        >
          <IonAvatar
            hidden
            style={{ height: "50px", width: "50px" }}
            className="ion-margin-end"
            color="medium"
          >
            <IonIcon
              icon={keypad}
              color="medium"
              style={{ fontSize: "50px" }}
            ></IonIcon>
          </IonAvatar>
          <IonLabel>
            <h2>{phoneNumber.name}</h2>
            <p>{phoneNumber.phone}</p>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonAvatar
          hidden
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={keypad}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
          </h2>
        </IonLabel>
      </IonItem>
    );
  }
);

export default PhoneNumbersListItem;
