import React, { useContext, useEffect, useRef, useState } from "react";
import { when } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonFooter,
  IonTextarea,
  IonBadge,
  IonChip,
  IonText,
  CreateAnimation,
  IonLabel,
  IonImg,
} from "@ionic/react";

import { close, chatbubblesOutline, send, camera } from "ionicons/icons";

import InternalMessagesListItem from "./InternalMessagesListItem";

import * as Models from "../../../models";

import { relativeDateString } from "../../../utils/Helpers";
import { SessionContext } from "../../../session";
import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

interface InternalMessagesButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const InternalMessagesButton: React.FC<InternalMessagesButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);

    const [messageInput, setMessageInput] = useState("");
    const [busySendingMessage, setBusySendingMessage] = useState(false);
    const [hideMessageActions, setHideMessageActions] = useState(true);

    const [lastReadAt, setLastReadAt] = useState<Date | null | undefined>();

    const gridRef = useRef<HTMLIonGridElement | null>(null);

    useEffect(() => {
      if (showModal) {
        return when(
          () => conversation?.workItem?.loaded ?? false,
          () => {
            setLastReadAt(conversation?.workItem?.lastInternalMessageReadAt);
          }
        );
      }
    }, [showModal]);

    const scrollToBottom = () => {
      if (gridRef?.current) {
        gridRef.current.scrollTop = gridRef.current.scrollHeight;
      }
    };

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const isOwner =
      conversation?.ownerId == session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status == "active";
    const workItemState = isActive
      ? conversation?.requestTransfer &&
        conversation?.requestTransfer.targetOwnerId ==
          session.currentUser?.workerProfileId
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !=
            session.currentUser?.workerProfileId &&
          isOwner
        ? "outgoing"
        : "assigned"
      : "completed";

    const newMessages =
      lastReadAt != undefined &&
      lastReadAt != null &&
      conversation &&
      conversation.internalMessages &&
      conversation.internalMessages.data
        ? conversation.internalMessages.data.filter((internalMessage) => {
            return (
              internalMessage.createdOn &&
              internalMessage.createdOn > lastReadAt &&
              internalMessage.senderId != session.currentUser?.workerProfileId
            );
          })
        : null;

    const lastReadMessage =
      newMessages && newMessages.length >= 1 ? newMessages[0] : null;

    const internalMessages = conversation?.internalMessages?.data?.reduce(
      (groups: Record<string, Models.InternalMessage[]>, item) => {
        if (item.createdOn) {
          const dateString = moment(item.createdOn).format("YYYY-MM-DD");

          if (!groups[dateString]) {
            groups[dateString] = [];
          }

          groups[dateString].push(item);
        }

        return groups;
      },
      {}
    );

    return (
      <>
        <IonButton
          slot="end"
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => {
            setMessageInput("");
            set_showModal(true);
          }}
          disabled={conversation === undefined || conversation === null}
        >
          <IonIcon icon={chatbubblesOutline} />
        </IonButton>
        {conversation?.workItem?.hasUnreadInternalMessages && (
          <CreateAnimation
            duration={1000}
            play={true}
            iterations={5}
            keyframes={[
              { offset: 0, transform: "scale(1)", opacity: "1" },
              { offset: 0.5, transform: "scale(0.8)", opacity: "1" },
              { offset: 1, transform: "scale(1)", opacity: "1" },
            ]}
          >
            <IonBadge
              color="primary"
              mode="ios"
              style={{
                opacity: 0,
                position: "absolute",
                top: "-7px",
                right: "-3px",
                fontSize: "0.8em",
                padding: "5px",
                borderRadius: "51%",
                zIndex: "1",
                width: "18px",
                height: "18px",
                border: "2px solid var(--ion-color-secondary)",
              }}
            >
              {" "}
            </IonBadge>
          </CreateAnimation>
        )}
        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar
              color={workItemState == "completed" ? "tertiary" : "tertiary"}
            >
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Conversation Internal Chat</IonTitle>

              <IonButtons slot="end"></IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {internalMessages && Object.keys(internalMessages).length > 0 ? (
              <IonGrid
                ref={gridRef}
                style={{
                  height: "100%",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                onScroll={(e) => {
                  if (
                    e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                    e.currentTarget.scrollHeight
                  ) {
                    conversation?.workItem?.markInternalMessagesAsRead();
                  }
                }}
              >
                {Object.keys(internalMessages)
                  .reverse()
                  .map((key) => (
                    <div key={key}>
                      <div
                        className="ion-padding-top"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          position: "sticky",
                          top: "0px",
                          zIndex: 999999,
                        }}
                      >
                        <IonChip>
                          {relativeDateString(moment(key).toDate(), false)}
                        </IonChip>
                      </div>
                      {internalMessages[key].map((item) => (
                        <>
                          {lastReadMessage && lastReadMessage.id == item.id && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  height: "1px",
                                  backgroundColor: "red",
                                  margin: `1px 0`,
                                }}
                              />
                              <IonText
                                style={{
                                  color: "red",
                                  margin: `0 0.5px`,
                                  //lineHeight: "1rem",
                                  fontSize: "12px",
                                }}
                              >
                                New
                              </IonText>
                            </div>
                          )}
                          <InternalMessagesListItem
                            key={item.id}
                            internalMessage={item}
                            onInView={(internalMessage) => {
                              when(
                                () =>
                                  (internalMessage.createdOn &&
                                    conversation &&
                                    conversation.workItem &&
                                    true) ??
                                  false,
                                () => {
                                  if (
                                    internalMessage.createdOn &&
                                    conversation &&
                                    conversation.workItem
                                  ) {
                                    if (
                                      conversation.workItem
                                        .lastInternalMessageReadAt == null ||
                                      internalMessage.createdOn >=
                                        conversation.workItem
                                          .lastInternalMessageReadAt
                                    ) {
                                      conversation.workItem.lastInternalMessageReadAt =
                                        internalMessage.createdOn;
                                    }
                                  }

                                  if (
                                    conversation &&
                                    conversation.internalMessages &&
                                    conversation.internalMessages.data &&
                                    conversation.internalMessages.data.length >
                                      0
                                  ) {
                                    const length =
                                      conversation.internalMessages.data.length;
                                    const lastInternalMessage =
                                      conversation.internalMessages.data[
                                        length - 1
                                      ];
                                    if (
                                      internalMessage.id ==
                                      lastInternalMessage.id
                                    ) {
                                      conversation?.workItem?.markInternalMessagesAsRead();
                                    }
                                  }
                                }
                              );
                            }}
                          />
                        </>
                      ))}
                    </div>
                  ))}
              </IonGrid>
            ) : (
              <IonItem style={{}} lines="none">
                <IonLabel className="ion-text-center">
                  <IonImg
                    style={{
                      maxWidth: "250px",
                      margin: "auto",
                      marginTop: "69px",
                    }}
                    src={"/assets/images/artboard009.png"}
                  />
                  <p>
                    <strong>
                      <IonText
                        color="dark"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Messages
                      </IonText>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <IonText
                      color="medium"
                      style={{
                        fontSize: "16px",
                      }}
                    ></IonText>
                  </p>
                </IonLabel>
              </IonItem>
            )}
          </IonContent>
          {conversation?.status == "active" && conversation?.isParticipant && (
            <IonFooter>
              <>
                <IonToolbar
                  color="light"
                  style={{
                    paddingBottom: session.keyboardInfo.visible
                      ? "0px"
                      : "var(--ion-safe-area-bottom, 0)",
                  }}
                >
                  <IonButtons slot="start">
                    <IonButton
                      style={{
                        height: "36px",
                      }}
                      color="dark"
                      shape="round"
                      fill="clear"
                      onClick={() => {
                        Camera.getPhoto({
                          quality: 90,
                          //allowEditing: true,
                          resultType: CameraResultType.DataUrl,
                          direction: CameraDirection.Rear,
                        })
                          .then((photo) => {})
                          .catch((error) => {});
                      }}
                      disabled={
                        conversation === undefined || conversation === null
                      }
                      hidden
                      //hidden={!((messageInput ?? "").trim().length === 0)}
                    >
                      <IonIcon icon={camera} />
                    </IonButton>
                  </IonButtons>
                  <IonTextarea
                    spellcheck={true}
                    autocapitalize="on"
                    color="dark"
                    className="ion-no-margin ion-no-padding"
                    autoGrow
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      //border: "1px solid var(--ion-color-medium)",
                      margin: "8px 0px 8px 3px",
                      padding: "5px 10px 5px 5px",
                      minHeight: "36px",
                    }}
                    disabled={busySendingMessage}
                    value={messageInput}
                    //placeholder="New Message"
                    onIonChange={(e) => {
                      setMessageInput(e.detail.value!);
                    }}
                  >
                    <IonButton
                      style={{
                        height: "36px",
                        position: "absolute",
                        bottom: "11px",
                        right: "0px",
                      }}
                      color="dark"
                      shape="round"
                      fill="clear"
                      onClick={() => {
                        Camera.getPhoto({
                          quality: 90,
                          //allowEditing: true,
                          resultType: CameraResultType.DataUrl,
                          direction: CameraDirection.Rear,
                        })
                          .then((photo) => {})
                          .catch((error) => {});
                      }}
                      disabled={
                        conversation === undefined || conversation === null
                      }
                      hidden
                      //hidden={!((messageInput ?? "").trim().length === 0)}
                    >
                      <IonIcon icon={camera} />
                    </IonButton>
                  </IonTextarea>

                  <IonButtons
                    slot="end"
                    style={{ marginLeft: "13px", marginRight: "10px" }}
                  >
                    <IonButton
                      style={{ height: "36px" }}
                      color="dark"
                      shape="round"
                      fill="solid"
                      disabled={
                        busySendingMessage ||
                        (messageInput ?? "").trim().length === 0
                      }
                      //hidden={(messageInput ?? "").trim().length === 0}
                      onClick={() => {
                        setBusySendingMessage(true);

                        conversation
                          ?.sendInternalMessage(
                            messageInput.replace("\n", "\r\n")
                          )
                          .then(() => {
                            setBusySendingMessage(false);
                            setMessageInput("");
                          });
                      }}
                    >
                      <IonIcon icon={send}></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </>
            </IonFooter>
          )}
        </IonModal>
      </>
    );
  }
);

export default InternalMessagesButton;
