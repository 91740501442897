import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useInView } from "react-intersection-observer";

import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonText,
  IonCol,
  IonGrid,
} from "@ionic/react";

import { documentOutline } from "ionicons/icons";

import AnimatedImg from "../../../components/AnimatedImg";

import * as Models from "../../../models";

import { fileSizeString, LinkifyIt, relativeDateString } from "../../../utils/Helpers";
import { SessionContext } from "../../../session";

interface NotesListItemProps {
  note: Models.Note;
  onInView?: (note: Models.Note) => void;
}

const NotesListItem: React.FC<NotesListItemProps> = observer(
  ({ note, onInView }) => {
    const [ref, inView, entry] = useInView({
      threshold: 0.5,
    });

    useEffect(() => {
      if (inView) {
        if (onInView) {
          onInView(note);
        }
      }
    }, [inView]);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    if (
      session.currentUser?.workerProfile?.loaded &&
      note.loaded &&
      note.workerProfile &&
      note.workerProfile.loaded
    ) {
      const noteDate = relativeDateString(note.createdOn);
      const noteWorkerProfile =
        note.workerProfile.id === session.currentUser?.workerProfile?.id
          ? "You"
          : `${note.workerProfile?.name}`;
      const noteBody = note.body;

      const imageFiles = note.files?.data?.filter(
        (file) =>
          typeof file.metadata.contentType == "string" &&
          file.metadata.contentType.startsWith("image")
      );

      const otherFiles = note.files?.data?.filter(
        (file) =>
          typeof file.metadata.contentType == "string" &&
          !file.metadata.contentType.startsWith("image")
      );

      return (
        <IonRow className={`ion-padding-bottom`}>
          <IonCol
            style={
              {
                //display: "flex",
                //margin: "auto",
                //justifyContent: noteWorkerProfile == "You" ? "flex-end" : "flex-start",
              }
            }
          >
            <IonItem
              ref={ref}
              //color="dark"
              lines="none"
              style={{
                minWidth: "100px",
                borderRadius: "5px",
              }}
            //className="ion-margin-start ion-margin-end"
            >
              <IonLabel className="ion-text-wrap">
                <h2>
                  <b>Note</b>
                  <span className="ion-text-end ion-float-right">
                    <IonText style={{ fontSize: "13px" }} color="medium">
                      {noteDate}
                    </IonText>
                  </span>
                </h2>
                <div style={{ userSelect: "text", WebkitUserSelect: "text", MozUserSelect: "text", msUserSelect: "text" }}>
                  <LinkifyIt>
                    <p style={{ whiteSpace: "pre-wrap" }}>{noteBody}</p>
                  </LinkifyIt>
                </div>
                {note.files?.loaded &&
                  note.files.data &&
                  note.files.data.length > 0 && (
                    <IonGrid>
                      {imageFiles && imageFiles.length > 0 && (
                        <IonRow>
                          {imageFiles.map((file) => (
                            <IonCol
                              key={file.id}
                              sizeXs="6"
                              sizeSm={imageFiles.length == 1 ? "12" : "3"}
                            >
                              {file.loaded && (
                                <a
                                  //lines="none"

                                  style={{ borderRadius: "5px" }}
                                  href={file.url ?? undefined}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AnimatedImg
                                    src={file.data?.url ?? undefined}
                                    style={{
                                      padding: "0px",
                                      //maxWidth: "300px",
                                      //maxHeight: "600px",
                                      borderRadius: "5px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </a>
                              )}
                            </IonCol>
                          ))}
                        </IonRow>
                      )}

                      {otherFiles && otherFiles.length > 0 && (
                        <IonRow style={{ maxWidth: "300px" }}>
                          {otherFiles.map((file) => (
                            <IonCol
                              key={file.id}
                              sizeXs="12"
                              sizeSm={otherFiles.length == 1 ? "12" : "6"}
                            >
                              {file.loaded && (
                                <>
                                  <IonItem
                                    lines="none"
                                    style={{ borderRadius: "5px" }}
                                    href={file.url ?? undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-title={file.metadata.name}
                                  >
                                    <IonIcon
                                      //slot="start"
                                      icon={documentOutline}
                                      className="ion-padding-end"
                                    />
                                    <IonLabel>
                                      <b>{file.metadata.name}</b>
                                      <p>
                                        {fileSizeString(file.metadata.size)}
                                      </p>
                                    </IonLabel>
                                  </IonItem>
                                </>
                              )}
                            </IonCol>
                          ))}
                        </IonRow>
                      )}
                    </IonGrid>
                  )}
                <div>
                  <p>
                    <span className="ion-text-end ion-float-right">
                      <IonAvatar
                        style={{
                          height: "28px",
                          width: "28px",
                          marginTop: "13px",
                        }}
                      >
                        {note.workerProfile?.avatar
                          ? <AnimatedImg alt="" src={note.workerProfile?.avatar ?? undefined} />
                          : undefined
                        }
                      </IonAvatar>
                    </span>
                  </p>
                </div>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
      );
    }

    return <></>;
  }
);

export default NotesListItem;
