import firebase from "firebase/app";

import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  IReactionDisposer,
  reaction,
} from "mobx";

import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { WorkerProfile } from "../WorkerProfiles";
import { ParticipantData, participantDataConverter, participantDefaultValue } from "./ParticipantData";

const __cache: Record<string, Participant> = {};

export class Participant {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: ParticipantData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mWorkerProfile: WorkerProfile | null | undefined;
  private mWorkerProfileDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: ParticipantData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? participantDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
    onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
  }

  static createCacheInstance(session: Session, path: string, data?: ParticipantData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new Participant(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: Participant) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: ParticipantData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(participantDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(participantDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<ParticipantData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(participantDataConverter(this.session))
            .add(this.data).then((doc) => {
              this.path = doc.path;
              Participant.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(participantDataConverter(this.session))
            .set(this.data, { merge: true }).then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  public get workerProfileId(): string | null | undefined {
    if (this.data) return this.data.workerProfileId;
    return undefined;
  }

  public get workerProfile(): WorkerProfile | null | undefined {
    return this.mWorkerProfile;
  }
  public subscribeWorkerProfile = () => {
    when(
      () => this.workerProfileId !== undefined,
      () => {
        const initWorkerProfile = () => {
          if (this.mWorkerProfile && this.mWorkerProfile.id !== this.workerProfileId) {
            this.unsubscribeWorkerProfile();
          }

          if (this.mWorkerProfile === undefined && this.workerProfileId !== undefined) {
            this.mWorkerProfile = this.workerProfileId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.workerProfileId}`
              )
              : null;
            this.mWorkerProfile?.subscribe();

            this.mWorkerProfileDisposer = reaction(
              () => this.workerProfileId,
              (workerProfileId) => initWorkerProfile()
            );
          }
        };

        initWorkerProfile();
      }
    );
  };
  public unsubscribeWorkerProfile = () => {
    if (this.mWorkerProfileDisposer) this.mWorkerProfileDisposer();
    this.mWorkerProfile?.unsubscribe();
    this.mWorkerProfile = undefined;
  };
}

