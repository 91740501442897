import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  Camera,
  CameraDirection,
  CameraResultType,
  Photo,
} from "@capacitor/camera";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonImg,
  IonFooter,
  IonTextarea,
} from "@ionic/react";

import {
  close,
  camera,
  send,
  chatboxEllipses,
  logoFacebook,
  logoWhatsapp,
} from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import ThumbnailPreview from "./ThumbnailPreview";
import imageCompression from "browser-image-compression";

const getChannelIcon = (channel: string) => {
  switch (channel.toLowerCase()) {
    case Models.messageChannels.WHATSAPP:
      return <IonIcon style={{ marginTop: "3px" }} icon={logoWhatsapp} />;
    case Models.messageChannels.SMS:
      return <IonIcon style={{ marginTop: "3px" }} icon={chatboxEllipses} />;
    case Models.messageChannels.DEBUG:
      return <></>;
    case Models.messageChannels.MESSENGER:
      return <IonIcon style={{ marginTop: "3px" }} icon={logoFacebook} />;
    case Models.messageChannels.DEBUGINCOMING:
      return <></>;
  }
};

function compressImage(photo: Photo) {
  return new Promise<string>(async (resolve, reject) => {
    const dataURI = photo.dataUrl;
    if (dataURI) {
      const res = await fetch(dataURI);
      const buf = await res.arrayBuffer();
      const imageFile = new File([buf], "image", { type: `image/${photo?.format ?? ""}` });

      //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 5,
        //maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof (reader.result) === "string") {
            resolve(reader.result);
          } else {
            reject(new Error("Failed to compress image"));
          }
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        reject(error);
      }
    }
  });
}

function srcToFile(src: string, fileName: string, mimeType: string) {
  return (fetch(src)
    .then(function (res) { return res.arrayBuffer(); })
    .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
  );
}

interface TakePictureButtonProps {
  parent?: HTMLElement | null;
  disabled?: boolean;
  hidden?: boolean;
  conversation: Models.Conversation | null | undefined;
}

const TakePictureButton: React.FC<TakePictureButtonProps> = observer(
  ({ parent, conversation, hidden, disabled }) => {
    const [showModal, set_showModal] = useState(false);
    const [photo, set_photo] = useState<Photo | undefined>(undefined);
    const [messageInput, setMessageInput] = useState("");
    const [busySendingMessage, setBusySendingMessage] = useState(false);
    const [hideMessageActions, setHideMessageActions] = useState(true);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const button_onClick = () => {
      Camera.getPhoto({
        quality: 90,
        //allowEditing: true,
        resultType: CameraResultType.DataUrl,
        direction: CameraDirection.Rear,
      })
        .then((photo) => {
          set_photo(photo);
          setMessageInput("");
          set_showModal(true);
        })
        .catch((error) => {
          set_showModal(false);
        });
    };

    const remove_onClick = () => {
      set_photo(undefined);
      button_onClick();
    };

    return (
      <>
        <IonButton
          style={{ height: "36px" }}
          color="dark"
          shape="round"
          fill="clear"
          onClick={() => button_onClick()}
          disabled={!conversation || disabled}
          hidden={
            !(
              conversation?.status === "active" &&
              conversation.ownerId === session.currentUser?.workerProfileId &&
              !(hidden || false)
            )
          }
        >
          <IonIcon icon={camera} />
        </IonButton>

        <IonModal
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Send photo</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonImg src={photo?.dataUrl} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar
              color="light"
              style={{
                paddingBottom: session.keyboardInfo.visible
                  ? "0px"
                  : "var(--ion-safe-area-bottom, 0)",
              }}
            >
              {/*<IonLabel>
                <IonText style={{ fontSize: "16px" }}>
                  {getChannelIcon(
                    conversation?.lastMessage?.communicationChannel?.channel ??
                      ""
                  )}
                </IonText>
                  </IonLabel>*/}
              <IonButtons slot="start">
                <IonButton
                  style={{
                    height: "36px",
                  }}
                  color="dark"
                  shape="round"
                  fill="clear"
                  onClick={() => {
                    Camera.getPhoto({
                      quality: 90,
                      //allowEditing: true,
                      resultType: CameraResultType.DataUrl,
                      direction: CameraDirection.Rear,
                    })
                      .then((photo) => { })
                      .catch((error) => { });
                  }}
                  disabled={conversation === undefined || conversation === null}
                  hidden
                //hidden={!((messageInput ?? "").trim().length === 0)}
                >
                  <IonIcon icon={camera} />
                </IonButton>
              </IonButtons>
              <IonTextarea
                spellcheck={true}
                autocapitalize="on"
                color="dark"
                className="ion-no-margin ion-no-padding"
                autoGrow
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  //border: "1px solid var(--ion-color-medium)",
                  margin: "8px 0px 8px 3px",
                  padding: "5px 10px 5px 5px",
                  minHeight: "36px",
                }}
                disabled={busySendingMessage}
                value={messageInput}
                //placeholder="Type message"
                onIonChange={(e) => {
                  setMessageInput(e.detail.value!);
                }}
              >
                <IonButton hidden>
                  <IonIcon icon={camera} />
                </IonButton>
              </IonTextarea>
              <IonButtons
                slot="end"
                style={{ marginLeft: "13px", marginRight: "10px" }}
              >
                <IonButton
                  style={{ height: "36px" }}
                  color="dark"
                  shape="round"
                  fill="solid"
                  disabled={busySendingMessage}
                  onClick={async () => {
                    if (photo) {
                      setBusySendingMessage(true);

                      try {
                        const compressedDataUrl = await compressImage(photo);

                        await conversation?.sendMessage(messageInput.replace("\n", "\r\n"), "image", compressedDataUrl, `image/${photo?.format ?? ""}`)

                        setBusySendingMessage(false);
                        setMessageInput("");
                        set_showModal(false);
                      } catch (error) {
                        if (error instanceof Error) {
                          alert(error.message);
                        }
                      }
                      finally {
                        setBusySendingMessage(false);
                      }
                    }
                  }}
                >
                  <IonIcon icon={send}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
            {/*<IonToolbar hidden color="light">
            {
              photo 
                ? <ThumbnailPreview imgSrc={photo.dataUrl!} onRemove={remove_onClick} />
                : null
            }
          </IonToolbar>*/}
          </IonFooter>
        </IonModal>
      </>
    );
  }
);

export default TakePictureButton;
