import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

import {
  close,
  closeOutline,
  checkmarkOutline,
  trash,
  peopleCircle,
  peopleCircleOutline,
  remove,
  personRemove,
  add
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AddUserButton from "./AddUserButton";
import moment from "moment";
import { GroupDetail_segment_info } from "./GroupDetail_segment_info";
import { GroupDetail_segment_users } from "./GroupDetail_segment_users";
import AvatarButton from "./AvatarButton";
import AnimatedImg from "../../../components/AnimatedImg";

interface ToggleChangeEventDetail {
  value: boolean;
  checked: boolean;
}
interface GroupDetailProps {
  session: Session;
  group: Models.Group;
  onDismiss: () => void;
}
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GroupDetail: React.FC<GroupDetailProps> = observer(
  (props) => {
    const { session, group, onDismiss } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { control, formState, errors, handleSubmit, watch } = useForm({
      mode: "onChange",
    });

    const [segment, setSegment] = useState("info");

    /// Handlers ---
    const onDeleteGroup = () => {
      // Validation
      if (!session.currentUser) {
        console.warn('You need to be logged in to perform this action.');
        return;
      }

      if (session.currentUser?.role !== "admin") {
        console.warn('Logged in group does not have the required permissions to perform this action.');
        return;
      }

      // Perform action
      group
        .delete()
        .then(() => onDismiss())
        .catch(error => console.error('Error deleting group.', error));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && group) {
        const acceptPeriod = toTimeSeconds(data.conversationAcceptPeriodSeconds);
        const replyPeriod = toTimeSeconds(data.messageResponsePeriodSeconds);

        group.name = data.name.trim();
        group.tag = data.tag.trim();
        group.phoneNumberId = data.phoneNumberId;
        group.greetingMessage = data.greetingMessage;
        group.noAgentAvailableMessage = data.noAgentAvailableMessage;
        group.slowConversationAcceptMessage = data.slowConversationAcceptMessage;
        group.conversationAcceptPeriodSeconds = acceptPeriod;
        group.messageResponsePeriodSeconds = replyPeriod;

        try {
          await group.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save group.");
          console.log("Error saving group.", error);
        }
      }
    };
    /// --- handlers

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const toTimeSeconds = (timeString: string) => {
      if (!isNaN(timeString as any)) {
        return parseInt(timeString);
      }

      const parts = (timeString || "0:0:0")
        .split(":")
        .map(part => parseInt(part));
      return (parts[0] * 60 * 60) + (parts[1] * 60) + parts[2];
    }

    const toTimeString = (totalSeconds: number) => {
      const hours = Math.floor(totalSeconds / (60 * 60));
      totalSeconds -= (hours * 60 * 60);

      const minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= (minutes * 60);

      return moment(`${hours}:${minutes}:${totalSeconds}`, "h:m:s").format("HH:mm:ss");
    }

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Group</IonTitle>
            <IonButtons slot="end">
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={() => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeleteGroup}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {session.currentUser?.role === "admin" && group.id ? (
                    <IonButton
                      onClick={() => setIsDeleting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    color="light"
                    expand="block"
                    fill="solid"
                    shape="round"
                    size="large"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
            <>
              <IonAvatar
                hidden
                slot="start"
                style={{
                  height: "35px",
                  width: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
              >
                <img alt="" src={peopleCircle} />
              </IonAvatar>

              <IonLabel hidden slot="start">
                <h2>
                  <b>
                    &nbsp;
                    {group?.name}
                  </b>
                </h2>
              </IonLabel>
            </>
          </IonToolbar>
          <IonToolbar>
            <IonList lines="none" className="ion-no-padding">
              <IonItem
                lines="none"
                className="ion-no-padding ion-no-margin"
              >
                <IonLabel className="ion-text-center ion-padding-top">
                  <AvatarButton session={session} group={group} />
                  <IonLabel className="ion-margin-top">
                    <h2>
                      <strong>{group?.name}</strong>
                    </h2>
                  </IonLabel>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonToolbar>
          <IonToolbar
            className="ion-no-padding"
            hidden={!group.id}
          >
            <IonSegment scrollable mode="md" value={segment} onIonChange={e => setSegment(e.detail.value!)}>
              <IonSegmentButton value="info">
                <IonLabel>Info</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="users">
                <IonLabel>users</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>

        <>
          <GroupDetail_segment_info segment={segment} {...props} control={control} formState={formState} errors={errors} />
          <GroupDetail_segment_users segment={segment} {...props} />
        </>
      </IonPage>
    );
    /// --- render
  }
);

export default GroupDetail;
