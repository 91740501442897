import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { when } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Keyboard } from "@capacitor/keyboard";

import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonPopover,
  IonProgressBar,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

import {
  send,
  arrowBack,
  personAdd,
  checkmarkOutline,
  closeOutline,
  enter,
} from "ionicons/icons";

import MessageListItem from "./components/MessageListItem";

import CloseConversationButton from "./components/CloseConversationButton";
import ConversationInfoButton from "./components/ConversationInfoButton";
import CustomerButton from "./components/CustomerButton";
import InternalMessagesButton from "./components/InternalMessagesButton";
import NotesButton from "./components/NotesButton";
import ParticipantsButton from "./components/ParticipantsButton";
import StandardResponseButton from "./components/StandardResponseButton";
import TakePictureButton from "./components/TakePictureButton";

import * as Models from "../../models";
import { Conversation } from "../../models/Conversations";
import { Message } from "../../models/Messages";

import { getChannelIcon, relativeDateString } from "../../utils/Helpers";
import { SessionContext } from "../../session";
import { conversationsSettingsDefaultValue } from "../../models";
import BroadcastMessagesButton from "./components/BroadcastMessagesButton";
import AttachFileButton from "./components/AttachFileButton";
import TransferButton from "./components/TransferButton";
import { Button } from "../../components/Button";

interface ConversationPageProps {
  hideBackButton?: boolean;
}

const ConversationPage: React.FC<ConversationPageProps> = observer(
  ({ hideBackButton }) => {
    const params = useParams<{ id: string }>();

    const session = useContext(SessionContext);

    const [conversation, setConversation] = useState<
      Conversation | undefined
    >();
    const [lastReadAt, setLastReadAt] = useState<Date | null | undefined>();

    useEffect(() => {
      if (session && params.id) {
        const item = Conversation.createCacheInstance(
          session,
          `conversations/${params.id}`
        );
        item.subscribe();
        setConversation(item);
        return () => {
          setConversation(undefined);
          item.unsubscribe();
        };
      }
    }, [session, params.id]);

    useEffect(() => {
      if (conversation !== undefined) {
        setLastReadAt(undefined);
        return when(
          () => conversation.workItem?.loaded ?? false,
          () => {
            setLastReadAt(conversation.workItem?.lastMessageReadAt);
          }
        );
      }
    }, [conversation, conversation?.id]);

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const gridRef = useRef<HTMLIonGridElement | null>(null);

    const [messageInput, setMessageInput] = useState("");
    const [busySendingMessage, setBusySendingMessage] = useState(false);
    const [hideMessageActions, setHideMessageActions] = useState(true);

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

    if (!session) {
      return <></>;
    }

    const isOwner =
      conversation?.ownerId === session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status === "active";
    const workItemState = isActive
      ? conversation.requestTransfer
        && (
          conversation.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId
          || (conversation.requestTransfer.targetGroupId && session.currentUser?.groupIds?.includes(conversation.requestTransfer.targetGroupId) && !isOwner)
        )
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !==
          session.currentUser?.workerProfileId &&
          isOwner
          ? "outgoing"
          : "assigned"
      : "completed";

    const newMessages =
      lastReadAt !== undefined &&
        lastReadAt != null &&
        conversation &&
        conversation.messages &&
        conversation.messages.data
        ? conversation.messages.data.filter((message) => {
          return (
            message.createdOn &&
            message.createdOn > lastReadAt &&
            !(
              message.type === "agent" &&
              message.workerProfileId === session.currentUser?.workerProfileId
            )
          );
        })
        : null;

    const lastReadMessage =
      newMessages && newMessages.length >= 1 ? newMessages[0] : null;

    const messages = conversation?.messages?.data
      ?.slice()
      .reduce((groups: Record<string, Message[]>, item) => {
        if (item.createdOn) {
          const dateString = moment(item.createdOn).format("YYYY-MM-DD");

          if (!groups[dateString]) {
            groups[dateString] = [];
          }

          groups[dateString].push(item);
        }

        return groups;
      }, {});

    if (conversation !== undefined && conversation.loaded) {
      return (
        <IonPage>
          <IonHeader
            translucent
          //className="ion-no-border"
          >
            <IonToolbar
              color={workItemState === "completed" ? "primary" : "primary"}
            >
              <IonButtons slot="start">
                {session.windowSize === "small" ? (
                  <>
                    {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                    <IonButton routerLink="/conversations">
                      <IonIcon icon={arrowBack} />
                    </IonButton>
                  </>
                ) : (
                  <></>
                )}
              </IonButtons>
              <CustomerButton
                conversation={conversation}
                buttonType="display"
              />

              {conversation?.status !== "new" && (
                <IonButtons slot="end">
                  <ParticipantsButton conversation={conversation} />
                </IonButtons>
              )}
            </IonToolbar>
          </IonHeader>

          <IonContent
            ref={contentRef}
          //scrollEvents={true}
          //fullscreen
          >
            <IonProgressBar
              color={workItemState === "completed" ? "primary" : "primary"}
              hidden={conversation?.messages?.loaded}
              type="indeterminate"
            />

            <IonGrid
              ref={gridRef}
              style={{
                height: "100%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
              onScroll={(e) => {
                if (
                  e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                  e.currentTarget.scrollHeight
                ) {
                  if (conversation?.status !== "new")
                    conversation?.workItem?.markMessagesAsRead();
                }
              }}
            >

              {messages &&
                Object.keys(messages)
                  .reverse()
                  .map((key) => (
                    <div key={key}>
                      <div
                        className="ion-padding-top"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          //position: "sticky",
                          //top: "0px",
                          //zIndex: 999999,
                        }}
                      >
                        <IonChip>
                          {relativeDateString(moment(key).toDate(), false)}
                        </IonChip>
                      </div>

                      {messages[key].map((item) => (
                        <>
                          {conversation.firstMessage?.id === item.id && (

                            <IonGrid>
                              <IonRow className="ion-align-items-center ion-justify-content-center">
                                <IonCol
                                  className="ion-align-self-center ion-text-center"
                                  size="12"
                                >
                                  <IonLabel>
                                    <p>
                                      <IonText
                                        color="medium"
                                        style={{
                                          fontSize: "26px",
                                        }}
                                      >
                                        {getChannelIcon(
                                          conversation.firstMessage
                                            .communicationChannel
                                            ?.channel ?? ""
                                        )}
                                      </IonText>
                                    </p>
                                    <p>
                                      <IonText
                                        color="medium"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {conversation?.firstMessage
                                          .communicationChannelFacebookPage
                                          ?.name ??
                                          conversation?.firstMessage
                                            .communicationChannel
                                            ?.facebookId ??
                                          conversation?.firstMessage
                                            .communicationChannel
                                            ?.mobileNumber ??
                                          ""}
                                      </IonText>
                                    </p>
                                    <p>
                                      <IonText
                                        color="medium"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        Beginning of conversation
                                      </IonText>
                                    </p>
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                            </IonGrid>

                          )}
                          {lastReadMessage && lastReadMessage.id === item.id && (

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  height: "1px",
                                  backgroundColor: "red",
                                  margin: `1px 0`,
                                }}
                              />
                              <IonText
                                style={{
                                  color: "red",
                                  margin: `0 0.5px`,
                                  fontSize: "12px",
                                }}
                              >
                                New
                              </IonText>
                            </div>
                          )}
                          <MessageListItem
                            key={item.id}
                            conversation={conversation}
                            message={item}
                            onInView={(message) => {
                              when(
                                () =>
                                  (message.createdOn &&
                                    conversation &&
                                    conversation.workItem &&
                                    true) ??
                                  false,
                                () => {
                                  if (
                                    message.createdOn &&
                                    conversation &&
                                    conversation.workItem &&
                                    conversation.workItem.loaded
                                  ) {
                                    if (
                                      conversation.workItem
                                        .lastMessageReadAt == null ||
                                      message.createdOn >=
                                      conversation.workItem
                                        .lastMessageReadAt
                                    ) {
                                      conversation.workItem.lastMessageReadAt =
                                        message.createdOn;
                                    }
                                  }

                                  if (
                                    message.id ===
                                    conversation?.lastMessage?.id
                                  ) {
                                    when(
                                      () =>
                                        conversation?.workItem
                                          ?.hasUnreadMessages ?? false,
                                      () => {
                                        if (
                                          message.id ===
                                          conversation?.lastMessage?.id
                                        ) {
                                          conversation?.workItem?.markMessagesAsRead();
                                        }
                                      }
                                    );
                                  }
                                }
                              );
                            }}
                          />
                        </>
                      ))}
                    </div>
                  ))}
            </IonGrid>
          </IonContent>

          {conversation?.status === "new" ? (
            <IonFooter>
              {conversation?.status === "new" && (
                <>
                  <IonItem lines="none">
                    <IonLabel>
                      <p>
                        <b>{`New Conversation: `}</b>
                      </p>
                      <p>
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonToolbar>
                    <IonButtons slot="end">
                      <Button
                        color="success"
                        shape="round"
                        expand="block"
                        size="large"
                        fill="solid"
                        onClick={() => conversation.acceptConversation()}
                      >
                        <IonIcon
                          slot="start"
                          icon={checkmarkOutline}
                          title="Accept"
                        />
                        Accept&nbsp;
                      </Button>
                    </IonButtons>
                  </IonToolbar>
                </>
              )}

              <IonToolbar color="secondary">
                <IonButtons slot="start">
                  <ConversationInfoButton conversation={conversation} />
                </IonButtons>
                <IonButtons slot="end">
                  <Button
                    hidden
                    shape="round"
                    expand="block"
                    size="large"
                    fill="solid"
                    color="success"
                    onClick={() => conversation.acceptConversation()}
                  >
                    <IonIcon
                      slot="start"
                      icon={checkmarkOutline}
                      title="Accept"
                    />
                    Accept&nbsp;
                  </Button>
                </IonButtons>
              </IonToolbar>
            </IonFooter>
          ) : (
            conversation?.status !== "new" && (
              <IonFooter>
                {workItemState === "incoming" && (
                  <>
                    <IonItem lines="none">
                      <IonLabel>
                        <p>
                          <b>{`Transfer from ${conversation?.owner?.name ?? ""
                            }: `}</b>
                        </p>
                        <p>
                          {conversation?.requestTransfer?.description ?? ""}
                        </p>
                      </IonLabel>
                    </IonItem>
                    <IonToolbar>
                      <IonButtons slot="end">
                        <Button
                          color="danger"
                          shape="round"
                          expand="block"
                          size="large"
                          fill="solid"
                          onClick={() =>
                            conversation?.declineIncomingTransfer()
                          }
                          hidden={typeof (conversation?.requestTransfer?.targetGroupId) === "string"}
                        >
                          <IonIcon
                            slot="start"
                            icon={closeOutline}
                            title="Decline"
                          />
                          Decline&nbsp;
                        </Button>
                        <Button
                          color="success"
                          shape="round"
                          expand="block"
                          size="large"
                          fill="solid"
                          onClick={() => conversation?.acceptIncomingTransfer()}
                        >
                          <IonIcon
                            slot="start"
                            icon={checkmarkOutline}
                            title="Accept"
                          />
                          Accept&nbsp;
                        </Button>
                      </IonButtons>
                    </IonToolbar>
                  </>
                )}
                <IonToolbar
                  color={workItemState === "completed" ? "light" : "secondary"}
                >
                  <IonButtons slot="start">
                    <CustomerButton
                      conversation={conversation}
                      buttonType="button"
                    />
                  </IonButtons>
                  <IonButtons slot="start">
                    <InternalMessagesButton conversation={conversation} />
                  </IonButtons>
                  <IonButtons slot="start">
                    <NotesButton conversation={conversation} />
                  </IonButtons>
                  <IonButtons slot="start">
                    <ConversationInfoButton conversation={conversation} />
                  </IonButtons>
                  <IonButtons slot="start">
                    <BroadcastMessagesButton conversation={conversation} />
                  </IonButtons>
                  <IonButtons slot="start">
                    <TransferButton conversation={conversation} />
                  </IonButtons>
                  <IonButtons
                    slot="end"
                  //hidden={
                  //  !(
                  //    conversation?.status == "active" &&
                  //    conversation?.ownerId ==
                  //      session.currentUser?.workerProfileId
                  //  )
                  //}
                  >
                    <CloseConversationButton conversation={conversation} />
                    <Button
                      shape="round"
                      expand="block"
                      size="large"
                      fill="solid"
                      color="light"
                      onClick={() => {
                        if (session.currentUser?.workerProfileId) {
                          return conversation.addParticipant(
                            session.currentUser?.workerProfileId
                          );
                        }
                      }}
                      hidden={
                        !(
                          conversation.status === "active" &&
                          conversation.ownerId !==
                          session.currentUser?.workerProfileId &&
                          !conversation.isParticipant &&
                          session.currentUser?.role === "admin"
                        )
                      }
                    >
                      <IonIcon icon={personAdd} />
                      <IonText>&nbsp;Participate</IonText>
                    </Button>
                  </IonButtons>
                </IonToolbar>

                {conversation?.status === "active" &&
                  conversation?.ownerId ===
                  session.currentUser?.workerProfileId && (
                    <>
                      <IonToolbar
                        color="light"
                        hidden={
                          !(
                            conversation?.status === "active" &&
                            conversation?.ownerId ===
                            session.currentUser?.workerProfileId
                          )
                        }
                        style={{
                          paddingBottom: session.keyboardInfo.visible
                            ? "0px"
                            : "var(--ion-safe-area-bottom, 0)",
                        }}
                      >
                        <IonButtons slot="start">
                          <TakePictureButton
                            disabled={
                              !((messageInput ?? "").trim().length === 0)
                            }
                            conversation={conversation}
                          />
                          <AttachFileButton
                            disabled={
                              !((messageInput ?? "").trim().length === 0)
                            }
                            conversation={conversation}
                          />
                        </IonButtons>

                        <IonTextarea
                          spellcheck={true}
                          autocapitalize="on"
                          color="dark"
                          className="ion-no-margin ion-no-padding"
                          autoGrow
                          style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            //border: "1px solid var(--ion-color-medium)",
                            margin: "8px 0px 8px 3px",
                            padding: "1px 50px 20px 5px",
                            minHeight: "36px",
                          }}
                          disabled={busySendingMessage}
                          value={messageInput}
                          //placeholder="Message customer"
                          onIonChange={(e) => {
                            setMessageInput(e.detail.value!);
                          }}
                        >
                          <IonLabel
                            color="medium"
                            className="ion-no-margin ion-no-padding"
                            style={{
                              backgroundColor: "var(--ion-color-light)",
                              position: "absolute",
                              bottom: "-3px",
                              right: "0px",
                              padding: "0px 3px 2px 5px",
                              borderRadius: "11px 0px 0px 0px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            <span>
                              <IonText
                                style={{
                                  fontSize: "13px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              >
                                {getChannelIcon(
                                  conversation?.serviceProvider?.channel ?? ""
                                )}
                              </IonText>
                            </span>
                            <IonPopover
                              mode="ios"
                              showBackdrop={true}
                              event={popoverState.event}
                              isOpen={popoverState.showPopover}
                              onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                            >
                              <IonContent >

                                <IonLabel>
                                  <IonText color="medium">
                                    <p style={{ padding: "10px" }}>
                                      {getChannelIcon(
                                        conversation?.serviceProvider?.channel ?? ""
                                      )}
                                      &nbsp;
                                      {conversation?.communicationChannelFacebookPage?.name
                                        ?? conversation?.serviceProvider?.facebookId
                                        ?? conversation?.serviceProvider?.mobileNumber
                                        ?? ""}
                                    </p>
                                  </IonText>
                                </IonLabel>

                              </IonContent>
                            </IonPopover>
                            <span
                              title={conversation?.communicationChannelFacebookPage?.name
                                ?? conversation?.serviceProvider?.facebookId
                                ?? conversation?.serviceProvider?.mobileNumber
                                ?? ""}
                              style={{
                                fontSize: "11px",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }}
                              onClick={
                                (e: any) => {
                                  e.persist();
                                  setShowPopover({ showPopover: true, event: e })
                                }}
                            >
                              &nbsp;
                              <IonText>
                                {
                                  (() => {
                                    const channelName = conversation?.communicationChannelFacebookPage?.name
                                      ?? conversation?.serviceProvider?.facebookId
                                      ?? conversation?.serviceProvider?.mobileNumber
                                      ?? "";
                                    return channelName.length > 23 ? `${channelName.substring(0, 20)}...` : channelName;
                                  })()
                                }
                              </IonText>
                            </span>
                          </IonLabel>
                          <StandardResponseButton
                            conversation={conversation}
                            onSelectStandardResponse={(standardResponse) => {
                              if ((messageInput ?? "") !== "") {
                                setMessageInput(
                                  (prev) =>
                                    `${prev} ${standardResponse?.text ?? ""}`
                                );
                              } else {
                                setMessageInput(standardResponse?.text ?? "");
                              }
                            }}
                          />
                        </IonTextarea>

                        <IonButtons
                          slot="end"
                          style={{ marginLeft: "13px", marginRight: "10px" }}
                        >
                          <IonButton
                            style={{ height: "36px" }}
                            color="dark"
                            shape="round"
                            fill="solid"
                            disabled={
                              busySendingMessage ||
                              (messageInput ?? "").trim().length === 0
                            }
                            //hidden={(messageInput ?? "").trim().length === 0}
                            onClick={() => {
                              setBusySendingMessage(true);

                              conversation
                                ?.sendMessage(
                                  messageInput.replace("\n", "\r\n")
                                )
                                .then(() => {
                                  setBusySendingMessage(false);
                                  setMessageInput("");
                                });
                            }}
                          >
                            <IonIcon icon={send}></IonIcon>
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </>
                  )}
              </IonFooter>
            )
          )}
        </IonPage>
      );
    }

    return (
      <IonPage>
        <IonSpinner color="light" style={{ margin: "auto" }} />
      </IonPage>
    );
  }
);

export default ConversationPage;
