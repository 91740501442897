import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

import {
  close,
  closeOutline,
  checkmarkOutline,
  trash
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";
import AddGroupButton from "./AddGroupButton";
import AnimatedImg from "../../../components/AnimatedImg";

interface ToggleChangeEventDetail {
  value: boolean;
  checked: boolean;
}
interface UserDetailProps {
  session: Session;
  user: Models.User;
  onDismiss: () => void;
}
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UserDetail: React.FC<UserDetailProps> = observer(
  ({ session, user, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const { control, formState, errors, handleSubmit, watch } = useForm({
      mode: "onChange",
    });


    /// Handlers ---
    const onDeleteUser = () => {
      // Validation
      if (!session.currentUser) {
        console.warn('You need to be logged in to perform this action.');
        return;
      }

      if (session.currentUser?.role !== "admin") {
        console.warn('Logged in user does not have the required permissions to perform this action.');
        return;
      }

      if (session.currentUserId === user.id) {
        console.warn('You cannot delete yourself.');
        return;
      }

      // Perform action
      user
        .delete()
        .then(() => onDismiss())
        .catch(error => console.error('Error deleting user.', error));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && user) {
        user.email = data.email.trim();
        user.firstName = data.firstName.trim();
        user.lastName = data.lastName.trim();
        user.canLogin = !!data.canLogin;
        user.role = data.role.toLowerCase().trim();

        if (user.id === null || (user.id !== null && (data.password || "") !== "")) {
          user.password = data.password;
        }

        try {
          await user.save();
          onDismiss();
        } catch (error) {
          alert("Unable to create user.");
          console.log("Error submitting UserDetail.", error);
        }
      }
    };
    /// --- handlers

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>User</IonTitle>
            <IonButtons slot="end">
              <AddGroupButton session={session} user={user} />
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={() => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeleteUser}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {session.currentUser?.role === "admin" && user.id ? (
                    <IonButton
                      onClick={() => setIsDeleting(true)}
                      color="light"
                      disabled={session.currentUser.id === user.id}
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    color="light"
                    expand="block"
                    fill="solid"
                    shape="round"
                    size="large"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
            <>
              <IonAvatar
                hidden
                slot="start"
                style={{
                  height: "35px",
                  width: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
              >
                {user?.avatar
                  ? <AnimatedImg alt="" src={user?.avatar ?? undefined} />
                  : undefined
                }
              </IonAvatar>

              <IonLabel hidden slot="start">
                <h2>
                  <b>
                    &nbsp;
                    {user?.fullName}
                  </b>
                </h2>
              </IonLabel>
            </>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {user ? (
            <IonList lines="none" className="ion-no-padding ion-padding-bottom">
              <IonItem
                color="light"
                lines="none"
                className="ion-no-padding ion-no-margin"
              >
                <IonLabel className="ion-text-center ion-padding-top">
                  <IonAvatar
                    style={{
                      height: "100px",
                      width: "100px",
                      margin: "auto",
                    }}
                    className="ion-margin-bottom"
                  >
                    {user?.avatar
                      ? <AnimatedImg alt="" src={user?.avatar ?? undefined} />
                      : undefined
                    }
                  </IonAvatar>
                  <IonLabel className="ion-margin-top">
                    <h2>
                      <strong>{user?.fullName}</strong>
                    </h2>
                  </IonLabel>
                </IonLabel>
              </IonItem>
              <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                <strong>BASIC DETAILS</strong>
              </IonListHeader>
              <IonItem key="firstName" lines="none">
                <IonLabel position="stacked" color="medium">
                  First Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={user.firstName}
                  name="firstName"
                  rules={{
                    required: {
                      message: "A first name is required.",
                      value: true,
                    },
                    minLength: {
                      message: "Must be at least 2 characters long.",
                      value: 2,
                    },
                    validate: (value) => {
                      return (value || "").trim().length >= 2;
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("firstName")}
              </IonItem>

              <IonItem key="lastName" lines="none">
                <IonLabel position="stacked" color="medium">
                  Last Name
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={user.lastName}
                  name="lastName"
                  rules={{
                    required: {
                      message: "A last name is required.",
                      value: true,
                    },
                    minLength: {
                      message: "Must be at least 2 characters long.",
                      value: 2,
                    },
                    validate: (value) => {
                      return (value || "").trim().length >= 2;
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("lastName")}
              </IonItem>

              <IonItem key="email" lines="none">
                <IonLabel position="stacked" color="medium">
                  Email
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={user.email}
                  name="email"
                  rules={{
                    required: {
                      message: "A valid email address is required.",
                      value: true,
                    },
                    pattern: {
                      message: "The email address is invalid.",
                      value: emailRegEx,
                    },
                    validate: (value) => {
                      if ((value || "").trim().length === 0) {
                        return false;
                      }

                      const searchString = value.toLowerCase().trim();
                      const found =
                        session.users?.data?.filter(
                          (existing) =>
                            existing.id !== user.id &&
                            existing.email?.toLowerCase().trim() == searchString
                        ) ?? [];
                      if (found.length > 0) {
                        return "User with same email address already exists.";
                      }

                      return emailRegEx.test(value);
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("email")}
              </IonItem>

              <IonItem key="userPresenceStatus" lines="none" hidden={!user.id}>
                <IonLabel position="stacked" color="medium">
                  Presence
                </IonLabel>
                <IonInput readonly>{user.userPresenceStatus}</IonInput>
              </IonItem>

              <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                <strong>SECURITY</strong>
              </IonListHeader>
              <IonItem key="password" lines="none">
                <IonLabel position="stacked" color="medium">
                  {user.id === null ? "Password" : "New Password"}
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue=""
                  name="password"
                  rules={{
                    validate: (value) => {
                      return (user.id !== null && (value || "") === "") || (value || "").trim().length >= 8 || "Must be at least 8 characters long.";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      type="password"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("password")}
              </IonItem>

              <IonItem key="passwordConfirmation" lines="none" hidden={user.id !== null && watch("password", "") === ""}>
                <IonLabel position="stacked" color="medium">
                  Confirm Password
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue=""
                  name="passwordConfirmation"
                  rules={{
                    validate: (value) => {
                      return (user.id !== null && watch("password", "") === "") || (value === watch("password", "")) || "The passwords do not match.";
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonInput
                      className="input-field"
                      type="password"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                />
                {renderError("passwordConfirmation")}
              </IonItem>

              <IonItem key="role" lines="none" style={{ paddingBottom: "10px" }}>
                <IonLabel position="stacked" color="medium">
                  Role
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={user.role}
                  name="role"
                  rules={{
                    required: {
                      message: "A valid user role must be selected.",
                      value: true,
                    },
                    validate: (value) => {
                      const role = (value || "").trim().toLowerCase();
                      switch (role) {
                        case "admin":
                        case "new":
                        case "user":
                          return true;
                        default:
                          return false;
                      }
                    },
                  }}
                  render={({ onBlur, onChange, value }) => (
                    <IonSelect
                      className="input-field"
                      okText="Okay"
                      cancelText="Dismiss"
                      value={value}
                      onBlur={onBlur}
                      placeholder="None"
                      onIonChange={(e) => {
                        onChange(e);
                      }}
                    >
                      <IonSelectOption value="admin">System Admin</IonSelectOption>
                      <IonSelectOption value="user">User</IonSelectOption>
                    </IonSelect>
                  )}
                />
                {renderError("role")}
              </IonItem>

              <IonItem
                lines="none"
                key="canLogin"
                hidden={watch("role", user?.role || "new") === "new"}
                className=""
              >
                <IonLabel position="stacked" color="medium">
                  Can Login
                </IonLabel>
                <Controller
                  control={control}
                  defaultValue={user.canLogin}
                  name="canLogin"
                  render={({ onBlur, onChange, value }) => {
                    return (
                      <IonToggle

                        checked={value}
                        onBlur={onBlur}
                        onIonChange={(
                          e: CustomEvent<ToggleChangeEventDetail>
                        ) => {
                          onChange(e?.detail.checked);
                        }}
                      />
                    );
                  }}
                />
                {renderError("canLogin")}
              </IonItem>
            </IonList>
          ) : (
            <div>User not found</div>
          )}
        </IonContent>
      </IonPage>
    );
    /// --- render
  }
);

export default UserDetail;
