import React, { useContext, useEffect, useRef, useState } from "react";
import { when } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonFooter,
  IonTextarea,
  IonBadge,
  IonChip,
  IonText,
  CreateAnimation,
  IonLabel,
  IonImg,
  IonSpinner,
} from "@ionic/react";

import { close, chatbubblesOutline, send, camera, megaphoneOutline } from "ionicons/icons";

import BroadcastMessagesListItem from "./BroadcastMessagesListItem";

import * as Models from "../../../models";

import { relativeDateString } from "../../../utils/Helpers";
import { SessionContext } from "../../../session";
import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

interface BroadcastMessagesButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const BroadcastMessagesButton: React.FC<BroadcastMessagesButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);

    const gridRef = useRef<HTMLIonGridElement | null>(null);

    useEffect(() => {
      console.log("Refetch started...")
      if (showModal) {
        return when(
          () => ((conversation?.customer?.loaded ?? false) && (typeof (conversation?.broadcastMessages) !== "undefined")),
          async () => {
            await conversation?.broadcastMessages?.refetch();
          }
        );
      }
    }, [showModal]);

    const scrollToBottom = () => {
      if (gridRef?.current) {
        gridRef.current.scrollTop = gridRef.current.scrollHeight;
      }
    };

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const isOwner =
      conversation?.ownerId == session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status == "active";
    const workItemState = isActive
      ? conversation?.requestTransfer &&
        conversation?.requestTransfer.targetOwnerId ==
        session.currentUser?.workerProfileId
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !=
          session.currentUser?.workerProfileId &&
          isOwner
          ? "outgoing"
          : "assigned"
      : "completed";

    const broadcastMessages = conversation?.broadcastMessages?.data?.reduce(
      (groups: Record<string, Models.BroadcastMessage[]>, item) => {
        if (item.createdAt && typeof (item.data?.broadcastMessageRef) === "string") {
          const dateString = moment(item.createdAt).format("YYYY-MM-DD");

          if (!groups[dateString]) {
            groups[dateString] = [];
          }

          groups[dateString].push(item);
        }

        return groups;
      },
      {}
    );

    return (
      <>
        <IonButton
          slot="end"
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => {
            set_showModal(true);
          }}
          disabled={conversation === undefined || conversation === null}
        >
          <IonIcon icon={megaphoneOutline} />
        </IonButton>
        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar
              color={workItemState == "completed" ? "tertiary" : "tertiary"}
            >
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Broadcasted Messages</IonTitle>

              <IonButtons slot="end"></IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {broadcastMessages && Object.keys(broadcastMessages).length > 0 ? (
              <IonGrid
                ref={gridRef}
                style={{
                  height: "100%",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                onScroll={(e) => {
                  if (
                    e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                    e.currentTarget.scrollHeight
                  ) {
                    //conversation?.workItem?.markBroadcastMessagesAsRead();
                  }
                }}
              >
                {Object.keys(broadcastMessages)
                  .reverse()
                  .map((key) => (
                    <div key={key}>
                      <div
                        className="ion-padding-top"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          position: "sticky",
                          top: "0px",
                          zIndex: 999999,
                        }}
                      >
                        <IonChip>
                          {relativeDateString(moment(key).toDate(), false)}
                        </IonChip>
                      </div>
                      {broadcastMessages[key].map((item) => (
                        <BroadcastMessagesListItem
                          key={item.id}
                          broadcastMessage={item}
                          onInView={(broadcastMessage) => {

                          }}
                        />

                      ))}
                    </div>
                  ))}
              </IonGrid>
            ) : (
               (conversation?.broadcastMessages?.loading ?? true) && (conversation?.broadcastMessages !== null ?? true)
                ? <div style={{ width: "100%" }}><IonSpinner style={{ margin: "10px", paddingTop: "40px" }}/></div>
                : (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-center">
                      <IonImg
                        style={{
                          maxWidth: "250px",
                          margin: "auto",
                          marginTop: "69px",
                        }}
                        src={"/assets/images/artboard009.png"}
                      />
                      <p>
                        <strong>
                          <IonText
                            color="dark"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            No Broadcasted Messages
                          </IonText>
                        </strong>
                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )
            )}
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default BroadcastMessagesButton;
