import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  chatbubblesOutline,
  logoFacebook,
  peopleOutline,
} from "ionicons/icons";

import { SessionContext } from "../../session";
import { createSwipeBackGesture } from "../../utils/gestures/swipeBackGesture";

const DashboardsPage: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const pageRef = useRef<HTMLElement>()

  useEffect(() => {
    if (pageRef.current) {
      const swipeBackGesture = createSwipeBackGesture(
        pageRef.current,
        () => { return true; }, // canStartHandler
        () => { }, // onStartHandler
        (step: number) => { }, // onMoveHandler
        (shouldComplete: boolean, step: number, dur: number) => { } // onEndHandler
      );
      swipeBackGesture.enable();
    }
  }, [pageRef.current])

  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {(session.windowSize === "small") ? (
              <></>
            ) : (
              <IonMenuButton hidden menu="start" autoHide={false} />
            )}
          </IonButtons>
          <IonTitle>Dashboards</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <IonList lines="none" className="ion-no-padding">
          <IonListHeader color="light" style={{ paddingTop: "5px" }}>
            <strong>GENERAL</strong>
          </IonListHeader>
          <IonItem
            color={
              location.pathname == `${match.path}/workspace` ? "primary" : ""
            }
            routerLink={`${match.path}/workspace`}
          >
            <IonLabel>Workspace Dashboard</IonLabel>
          </IonItem>

          {
            session.currentUser?.role === "admin"
              ? (
                <IonItem
                  color={
                    location.pathname === `${match.path}/usage` ? "primary" : ""
                  }
                  routerLink={`${match.path}/usage`}
                >
                  <IonLabel>Usage Dashboard</IonLabel>
                </IonItem>
              ) : (
                <></>
              )
          }

          <IonItem
            hidden
            color={location.pathname == `${match.path}/me` ? "primary" : ""}
            routerLink={`${match.path}/me`}
          >
            <IonLabel>My Dashboard</IonLabel>
            <IonIcon hidden slot="start" icon={peopleOutline} />
          </IonItem>

          <IonListHeader color="light" style={{ paddingTop: "15px" }}>
            <strong></strong>
          </IonListHeader>
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default DashboardsPage;
