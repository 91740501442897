import firebase from "firebase/app";
import { Session } from "../../session";

import { InternalMessage } from "./InternalMessage";

export interface InternalMessageData {
    body: string | null;
    createdOn: firebase.firestore.Timestamp | null;
    senderId: string | null;
    status: string | null;
}

export const internalMessageDefaultValue = {
    body: null,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    senderId: null,
    status: null,
} as InternalMessageData;

export const internalMessageDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: InternalMessageData) {
            return {
                body: data.body,
                createdOn: data.createdOn,
                senderId: data.senderId,
                status: data.status,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<InternalMessageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                body: data.body ?? null,
                createdOn: data.createdOn ?? null,
                senderId: data.senderId ?? null,
                status: "sent",
            } as InternalMessageData;
        },
    }
};

export const internalMessageConverter = (session: Session) => {
    return {
        toFirestore: function (message: InternalMessage) {
            return message.data
                ? {
                    body: message.data.body,
                    createdOn: message.data.createdOn,
                    senderId: message.data.senderId,
                    status: message.data.status,
                } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<InternalMessageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return InternalMessage.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    body: data.body ?? null,
                    createdOn: data.createdOn ?? null,
                    senderId: data.senderId ?? null,
                    status: "sent",
                });
        },
    }
};