import firebase from "firebase/app";
import { Session } from "../../session";

import { UserPresence } from "./UserPresence";

export interface UserPresenceData {
  modifiedOn: firebase.firestore.Timestamp | null;
  status: "online" | "offline" | null;
};

export const userPresenceDefaultValue = {
  modifiedOn: null,
  status: null
} as UserPresenceData;

export const userPresenceDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: UserPresenceData) {
      return {
        modifiedOn: data.modifiedOn,
        status: data.status
      };
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<UserPresenceData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        modifiedOn: data.modifiedOn ?? null,
        status: data.status ?? null
      } as UserPresenceData;
    },
  };
};

export const userPresenceConverter = (session: Session) => {
  return {
    toFirestore: function (userPresence: UserPresence) {
      return userPresence.data ? {
        modifiedOn: userPresence.data.modifiedOn,
        status: userPresence.data.status,
      } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<UserPresenceData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return UserPresence.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          modifiedOn: data.modifiedOn ?? null,
          status: data.status ?? null
        }
      );
    },
  };
};
