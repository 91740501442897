import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonProgressBar,
  IonToolbar,
} from "@ionic/react";
import EmptyContainer from "../../components/EmptyContainer";

interface EmptyPageProps {
  title: string;
  message: string;
  image?: string;
}

const EmptyPage: React.FC<EmptyPageProps> = ({ title, message, image }) => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start"></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <EmptyContainer
          title={title}
          message={message}
          image={image}
        />
      </IonContent>
    </IonPage>
  );
};

export const NoConversationSelectedEmptyPage: React.FC = () => <EmptyPage title="No Conversation Selected" message="Select a Conversation on the left." image="/assets/images/artboard001.png"/>
export const NoDashboardSelectedEmptyPage: React.FC = () => <EmptyPage title="No Dashboard Selected" message="Select a Dashboard on the left." image="/assets/images/artboard002.png"/>
export const NoAdminSectionSelectedEmptyPage: React.FC = () => <EmptyPage title="No Admin Section Selected" message="Select an Admin Section on the left." image="/assets/images/artboard003.png"/>
export const NoProfileDetailsEmptyPage: React.FC = () => <EmptyPage title="Profile" message="" />

export default EmptyPage;
