import firebase from "firebase/app";

import {
    makeAutoObservable,
    onBecomeObserved,
    onBecomeUnobserved,
    when,
    IReactionDisposer,
    reaction,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { WorkerProfile } from "../WorkerProfiles";
import { BroadcastMessageData, broadcastMessageDefaultValue } from "./BroadcastMessageData";

const __cache: Record<string, BroadcastMessage> = {};

export class BroadcastMessage {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: BroadcastMessageData | undefined;

    constructor(session: Session, path: string, data?: BroadcastMessageData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = getId(this.mPath);
        this.mData = data ? data : (this.mId == null ? broadcastMessageDefaultValue : undefined);
    }

    static createCacheInstance(session: Session, path: string, data?: BroadcastMessageData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        const id = getId(cachePath);
        if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new BroadcastMessage(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: BroadcastMessage) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = getId(this.mPath);
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: BroadcastMessageData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public get body(): string | null | undefined {
        if (this.data) return this.data.body;
        return undefined;
    }

    public get createdAt(): Date | null | undefined {
        if (this.data) return this.data.createdAt.toDate();
        return undefined;
    }

    public get senderId(): string | null | undefined {
        if (this.data) return "";
        return undefined;
    }

    public get status(): string | null | undefined {
        if (this.data) return "";
        return undefined;
    }
}