import firebase from "firebase/app";

import {
  IReactionDisposer,
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  when,
} from "mobx";

import { Session } from "../../session";
import { getId } from "../../utils/Helpers";
import { Message } from "../Messages";

import {
  ConversationHistoryEntryArgs,
  ConversationHistoryEntryData,
  conversationHistoryEntryDataConverter,
  conversationHistoryEntryDefaultValue
} from "./ConversationHistoryEntryData";

const CONVERSATION_HISTORY_VARIABLES = {
  Actor_Active: "[Actor.Active]",
  Actor_Passive: "[Actor.Passive]",
  Conversation: "[Conversation]",
  Message: "[Message.Text]",
  Outcome: "[Outcome.Text]",
  Transfer: "[Transfer.Text]"
};

const __cache: Record<string, ConversationHistoryEntry> = {};

export class ConversationHistoryEntry {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: ConversationHistoryEntryData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mMessage: Message | null | undefined;
  private mMessageDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: ConversationHistoryEntryData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? conversationHistoryEntryDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "message", this.subscribeMessage);
    onBecomeUnobserved(this, "message", this.unsubscribeMessage);
  }

  static createCacheInstance(session: Session, path: string, data?: ConversationHistoryEntryData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new ConversationHistoryEntry(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: ConversationHistoryEntry) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data(): ConversationHistoryEntryData | undefined {
    return this.mData;
  }
  protected set data(newValue: ConversationHistoryEntryData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationHistoryEntryDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationHistoryEntryDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<ConversationHistoryEntryData>) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get createdOn(): Date | null | undefined {
    if (this.data) return this.data.createdOn?.toDate();
    return undefined;
  }

  public get trigger(): string | null | undefined {
    if (this.data) return this.data.trigger;
    return undefined;
  }

  public get args(): ConversationHistoryEntryArgs | undefined {
    if (this.data) return this.data.data;
    return undefined;
  }

  public get displayText(): string | null {
    if (this.displayFormats 
      && this.trigger 
      && this.displayFormats[this.trigger]
    ) {
      const text = this.displayFormats[this.trigger]
        .replace(CONVERSATION_HISTORY_VARIABLES.Actor_Active, this.args?.actor.active?.text || "")
        .replace(CONVERSATION_HISTORY_VARIABLES.Actor_Passive, this.args?.actor.passive?.text || "")
        .replace(CONVERSATION_HISTORY_VARIABLES.Conversation, this.args?.conversation?.text || "")
        .replace(CONVERSATION_HISTORY_VARIABLES.Message, this.args?.message?.text || "")
        .replace(CONVERSATION_HISTORY_VARIABLES.Outcome, this.args?.outcome?.text || "")
        .replace(CONVERSATION_HISTORY_VARIABLES.Transfer, this.args?.transfer?.text || "");
      return text;
    }
    
    return `[${this.trigger || "Unknown event"}] from ${this.args?.actor.active?.text} to ${this.args?.actor.passive?.text}`;
  }

  public get displayFormats(): Record<string, string> {
    return this.session
      .formats
      ?.data
      ?.find(doc => doc.id === "conversationHistory")
      ?.DisplayFormats || {};
  }

  public get messageRef(): string | null | undefined {
    
    if (this.data) return this.data?.data?.message?.ref?.path;
    return undefined;
  }

  public get message(): Message | null | undefined {
    return this.mMessage;
  }
  public subscribeMessage = () => {
    when(
      () => this.messageRef !== undefined,
      () => {
        const initMessage = () => {
          if (this.mMessage && this.mMessage.id !== this.messageRef) {
            this.unsubscribeMessage();
          }

          if (this.mMessage === undefined && this.messageRef !== undefined) {
            this.mMessage = this.messageRef
              ? Message.createCacheInstance(
                this.session,
                `${this.messageRef}`
              )
              : null;
            this.mMessage?.subscribe();

            this.mMessageDisposer = reaction(
              () => this.messageRef,
              (customerId) => initMessage()
            );
          }
        };

        initMessage();
      }
    );
  };
  public unsubscribeMessage = () => {
    if (this.mMessageDisposer) this.mMessageDisposer();
    this.mMessage?.unsubscribe();
    this.mMessage = undefined;
  };
}