import firebase from "firebase/app";

import { IReactionDisposer, makeAutoObservable, onBecomeObserved, onBecomeUnobserved, reaction, when } from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";
import { Group } from "../Groups";

import { ConversationsSettingsData, conversationsSettingsDataConverter, conversationsSettingsDefaultValue } from "./ConversationsSettingsData";

const __cache: Record<string, ConversationsSettings> = {};

export class ConversationsSettings {
  private mSession: Session;
  private mPath: string;
  private mId: string | null;
  private mData: ConversationsSettingsData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mGroup: Group | null | undefined;
  private mGroupDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: ConversationsSettingsData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? conversationsSettingsDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "group", this.subscribeGroup);
    onBecomeUnobserved(this, "group", this.unsubscribeGroup);
  }

  static createCacheInstance(session: Session, path: string, data?: ConversationsSettingsData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new ConversationsSettings(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: ConversationsSettings) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: ConversationsSettingsData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationsSettingsDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationsSettingsDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<ConversationsSettingsData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(conversationsSettingsDataConverter(this.session))
            .add(this.data).then((doc) => {
              this.path = doc.path;
              ConversationsSettings.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(conversationsSettingsDataConverter(this.session))
            .set(this.data, { merge: true }).then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  get groupId(): string | undefined {
    if (this.data && this.data.groupId) {
      return this.data.groupId;
    }
    return undefined;
  }

  public get group(): Group | null | undefined {
    return this.mGroup;
  }

  public subscribeGroup = () => {
    when(
      () => this.groupId !== undefined,
      () => {
        const initGroup = () => {
          if (this.mGroup && this.mGroup.id !== this.groupId) {
            this.unsubscribeGroup();
          }

          if (this.mGroup === undefined && this.groupId !== undefined) {
            this.mGroup = this.groupId
              ? Group.createCacheInstance(
                this.session,
                `groups/${this.groupId}`
              )
              : null;
            this.mGroup?.subscribe();

            this.mGroupDisposer = reaction(
              () => this.groupId,
              (groupId) => initGroup()
            );
          }
        };

        initGroup();
      }
    );
  };
  public unsubscribeGroup = () => {
    if (this.mGroupDisposer) this.mGroupDisposer();
    this.mGroup?.unsubscribe();
    this.mGroup = undefined;
  };

  update = (data: ConversationsSettingsData) => {
    return new Promise((resolve, reject) => {
      this.session.firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(conversationsSettingsDataConverter(this.session))
        .set(data, { merge: true })
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}