import firebase from "firebase/app";

import { makeAutoObservable } from "mobx";
import { Session } from "../../session";

import { BroadcastMessage } from "./BroadcastMessage";

export class BroadcastMessages {
  private mSession: Session;
  private mPath: string;
  private mData: BroadcastMessage[] | null | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;

    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  public get session() {
    return this.mSession;
  }

  public get path() {
    return this.mPath;
  }

  public get data() {
    //if (this.mData) return this.mData.filter((item) => item.data?.isDeleted !== true ?? true);
    return this.mData;
  }
  public set data(newValue: BroadcastMessage[] | null | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public async refetch() {
    if (!this.mSubscription) {
      this.data = undefined;

      const fetchBroadcastMessages = this.session.firebase
        .functions()
        .httpsCallable("fetchBroadcastMessages");

      try {
        const result = await fetchBroadcastMessages({
          instanceId: this.session.instanceId,
          phoneNumber: this.path,
        });

        if (result.data.isError === true) {
          this.data = null;
        } else {
          this.data = result.data.broadcastMessages?.map((itemData: any) => new BroadcastMessage(this.session, itemData.id, {
            ...itemData,
            createdAt: new firebase.firestore.Timestamp(itemData.createdAt._seconds, itemData.createdAt._nanoseconds)
          }));
        }
      } catch (error) {
        console.log(error)
        this.data = null;
      }
    }
  }
}
