import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { iosTransitionAnimation, isPlatform, setupConfig } from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";

setupConfig({
  mode: "md",
  animated: false, //isPlatform("capacitor"),
  swipeBackEnabled: isPlatform("capacitor") && isPlatform("mobile"),
  rippleEffect: false,
  navAnimation: iosTransitionAnimation
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

if (isPlatform("capacitor")) {
  if (isPlatform("ios")) {
    Keyboard.addListener("keyboardWillShow", (info) => {
      //console.log("keyboard will show with height:", info.keyboardHeight);

      const app = document.querySelector("ion-app");
      if (app) {
        app.style.marginBottom = `${info.keyboardHeight}px`;
      }
    });

    Keyboard.addListener("keyboardDidShow", (info) => {
      //console.log("keyboard did show with height:", info.keyboardHeight);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      const app = document.querySelector("ion-app");
      if (app) {
        app.style.marginBottom = `0px`;
      }
    });

    Keyboard.addListener("keyboardDidHide", () => {
      //console.log("keyboard did hide");
    });
  } else if (isPlatform("android")) {
  }
} /*else {
  window.addEventListener('ionKeyboardWillShow', (info: any) => {
    console.log('keyboard will show with height:', info?.keyboardHeight);

    const app = document.querySelector("ion-app");
    if (app && info?.keyboardHeight) {
      app.style.marginBottom = `${info.keyboardHeight}px`;
    }
  });
  
  window.addEventListener('ionKeyboardDidShow', (info: any) => {
    console.log('keyboard did show with height:', info.keyboardHeight);

    const app = document.querySelector("ion-app");
    if (app && info?.keyboardHeight) {
      app.style.marginBottom = `${info.keyboardHeight}px`;
    }
  });
  
  window.addEventListener('ionKeyboardWillHide', () => {
    const app = document.querySelector("ion-app");
    if (app) {
      app.style.marginBottom = `0px`;
    }
  });

  window.addEventListener('ionKeyboardDidHide', () => {
    const app = document.querySelector("ion-app");
    if (app) {
      app.style.marginBottom = `0px`;
    }
  });
}*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert("New update found. Reloading app.");
    registration.update().then(() => window.location.reload());
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

defineCustomElements(window);
