import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ApolloProvider } from "@apollo/client";

import {
  IonPage,
} from "@ionic/react";

import { SessionContext } from "../../session";
import ExportCompletedConversations from "./components/ExportCompletedConversations";

const ExportCompletedConversationsPage: React.FC = observer(() => {
  const session = useContext(SessionContext);
  if (!session || !session.apolloClient) {
    return <></>;
  }

  return (
    <ApolloProvider client={session.apolloClient}>
      <IonPage>
        <ExportCompletedConversations />
      </IonPage>
    </ApolloProvider>
  );
});

export default ExportCompletedConversationsPage;
