import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

import {
  close,
  closeOutline,
  checkmarkOutline,
  trash,
  peopleCircle,
  peopleCircleOutline,
  remove,
  personRemove
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";

interface ToggleChangeEventDetail {
  value: boolean;
  checked: boolean;
}
interface PhoneNumberDetailProps {
  session: Session;
  phoneNumber: Models.PhoneNumber;
  onDismiss: () => void;
}
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PhoneNumberDetail: React.FC<PhoneNumberDetailProps> = observer(
  ({ session, phoneNumber, onDismiss }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const { control, formState, errors, handleSubmit, watch } = useForm({
      mode: "onChange",
    });

    const groups = session.groups?.data;

    const [phoneNumbers, setPhoneNumbers] = useState<{
      phoneNumber: string,
      smsMethod: string,
      smsUrl: string,
      sid: string,
      capabilities: {
        voice: boolean,
        sms: boolean,
        mms: boolean,
      },
    }[] | undefined>();

    useEffect(() => {
      async function fetchData() {
        try {
          if (session) {
            const server_fetchPhoneNumbers = session.firebase
              .functions()
              .httpsCallable('fetchPhoneNumbers');
            const result = await server_fetchPhoneNumbers({
              instanceId: session.instanceId
            }) as {
              data: {
                phoneNumbers: {
                  phoneNumber: string,
                  smsMethod: string,
                  smsUrl: string,
                  sid: string,
                  capabilities: {
                    voice: boolean,
                    sms: boolean,
                    mms: boolean,
                  },
                }[]
              }
            };

            const response = result.data.phoneNumbers;
            setPhoneNumbers(response);
          }
        } catch (error) {
          return undefined;
        }
      }
      fetchData();
    }, [session]);

    /// Handlers ---
    const onDeletePhoneNumber = () => {
      // Validation
      if (!session.currentUser) {
        console.warn('You need to be logged in to perform this action.');
        return;
      }

      if (session.currentUser?.role !== "admin") {
        console.warn('Logged in phoneNumber does not have the required permissions to perform this action.');
        return;
      }

      // Perform action
      phoneNumber
        .delete()
        .then(() => onDismiss())
        .catch(error => console.error('Error deleting phoneNumber.', error));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && phoneNumber) {
        phoneNumber.name = data.name.trim();
        phoneNumber.phone = data.phone.trim();
        phoneNumber.groupId = data.groupId;

        console.log("Debug", "phoneNumber save", phoneNumber)
        try {
          await phoneNumber.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save phoneNumber.");
          console.log("Error saving phoneNumber.", error);
        }
      }
    };
    /// --- handlers

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Phone Number</IonTitle>
            <IonButtons slot="end">
              {isDeleting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={trash} title="Delete" />
                  <IonButton
                    onClick={() => setIsDeleting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDeletePhoneNumber}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {session.currentUser?.role === "admin" && phoneNumber.id ? (
                    <IonButton
                      onClick={() => setIsDeleting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                    >
                      <IonIcon icon={trash} title="Delete" />
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    color="light"
                    expand="block"
                    fill="solid"
                    shape="round"
                    size="large"
                  >
                    <IonIcon hidden icon={checkmarkOutline} />
                    {formState.isSubmitting ? "Saving..." : "Save"}
                  </IonButton>
                </>
              )}
            </IonButtons>
            <>
              <IonAvatar
                hidden
                slot="start"
                style={{
                  height: "35px",
                  width: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
              >
                <img alt="" src={peopleCircle} />
              </IonAvatar>

              <IonLabel hidden slot="start">
                <h2>
                  <b>
                    &nbsp;
                    {phoneNumber?.name}
                  </b>
                </h2>
              </IonLabel>
            </>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {phoneNumber ? (
            <>
              <IonList lines="none" className="ion-no-padding">
                <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                  <strong>BASIC DETAILS</strong>
                </IonListHeader>
                <IonItem key="name" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Name
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={phoneNumber.name}
                    name="name"
                    rules={{
                      required: {
                        message: "A name is required.",
                        value: true,
                      },
                      minLength: {
                        message: "Must be at least 2 characters long.",
                        value: 2,
                      },
                      validate: (value) => {
                        return (value || "").trim().length >= 2;
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {renderError("name")}
                </IonItem>
                <IonItem className="default-element-border" lines="none">
                  <IonLabel position="stacked" color="medium">Phone Number</IonLabel>
                  <>
                    {typeof phoneNumbers === "undefined"
                      ? (
                        <>
                          <IonInput
                            className="input-field"
                            readonly
                            value={phoneNumber.phone}
                          />
                          <IonSpinner slot="end" />
                        </>
                      ) : (
                        <>
                          <Controller
                            control={control}
                            defaultValue={phoneNumber.phone}
                            name="phone"
                            rules={{
                              required: "Phone number is required",
                            }}
                            render={({ onBlur, onChange, value }) => (
                              <IonSelect
                                className="input-field"
                                value={value}
                                onIonChange={onChange}
                                onIonBlur={onBlur}
                                interfaceOptions={{
                                  cssClass: "select-alert"
                                }}
                              >
                                <>
                                  {phoneNumbers?.findIndex((item) => item.phoneNumber === phoneNumber.phone) === -1 && (
                                    <IonSelectOption
                                      key={phoneNumber.phone}
                                      value={phoneNumber.phone}
                                    >
                                      {phoneNumber.phone}
                                    </IonSelectOption>
                                  )}
                                  {phoneNumbers && phoneNumbers.length > 0 && phoneNumbers.map(phoneNumber => {
                                    return (
                                      <IonSelectOption
                                        key={phoneNumber.sid}
                                        value={phoneNumber.phoneNumber}
                                        disabled={!(
                                          phoneNumber.capabilities.sms && phoneNumber.smsMethod === "POST"
                                          && (
                                            phoneNumber.smsUrl.toLowerCase().startsWith(`https://reach.voyagernetz.us/instances/${session.instanceId}/api/twilio#rc=2&rp=all`)
                                            || phoneNumber.smsUrl.toLowerCase().startsWith(`https://reach.voyagernetz.us/instances/${session.instanceId}/api/twilio?conversations=inbox#rc=2&rp=all`)
                                            || phoneNumber.smsUrl.toLowerCase().startsWith(`https://vrz-reach-staging.web.app/instances/${session.instanceId}/api/twilio#rc=2&rp=all`)
                                            || phoneNumber.smsUrl.toLowerCase().startsWith(`https://vrz-reach-staging.web.app/instances/${session.instanceId}/api/twilio?conversations=inbox#rc=2&rp=all`)
                                            || phoneNumber.smsUrl.toLowerCase().startsWith(`https://us-central1-vrz--${session.instanceId}.cloudfunctions.net/twilio_sms_message#rc=2&rp=all`)
                                          )
                                        )}
                                      >
                                        {`${phoneNumber.phoneNumber}${!phoneNumber.capabilities.sms
                                          ? " - SMS capability missing"
                                          : !(
                                            phoneNumber.smsMethod === "POST"
                                            && (
                                              phoneNumber.smsUrl.toLowerCase().startsWith(`https://reach.voyagernetz.us/instances/${session.instanceId}/api/twilio#rc=2&rp=all`)
                                              || phoneNumber.smsUrl.toLowerCase().startsWith(`https://reach.voyagernetz.us/instances/${session.instanceId}/api/twilio?conversations=inbox#rc=2&rp=all`)
                                              || phoneNumber.smsUrl.toLowerCase().startsWith(`https://vrz-reach-staging.web.app/instances/${session.instanceId}/api/twilio#rc=2&rp=all`)
                                              || phoneNumber.smsUrl.toLowerCase().startsWith(`https://vrz-reach-staging.web.app/instances/${session.instanceId}/api/twilio?conversations=inbox#rc=2&rp=all`)
                                              || phoneNumber.smsUrl.toLowerCase().startsWith(`https://us-central1-vrz--${session.instanceId}.cloudfunctions.net/twilio_sms_message#rc=2&rp=all`)
                                            )
                                          )
                                            ? " - Incorrect Twilio configuration"
                                            : ""}`}
                                      </IonSelectOption>
                                    )
                                  })}
                                </>
                              </IonSelect>
                            )}
                          />
                          {renderError("phone")}
                        </>
                      )
                    }
                  </>
                </IonItem>
                <IonItem className="default-element-border ion-padding-bottom" lines="none">
                  <IonLabel position="stacked" color="medium">Default Group</IonLabel>
                  <Controller
                    control={control}
                    defaultValue={phoneNumber.groupId}
                    name="groupId"
                    rules={{
                      required: "A default group for the phone number is required.",
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonSelect
                        className="input-field"
                        value={value}
                        onIonChange={onChange}
                        onIonBlur={onBlur}
                        interfaceOptions={{
                          cssClass: "select-alert"
                        }}
                      >
                        {groups && groups.length > 0 && groups.map(group => {
                          return (
                            <IonSelectOption
                              key={group.id}
                              value={group.id}
                            >
                              {group.name}
                            </IonSelectOption>
                          )
                        })}
                      </IonSelect>
                    )}
                  />
                  {renderError("group")}
                </IonItem>
              </IonList>
            </>
          ) : (
            <div>Phone Number not found</div>
          )}
        </IonContent>
      </IonPage>
    );
    /// --- render
  }
);

export default PhoneNumberDetail;
