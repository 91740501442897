import React, { useState } from "react";
import { IonButton, IonSpinner, useIonToast } from "@ionic/react";

const withBusyIndicator = <
    T extends {
        onClick?: React.MouseEventHandler<HTMLIonButtonElement> | undefined;
        children?: React.ReactNode;
        showIndicator?: boolean;
    }
>(Component: React.ComponentType<T>) => (hocProps: T) => {
    const [isBusy, setIsBusy] = useState(false);
    const onClickInternal = hocProps.onClick;
    const childrenInternal = hocProps.children;
    const showIndicator = hocProps.showIndicator;

    //const [present, dismiss] = useIonToast();

    return (
        <Component
            {...{
                ...hocProps,
                onClick: async (e) => {
                    //alert("Clicked button");
                    setIsBusy(true);
                    //present(hocProps.busyMessage ?? "Busy...")
                    //await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))

                    await onClickInternal?.(e)
                    setIsBusy(false);
                    //dismiss();
                },
                ...(isBusy ? { disabled: true } : {})
            }}

        >
            {isBusy && showIndicator
                ? (
                    <>
                        <IonSpinner slot="start" />
                    </>
                ) : (
                    childrenInternal
                )
            }
        </Component>
    );
}


export const Button = withBusyIndicator(IonButton);