import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { gql, useLazyQuery } from "@apollo/client";

import { Parser } from "json2csv";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonProgressBar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { arrowBack, checkmarkOutline } from "ionicons/icons";
import { SessionContext } from "../../../session";
import { Prompt, useLocation } from "react-router";
import moment from "moment";

const GRAPHQL_QUERY = gql(`
query Conversations($where: [WhereConstraint]) {
  conversations(where: $where) {
    status
    communicationChannel {
      channel
      facebookId
      mobileNumber
      provider
    }
    createdOn
    lastContactDate
    lastMessageDirection
    outcome {
      id
      path
      name
      category {
        name
      }
    }
    customer {
      firstName
      lastName
      mobileNumber
      facebookId
      email
    }
  }
}
`);

const ExportCompletedConversations: React.FC = observer(() => {
  let location = useLocation();
  const session = useContext(SessionContext);
  if (!session || !session.apolloClient) {
    return <></>;
  }

  const settings = session.conversationsSettings ?? null;

  const [getData, { loading, error, data }] = useLazyQuery(GRAPHQL_QUERY);

  const { control, handleSubmit, formState, reset, errors } = useForm(
    {
      mode: "onChange",
    }
  );

  useEffect(() => {
    reset();
  }, [location]);

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <IonNote color="danger" mode="md">
        {error.message || "Field Is Required"}
      </IonNote>
    ) : null;
  };

  const onSubmit = async (data: { fromDate: string, toDate: string }) => {
    if (formState.isValid && settings) {
      try {
        const result = await getData({
          variables: {
            where: [
              { fieldPath: "status", opStr: "==", value: "inactive" },
              { fieldPath: "modifiedAt", opStr: ">=", value: moment(data.fromDate).startOf("day").toISOString(), valueType: "timestamp" },
              { fieldPath: "modifiedAt", opStr: "<", value: moment(data.toDate).startOf("day").add(1, "day").toISOString(), valueType: "timestamp" },
            ]
          }
        });


        const fields = [
          "status",
          "communicationChannel.channel",
          "communicationChannel.facebookId",
          "communicationChannel.mobileNumber",
          "communicationChannel.provider",
          "createdOn",
          "lastContactDate",
          "lastMessageDirection",
          "outcome.id",
          "outcome.path",
          "outcome.name",
          "category.name",
          "customer.firstName",
          "customer.lastName",
          "customer.mobileNumber",
          "customer.facebookId",
          "customer.email",
        ];

        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(result.data.conversations);

        const fileName = `vrz-engage-export-${moment().toISOString()}`;
        //const json = JSON.stringify(result.data, null, 2);
        //const blob = new Blob([json], { type: "application/json" });
        const blob = new Blob([csv], { type: "application/csv" });
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        reset();
        alert("Downloaded " + fileName + ".csv");
      } catch (error) {
        alert("Unable to export data");
        console.error("Error exporting data.", error);
      }
    }
  };

  if (!settings?.loaded) {
    return <IonProgressBar type="indeterminate" />;
  }

  return (
      <>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              {(session.windowSize === "small") ? (
                <>
                  {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                  <IonButton routerLink="/admin">
                    <IonIcon icon={arrowBack} />
                  </IonButton>
                </>
              ) : (
                <></>
              )}
            </IonButtons>
            <IonTitle>Export</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !formState.isValid ||
                  formState.isSubmitting ||
                  !formState.isDirty
                }
                shape="round"
                expand="block"
                size="large"
                fill="solid"
                color="light"
              >
                <IonIcon hidden icon={checkmarkOutline} />
                {formState.isSubmitting ? "Exporting..." : "Export"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {settings ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList lines="none" className="ion-no-padding">
                <IonListHeader
                  color="light"
                  style={{ paddingTop: "40px", paddingBottom: "10px" }}
                >
                  <IonLabel>
                    <strong>DATE RANGE</strong>
                    <p style={{ paddingTop: "5px" }}>
                      <IonText color="dark" style={{ fontSize: "12px" }}>
                        Export Completed Conversations that were modified between the specified date range.
                      </IonText>
                    </p>
                  </IonLabel>
                </IonListHeader>
                <IonItem key="fromDate" lines="none">
                  <IonLabel position="stacked" color="medium">
                    From Date
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={""}
                    name="fromDate"
                    rules={{
                      required: true
                    }}
                    render={({ onBlur, onChange, value }) => {
                      return (
                        <IonDatetime
                          onIonBlur={onBlur}
                          onIonChange={onChange}
                          displayFormat="YYYY-MM-DD"
                          value={value}
                        />
                      );
                    }}
                  />
                </IonItem>
                <IonItem key="toDate" lines="none">
                  <IonLabel position="stacked" color="medium">
                    To Date
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={""}
                    name="toDate"
                    rules={{
                      required: true
                    }}
                    render={({ onBlur, onChange, value }) => {
                      return (
                        <IonDatetime
                          onIonBlur={onBlur}
                          onIonChange={onChange}
                          displayFormat="YYYY-MM-DD"
                          value={value}
                        />
                      );
                    }}
                  />
                </IonItem>
              </IonList>
            </form>
          ) : (
            <div></div>
          )}
        </IonContent>
      </>
  );
});

export default ExportCompletedConversations;
