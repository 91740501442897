import React, { useContext } from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonSkeletonText,
  IonChip,
  IonText,
} from "@ionic/react";

import { arrowForward, checkmarkOutline, closeOutline, documentAttachOutline, radioButtonOffOutline, swapHorizontalOutline, trendingDown } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import { relativeDateString } from "../../../utils/Helpers";
import AnimatedImg from "../../../components/AnimatedImg";
import { Button } from "../../../components/Button";

interface WaitingConversationsListItemProps {
  conversation: Models.Conversation;
  onConversationAccept: (conversation: Models.Conversation) => void;
}

const ConversationsListItem: React.FC<WaitingConversationsListItemProps> =
  observer(({ conversation, onConversationAccept }) => {
    const location = useLocation();

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    if (
      conversation &&
      conversation.loaded &&
      conversation.lastMessage &&
      conversation.lastMessage.loaded &&
      conversation.customer &&
      conversation.customer.loaded
    ) {
      // const workerProfile = session.currentUser?.workerProfile?.loaded
      //   ? session.currentUser?.workerProfile
      //   : undefined;
      const customerName = conversation.customer.displayName;
      const lastMessageSender =
        conversation.lastMessage?.type === "agent"
          ? conversation.lastMessage.workerProfile?.id === session.currentUser?.workerProfile?.id
            ? "You: "
            : `${conversation.lastMessage.workerProfile?.name}: `
          : conversation.lastMessage?.type === "system"
            ? "System: "
            : "";
      const lastMessage = conversation.lastMessage.body;
      const lastMessageHasFile = (conversation.lastMessage?.files?.data?.length ?? 0) > 0;
      const isOwner = conversation.ownerId === session.currentUser?.workerProfile?.id;
      const isParticipant = conversation.isParticipant;
      const isActive = conversation.status === "active";
      const conversationState = conversation.status === "new"
        ? "new"
        : isActive
          ? conversation.requestTransfer && !conversation.requestTransfer.isDeclined
            && (
              conversation.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId
              || (conversation.requestTransfer.targetGroupId && session.currentUser?.groupIds?.includes(conversation.requestTransfer.targetGroupId) && !isOwner)
            )
            ? "incoming"
            : conversation.requestTransfer
              && conversation.requestTransfer.targetOwnerId !== session.currentUser?.workerProfileId
              && isOwner
              ? "outgoing"
              : "assigned"
          : "completed";
      const messageDateString = conversation.lastMessage
        ? relativeDateString(conversation.lastMessage.createdOn)
        : "";
      const transferDateString = relativeDateString(
        conversation.requestTransfer?.requestedOn?.toDate()
      );
      return (
        <IonItem
          color={
            location.pathname === `/conversations/${conversation.id}`
              ? "light"
              : ""
          }
          routerLink={`/conversations/${conversation.id}`}
        >
          <IonIcon
            icon={conversationState === "incoming"
              ? "/icons/swap-horizontal-circle-outline.svg"
              : radioButtonOffOutline
            }
            color={isOwner ? "primary" : isParticipant ? "secondary" : "medium"}
            style={{ fontSize: "60px" }}
            className="ion-margin-end"
          ></IonIcon>
          <IonLabel>
            <h2>
              <b>{customerName}</b>
              <span className="ion-text-end ion-float-right">
                <IonText style={{ fontSize: "13px" }} color="medium">
                  {conversationState === "incoming"
                    ? transferDateString
                    : messageDateString}
                </IonText>
              </span>
            </h2>
            <p>
              <b>
                {conversationState === "incoming" && (conversation.requestTransfer?.description?.trim() ?? "") !== ""
                  ? `${conversation?.owner?.name ?? ""}: `
                  : lastMessageSender}
              </b>
              {conversationState === "incoming" && (conversation.requestTransfer?.description?.trim() ?? "") !== "" ? (
                conversation.requestTransfer?.description ?? ""
              ) : (
                <>
                  {lastMessageHasFile ? (
                    <b>
                      <IonIcon icon={documentAttachOutline} />{" "}
                    </b>
                  ) : (
                    <></>
                  )}
                  {lastMessage}
                </>
              )}
            </p>
            <p>

              <span
                className="ion-text-end ion-float-right"
                style={{ marginLeft: "5px" }}
                hidden={true}
              >
                <IonAvatar
                  style={{ height: "28px", width: "28px", marginTop: "13px" }}
                >
                  {conversation?.owner?.avatar
                    ? <AnimatedImg alt="" src={conversation?.owner?.avatar ?? undefined} />
                    : undefined
                  }
                </IonAvatar>
              </span>

              {conversationState === "new" || conversationState === "incoming"
                ? (
                  <span className="ion-text-end ion-float-right">

                    <IonChip color="medium" style={{ height: "47px", top: "3px", paddingLeft: "0px" }}>
                      <IonChip color="dark" className="ion-margin-end" style={{ height: "40px", paddingLeft: "10px", paddingRight: "20px" }}>
                        <IonIcon hidden icon={conversationState === "incoming" ? swapHorizontalOutline : trendingDown} title="New" />
                        {conversationState === "incoming"
                          ? (
                            <>
                              <IonAvatar
                                hidden={!conversation?.group?.avatar}
                                className="border-radius-0"
                                style={{ height: "28px", width: "28px" }}
                              >
                                {conversation?.group?.avatar
                                  ? <AnimatedImg alt="" src={conversation?.group?.avatar ?? undefined} />
                                  : undefined
                                }
                              </IonAvatar>
                              <IonAvatar
                                hidden={!conversation?.owner?.avatar}
                                style={{ marginLeft: "5px", height: "28px", width: "28px" }}
                              >
                                {conversation?.owner?.avatar
                                  ? <AnimatedImg alt="" src={conversation?.owner?.avatar ?? undefined} />
                                  : undefined
                                }
                              </IonAvatar>
                            </>
                          ) : undefined}

                        <IonIcon icon={arrowForward} color="medium" style={{ ...(conversationState === "incoming" ? { marginLeft: "5px" } : {}), marginRight: "5px" }} />
                        {conversationState === "incoming"
                          ? (
                            <>
                              <IonAvatar
                                hidden={!conversation?.transferGroup?.avatar}
                                className="border-radius-0"
                                style={{ height: "28px", width: "28px" }}
                              >
                                {conversation?.transferGroup?.avatar
                                  ? <AnimatedImg alt="" src={conversation?.transferGroup?.avatar ?? undefined} />
                                  : undefined
                                }
                              </IonAvatar>
                            </>
                          ) : (
                            <>
                              <IonAvatar
                                hidden={!conversation?.group?.avatar}
                                className="border-radius-0"
                                style={{ height: "28px", width: "28px" }}
                              >
                                {conversation?.group?.avatar
                                  ? <AnimatedImg alt="" src={conversation?.group?.avatar ?? undefined} />
                                  : undefined
                                }
                              </IonAvatar>
                            </>
                          )}
                      </IonChip>
                      <Button
                        fill="solid"
                        color="success"
                        shape="round"
                        onClick={() =>
                          conversation
                            .acceptConversation()
                            .then(() => onConversationAccept(conversation))
                        }
                      >
                        <IonIcon icon={checkmarkOutline} title="Accept" />
                      </Button>
                    </IonChip>
                  </span>
                ) : null
              }

            </p>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem lines="full">
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={radioButtonOffOutline}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
          <span className="ion-text-end ion-float-right">
            <IonIcon
              icon={undefined}
              color="medium"
              style={{ fontSize: "40px" }}
            ></IonIcon>
          </span>
        </IonLabel>
      </IonItem>
    );
  });

export default ConversationsListItem;
