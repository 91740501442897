import firebase from "firebase/app";

import {
  makeAutoObservable,
  runInAction,
} from "mobx";
import { Session } from "../../session";

import { File } from "./File";

export class Files {
  private mSession: Session;
  private mStorageRef: firebase.storage.Reference | null;
  private mPath: string;
  private mData: File[] | null | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;

    try {
      this.mStorageRef = this.session.firebase
        .storage()
        .ref()
        .child(path);
    } catch (error) {
      console.log("storage ref error", error);
      this.mStorageRef = null;
    }

    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  public get session() {
    return this.mSession;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    return this.mData;
  }
  protected set data(newValue: File[] | null | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      console.log(`refetch ${this.mPath}`);
      if (this.mStorageRef) {
        this.mStorageRef
          .listAll()
          .then(async (listResult) => {
            const data = await Promise.all(listResult.items.map(async (item) => {
              const metadata = await item.getMetadata();
              const url = await item.getDownloadURL();
              return File.createCacheInstance(this.session, item.fullPath, { metadata, url });
            }));
            this.data = data;
          });
      }
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = () => { };
      if (this.mStorageRef) {
        console.log(`subscribe to ${this.mPath}`, this.mStorageRef);
        this.mStorageRef
          .listAll()
          .then(async (listResult) => {
            const data = await Promise.all(listResult.items.map(async (item) => {
              const metadata = await item.getMetadata();
              const url = await item.getDownloadURL();
              return File.createCacheInstance(this.session, item.fullPath, { metadata, url });
            }));
            runInAction(() => this.mData = data);
          });
      }
    }
  }

  unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
