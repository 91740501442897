import firebase from "firebase/app";
import {
  IReactionDisposer,
  onBecomeObserved,
  onBecomeUnobserved,
  makeAutoObservable,
  reaction,
  when,
  runInAction,
} from "mobx";
import { Session } from "../../session";
import { getId, hashCode } from "../../utils/Helpers";
import { Files } from "../Files";
import { UserGroupRole, UserGroups } from "../UserGroups";

import { UserPresence } from "../UserPresences";
import { WorkerProfile } from "../WorkerProfiles";
import { UserData, userDataConverter, userDefaultValue, UserRole } from "./UserData";

const __cache: Record<string, User> = {};

export class User {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: UserData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mUserPresence: UserPresence | null | undefined;
  private mUserPresenceDisposer: IReactionDisposer | null | undefined;
  private mWorkerProfile: WorkerProfile | null | undefined;
  private mWorkerProfileDisposer: IReactionDisposer | null | undefined;
  private mUserGroups: UserGroups | null | undefined;
  private mFiles: Files | null | undefined;

  constructor(session: Session, path: string, data?: UserData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? userDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "userPresence", this.subscribeUserPresence);
    onBecomeUnobserved(this, "userPresence", this.unsubscribeUserPresence);
    onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
    onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
    onBecomeObserved(this, "userGroups", this.subscribeUserGroups);
    onBecomeUnobserved(this, "userGroups", this.unsubscribeUserGroups);
    onBecomeObserved(this, "files", this.subscribeFiles);
    onBecomeUnobserved(this, "files", this.unsubscribeFiles);
  }

  static createCacheInstance(session: Session, path: string, data?: UserData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new User(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: User) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: UserData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(userDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<UserData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get firstName(): string | null | undefined {
    if (this.data) return this.data.firstName;
    return undefined;
  }
  public set firstName(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.firstName = newValue;
    }
  }

  public get lastName(): string | null | undefined {
    if (this.data) return this.data.lastName;
    return undefined;
  }
  public set lastName(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.lastName = newValue;
    }
  }

  public get email(): string | null | undefined {
    if (this.data) return this.data.email;
    return undefined;
  }
  public set email(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.email = newValue;
    }
  }

  public get password(): string | null | undefined {
    if (this.data) return this.data.password;
    return undefined;
  }
  public set password(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.password = newValue;
    }
  }

  public get role(): UserRole | "new" | null | undefined {
    return this.data?.userType || undefined;
  }
  public set role(newValue: UserRole | "new" | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.userType = newValue;
    }
  }

  public get canLogin(): boolean | null | undefined {
    return this.data?.isEnabled ?? undefined;
  }
  public set canLogin(newValue: boolean | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.isEnabled = newValue;
    }
  }

  public get groupIds(): string[] | null | undefined {
    if (this.data) return this.data.groupIds;
    return undefined;
  }

  public get adminGroupIds(): string[] | null | undefined {
    if (this.data) return this.data.adminGroupIds;
    return undefined;
  }

  public get userGroups(): UserGroups | null | undefined {
    return this.mUserGroups;
  }
  public subscribeUserGroups = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mUserGroups === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mUserGroups = this.id
              ? new UserGroups(this.session, `${this.path}/userGroups`)
              : null;

            //if (this.mUserGroups) {
            //  let query = this.mUserGroups.collectionRef.orderBy("name");
            //  this.mUserGroups.query = query;
            //}

            this.mUserGroups?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeUserGroups = () => {
    this.mUserGroups?.unsubscribe();
    this.mUserGroups = undefined;
  };


  public get isDeleted(): boolean | undefined {
    return this.data?.isDeleted ?? undefined;
  }

  public get fullName(): string | null | undefined {
    if (this.data) {
      const tempValue = `${this.firstName || ""} ${this.lastName || ""}`.trim();
      return tempValue === "" ? null : tempValue;
    }
    return undefined;
  }

  public get userPresence(): UserPresence | null | undefined {
    return this.mUserPresence;
  }
  public get userPresenceStatus(): "offline" | "online" | null | undefined {
    return this.userPresence?.status;
  }
  public subscribeUserPresence = () => {
    when(
      () => this.id !== undefined,
      () => {
        const initUserPresence = () => {
          if (this.mUserPresence && this.mUserPresence.id !== this.id) {
            this.unsubscribeUserPresence();
          }

          if (this.mUserPresence === undefined && this.id !== undefined) {
            this.mUserPresence = this.id
              ? UserPresence.createCacheInstance(
                this.session,
                `userPresences/${this.id}`
              )
              : null;
            this.mUserPresence?.subscribe();

            this.mUserPresenceDisposer = reaction(
              () => this.id,
              (uid) => initUserPresence()
            );
          }
        };

        initUserPresence();
      }
    );
  };
  public unsubscribeUserPresence = () => {
    if (this.mUserPresenceDisposer) this.mUserPresenceDisposer();
    this.mUserPresence?.unsubscribe();
    this.mUserPresence = undefined;
  };

  public get workerProfileId(): string | null | undefined {
    if (this.data) return this.data.activeWorkerProfileId;
    return undefined;
  }

  public get workerProfile(): WorkerProfile | null | undefined {
    return this.mWorkerProfile;
  }
  public subscribeWorkerProfile = () => {
    when(
      () => this.workerProfileId !== undefined,
      () => {
        const initWorkerProfile = () => {
          if (this.mWorkerProfile && this.mWorkerProfile.id !== this.workerProfileId) {
            this.unsubscribeWorkerProfile();
          }

          if (this.mWorkerProfile === undefined && this.workerProfileId !== undefined) {
            this.mWorkerProfile = this.workerProfileId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.workerProfileId}`
              )
              : null;
            this.mWorkerProfile?.subscribe();

            this.mWorkerProfileDisposer = reaction(
              () => this.workerProfileId,
              (workerProfileId) => initWorkerProfile()
            );
          }
        };

        initWorkerProfile();
      }
    );
  };
  public unsubscribeWorkerProfile = () => {
    if (this.mWorkerProfileDisposer) this.mWorkerProfileDisposer();
    this.mWorkerProfile?.unsubscribe();
    this.mWorkerProfile = undefined;
  };


  public get files(): Files | null | undefined {
    return this.mFiles;
  }
  private set files(newValue: Files | null | undefined) {
    this.mFiles = newValue;
  }
  public subscribeFiles = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.files === undefined && this.id !== undefined) {
          this.files = this.id
            ? new Files(this.session, `${this.path}`)
            : null;
          this.files?.subscribe();
        }
      }
    );
  };
  public unsubscribeFiles = () => {
    this.files?.unsubscribe();
    this.files = undefined;
  };

  public get avatar() {
    const fetchAvatarImage = false;
    if (fetchAvatarImage) {
      if (!(this.files?.data)) {
        return undefined;
      }

      const avatarPath = `${this.path}/avatar.jpg`;
      const avatarUrl = this.files?.data?.find(file => file.path === avatarPath)?.url;
      if (avatarUrl) {
        return avatarUrl;
      }
    }

    if (this.data) {
      const text = this.fullName?.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('').substring(0, 2) ?? "";
      const foregroundColor = "white";
      const hue = hashCode(this.id || "") % 360;
      const backgroundColor = `hsl(${hue}, 90%, 70%)`;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      if (context != null) {
        // Draw background
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text
        context.font = "bold 100px 'Helvetica Neue'";
        context.fillStyle = foregroundColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL("image/svg");
      } else {
        return null;
      }
    }
    return undefined;
  }

  public delete = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          () => {
            if (this.id) {
              if (this.session.currentUser) {
                if (this.session.currentUserId === this.id) {
                  reject("You cannot delete yourself.");
                } else if (this.session.currentUser.role === "admin") {
                  this.session.firebase
                    .functions()
                    .httpsCallable("deleteUser")({ userId: this.id })
                    .then(() => resolve())
                    .catch(error => reject(error));
                } else {
                  reject("You do not have the required permissions to perform this action.")
                }
              } else {
                reject("You need to be logged in to perform this action.")
              }
            } else {
              reject("Unable delete the selected user. Try refreshing the user first.")
            }
          }
        );
      }
    });
  };

  public save = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.data) {
        when(
          () => this.session.currentUser?.loaded ?? false,
          () => {
            if (this.session.currentUser) {
              const request = {
                type: this.id ? "updateUser" : "createUser",
                payload: {}
              };

              if (this.session.currentUser.role === "admin") {
                request.payload = this.id
                  ? {
                    userProfileId: this.id,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    isEnabled: this.canLogin,
                    userType: this.role
                  } : {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    isEnabled: this.canLogin,
                    userType: this.role,
                  };
              } else if (this.session.currentUser.id === this.id) {
                request.payload = {
                  userProfileId: this.id,
                  firstName: this.firstName,
                  lastName: this.lastName,
                  email: this.email,
                  password: this.password
                }
              }

              if (this.session.currentUser.role === "admin" || this.session.currentUser.id === this.id) {
                this.session.firebase
                  .functions()
                  .httpsCallable(request.type)(request.payload)
                  .then((res) => {
                    if (request.type === "createUser") {
                      this.path = `userProfiles/${res.data.uid}`;
                      User.addCacheInstance(this);
                    }
                    resolve();
                  })
                  .catch(error => {
                    reject(error);
                  });
              } else {
                reject(new Error("You do not have the neccessary permissions to update this user."));
              }
            } else {
              reject(new Error("Unable to determine currently logged in user."));
            }
          }
        );
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  };

  public addGroup = async (groupId: string, role: UserGroupRole) => {
    const executeCommandArgs = {
      commandId: "user.addGroup",
      commandArgs: {
        path: this.path,
        groupId: groupId,
        data: {
          role: role
        }
      }
    }

    const executeCommand = this.session.firebase
      .functions()
      .httpsCallable("engage-executeCommand");

    await executeCommand(executeCommandArgs);

    return;
  }

  public updateGroup = async (groupId: string, role: UserGroupRole) => {
    const executeCommandArgs = {
      commandId: "user.updateGroup",
      commandArgs: {
        path: this.path,
        groupId: groupId,
        data: {
          role: role
        }
      }
    }

    const executeCommand = this.session.firebase
      .functions()
      .httpsCallable("engage-executeCommand");

    await executeCommand(executeCommandArgs);

    return;
  }

  public removeGroup = async (groupId: string) => {
    const executeCommandArgs = {
      commandId: "user.removeGroup",
      commandArgs: {
        path: this.path,
        groupId: groupId
      }
    }

    const executeCommand = this.session.firebase
      .functions()
      .httpsCallable("engage-executeCommand");

    await executeCommand(executeCommandArgs);

    return;
  }

  public uploadAvatar = async (dataUrl: string) => {
    if (this.path) {
      const storageRef = this.session.firebase
        .storage()
        .ref()
        .child(`${this.path}/avatar.jpg`);

      await storageRef.putString(dataUrl, 'data_url');
      this.files?.unsubscribe();
      this.files?.subscribe();
    }
  }
}
