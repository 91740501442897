import firebase from "firebase/app";
import { Session } from "../../session";

import { Group } from "./Group";

export type GroupRole = "admin" | "user";

export interface GroupData {
    name: string | null;
    tag: string | null;
    phoneNumberId: string | null;
    messages?: {
        greeting: string | null,
        noAgent: string | null,
        slowConversationAccept: string | null
    } | null;
    tasks?: {
        conversationAcceptPeriodSeconds: number | null,
        messageResponsePeriodSeconds: number | null
    } | null;
};

export const groupDefaultValue = {
    name: null,
    tag: null,
    phoneNumberId: null,
    messages: {
        greeting: "",
        noAgent: "",
        slowConversationAccept: ""
    },
    tasks: {
        conversationAcceptPeriodSeconds: 120,
        messageResponsePeriodSeconds: 150,
    },
} as GroupData;

export const groupDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: GroupData) {
            return {
                name: data.name,
                tag: data.tag,
                phoneNumberId: data.phoneNumberId,
                messages: data.messages,
                tasks: data.tasks
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<GroupData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                name: data.name ?? null,
                tag: data.tag ?? null,
                phoneNumberId: data.phoneNumberId ?? null,
                messages: data.messages ?? null,
                tasks: data.tasks ?? null,
            } as GroupData;
        },
    };
};

export const groupConverter = (session: Session) => {
    return {
        toFirestore: function (group: Group) {
            return group.data ? {
                name: group.data.name,
                tag: group.data.tag,
                phoneNumberId: group.data.phoneNumberId,
                messages: group.data.messages,
                tasks: group.data.tasks
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<GroupData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return Group.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    name: data.name ?? null,
                    tag: data.tag ?? null,
                    phoneNumberId: data.phoneNumberId ?? null,
                    messages: data.messages ?? null,
                    tasks: data.tasks ?? null,
                }
            );
        },
    };
};
