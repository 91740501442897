import firebase from "firebase/app";


import { makeAutoObservable } from "mobx";
import { Session } from "../../session";

import { Conversation } from "./Conversation";
import { conversationConverter } from "./ConversationData";

export class Conversations {
  private mSession: Session;
  mCollectionRef: firebase.firestore.CollectionReference<Conversation>;
  mQuery: firebase.firestore.Query<Conversation> | null;
  mPath: string;
  mData: Conversation[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(session: Session, path: string) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;

    this.mCollectionRef = this.session.firebase
      .firestore()
      .collection(path)
      .withConverter(conversationConverter(session));

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  public get session() {
    return this.mSession;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  public get query() {
    return this.mQuery;
  }
  public set query(newValue: firebase.firestore.Query<Conversation> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  get path() {
    return this.mPath;
  }

  public get data() {
    //if (this.mData) return this.mData.filter((item) => item.data?.isDeleted !== true ?? true);
    return this.mData;
  }
  protected set data(newValue: Conversation[] | null | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get()
        .then((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.data = data;
        });
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }
}
