import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { when } from "mobx";

import {
  Camera,
  CameraDirection,
  CameraResultType,
  Photo,
} from "@capacitor/camera";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonFooter,
  IonTextarea,
  IonBadge,
  IonChip,
  IonText,
  IonSlides,
  IonSlide,
  CreateAnimation,
  IonThumbnail,
  IonImg,
  IonLabel,
} from "@ionic/react";

import {
  close,
  readerOutline,
  addCircleOutline,
  addOutline,
  camera,
  removeCircle,
  arrowUpOutline,
  closeCircleOutline,
  closeOutline,
  attach,
  documentOutline,
} from "ionicons/icons";

import AnimatedImg from "../../../components/AnimatedImg";
import NotesListItem from "./NotesListItem";

import * as Models from "../../../models";

import { fileSizeString, relativeDateString } from "../../../utils/Helpers";
import { SessionContext } from "../../../session";
import { FileAttachment } from "../../../models";

interface NotesButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const NotesButton: React.FC<NotesButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);

    const [messageInput, setMessageInput] = useState("");
    const [busySendingMessage, setBusySendingMessage] = useState(false);
    const [hideMessageActions, setHideMessageActions] = useState(true);
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [files, setFiles] = useState<FileAttachment[]>([]);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const [lastReadAt, setLastReadAt] = useState<Date | null | undefined>();

    const gridRef = useRef<HTMLIonGridElement | null>(null);

    useEffect(() => {
      if (showModal) {
        return when(
          () => conversation?.workItem?.loaded ?? false,
          () => {
            setLastReadAt(conversation?.workItem?.lastNoteReadAt);
          }
        );
      }
    }, [showModal]);

    const scrollToBottom = () => {
      if (gridRef?.current) {
        gridRef.current.scrollTop = gridRef.current.scrollHeight;
      }
    };

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const removePhotoHandler = (photo: Photo) => {
      setPhotos((prev) => prev.filter((doc) => doc !== photo));
    };

    const removeFileHandler = (file: FileAttachment) => {
      setFiles((prev) => prev.filter((doc) => doc !== file));
    };

    const isOwner =
      conversation?.ownerId === session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status === "active";
    const workItemState = isActive
      ? conversation?.requestTransfer &&
        conversation?.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !== session.currentUser?.workerProfileId &&
          isOwner
          ? "outgoing"
          : "assigned"
      : "completed";

    const newNotes =
      lastReadAt !== undefined &&
        lastReadAt != null &&
        conversation &&
        conversation.notes &&
        conversation.notes.data
        ? conversation.notes.data.filter((note) => {
          return (
            note.createdOn &&
            note.createdOn > lastReadAt &&
            note.workerProfileId !== session.currentUser?.workerProfileId
          );
        })
        : null;

    const lastReadMessage =
      newNotes && newNotes.length >= 1 ? newNotes[0] : null;

    const notes = conversation?.notes?.data?.reduce(
      (groups: Record<string, Models.Note[]>, item) => {
        if (item.createdOn) {
          const dateString = moment(item.createdOn).format("YYYY-MM-DD");

          if (!groups[dateString]) {
            groups[dateString] = [];
          }

          groups[dateString].push(item);
        }

        return groups;
      },
      {}
    );

    return (
      <>
        <IonButton
          slot="end"
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => {
            setPhotos([]);
            setMessageInput("");
            set_showModal(true);
          }}
          disabled={conversation === undefined || conversation === null}
        >
          <IonIcon icon={readerOutline} />
        </IonButton>

        {conversation?.workItem?.hasUnreadNotes && (
          <CreateAnimation
            duration={1000}
            play={true}
            iterations={5}
            keyframes={[
              { offset: 0, transform: "scale(1)", opacity: "1" },
              { offset: 0.5, transform: "scale(0.8)", opacity: "1" },
              { offset: 1, transform: "scale(1)", opacity: "1" },
            ]}
          >
            <IonBadge
              color="primary"
              mode="ios"
              style={{
                opacity: 0,
                position: "absolute",
                top: "-7px",
                right: "-3px",
                fontSize: "0.8em",
                padding: "5px",
                borderRadius: "51%",
                zIndex: "1",
                width: "18px",
                height: "18px",
                border: "2px solid var(--ion-color-secondary)",
              }}
            >
              {" "}
            </IonBadge>
          </CreateAnimation>
        )}
        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar
              color={workItemState === "completed" ? "tertiary" : "tertiary"}
            >
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Conversation Notes</IonTitle>

              <IonButtons slot="end"></IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent
            color={notes && Object.keys(notes).length > 0 ? "light" : ""}
          >
            {notes && Object.keys(notes).length > 0 ? (
              <IonGrid
                ref={gridRef}
                style={{
                  height: "100%",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                onScroll={(e) => {
                  if (
                    e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                    e.currentTarget.scrollHeight
                  ) {
                    conversation?.workItem?.markNotesAsRead();
                  }
                }}
              >
                {notes &&
                  Object.keys(notes)
                    .reverse()
                    .map((key) => (
                      <div key={key}>
                        <div
                          className="ion-padding"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: "0px",
                            zIndex: 999999,
                          }}
                        >
                          <IonChip>
                            {relativeDateString(moment(key).toDate(), false)}
                          </IonChip>
                        </div>
                        {notes[key].map((item) => (
                          <>
                            {lastReadMessage && lastReadMessage.id === item.id && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    height: "1px",
                                    backgroundColor: "red",
                                    margin: `1px 0`,
                                  }}
                                />
                                <IonText
                                  style={{
                                    color: "red",
                                    margin: `0 0.5px`,
                                    fontSize: "12px",
                                  }}
                                >
                                  New
                                </IonText>
                              </div>
                            )}
                            <NotesListItem
                              key={item.id}
                              note={item}
                              onInView={(note) => {
                                when(
                                  () =>
                                    (note.createdOn &&
                                      conversation &&
                                      conversation.workItem &&
                                      true) ??
                                    false,
                                  () => {
                                    if (
                                      note.createdOn &&
                                      conversation &&
                                      conversation.workItem
                                    ) {
                                      if (
                                        conversation.workItem.lastNoteReadAt ==
                                        null ||
                                        note.createdOn >=
                                        conversation.workItem.lastNoteReadAt
                                      ) {
                                        conversation.workItem.lastNoteReadAt =
                                          note.createdOn;
                                      }
                                    }

                                    if (
                                      conversation &&
                                      conversation.notes &&
                                      conversation.notes.data &&
                                      conversation.notes.data.length > 0
                                    ) {
                                      const length =
                                        conversation.notes.data.length;
                                      const lastNote =
                                        conversation.notes.data[length - 1];
                                      if (note.id === lastNote.id) {
                                        conversation?.workItem?.markNotesAsRead();
                                      }
                                    }
                                  }
                                );
                              }}
                            />
                          </>
                        ))}
                      </div>
                    ))}
              </IonGrid>
            ) : (
              <IonItem style={{}} lines="none">
                <IonLabel className="ion-text-center">
                  <IonImg
                    style={{
                      maxWidth: "250px",
                      margin: "auto",
                      marginTop: "69px",
                    }}
                    src={"/assets/images/artboard007.png"}
                  />
                  <p>
                    <strong>
                      <IonText
                        color="dark"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Notes
                      </IonText>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <IonText
                      color="medium"
                      style={{
                        fontSize: "16px",
                      }}
                    ></IonText>
                  </p>
                </IonLabel>
              </IonItem>
            )}
          </IonContent>

          {conversation?.status === "active" && conversation?.isParticipant && (
            <IonFooter>
              <IonToolbar
                color="light"
                style={{
                  paddingBottom:
                    session.keyboardInfo.visible ||
                      (photos && photos.length > 0)
                      ? "0px"
                      : "var(--ion-safe-area-bottom, 0)",
                }}
              >
                <IonButtons slot="start">
                  <IonButton
                    style={{
                      height: "36px",
                    }}
                    color="dark"
                    shape="round"
                    fill="clear"
                    onClick={() => {
                      Camera.getPhoto({
                        quality: 90,
                        //allowEditing: true,
                        resultType: CameraResultType.DataUrl,
                        direction: CameraDirection.Rear,
                      })
                        .then((photo) => {
                          setPhotos((prev) => [...prev, photo]);
                        })
                        .catch((error) => { });
                    }}
                    disabled={
                      conversation === undefined || conversation === null
                    }
                  //hidden={!((messageInput ?? "").trim().length === 0)}
                  >
                    <IonIcon icon={camera} />
                  </IonButton>
                  <input
                    ref={hiddenFileInput}
                    accept="application/pdf,image/*"
                    onChange={
                      (e) => {
                        const fileUploaded = e?.target?.files?.[0];

                        let fileReader: FileReader, isCancel = false;
                        if (fileUploaded) {
                          fileReader = new FileReader();
                          fileReader.onload = (e) => {
                            if (e.target?.result) {
                              const { result } = e.target;
                              if (result && typeof (result) === "string" && !isCancel) {
                                setFiles((prev) => [...prev, { file: fileUploaded, dataUrl: result }]);
                              }
                            }
                          }
                          fileReader.readAsDataURL(fileUploaded);
                        }
                      }
                    }
                    type="file"
                    style={{ display: 'none' }}
                  />
                  <IonButton
                    style={{
                      height: "36px",
                    }}
                    color="dark"
                    shape="round"
                    fill="clear"
                    onClick={() => {
                      if (hiddenFileInput?.current) {
                        hiddenFileInput.current.value = "";
                        hiddenFileInput.current.click();
                      }
                    }}
                    disabled={
                      conversation === undefined || conversation === null
                    }
                  //hidden={!((messageInput ?? "").trim().length === 0)}
                  >
                    <IonIcon icon={attach} />
                  </IonButton>
                </IonButtons>
                <IonTextarea
                  spellcheck={true}
                  autocapitalize="on"
                  color="dark"
                  className="ion-no-margin ion-no-padding"
                  autoGrow
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    //border: "1px solid var(--ion-color-medium)",
                    margin: "8px 0px 8px 3px",
                    padding: "5px 10px 5px 5px",
                    minHeight: "36px",
                  }}
                  disabled={busySendingMessage}
                  value={messageInput}
                  //placeholder="Type message"
                  onIonChange={(e) => {
                    setMessageInput(e.detail.value!);
                  }}
                >
                  <IonButton
                    style={{
                      height: "36px",
                      position: "absolute",
                      bottom: "11px",
                      right: "0px",
                    }}
                    color="dark"
                    shape="round"
                    fill="clear"
                    onClick={() => {
                      Camera.getPhoto({
                        quality: 90,
                        //allowEditing: true,
                        resultType: CameraResultType.DataUrl,
                        direction: CameraDirection.Rear,
                      })
                        .then((photo) => {
                          setPhotos((prev) => [...prev, photo]);
                        })
                        .catch((error) => { });
                    }}
                    disabled={
                      conversation === undefined || conversation === null
                    }
                    hidden
                  //hidden={!((messageInput ?? "").trim().length === 0)}
                  >
                    <IonIcon icon={camera} />
                  </IonButton>
                </IonTextarea>

                <IonButtons
                  slot="end"
                  style={{ marginLeft: "13px", marginRight: "10px" }}
                >
                  <IonButton
                    style={{ height: "36px" }}
                    color="dark"
                    shape="round"
                    fill="solid"
                    disabled={
                      busySendingMessage ||
                      ((messageInput ?? "").trim() === "" &&
                        photos.length === 0)
                    }
                    //hidden={(messageInput ?? "").trim().length === 0}
                    onClick={() => {
                      setBusySendingMessage(true);

                      conversation
                        ?.addNote(messageInput.replace("\n", "\r\n"), { photos, files })
                        .then(() => {
                          setPhotos([]);
                          setFiles([]);
                          setMessageInput("");
                          setBusySendingMessage(false);
                        });
                    }}
                  >
                    <IonIcon icon={arrowUpOutline}></IonIcon>
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              {photos && photos.length > 0 && (
                <IonToolbar
                  color="dark"
                  style={{
                    paddingBottom: session.keyboardInfo.visible
                      ? "0px"
                      : "var(--ion-safe-area-bottom, 0)",
                  }}
                >
                  {photos.map((photo) => (
                    <IonItem
                      lines="none"
                      color="dark"
                      style={{
                        // border: "1px solid #ccc",
                        // borderRadius: "3px",
                        display: "inline-block",
                        //margin: "5px",
                      }}
                    >
                      <IonThumbnail>
                        <IonImg
                          style={{ borderRadius: "5px" }}
                          src={photo.dataUrl ?? undefined}
                        />
                      </IonThumbnail>
                      {/*<AnimatedImg
                          src={photo.dataUrl ?? undefined}
                          style={{
                            padding: "0px",
                            width: "60px",
                            height: "60px",
                            borderRadius: "5px",
                            objectFit: "cover",
                          }}
                        />*/}
                      <IonButton
                        onClick={() => removePhotoHandler(photo)}
                        fill="solid"
                        shape="round"
                        size="small"
                        color="light"
                        style={{
                          position: "absolute",
                          zIndex: "2",
                          top: "-3px",
                          right: "5px",
                          borderRadius: "64px",
                          border: "2px solid var(--ion-color-dark)",
                        }}
                      >
                        <IonIcon src={closeOutline} />
                      </IonButton>
                    </IonItem>
                  ))}

                </IonToolbar>
              )}
              {files && files.length > 0 && (
                <IonToolbar
                  color="dark"
                  style={{
                    paddingBottom: session.keyboardInfo.visible
                      ? "0px"
                      : "var(--ion-safe-area-bottom, 0)",
                  }}
                >
                  {files.map((file) => (
                    <IonItem
                      lines="none"
                      color="dark"
                      style={{
                        // border: "1px solid #ccc",
                        // borderRadius: "3px",
                        display: "inline-block",
                        //margin: "5px",
                      }}
                    >
                      <IonIcon
                        //slot="start"
                        icon={documentOutline}
                        className="ion-padding-end"
                      />
                      <IonLabel
                        style={{
                          padding: "0px",
                          width: "100px",
                          height: "60px",
                          borderRadius: "5px",
                          objectFit: "cover",
                        }}
                      >
                        <b>{file.file.name}</b>
                        <p>
                          {fileSizeString(file.file.size)}
                        </p>
                      </IonLabel>
                      <IonButton
                        onClick={() => removeFileHandler(file)}
                        fill="solid"
                        shape="round"
                        size="small"
                        color="light"
                        style={{
                          position: "absolute",
                          zIndex: "2",
                          top: "-3px",
                          right: "5px",
                          borderRadius: "64px",
                          border: "2px solid var(--ion-color-dark)",
                        }}
                      >
                        <IonIcon src={closeOutline} />
                      </IonButton>
                    </IonItem>
                  ))}
                </IonToolbar>
              )}
            </IonFooter>
          )}
        </IonModal>
      </>
    );
  }
);

export default NotesButton;
