import React from "react";
import { observer } from "mobx-react-lite";

import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonListHeader,
} from "@ionic/react";

const InfocenterPage: React.FC = observer(() => {
  return (
    <IonPage>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonTitle>Infocenter</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          <IonListHeader></IonListHeader>
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default InfocenterPage;
