import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItemDivider,
  IonList,
  IonInput,
  IonTextarea,
  IonFooter,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonListHeader,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { close, chatboxEllipses, readerOutline, add, send, personCircle } from "ionicons/icons";

import * as Models from "../../../models";
import { Session, SessionContext } from "../../../session";
import { Controller, useForm } from "react-hook-form";
import { phone, PhoneResult } from "phone";
import { useHistory } from "react-router";
import { Group } from "../../../models";

interface ConversationCustomerData {
  id: string | null,
  externalReference: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  mobileNumber: string | null;
  facebookId: string | null;
}

interface NewConversationButtonProps {
  session: Session;
  displayAsItem?: boolean;
  parent?: HTMLElement | null;
  defaults?: {
    phoneNumber?: string;
  }
  onDismiss?: () => void;
}

const NewConversationButton: React.FC<NewConversationButtonProps> = observer(
  ({ session, parent, defaults, onDismiss, displayAsItem }) => {
    const history = useHistory();

    const [showModal, set_showModal] = useState(false);
    const [customer, setCustomer] = useState<ConversationCustomerData | null | undefined>();
    const [conversation, setConversation] = useState<{ id: string, owner?: { id: string | null, name: string | null } | null, participants: string[] } | null | undefined>();
    const [searching, setSearching] = useState(false);
    const [messageInput, setMessageInput] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneResult, setPhoneResult] = useState<PhoneResult>();
    const [group, setGroup] = useState<Group>();
    const [busySendingMessage, setBusySendingMessage] = useState(false);

    //const session = useContext(SessionContext);
    //if (!session) {
    //  return <></>;
    //}

    const groups = session.groups?.data?.filter((group) => session.currentUser?.groupIds?.includes(group.id!));

    const senderPhoneResult = phone(session.conversationsSettings?.group?.phoneNumber?.phone ?? "");

    useEffect(() => {
      if (showModal && defaults?.phoneNumber) {
        setPhoneNumber(defaults?.phoneNumber)
      } else {
        setPhoneNumber("");
      }
    }, [showModal, defaults?.phoneNumber])

    useEffect(() => {
      const result = typeof (phoneNumber) === "string" && phoneNumber.trim().startsWith("+")
        ? phone(phoneNumber, { validateMobilePrefix: false })
        : phone(phoneNumber, { country: senderPhoneResult.countryIso3 ?? undefined, validateMobilePrefix: false });

      if (result.isValid) {
        setSearching(true);
        setPhoneResult(result);
      } else {
        setCustomer(undefined);
        setConversation(undefined);
        setPhoneResult(undefined);
      }
    }, [phoneNumber]);

    useEffect(() => {
      if (phoneResult?.isValid) {
        const args = {
          data:
          {
            groupId: group?.id,
            to: phoneResult.phoneNumber,
          }
        };

        session.executeCommand({
          commandId: "conversation.search",
          commandArgs: args
        })
          .then((searchResult: any) => {
            console.log("debug", searchResult)
            setCustomer(searchResult.data.customer);
            setConversation(searchResult.data.conversation);
            setSearching(false);
          });
      } else {
        setCustomer(undefined);
        setConversation(undefined);
      }


    }, [phoneResult]);

    const sendMessage = async () => {
      if (phoneResult?.isValid) {
        try {
          const args = {
            data:
            {
              groupId: group?.id,
              to: phoneResult.phoneNumber,
              message: messageInput
            }
          };

          const createResult = await session.executeCommand({
            commandId: "conversation.create",
            commandArgs: args
          }) as {
            data: {
              conversation: {
                id: string;
              }
            }
          };
          history.push(`/conversations/${createResult.data.conversation.id}`);

          setCustomer(undefined);
          setConversation(undefined);
          setMessageInput("");
          setPhoneNumber("");
          set_showModal(false);
        } catch (error) {
          console.error("Error creating new conversation.", error);
        }
      }
    };

    if ((session.currentUser?.groupIds?.length || 0) === 0) {
      return <></>;
    }

    return (
      <>
        {displayAsItem
          ? (
            <IonItem button onClick={() => set_showModal(true)}>
              <IonIcon
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                icon={chatboxEllipses}
                slot="start"
                color="primary"
              />
              <IonLabel color="primary">Start new conversation</IonLabel>
            </IonItem>
          )
          : (
            <IonButton
              //style={{ position: "absolute", bottom: "19px", right: "4px", borderRadius: "5px" }}
              fill="clear"
              color="light"
              shape="round"
              size="small"
              onClick={() => set_showModal(true)}
            >
              <IonIcon icon={add} />
            </IonButton>
          )}

        <IonModal
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => {
            setBusySendingMessage(false);
            setPhoneNumber("");
            setPhoneResult(undefined);
            setMessageInput("")
            setCustomer(undefined);
            setConversation(undefined);
            set_showModal(false);
            onDismiss?.();
          }}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>New Conversation</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent color="light">
            {groups && groups.length > 0 && (
              <IonItem className="default-element-border ion-padding-bottom" lines="none">
                <IonLabel position="stacked" color="medium">Group</IonLabel>

                <IonSelect
                  className="input-field"
                  value={group}
                  onIonChange={(e) => setGroup(e.detail.value!)}
                  interfaceOptions={{
                    cssClass: "select-alert"
                  }}
                >
                  {groups && groups.length > 0 && groups.map(group => {
                    return (
                      <IonSelectOption
                        key={group.id}
                        value={group}
                      >
                        {group.name}
                      </IonSelectOption>
                    )
                  })}
                </IonSelect>
              </IonItem>
            )}
            <IonItem lines="none" className="ion-padding-bottom" style={{ paddingTop: 18 }} hidden={!group}>
              <IonLabel position="stacked" color="medium">
                Phone Number
              </IonLabel>
              <IonInput
                className="input-field"
                onIonChange={(e) => setPhoneNumber(e.detail.value!)}
                value={phoneNumber}
              />
            </IonItem>
            <IonProgressBar hidden={!searching} type="indeterminate" />

            {conversation && (
              <>
                <div style={{ padding: "15px" }}>
                  <IonLabel className="ion-text-center">
                    There is already an open conversation for this contact. {conversation.owner ? `The owner of the conversation is ${conversation.owner?.name}.` : ""}
                  </IonLabel>
                </div>
                <div style={{ paddingLeft: "15px" }}>
                  <IonButton
                    hidden={!(
                      conversation.owner === null
                      || conversation.participants.findIndex((value) => (session.currentUser?.workerProfileId ?? "loading") === value) !== -1
                      || session.currentUser?.role === "admin"
                    )}
                    onClick={() => {
                      setPhoneNumber("");
                      setPhoneResult(undefined);
                      setMessageInput("")
                      setCustomer(undefined);
                      setConversation(undefined);

                      set_showModal(false);
                      history.push(`/conversations/${conversation.id}`);
                    }}
                  >
                    Open Conversation
                  </IonButton>
                </div>
              </>
            )}

            {customer && (
              <IonList
                lines="none"
                //style={{ marginTop: "20px" }}
                className="ion-no-padding"
              >
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "10px" }}
                >Found Contact: </IonListHeader>
                <IonItem hidden color="light">
                  <IonGrid >
                    <IonRow style={{ paddingTop: "5px" }}>
                      <IonCol>
                        <IonAvatar
                          style={{
                            height: "100px",
                            width: "100px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <IonIcon
                            color="medium"
                            style={{
                              //border: "1px solid #FFFFFF",
                              height: "100px",
                              width: "100px",
                              minHeight: "100px",
                              minWidth: "100px",
                            }}
                            //className="ion-margin-end"
                            icon={personCircle}
                          />
                        </IonAvatar>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{ paddingTop: "0px", paddingBottom: "20px" }}
                    >
                      <IonCol>
                        <IonLabel className="ion-text-center">
                          <h2>
                            <strong>
                              {`${customer.firstName ?? ""} ${customer.lastName ?? ""}`.trim()}
                            </strong>
                          </h2>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
                <IonListHeader color="light" style={{ paddingTop: "0px" }}>
                  <strong></strong>
                </IonListHeader>
                <IonItem
                  lines="none"
                  key="customerReference"
                  style={{ paddingTop: "0px" }}
                  className="ion-padding-bottom"
                >
                  <IonLabel position="stacked" color="medium">
                    Contact Reference
                  </IonLabel>

                  <IonInput
                    readonly
                    value={customer?.externalReference}
                  >
                  </IonInput>
                </IonItem>
                <IonListHeader color="light" style={{ paddingTop: "0px" }}>
                  <strong></strong>
                </IonListHeader>
                <IonItem
                  lines="none"
                  style={{ marginTop: "0px" }}
                  key="firstName"
                >
                  <IonLabel position="stacked" color="medium">
                    First Name
                  </IonLabel>
                  <IonInput
                    readonly
                    value={customer.firstName}
                  />
                </IonItem>
                <IonItem lines="none" key="lastName">
                  <IonLabel position="stacked" color="medium">
                    Last Name
                  </IonLabel>
                  <IonInput
                    readonly
                    value={customer?.lastName}

                  />
                </IonItem>
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "0px" }}
                ></IonListHeader>
                <IonItem style={{ marginTop: "0px" }} key="email" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Email
                  </IonLabel>
                  <IonInput
                    type="email"
                    readonly
                    value={customer?.email}
                  //placeholder="Last Name"
                  />
                </IonItem>
                <IonItem key="mobile" hidden>
                  <IonLabel position="stacked" color="medium">
                    Mobile
                  </IonLabel>
                  <IonInput
                    disabled
                    value={customer?.mobileNumber}
                  ></IonInput>
                </IonItem>
                <IonItem key="facebook" hidden>
                  <IonLabel position="stacked" color="medium">
                    Facebook
                  </IonLabel>
                  <IonInput
                    disabled
                    value={customer?.facebookId}
                  ></IonInput>
                </IonItem>
                <IonListHeader
                  color="light"
                  style={{ paddingTop: "0px" }}
                ></IonListHeader>
              </IonList>
            )}
          </IonContent>
          <IonFooter
            className={conversation !== null || conversation === undefined ? "ion-no-border" : ""}
            translucent
          >
            <IonToolbar
              color="light"
              style={{
                paddingBottom: session.keyboardInfo.visible
                  ? "0px"
                  : "var(--ion-safe-area-bottom, 0)",
              }}
            >
              <IonTextarea
                spellcheck={true}
                autocapitalize="on"
                color="dark"
                className="ion-no-margin ion-no-padding"
                autoGrow
                style={{
                  backgroundColor: conversation !== null || conversation === undefined ? "var(ion-color-light)" : "white",
                  borderRadius: "10px",
                  //border: "1px solid var(--ion-color-medium)",
                  margin: "8px 0px 8px 8px",
                  padding: "5px 10px 5px 5px",
                  minHeight: "36px",
                }}
                disabled={busySendingMessage || conversation !== null || conversation === undefined}
                value={messageInput}
                //placeholder="Type message"
                onIonChange={(e) => {
                  setMessageInput(e.detail.value ?? "");
                }}
              >
                <IonButton hidden>
                  <IonIcon icon={send} />
                </IonButton>
              </IonTextarea>
              <IonButtons
                slot="end"
                style={{ marginLeft: "13px", marginRight: "10px" }}
              >
                <IonButton
                  hidden={conversation !== null || conversation === undefined}
                  style={{ height: "36px" }}
                  color="dark"
                  shape="round"
                  fill="solid"
                  disabled={busySendingMessage || !(conversation === null) || messageInput.trim() === ""}
                  onClick={async () => {
                    setBusySendingMessage(true);

                    await sendMessage();

                    setBusySendingMessage(false);
                  }}
                >
                  <IonIcon icon={send}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </>
    );
  }
);

export default NewConversationButton;
