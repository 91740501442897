import React, { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  IonAvatar,
  IonButton,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonText,
} from "@ionic/react";

import {
  checkmarkCircleOutline,
  chatbubblesOutline,
  chatboxOutline,
  checkmarkOutline,
  closeOutline,
  documentAttachOutline,
  radioButtonOffOutline,
  readerOutline,
  swapHorizontalOutline,
  ellipsisHorizontalCircleOutline,
  radioButtonOn,
  people,
  arrowForward,
  trendingDown,
} from "ionicons/icons";

import * as Models from "../../../models";

import { relativeDateString } from "../../../utils/Helpers";
import { SessionContext } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";
import { Button } from "../../../components/Button";

interface WorkItemsListItemProps {
  workItem: Models.WorkItem;
  inWaitingSection?: boolean;
}

const WorkItemsListItem: React.FC<WorkItemsListItemProps> = observer(
  ({ workItem, inWaitingSection }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    if (
      workItem.conversation &&
      workItem.conversation.loaded &&
      workItem.conversation.customer &&
      workItem.conversation.customer.loaded /*&&
      workItem.conversation.lastMessage &&
      workItem.conversation.lastMessage.loaded &&
      ((workItem.conversation.lastMessage.type === "agent" &&
        workItem.conversation.lastMessage.workerProfile &&
        workItem.conversation.lastMessage.workerProfile.loaded) ||
        workItem.conversation.lastMessage.type !== "agent")*/
    ) {
      const customerName = workItem.conversation.customer.displayName;
      const lastMessageSender =
        workItem.conversation.lastMessage?.type === "agent"
          ? workItem.conversation.lastMessage.workerProfile?.id === session.currentUser?.workerProfile?.id
            ? "You: "
            : `${workItem.conversation.lastMessage.workerProfile?.name}: `
          : workItem.conversation.lastMessage?.type === "system"
            ? "System: "
            : "";
      const lastMessage = workItem.conversation.lastMessage?.body;
      const lastMessageHasFile = (workItem.conversation.lastMessage?.files?.data?.length ?? 0) > 0;
      const isOwner = workItem.conversation.ownerId === session.currentUser?.workerProfile?.id;
      const isParticipant = workItem.conversation.isParticipant;
      const isActive = workItem.conversation.status === "active";
      const workItemState = isActive
        ? workItem.conversation.requestTransfer && !workItem.conversation.requestTransfer.isDeclined
          && (
            workItem.conversation.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId
            || (workItem.conversation.requestTransfer.targetGroupId && session.currentUser?.groupIds?.includes(workItem.conversation.requestTransfer.targetGroupId) && !isOwner)
          )
          ? "incoming"
          : workItem.conversation.requestTransfer
            && workItem.conversation.requestTransfer.targetOwnerId !== session.currentUser?.workerProfileId
            && isOwner
            ? "outgoing"
            : "assigned"
        : "completed";
      const messageDateString = workItem.conversation.lastMessage
        ? relativeDateString(workItem.conversation.lastMessage.createdOn)
        : "";
      const transferDateString = relativeDateString(
        workItem.conversation.requestTransfer?.requestedOn?.toDate()
      );
      return (
        <IonItem
          color={
            location.pathname === `${match.path}/${workItem.id}` ? "light" : ""
          }
          routerLink={`${match.path}/${workItem.id}`}
          detail={false}
        >
          <IonIcon
            icon={
              workItemState === "incoming"
                ? "/icons/swap-horizontal-circle-outline.svg"
                : workItemState === "outgoing"
                  ? "/icons/swap-horizontal-circle-outline.svg"
                  : workItemState === "assigned"
                    ? workItem.conversation.requestTransfer
                      ? "/icons/swap-horizontal-circle-outline.svg"
                      : radioButtonOn
                    : checkmarkCircleOutline
            }
            color={isOwner ? "primary" : isParticipant ? "secondary" : "medium"}
            style={{
              fontSize: "60px",
            }}
            className="ion-margin-end"
          ></IonIcon>

          <IonLabel>
            <h2>
              <b>{customerName}</b>
              <span className="ion-text-end ion-float-right">
                <IonText style={{ fontSize: "13px" }} color="medium">
                  {workItemState === "incoming"
                    ? transferDateString
                    : messageDateString}
                </IonText>
              </span>
            </h2>
            <p>
              <b>
                {workItemState === "incoming" && (workItem.conversation.requestTransfer?.description?.trim() ?? "") !== ""
                  ? `${workItem.conversation?.owner?.name ?? ""}: `
                  : lastMessageSender}
              </b>
              {workItemState === "incoming" && (workItem.conversation.requestTransfer?.description?.trim() ?? "") !== "" ? (
                workItem.conversation.requestTransfer?.description ?? ""
              ) : (
                <>
                  {lastMessageHasFile ? (
                    <b>
                      <IonIcon icon={documentAttachOutline} />{" "}
                    </b>
                  ) : (
                    <></>
                  )}
                  {lastMessage}
                </>
              )}
            </p>

            <p>
              {workItemState !== "incoming"
                ? (
                  <>
                    <span
                      className="ion-text-end ion-float-right"
                      style={{ marginLeft: "5px" }}
                    >
                      <IonAvatar
                        style={{ height: "28px", width: "28px", marginTop: "13px" }}
                      >
                        {workItem.conversation?.owner?.avatar
                          ? <AnimatedImg alt="" src={workItem.conversation?.owner?.avatar ?? undefined} />
                          : undefined
                        }
                      </IonAvatar>
                    </span>
                    <span className="ion-text-end ion-float-right">
                      <IonAvatar
                        hidden={!workItem.conversation?.group?.avatar}
                        className="border-radius-0"
                        style={{ height: "28px", width: "28px", marginTop: "13px" }}
                      >
                        {workItem.conversation?.group?.avatar
                          ? <AnimatedImg alt="" src={workItem.conversation?.group?.avatar ?? undefined} />
                          : undefined
                        }
                      </IonAvatar>
                    </span>
                  </>
                ) : undefined
              }
              {!inWaitingSection && (workItem.conversation.workItem?.hasUnreadMessages || workItem.conversation.workItem?.hasUnreadInternalMessages || workItem.conversation.workItem?.hasUnreadNotes)
                ? (

                  <span className="ion-text-end ion-float-right">
                    <IonChip color="light" style={{ marginTop: "11px" }}>
                      <IonIcon
                        icon={chatboxOutline}
                        color="primary"
                        hidden={
                          !(
                            workItem.conversation.workItem?.hasUnreadMessages ||
                            false
                          )
                        }
                      />
                      <IonIcon
                        icon={chatbubblesOutline}
                        color="primary"
                        hidden={
                          !(
                            workItem.conversation.workItem
                              ?.hasUnreadInternalMessages || false
                          )
                        }
                      />
                      <IonIcon
                        icon={readerOutline}
                        color="primary"
                        hidden={
                          !(workItem.conversation.workItem?.hasUnreadNotes || false)
                        }
                      />
                    </IonChip>
                  </span>
                ) : (
                  undefined
                )
              }
            </p>

            {!isOwner && workItemState === "incoming"
              ? (
                <p>
                  <span className="ion-text-end ion-float-right">
                    <IonChip color="medium" style={{ height: "47px", top: "3px", paddingLeft: "0px" }}>
                      <IonChip color="dark" className="ion-margin-end" style={{ height: "40px", paddingLeft: "10px", paddingRight: "20px" }}>
                        <IonIcon hidden icon={workItemState === "incoming" ? swapHorizontalOutline : trendingDown} title="New" />
                        <IonAvatar
                          hidden={!workItem.conversation?.group?.avatar}
                          className="border-radius-0"
                          style={{ height: "28px", width: "28px" }}
                        >
                          {workItem.conversation?.group?.avatar
                            ? <AnimatedImg alt="" src={workItem.conversation?.group?.avatar ?? undefined} />
                            : undefined
                          }
                        </IonAvatar>
                        <IonAvatar
                          hidden={!workItem.conversation?.owner?.avatar}
                          style={{ marginLeft: "5px", height: "28px", width: "28px" }}
                        >
                          {workItem.conversation?.owner?.avatar
                            ? <AnimatedImg alt="" src={workItem.conversation?.owner?.avatar ?? undefined} />
                            : undefined
                          }
                        </IonAvatar>
                        <IonIcon icon={arrowForward} color="medium" style={{ marginLeft: "5px" }} />
                        {workItem.conversation?.transferGroup?.avatar
                          ? (
                            <IonAvatar
                              hidden={!workItem.conversation?.transferGroup?.avatar}
                              className="border-radius-0"
                              style={{ height: "28px", width: "28px" }}
                            >
                              <AnimatedImg alt="" src={workItem.conversation?.transferGroup?.avatar ?? undefined} />
                            </IonAvatar>
                          ) : workItem.conversation?.transferWorkerProfile?.avatar
                            ? (
                              <IonAvatar
                                hidden={!workItem.conversation?.transferWorkerProfile?.avatar}
                                style={{ height: "28px", width: "28px" }}
                              >
                                <AnimatedImg alt="" src={workItem.conversation?.transferWorkerProfile?.avatar ?? undefined} />
                              </IonAvatar>
                            ) : undefined
                        }
                      </IonChip>
                      <Button
                        hidden={!(workItem.conversation.requestTransfer?.targetOwnerId)}
                        onClick={() =>
                          workItem.conversation?.declineIncomingTransfer()
                        }
                        color="danger"
                        fill="solid"
                        shape="round"
                      >
                        <IonIcon icon={closeOutline} title="Decline" />
                      </Button>
                      <Button
                        fill="solid"
                        color="success"
                        shape="round"
                        onClick={() =>
                          workItem.conversation
                            ?.acceptIncomingTransfer()
                            .then(() =>
                              history.push(`/conversations/${workItem.id}`)
                            )
                        }
                      >
                        <IonIcon icon={checkmarkOutline} title="Accept" />
                      </Button>
                    </IonChip>
                  </span>
                </p>
              ) : (
                <>

                </>
              )
            }

          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonIcon
          icon={radioButtonOffOutline}
          color="medium"
          style={{ fontSize: "60px" }}
        ></IonIcon>

        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
          <span className="ion-text-end ion-float-right">
            <IonIcon
              icon={undefined}
              color="medium"
              style={{ fontSize: "40px" }}
            ></IonIcon>
          </span>
        </IonLabel>
      </IonItem>
    );
  }
);

export default WorkItemsListItem;
