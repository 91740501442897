import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItemDivider,
  IonList,
  IonText
} from "@ionic/react";

import {
  close,
  checkmarkCircle,
} from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";

interface CloseConversationButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const CloseConversationButton: React.FC<CloseConversationButtonProps> = observer(({ parent, conversation }) => {
  const [showModal, set_showModal] = useState(false);

  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }
  
  const isOwner =
      conversation?.ownerId == session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status == "active";
    const workItemState = isActive
      ? conversation?.requestTransfer &&
        conversation?.requestTransfer.targetOwnerId ==
          session.currentUser?.workerProfileId
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !=
            session.currentUser?.workerProfileId &&
          isOwner
        ? "outgoing"
        : "assigned"
      : "completed";

  const outcomes = session.conversationOutcomes?.data?.reduce(
    (groups: Record<string, Models.ConversationOutcome[]>, item) => {
      if (item.category) {
        const groupName = item.category;

        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        groups[groupName].push(item);
      }

      return groups;
    },
    {}
  );

  return (
    <>
      <IonButton
        shape="round"
        expand="block"
        size="large"
        fill={workItemState == "completed" ? "clear" : "solid"}
        color={workItemState == "completed" ? "dark" : "light"}
        onClick={() => set_showModal(true)}
        disabled={conversation === undefined || conversation === null || conversation.workItem === undefined || conversation.workItem === null || conversation.workItem.loading || conversation.workItem.status !== "active" || !(conversation?.status == 'active' && conversation.ownerId == session.currentUser?.workerProfileId)}
        hidden={(conversation?.status == 'active' && conversation.ownerId != session.currentUser?.workerProfileId)}
      >
        <IonIcon icon={checkmarkCircle} />
        <IonText>&nbsp;{workItemState == "completed" ? "Completed" : "Complete"}</IonText>
      </IonButton>

      <IonModal animated isOpen={showModal} swipeToClose onDidDismiss={(e) => set_showModal(false)}>
        <IonHeader>
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={() => set_showModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>

            <IonTitle>Conversation Outcome</IonTitle>

            <IonButtons slot="end">
              {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList lines="none" className="ion-no-padding">
          {outcomes &&
            Object.keys(outcomes).map((key) => (
              <div key={key}>
                <IonItemDivider mode="ios" color="light" sticky>{key}</IonItemDivider>
                {outcomes[key].map((item) => (
                  <IonItem button key={item.id} onClick={() => {
                        conversation?.closeConversation(item).then(() => set_showModal(false))
                      }
                    }>
                    <IonLabel>
                      {item.name}
                    </IonLabel>
                  </IonItem>
                ))}
              </div>
            ))
          }
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
});

export default CloseConversationButton;