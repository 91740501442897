import firebase from "firebase/app";
import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
  IReactionDisposer,
  reaction,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { CommunicationChannel } from "../common";
import { Customer } from "../Customers";
import { FacebookPage } from "../FacebookPages";
import { Files } from "../Files";
import { WorkerProfile } from "../WorkerProfiles";
import {
  MessageData,
  MessageMedia,
  messageDataConverter,
  messageDefaultValue,
  MessageError
} from "./MessageData";

const __cache: Record<string, Message> = {};

export class Message {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: MessageData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mCustomer: Customer | null | undefined;
  private mCustomerDisposer: IReactionDisposer | null | undefined;
  private mWorkerProfile: WorkerProfile | null | undefined;
  private mWorkerProfileDisposer: IReactionDisposer | null | undefined;
  private mFiles: Files | null | undefined;
  private mCommunicationChannelFacebookPage: FacebookPage | null | undefined;
  private mCommunicationChannelFacebookPageDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: MessageData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? messageDefaultValue : undefined);
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "customer", this.subscribeCustomer);
    onBecomeUnobserved(this, "customer", this.unsubscribeCustomer);
    onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
    onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
    onBecomeObserved(this, "files", this.subscribeFiles);
    onBecomeUnobserved(this, "files", this.unsubscribeFiles);
    onBecomeObserved(this, "communicationChannelFacebookPage", this.subscribeCommunicationChannelFacebookPage);
    onBecomeUnobserved(this, "communicationChannelFacebookPage", this.unsubscribeCommunicationChannelFacebookPage);
  }

  static createCacheInstance(session: Session, path: string, data?: MessageData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new Message(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: Message) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: MessageData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(messageDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(messageDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<MessageData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            ?.firestore()
            .collection(this.mPath)
            .withConverter(messageDataConverter(this.session))
            .add(this.data).then((doc) => {
              this.path = doc.path;
              Message.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            ?.firestore()
            .doc(this.mPath)
            .withConverter(messageDataConverter(this.session))
            .set(this.data, { merge: true }).then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }

  public get communicationChannel(): CommunicationChannel | null | undefined {
    if (this.data) return this.data.communicationChannel;
    return undefined;
  }

  public get body(): string | null | undefined {
    if (this.data) return this.data.body;
    return undefined;
  }

  public get createdOn(): Date | null | undefined {
    if (this.data) return this.data.createdOn?.toDate();
    return undefined;
  }

  public get direction(): "in" | "out" | null | undefined {
    if (this.data) return this.data.direction;
    return undefined;
  }

  public get status(): string | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  public get type(): string | null | undefined {
    if (this.data) return this.data.type;
    return undefined;
  }

  public get error(): MessageError | null | undefined {
    if (this.data) return this.data.error;
    return undefined;
  }

  public get customerId(): string | null | undefined {
    if (this.data) return this.data.customerId;
    return undefined;
  }

  public get customer(): Customer | null | undefined {
    return this.mCustomer;
  }
  public subscribeCustomer = () => {
    when(
      () => this.customerId !== undefined,
      () => {
        const initCustomer = () => {
          if (this.mCustomer && this.mCustomer.id != this.customerId) {
            this.unsubscribeCustomer();
          }

          if (this.mCustomer === undefined && this.customerId !== undefined) {
            this.mCustomer = this.customerId
              ? Customer.createCacheInstance(
                this.session,
                `customers/${this.customerId}`
              )
              : null;
            this.mCustomer?.subscribe();

            this.mCustomerDisposer = reaction(
              () => this.customerId,
              (customerId) => initCustomer()
            );
          }
        };

        initCustomer();
      }
    );
  };
  public unsubscribeCustomer = () => {
    if (this.mCustomerDisposer) this.mCustomerDisposer();
    this.mCustomer?.unsubscribe();
    this.mCustomer = undefined;
  };

  public get workerProfileId(): string | null | undefined {
    if (this.data) return this.data.workerProfileId;
    return undefined;
  }

  public get workerProfile(): WorkerProfile | null | undefined {
    return this.mWorkerProfile;
  }
  public subscribeWorkerProfile = () => {
    when(
      () => this.workerProfileId !== undefined,
      () => {
        const initWorkerProfile = () => {
          if (this.mWorkerProfile && this.mWorkerProfile.id != this.workerProfileId) {
            this.unsubscribeWorkerProfile();
          }

          if (this.mWorkerProfile === undefined && this.workerProfileId !== undefined) {
            this.mWorkerProfile = this.workerProfileId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.workerProfileId}`
              )
              : null;
            this.mWorkerProfile?.subscribe();

            this.mWorkerProfileDisposer = reaction(
              () => this.workerProfileId,
              (workerProfileId) => initWorkerProfile()
            );
          }
        };

        initWorkerProfile();
      }
    );
  };
  public unsubscribeWorkerProfile = () => {
    if (this.mWorkerProfileDisposer) this.mWorkerProfileDisposer();
    this.mWorkerProfile?.unsubscribe();
    this.mWorkerProfile = undefined;
  };

  public get media(): Record<string, MessageMedia> | null | undefined {
    return this.mData?.media;
  }

  public get files(): Files | null | undefined {
    return this.mFiles;
  }
  public subscribeFiles = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mFiles === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mFiles = this.id
              ? new Files(this.session, `${this.path}`)
              : null;
            this.mFiles?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeFiles = () => {
    this.mFiles?.unsubscribe();
    this.mFiles = undefined;
  };

  public get communicationChannelFacebookPage(): FacebookPage | null | undefined {
    return this.mCommunicationChannelFacebookPage;
  }
  private set communicationChannelFacebookPage(newValue: FacebookPage | null | undefined) {
    this.mCommunicationChannelFacebookPage = newValue;
  }
  public subscribeCommunicationChannelFacebookPage = () => {
    when(
      () => this.loaded && (typeof (this.data?.communicationChannel?.facebookId) === "string" ?? false),
      () => {
        const initCommunicationChannelFacebookPage = () => {
          if (this.communicationChannelFacebookPage && (this.communicationChannelFacebookPage.id !== this.data?.communicationChannel?.facebookId)) {
            this.unsubscribeCommunicationChannelFacebookPage();
          }


          if (this.communicationChannelFacebookPage === undefined && typeof (this.data?.communicationChannel?.facebookId) === "string") {
            this.communicationChannelFacebookPage = this.id
              ? FacebookPage.createCacheInstance(
                this.session,
                `communication/channels/facebookPages/${this.data?.communicationChannel?.facebookId}`
              )
              : null;
            this.communicationChannelFacebookPage?.subscribe();

            this.mCommunicationChannelFacebookPageDisposer = reaction(
              () => this.data?.communicationChannel?.facebookId,
              () => initCommunicationChannelFacebookPage()
            );
          }
        };

        initCommunicationChannelFacebookPage();
      }
    );
  };
  public unsubscribeCommunicationChannelFacebookPage = () => {
    if (this.mCommunicationChannelFacebookPageDisposer) this.mCommunicationChannelFacebookPageDisposer();
    this.mCommunicationChannelFacebookPage?.unsubscribe();
    this.mCommunicationChannelFacebookPage = undefined;
  };
}