import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonSearchbar,
  IonAvatar,
  IonImg,
  IonText,
  IonItemDivider,
  IonChip,
  IonListHeader,
} from "@ionic/react";

import { checkmarkOutline, close, closeOutline, personAdd, swapHorizontalOutline } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import AnimatedImg from "../../../components/AnimatedImg";
import { Button } from "../../../components/Button";

interface TransferButtonProps {
  parent?: HTMLElement | null;
  conversation: Models.Conversation | null | undefined;
}

const TransferButton: React.FC<TransferButtonProps> = observer(
  ({ parent, conversation }) => {
    const [showModal, set_showModal] = useState(false);
    const [searchText, setSearchText] = useState("");

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    const isOwner = conversation?.ownerId === session.currentUser?.workerProfile?.id;
    const isActive = conversation?.status === "active";
    const workItemState = isActive
      ? conversation.requestTransfer
        && (
          conversation.requestTransfer.targetOwnerId === session.currentUser?.workerProfileId
          || (conversation.requestTransfer.targetGroupId && session.currentUser?.groupIds?.includes(conversation.requestTransfer.targetGroupId) && !isOwner)
        )
        ? "incoming"
        : conversation?.requestTransfer &&
          conversation?.requestTransfer.targetOwnerId !==
          session.currentUser?.workerProfileId &&
          isOwner
          ? "outgoing"
          : "assigned"
      : "completed";

    const workerProfiles = session.workerProfiles?.data?.filter(
      (workerProfile) =>
        workerProfile.id !== conversation?.transferToWorkerProfileId
        && workerProfile.id !== conversation?.ownerId
        && workerProfile.user && workerProfile.user.loaded && workerProfile.user.data && workerProfile.user.data.isEnabled
        && (workerProfile.name ?? "").trim() !== ""
        && (workerProfile.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())
    );

    const groups = session.groups?.data?.filter(
      (group) =>
        group.id !== conversation?.transferGroupId &&
        (group.name ?? "").trim() !== ""
        && (group.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())
    );

    return (
      <>
        <IonButton
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={() => set_showModal(true)}
          disabled={conversation === undefined || conversation === null}
          hidden={
            !(
              conversation?.status === "active" &&
              (
                conversation.ownerId === session.currentUser?.workerProfileId
                || session.currentUser?.role === "admin"
              )
            )
          }
        >
          <IonIcon icon={swapHorizontalOutline} />
        </IonButton>

        <IonModal
          presentingElement={parent || undefined}
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={(e) => set_showModal(false)}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Transfer</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {conversation?.requestTransfer && !conversation.requestTransfer?.isDeclined
              ? (
                <IonList lines="none" className="ion-no-padding">
                  <IonListHeader color="light" style={{ paddingTop: "20px" }}>
                    <strong>PENDING TRANSFER</strong>
                  </IonListHeader>
                  <IonItem>
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className={`ion-margin-end ${conversation?.transferGroup ? "border-radius-0" : ""}`}
                    >
                      {conversation?.transferGroup?.avatar
                        ? <AnimatedImg alt="" src={conversation?.transferGroup?.avatar ?? undefined} />
                        : conversation?.transferWorkerProfile?.avatar
                          ? <AnimatedImg alt="" src={conversation?.transferWorkerProfile?.avatar ?? undefined} />
                          : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{conversation?.transferGroup?.name || conversation.transferWorkerProfile?.name || ""}</IonLabel>
                    <IonChip
                      hidden={
                        !(
                          conversation?.status === "active" &&
                          (
                            conversation?.requestTransfer?.targetOwnerId === session.currentUser?.workerProfileId
                            || conversation.ownerId === session.currentUser?.workerProfileId
                            || session.currentUser?.role === "admin"
                          )
                        )
                      }
                      color="dark"
                      style={{ height: "40px" }}
                    >
                      <IonIcon icon={swapHorizontalOutline} title="Transfer" />
                      <Button
                        hidden={
                          !(
                            conversation
                            && conversation.requestTransfer
                            && session.currentUser
                            //&& participant.workerProfile
                            && conversation.status === "active"
                            //&& participant.workerProfile.id === session.currentUser?.workerProfileId
                            && conversation.requestTransfer.targetOwnerId === session.currentUser.workerProfileId
                          )
                        }
                        onClick={(_) => conversation?.declineIncomingTransfer()}
                        color="danger"
                        fill="solid"
                        shape="round"
                      >
                        <IonIcon icon={closeOutline} title="Decline" />
                      </Button>
                      <Button
                        hidden={
                          !(
                            conversation
                            && conversation.requestTransfer
                            && session.currentUser
                            //&& participant.workerProfile
                            && conversation.status === "active"
                            //&& participant.workerProfile.id === session.currentUser?.workerProfileId
                            && conversation.requestTransfer.targetOwnerId === session.currentUser.workerProfileId
                          )
                        }
                        shape="round"
                        fill="solid"
                        color="success"
                        onClick={() => conversation?.acceptIncomingTransfer()}
                      >
                        <IonIcon icon={checkmarkOutline} title="Accept" />
                      </Button>
                      <Button
                        hidden={
                          !(
                            conversation.requestTransfer.targetOwnerId !== session.currentUser?.workerProfileId &&
                            (
                              conversation?.ownerId === session.currentUser?.workerProfileId
                              || session.currentUser?.role === "admin"
                            )
                          )
                        }
                        shape="round"
                        fill="solid"
                        color="danger"
                        onClick={() => conversation?.cancelTransfer()}
                      >
                        <IonIcon icon={closeOutline} title="Cancel" />
                      </Button>
                    </IonChip>
                  </IonItem>
                </IonList>
              ) : undefined
            }
            <IonListHeader color="light" style={{ paddingTop: "20px" }} hidden={!(conversation?.requestTransfer)}>
              <strong>TRANSFER TO GROUP OR USER</strong>
            </IonListHeader>
            <IonSearchbar
              style={{ marginTop: "5px" }}
              className=""
              color="light"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
            <IonList lines="none">
              <IonItemDivider mode="ios" color="light" sticky>
                Groups
              </IonItemDivider>
              {groups && groups.length > 0
                ? groups.map((item) => (
                  <IonItem
                    button
                    key={item.id}
                    onClick={() => {
                      conversation
                        ?.transferToGroup(item.id!, "")
                        .then(() => set_showModal(false));
                    }}
                  >
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end border-radius-0"
                    >
                      {item.avatar
                        ? <AnimatedImg alt="" src={item.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{item.name}</IonLabel>
                  </IonItem>
                ))
                : (searchText ?? "") !== "" && (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-start">
                      <p>
                        <IonText
                          color="dark"
                          style={{
                            //fontSize: "20px",
                          }}
                        >
                          {(searchText ?? "") === ""
                            ? ""
                            : "No Groups Found"}
                        </IonText>

                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
              <IonItemDivider mode="ios" color="light" sticky>
                Users
              </IonItemDivider>
              {workerProfiles && workerProfiles.length > 0
                ? workerProfiles.map((item) => (
                  <IonItem
                    button
                    key={item.id}
                    onClick={() => {
                      conversation
                        ?.addParticipant(item.id!)
                        .then(() => {
                          conversation
                            ?.transferToParticipant(item.id!, "")
                            .then(() => set_showModal(false));
                        });
                    }}
                  >
                    <IonAvatar
                      style={{ height: "40px", width: "40px" }}
                      className="ion-margin-end"
                    >
                      {item.avatar
                        ? <AnimatedImg alt="" src={item.avatar ?? undefined} />
                        : undefined
                      }
                    </IonAvatar>
                    <IonLabel>{item.name}</IonLabel>
                  </IonItem>
                ))
                : (searchText ?? "") !== "" && (
                  <IonItem style={{}} lines="none">
                    <IonLabel className="ion-text-start">
                      <p>
                        <IonText
                          color="dark"
                          style={{
                            //fontSize: "20px",
                          }}
                        >
                          {(searchText ?? "") === ""
                            ? ""
                            : " No Users Found"}
                        </IonText>
                      </p>
                      <p
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        <IonText
                          color="medium"
                          style={{
                            fontSize: "16px",
                          }}
                        ></IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  }
);

export default TransferButton;
