import firebase from "firebase/app";

import { CommunicationChannel } from "../common";
import { Message } from "./Message";

import { Session } from "../../session";
import { urlManipulation } from "workbox-precaching/_types";

export const messageStatuses = {
    PENDING: 'pending',
    PUBLISHED: 'published',
    SENDING: 'sending',
    SUBMITTED: 'submitted',
    QUEUED: 'queued',
    SENT: 'sent',
    DELIVERED: 'delivered',
    FAILED: 'failed',
    READ: 'read',
    UNKNOWN: 'unknown',
}

export const messageChannels = {
    WHATSAPP: 'whatsapp',
    SMS: 'sms',
    DEBUG: 'debug',
    MESSENGER: 'messenger',
    WEBCHAT: 'webchat',
    DEBUGINCOMING: undefined
}

export type MessageError = {
    code: string | null;
    message: string | null;
}

export type MessageMedia = {
    contentType: string | null;
    error: string | null;
    name: string | null;
    link: string | null;
    sourceUrl: string | null;
}

export interface MessageData {
    body: string | null;
    communicationChannel: CommunicationChannel | null;
    createdOn: firebase.firestore.Timestamp | null;
    receivedOn: firebase.firestore.Timestamp | null;
    customerId: string | null;
    direction: "in" | "out" | null;
    serviceProviderId: string | null;
    status: string | null;
    workerProfileId: string | null;
    type: string | null;
    error: MessageError | null;
    media: Record<string, MessageMedia> | null;
}

export const messageDefaultValue = {
    body: null,
    communicationChannel: null,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    customerId: null,
    direction: "out",
    serviceProviderId: null,
    status: messageStatuses.PENDING,
    workerProfileId: null,
    type: null,
    error: null,
    media: null,
} as MessageData;

export const messageDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: MessageData) {
            return {
                body: data.body,
                communicationChannel: data.communicationChannel,
                createdOn: data.createdOn,
                receivedOn: data.receivedOn,
                customerId: data.customerId,
                direction: data.direction,
                serviceProviderId: data.serviceProviderId,
                status: data.status,
                workerProfileId: data.workerProfileId,
                type: data.type,
                error: data.error,
                media: data.media
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<MessageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                body: data.body ?? null,
                communicationChannel: data.communicationChannel ?? null,
                createdOn: data.createdOn ?? null,
                receivedOn: data.receivedOn ?? null,
                customerId: data.customerId ?? null,
                direction: data.direction ?? null,
                serviceProviderId: data.serviceProviderId ?? null,
                status: data.status ?? null,
                workerProfileId: data.workerProfileId ?? null,
                type: data.type ?? null,
                error: data.error ?? null,
                media: data.media ?? null,
            } as MessageData;
        },
    }
};

export const messageConverter = (session: Session) => {
    return {
        toFirestore: function (message: Message) {
            return message.data ? {
                body: message.data.body,
                communicationChannel: message.data.communicationChannel,
                createdOn: message.data.createdOn,
                receivedOn: message.data.receivedOn,
                customerId: message.data.customerId,
                direction: message.data.direction,
                serviceProviderId: message.data.serviceProviderId,
                status: message.data.status,
                workerProfileId: message.data.workerProfileId,
                type: message.data.type,
                error: message.data.error,
                media: message.data.media,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<MessageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return Message.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    body: data.body ?? null,
                    communicationChannel: data.communicationChannel ?? null,
                    createdOn: data.createdOn ?? null,
                    receivedOn: data.receivedOn ?? null,
                    customerId: data.customerId ?? null,
                    direction: data.direction ?? null,
                    serviceProviderId: data.serviceProviderId ?? null,
                    status: data.status ?? null,
                    workerProfileId: data.workerProfileId ?? null,
                    type: data.type ?? null,
                    error: data.error ?? null,
                    media: data.media ?? null,
                }
            );
        },
    }
};
