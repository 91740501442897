import firebase from "firebase/app";
import { Session } from "../../session";

import { User } from "./User";

export type UserRole = "admin" | "user";

export interface UserData {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    password: string | null;
    activeWorkerProfileId: string | null;
    userType: UserRole | "new" | null;
    isEnabled: boolean | null;
    isDeleted: boolean;
    groupIds: string[] | null;
    adminGroupIds: string[] | null;
};

export const userDefaultValue = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    activeWorkerProfileId: null,
    userType: null,
    isEnabled: true,
    isDeleted: false,
    groupIds: [],
    adminGroupIds: [],
} as UserData;

export const userDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: UserData) {
            return {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                groupIds: data.groupIds,
                adminGroupIds: data.adminGroupIds,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<UserData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                firstName: data.firstName ?? null,
                lastName: data.lastName ?? null,
                email: data.email ?? null,
                activeWorkerProfileId: data.activeWorkerProfileId ?? null,
                isEnabled: data.isEnabled ?? null,
                userType: data.userType ?? null,
                isDeleted: data.isDeleted ?? false,
                groupIds: data.groupIds ?? [],
                adminGroupIds: data.adminGroupIds ?? [],
            } as UserData;
        },
    };
};

export const userConverter = (session: Session) => {
    return {
        toFirestore: function (user: User) {
            return user.data ? {
                firstName: user.data.firstName,
                lastName: user.data.lastName,
                email: user.data.email,
                groupIds: user.data.groupIds,
                adminGroupIds: user.data.adminGroupIds,
            } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<UserData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return User.createCacheInstance(
                session,
                snapshot.ref.path,
                {
                    firstName: data.firstName ?? null,
                    lastName: data.lastName ?? null,
                    email: data.email ?? null,
                    password: null,
                    activeWorkerProfileId: data.activeWorkerProfileId ?? null,
                    isEnabled: data.isEnabled ?? null,
                    userType: data.userType ?? null,
                    isDeleted: data.isDeleted ?? false,
                    groupIds: data.groupIds ?? [],
                    adminGroupIds: data.adminGroupIds ?? [],
                }
            );
        },
    };
};
