import firebase from "firebase/app";
import { Session } from "../../session";

import { ConversationOutcome } from "./ConversationOutcome";

export interface ConversationOutcomeData {
  category: string | null;
  name: string | null;
  tag: string | null;
  isDeleted: boolean;
};

export const conversationOutcomeDefaultValue = {
  category: null,
  name: null,
  tag: "",
  isDeleted: false,
} as ConversationOutcomeData;

export const conversationOutcomeDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: ConversationOutcomeData) {
      return {
        category: data.category,
        name: data.name,
        tag: data.tag,
        isDeleted: data.isDeleted,
      };
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomeData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        category: data.category ?? null,
        name: data.name ?? null,
        tag: data.tag ?? null,
        isDeleted: data.isDeleted ?? false,
      } as ConversationOutcomeData;
    },
  }
};

export const conversationOutcomeConverter = (session: Session) => {
  return {
    toFirestore: function (conversationOutcome: ConversationOutcome) {
      return conversationOutcome.data
        ? {
          category: conversationOutcome.data.category,
          name: conversationOutcome.data.name,
          tag: conversationOutcome.data.tag,
          isDeleted: conversationOutcome.data.isDeleted,
        } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomeData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return ConversationOutcome.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          category: data.category ?? null,
          name: data.name ?? null,
          tag: data.tag ?? null,
          isDeleted: data.isDeleted ?? false,
        }
      );
    },
  }
};