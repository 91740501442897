import React, { useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { Document, Page } from "react-pdf";

import {
  Camera,
  CameraDirection,
  CameraResultType,
  Photo,
} from "@capacitor/camera";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonImg,
  IonFooter,
  IonTextarea,
  IonSpinner,
  IonLabel,
  IonText,
  IonNote,
} from "@ionic/react";

import {
  close,
  camera,
  send,
  chatboxEllipses,
  logoFacebook,
  logoWhatsapp,
  attach,
  alertCircle,
} from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import ThumbnailPreview from "./ThumbnailPreview";

const getChannelIcon = (channel: string) => {
  switch (channel.toLowerCase()) {
    case Models.messageChannels.WHATSAPP:
      return <IonIcon style={{ marginTop: "3px" }} icon={logoWhatsapp} />;
    case Models.messageChannels.SMS:
      return <IonIcon style={{ marginTop: "3px" }} icon={chatboxEllipses} />;
    case Models.messageChannels.DEBUG:
      return <></>;
    case Models.messageChannels.MESSENGER:
      return <IonIcon style={{ marginTop: "3px" }} icon={logoFacebook} />;
    case Models.messageChannels.DEBUGINCOMING:
      return <></>;
  }
};

interface TakePictureButtonProps {
  parent?: HTMLElement | null;
  disabled?: boolean;
  hidden?: boolean;
  conversation: Models.Conversation | null | undefined;
}

const AttachFileButton: React.FC<TakePictureButtonProps> = observer(
  ({ parent, conversation, hidden, disabled }) => {
    const [showModal, set_showModal] = useState(false);
    const [file, setFile] = useState<File | undefined>();
    const [fileDataURL, setFileDataURL] = useState<string>();
    const [messageInput, setMessageInput] = useState("");
    const [busySendingMessage, setBusySendingMessage] = useState(false);
    const [hideMessageActions, setHideMessageActions] = useState(true);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [numPages, setNumPages] = useState(1);

    const session = useContext(SessionContext);
    if (!session) {
      return <></>;
    }

    useEffect(() => {
      console.log("File Type", file?.type);
      let fileReader: FileReader, isCancel = false;
      if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (e.target?.result) {
            const { result } = e.target;
            if (result && typeof (result) === "string" && !isCancel) {
              setFileDataURL(result)
            }

          }
        }
        fileReader.readAsDataURL(file);
      }
      return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      }

    }, [file]);

    const button_onClick = () => {
      setFile(undefined);
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.value = "";
        hiddenFileInput.current.click();
      }
    };

    const remove_onClick = () => {
      button_onClick();
    };

    const pdfDocument = useMemo(() => {
      return (
        <Document file={file ? URL.createObjectURL(file) : undefined} onLoadError={(e) => console.log("error", e)} onLoadSuccess={(e) => setNumPages(e.numPages)}  >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )
    }, [file, numPages])
    return (
      <>
        <input
          ref={hiddenFileInput}
          accept="application/pdf,image/jpeg,image/png,image/jpg"
          onChange={
            (e) => {
              const fileUploaded = e?.target?.files?.[0];
              setFile(fileUploaded);
              set_showModal(true);
              setMessageInput("");
            }
          }
          type="file" style={{ display: 'none' }} />
        <IonButton
          style={{ height: "36px" }}
          color="dark"
          shape="round"
          fill="clear"
          onClick={() => button_onClick()}
          disabled={!conversation || disabled}
          hidden={
            !(
              conversation?.status === "active" &&
              conversation.ownerId === session.currentUser?.workerProfileId &&
              !(hidden || false)
            )
          }
        >
          <IonIcon icon={attach} />
        </IonButton>

        <IonModal
          animated
          isOpen={showModal}
          swipeToClose
          onDidDismiss={() => {
            set_showModal(false);
            setFile(undefined);
            setFileDataURL(undefined);
          }}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => {
                  set_showModal(false)
                  setFile(undefined);
                  setFileDataURL(undefined);
                }}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Send file</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonGrid style={{ height: "100%", width: "100%" }}>
              <IonRow style={{ height: "100%", width: "100%" }}>
                <IonCol style={{ height: "100%", width: "100%" }}>
                  {file && file.size <= 614400
                    ? file.type === "application/pdf"
                      ? pdfDocument
                      : file.type.startsWith("image")
                        ? (
                          <IonImg src={file ? URL.createObjectURL(file) : undefined} />
                        ) : (
                          <></>
                        )
                    : (
                      conversation?.lastMessage?.communicationChannel?.channel?.toLowerCase() === Models.messageChannels.SMS && file && file.size > 614400
                        ? (
                          <IonLabel color="danger">
                            <IonIcon style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }} slot="start"
                              color="danger"
                              icon={alertCircle}
                            />
                            <IonText style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}>{" "}SMS only supports files with a maximum size of 600KB.</IonText>
                          </IonLabel>
                        ) : (
                          <>
                            {file && (
                              <>
                                {file.type === "application/pdf"
                                  ? pdfDocument
                                  : file.type.startsWith("image")
                                    ? (
                                      <IonImg src={file ? URL.createObjectURL(file) : undefined} />
                                    ) : (
                                      <></>
                                    )
                                }
                              </>
                            )}
                          </>
                        )
                    )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar
              color="light"
              style={{
                paddingBottom: session.keyboardInfo.visible
                  ? "0px"
                  : "var(--ion-safe-area-bottom, 0)",
              }}
            >
              <IonTextarea
                hidden={conversation?.lastMessage?.communicationChannel?.channel?.toLowerCase() !== Models.messageChannels.SMS}
                spellcheck={true}
                autocapitalize="on"
                color="dark"
                className="ion-no-margin ion-no-padding"
                autoGrow
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  //border: "1px solid var(--ion-color-medium)",
                  margin: "8px 0px 8px 3px",
                  padding: "5px 10px 5px 5px",
                  minHeight: "36px",
                }}
                disabled={busySendingMessage}
                value={messageInput}
                //placeholder="Type message"
                onIonChange={(e) => {
                  setMessageInput(e.detail.value!);
                }}
              >
                <IonButton hidden>
                  <IonIcon icon={attach} />
                </IonButton>
              </IonTextarea>

              <IonButtons
                slot="end"
                style={{ marginLeft: "13px", marginRight: "10px" }}
              >
                <IonButton
                  style={{ height: "36px" }}
                  color="dark"
                  shape="round"
                  fill="solid"
                  disabled={busySendingMessage || (conversation?.lastMessage?.communicationChannel?.channel?.toLowerCase() === Models.messageChannels.SMS && file && file.size > 614400)}
                  onClick={() => {
                    setBusySendingMessage(true);

                    conversation?.sendMessage(messageInput.replace("\n", "\r\n"), file?.name, fileDataURL, file?.type)
                      .then(() => {
                        setBusySendingMessage(false);
                        setMessageInput("");
                        setFile(undefined);
                        setFileDataURL(undefined);
                        set_showModal(false);
                      });
                  }}
                >
                  <IonIcon icon={send}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </>
    );
  }
);

export default AttachFileButton;
