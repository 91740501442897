import { IonPage } from "@ionic/react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Route,
  RouteComponentProps,
  RouteProps,
  StaticContext,
} from "react-router";
import { UserRole } from "../models";
import UnauthenticatedPage from "../pages/Auth/Unauthenticated";
import LoadingPage from "../pages/common/LoadingPage";
import { SessionContext } from "../session";

export type ProtectedRouteProps = {
  roles?: UserRole[] | null;
  fallbackComponent:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
  loadingComponent?:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
} & RouteProps;

const ProtectedRoute = observer(
  ({ roles, fallbackComponent, loadingComponent, ...routeProps }: ProtectedRouteProps) => {
    const session = useContext(SessionContext);

    if (typeof(session) === "undefined" || (session && session.loading)) {
      return <Route {...routeProps} component={loadingComponent || IonPage} />;
    } else if (session && session.signedIn) {
      if (session.currentUser?.loading ?? true) {
        return <Route {...routeProps} component={loadingComponent || LoadingPage} />;
      } else if (roles && !(session?.currentUser?.loaded ?? false)) {
        return <Route {...routeProps} component={loadingComponent || LoadingPage} />;
      } else if (!roles || roles.includes(session?.currentUser?.role as UserRole ?? "")) {
        return <Route {...routeProps} />;
      }
    }

    return <Route {...routeProps} component={fallbackComponent} />;
  }
);

export default ProtectedRoute;
