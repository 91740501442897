import React, { useContext, useState } from "react";
import firebase from "firebase/app";
import { observer } from "mobx-react-lite";

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  IonList,
  isPlatform,
  IonButton,
  IonTitle,
  IonListHeader,
  IonProgressBar,
  IonText,
  useIonModal,
  IonSearchbar,
  IonImg,
  IonChip,
} from "@ionic/react";

import { arrowBack } from "ionicons/icons";

import * as Models from "../../models";
import { SessionContext } from "../../session";
import ConnectFacebookPagesButton from "./components/ConnectFacebookPagesButton";
import FacebookPageDetail from "./components/FacebookPageDetail";

const FacebookPagesPage: React.FC = observer(() => {
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState<Models.FacebookPage>();

  const session = useContext(SessionContext);

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(FacebookPageDetail, {
    session: session,
    facebookPage: selectedItem,
    onDismiss: handleDismiss,
  });

  if (!session) {
    return <></>;
  }

  if (!session.facebookPages?.loaded) {
    return <IonProgressBar type="indeterminate" />;
  }

  const facebookPages = session.facebookPages?.data?.filter(
    (facebookPage) =>
      ((facebookPage.name ?? "").trim() !== "" &&
        (facebookPage.name ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase())) ||
      ((facebookPage.pageId ?? "").trim() !== "" &&
        (facebookPage.pageId ?? "")
          .toUpperCase()
          .includes((searchText ?? "").toUpperCase()))
  );

  return (
    <IonPage>
      <IonHeader
      //translucent
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {(session.windowSize === "small") ? (
              <>
                {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                <IonButton routerLink="/admin">
                  <IonIcon icon={arrowBack} />
                </IonButton>
              </>
            ) : (
              <></>
            )}
          </IonButtons>
          <IonTitle>Facebook Pages</IonTitle>
          <IonButtons slot="end">
            <ConnectFacebookPagesButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonProgressBar hidden={!session.facebookPages?.loading} type="indeterminate" />
        <IonSearchbar
          color="light"
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        <IonList>
          {facebookPages && facebookPages.length > 0
            ? facebookPages?.map((item) => (
              <IonItem
                button
                key={item.id}
                onClick={() => {
                  setSelectedItem(item);
                  present({ cssClass: "modal-edit-view" });
                }}
              >
                <IonLabel>
                  <h2>
                    {item.name}{" "}
                  </h2>
                  <IonText color="medium">
                    <p>{item.category}</p>
                  </IonText>
                </IonLabel>
                <IonChip slot="end" color={
                  item.status === "Linked"
                    ? "success"
                    : item.status === "Pending"
                      ? "warning"
                      : item.status === "Failed"
                        ? "danger"
                        : "medium"
                }>{item.status}</IonChip>
              </IonItem>
            ))
            : (
              <IonItem style={{}} lines="none">
                <IonLabel className="ion-text-center">
                  <IonImg
                    style={{
                      maxWidth: "250px",
                      margin: "auto",
                      marginTop: "69px",
                    }}
                    src={
                      (searchText ?? "") === ""
                        ? "/assets/images/artboard007.png"
                        : "/assets/images/artboard008.png"
                    }
                  />
                  <p>
                    <strong>
                      <IonText
                        color="dark"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        {(searchText ?? "") === ""
                          ? ""
                          : " No Search Results"}
                      </IonText>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <IonText
                      color="medium"
                      style={{
                        fontSize: "16px",
                      }}
                    ></IonText>
                  </p>
                </IonLabel>
              </IonItem>
            )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default FacebookPagesPage;
