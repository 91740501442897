import firebase from "firebase/app";
import { Session } from "../../session";

import { FacebookPage } from "./FacebookPage";

export interface FacebookPageData {
    category: string | null;
    name: string | null;
    accessToken: string | null;
    status: string | null | undefined;
    pageId: string | null | undefined;
    groupId: string | null | undefined;
}

export const facebookPageDefaultValue = {
    category: null,
    name: null,
    accessToken: null,
    pageId: null,
    groupId: null,
} as FacebookPageData;

export const facebookPageDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: FacebookPageData) {
            return {
                category: data.category,
                name: data.name,
                accessToken: data.accessToken,
                groupId: data.groupId,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<FacebookPageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                category: data.category ?? null,
                name: data.name ?? null,
                accessToken: null,
                pageId: data.pageId ?? snapshot.id,
                groupId: data.groupId ?? null,
                status: data.status ?? 'Pending',
            } as FacebookPageData;
        },
    }
};

export const facebookPageConverter = (session: Session) => {
    return {
        toFirestore: function (facebookPage: FacebookPage) {
            return facebookPage.data
                ? {
                    category: facebookPage.data.category,
                    name: facebookPage.data.name,
                    accessToken: facebookPage.data.accessToken,
                    pageId: facebookPage.data.pageId,
                    groupId: facebookPage.data.groupId,
                } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<FacebookPageData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return FacebookPage.createCacheInstance(
                session,
                snapshot.ref.path, {
                category: data.category ?? null,
                name: data.name ?? null,
                pageId: data.pageId ?? snapshot.id,
                groupId: data.groupId ?? null,
                accessToken: null,
                status: data.status ?? 'Pending',
            });
        },
    }
};