import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  Camera,
  CameraDirection,
  CameraResultType,
  Photo,
} from "@capacitor/camera";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonImg,
  IonFooter,
  IonTextarea,
  IonAvatar,
} from "@ionic/react";

import {
  close,
  cloudUpload,
  pencil,
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session, SessionContext } from "../../../session";
import imageCompression from "browser-image-compression";
import AnimatedImg from "../../../components/AnimatedImg";

function compressImage(imageFile: File) {
  return new Promise<string>(async (resolve, reject) => {
    //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 400,
      useWebWorker: true,
      fileType: "image/jpeg",
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof (reader.result) === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to compress image"));
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      reject(error);
    }

  });
}

interface AvatarButtonProps {
  session: Session,
  parent?: HTMLElement | null;
  disabled?: boolean;
  hidden?: boolean;
  group: Models.Group | null | undefined;
}

const AvatarButton: React.FC<AvatarButtonProps> = observer(
  ({ session, parent, group, hidden, disabled }) => {
    const [showModal, set_showModal] = useState(false);
    const [file, setFile] = useState<File | undefined>();
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [busy, setBusy] = useState(false);

    const button_onClick = () => {
      setFile(undefined);
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.value = "";
        hiddenFileInput.current.click();
      }
    };

    return (
      <>
        <input
          ref={hiddenFileInput}
          accept="image/jpeg,image/png,image/jpg"
          onChange={
            (e) => {
              const fileUploaded = e?.target?.files?.[0];
              setFile(fileUploaded);
              set_showModal(true);
            }
          }
          type="file" style={{ display: 'none' }} />
        <div
          style={{
            height: "100px",
            width: "100px",
            margin: "auto",
          }}
        >
          <IonAvatar
            style={{
              height: "100px",
              width: "100px",
              //margin: "auto",
            }}
            className="ion-margin-bottom border-radius-0"
            onClick={() => button_onClick()}
          >
            {group?.avatar
              ? <AnimatedImg alt="" src={group?.avatar ?? undefined} />
              : undefined
            }
          </IonAvatar>
          <div
            onClick={() => button_onClick()}
            style={{
              backgroundColor: "var(--ion-color-light)",
              position: "relative",
              //zIndex: "2",
              top: "-125px",
              left: "85px",
              width: "25px",
              height: "25px",
              borderRadius: "64px",
              border: "1px solid var(--ion-color-dark)",
              boxShadow: "1px 1px 3px var(--ion-color-medium)",
            }}
          >
            <IonIcon
              style={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
              src={pencil}
            />
          </div>
        </div>


        <IonModal
          animated
          isOpen={showModal}
          swipeToClose
          cssClass="modal-create-view"
          onDidDismiss={() => {
            set_showModal(false);
            setFile(undefined);
          }}
        >
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => {
                  set_showModal(false)
                  setFile(undefined);
                }}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Upload Group Avatar</IonTitle>

              <IonButtons slot="end">
                {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonImg src={file ? URL.createObjectURL(file) : undefined} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar
              color="light"
              style={{
                paddingBottom: session.keyboardInfo.visible
                  ? "0px"
                  : "var(--ion-safe-area-bottom, 0)",
              }}
            >
              <IonButtons slot="start">

              </IonButtons>
              <IonButtons
                slot="end"
                style={{ marginLeft: "13px", marginRight: "10px" }}
              >
                <IonButton
                  style={{ height: "36px" }}
                  color="dark"
                  shape="round"
                  fill="solid"
                  disabled={busy}
                  onClick={async () => {
                    if (file) {
                      setBusy(true);

                      try {
                        const compressedDataUrl = await compressImage(file);

                        await group?.uploadAvatar(compressedDataUrl);

                        setBusy(false);
                        set_showModal(false);
                      } catch (error) {
                        if (error instanceof Error) {
                          alert(error.message);
                        }
                      }
                      finally {
                        setBusy(false);
                      }
                    }
                  }}
                >
                  <IonIcon icon={cloudUpload}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </>
    );
  }
);

export default AvatarButton;
