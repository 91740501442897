import firebase from "firebase/app";
import { Session } from "../../session";

import { Customer } from "./Customer";

export interface ProviderProfile {
    avatar: string | null;
    firstName: string | null;
    lastName: string | null;
    modifiedOn: Date | null;
}

export interface CustomerData {
    externalReference: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    mobileNumber: string | null;
    facebookId: string | null;
    notes: string | null;
    providerProfiles: Record<string, ProviderProfile> | null;
}

export const customerDefaultValue = {
    externalReference: null,
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    facebookId: null,
    notes: null,
    providerProfiles: null,
} as CustomerData;

export const customerDataConverter = (session: Session) => {
    return {
        toFirestore: function (data: CustomerData) {
            return {
                externalReference: data.externalReference,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.externalReference,
                mobileNumber: data.mobileNumber,
                facebookId: data.firstName,
                notes: data.notes,
                providerProfiles: data.providerProfiles,
            };
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<CustomerData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return {
                externalReference: data.externalReference ?? null,
                firstName: data.firstName ?? null,
                lastName: data.lastName ?? null,
                email: data.email ?? null,
                mobileNumber: data.mobileNumber ?? null,
                facebookId: data.facebookId ?? null,
                notes: data.notes ?? null,
                providerProfiles: data.providerProfiles ?? null,
            } as CustomerData;
        },
    }
};

export const customerConverter = (session: Session) => {
    return {
        toFirestore: function (customer: Customer) {
            return customer.data
                ? {
                    externalReference: customer.data.externalReference,
                    firstName: customer.data.firstName,
                    lastName: customer.data.lastName,
                    email: customer.data.externalReference,
                    mobileNumber: customer.data.mobileNumber,
                    facebookId: customer.data.firstName,
                    notes: customer.data.notes,
                    providerProfiles: customer.data.providerProfiles,
                } : {};
        },
        fromFirestore: function (
            snapshot: firebase.firestore.QueryDocumentSnapshot<CustomerData>,
            options: firebase.firestore.SnapshotOptions
        ) {
            const data = snapshot.data(options);
            return Customer.createCacheInstance(
                session,
                snapshot.ref.path, {
                externalReference: data.externalReference ?? null,
                firstName: data.firstName ?? null,
                lastName: data.lastName ?? null,
                email: data.email ?? null,
                mobileNumber: data.mobileNumber ?? null,
                facebookId: data.facebookId ?? null,
                notes: data.notes ?? null,
                providerProfiles: data.providerProfiles ?? null,
            });
        },
    }
};