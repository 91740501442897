import firebase from "firebase/app";

import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved, when, } from "mobx";
import { Session } from "../../session";

import { CustomerData, ProviderProfile, customerDataConverter, customerDefaultValue } from "./CustomerData";

import { getId } from "../../utils/Helpers";
import { Files } from "..";

const __cache: Record<string, Customer> = {};

export class Customer {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: CustomerData | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;
    private mFiles: Files | null | undefined;

    constructor(session: Session, path: string, data?: CustomerData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = getId(this.mPath);
        this.mData = data ? data : (this.mId == null ? customerDefaultValue : undefined);
        this.mSubscriptionCount = 0;

        onBecomeObserved(this, "files", this.subscribeFiles);
        onBecomeUnobserved(this, "files", this.unsubscribeFiles);
    }

    static createCacheInstance(session: Session, path: string, data?: CustomerData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        const id = getId(cachePath);
        if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new Customer(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: Customer) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = getId(this.mPath);
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: CustomerData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public refetch() {
        if (!this.mSubscription) {
            this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(customerDataConverter(this.session))
                .get()
                .then((snapshot) => {
                    const data = snapshot.data();
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.path}`);
            this.mSubscription = this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(customerDataConverter(this.session))
                .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<CustomerData>) => {
                    const data = snapshot.data();
                    this.data = data;
                }
                );
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.path}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    public save = () => {
        return new Promise<void>((resolve, reject) => {
            if (this.data) {
                if (this.id == null) {
                    this.session.firebase
                        ?.firestore()
                        .collection(this.mPath)
                        .withConverter(customerDataConverter(this.session))
                        .add(this.data).then((doc) => {
                            this.path = doc.path;
                            Customer.addCacheInstance(this);
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                } else {
                    this.session.firebase
                        ?.firestore()
                        .doc(this.mPath)
                        .withConverter(customerDataConverter(this.session))
                        .set(this.data, { merge: true }).then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
            } else {
                reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
            }
        });
    }

    public get externalReference(): string | null | undefined {
        if (this.data) return this.data.externalReference;
        return undefined;
    }

    public get firstName(): string | null | undefined {
        if (this.data) return this.data.firstName;
        return undefined;
    }

    public get lastName(): string | null | undefined {
        if (this.data) return this.data.lastName;
        return undefined;
    }

    public get email(): string | null | undefined {
        if (this.data) return this.data.email;
        return undefined;
    }

    public get mobileNumber(): string | null | undefined {
        if (this.data) return this.data.mobileNumber;
        return undefined;
    }

    public get notes(): string | null | undefined {
        if (this.data) return this.data.notes;
        return undefined;
    }

    public get facebookProfileId(): string | null | undefined {
        if (this.data) return this.data.facebookId;
        return undefined;
    }

    public get fullName(): string | null | undefined {
        if (this.data) {
            return `${this.firstName?.trim() || ""} ${this.lastName?.trim() || ""}`.trim() || null;
        }
        return undefined;
    }

    public get displayName(): string {
        if (this.fullName) {
            return this.fullName;
        }

        const latestProfile = this.getLatestProviderProfile();
        if (latestProfile) {
            const profileName = `${latestProfile.firstName || ""} ${latestProfile.lastName || ""}`.trim();
            if (profileName) {
                return profileName;
            }
        }

        const anonymous = "Anonymous";
        if ((this.mobileNumber?.trim() || "") !== "") {
            return `${this.mobileNumber}`;
        } else if ((this.email?.trim() || "") !== "") {
            return `${this.email}`;
        } else if ((this.facebookProfileId?.trim() || "") !== "") {
            return `${anonymous} Facebook (${this.facebookProfileId})`;
        }
        return anonymous;
    }

    public get providerProfiles(): Record<string, ProviderProfile> | null | undefined {
        if (this.data) return this.data.providerProfiles;
        return undefined;
    }

    public get providerProfileFirstName(): string | null | undefined {
        const latestProfile = this.getLatestProviderProfile();
        if (latestProfile) {
            return latestProfile.firstName;
        }
        return undefined;
    }

    public get providerProfileLastName(): string | null | undefined {
        const latestProfile = this.getLatestProviderProfile();
        if (latestProfile) {
            return latestProfile.lastName;
        }
        return undefined;
    }

    public get files(): Files | null | undefined {
        return this.mFiles;
    }
    private set files(newValue: Files | null | undefined) {
        this.mFiles = newValue;
    }
    public subscribeFiles = () => {
        when(
            () => this.id !== undefined,
            () => {
                if (this.files === undefined && this.id !== undefined) {
                    this.files = this.id
                            ? new Files(this.session, `${this.path}`)
                            : null;
                    this.files?.subscribe();
                }
            }
        );
    };
    public unsubscribeFiles = () => {
        this.files?.unsubscribe();
        this.files = undefined;
    };
    
    public get avatar(): string | null | undefined {
        const profile = this.getLatestProviderProfile();
        if (profile) {
            const avatarPath = `${this.path}/${profile.avatar}`;
            return this.files?.data?.find(file => file.path === avatarPath)?.url;
        }
        return undefined;
    }

    public get picture(): string | null | undefined {
        return undefined;
    }

    public update = (customerData: { externalReference?: string, firstName?: string, lastName?: string, email?: string, notes?: string }) => {
        return new Promise((resolve, reject) => {
            this.session.firebase
                .firestore()
                .doc(this.mPath)
                .update(customerData).then(() => {
                    resolve(undefined);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    }

    private getLatestProviderProfile(): ProviderProfile | null | undefined {
        if (this.providerProfiles) {
            let previousDate: Date | null = null;
            let profile: ProviderProfile | null = null;

            const profiles = Object.keys(this.providerProfiles);
            for (let i = 0; i < profiles.length; i += 1) {
                const currentProfile = this.providerProfiles[profiles[i]];
                if (!previousDate 
                    || (currentProfile.modifiedOn && currentProfile.modifiedOn > previousDate)
                ) {
                    previousDate = currentProfile.modifiedOn;
                    profile = currentProfile;
                }
            }
            return profile;
        }
        return undefined;
    }
}