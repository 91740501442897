import React, { useContext, useEffect, useState } from "react";
import {
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonSearchbar,
  isPlatform,
  useIonModal,
  IonItemDivider,
  IonImg,
  IonItem,
  IonText,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { add, arrowBack, checkmark } from "ionicons/icons";

import StandardResponsesListItem from "./components/StandardResponsesListItem";
import * as Models from "../../models";
import StandardResponseDetail from "./components/StandardResponseDetail";
import { SessionContext } from "../../session";

const StandardResponsesPage: React.FC = observer(() => {
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState<Models.StandardResponse>();

  const handleDismiss = () => {
    dismiss();
  };

  const session = useContext(SessionContext);

  const [present, dismiss] = useIonModal(StandardResponseDetail, {
    session: session,
    standardResponse: selectedItem,
    onDismiss: handleDismiss,
  });

  if (!session) {
    return <></>;
  }

  const foundStandardResponses = session.standardResponses?.data?.filter(
    (standardResponse) =>
      (standardResponse.name ?? "").trim() != "" &&
      (standardResponse.name ?? "")
        .toUpperCase()
        .includes((searchText ?? "").toUpperCase())
  );

  const standardResponses = foundStandardResponses?.reduce(
    (groups: Record<string, Models.StandardResponse[]>, item) => {
      if (item.category) {
        const groupName = item.category;

        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        groups[groupName].push(item);
      }

      return groups;
    },
    {}
  );

  return (
    <IonPage>
      <IonHeader
      //className="ion-no-border"
      >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {session.windowSize === "small" ? (
              <>
                {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                <IonButton routerLink="/admin">
                  <IonIcon icon={arrowBack} />
                </IonButton>
              </>
            ) : (
              <></>
            )}
          </IonButtons>
          <IonTitle>Standard Responses</IonTitle>
          <IonButtons slot="end">
            <IonButton
              shape="round"
              expand="block"
              size="large"
              fill="solid"
              color="light"
              onClick={() => {
                setSelectedItem(
                  new Models.StandardResponse(
                    session,
                    `templates/standardResponse/templateOptions`
                  )
                );
                present({ cssClass: "modal-create-view" });
              }}
            >
              <IonIcon icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonProgressBar
          hidden={!session.standardResponses?.loading}
          type="indeterminate"
        />
        <IonSearchbar
          color="light"
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        <IonList>
          {standardResponses && Object.keys(standardResponses).length > 0
            ? Object.keys(standardResponses).map((key) => (
                <div key={key}>
                  <IonItemDivider mode="ios" color="light" sticky>
                    {key}
                  </IonItemDivider>
                  {standardResponses[key].map((item) => (
                    <StandardResponsesListItem
                      key={item.id}
                      standardResponse={item}
                      onSelectItem={(item) => {
                        setSelectedItem(item);
                        present({ cssClass: "modal-edit-view" });
                      }}
                    />
                  ))}
                </div>
              ))
            : (searchText ?? "") !== "" && (
                <IonItem style={{}} lines="none">
                  <IonLabel className="ion-text-center">
                    <IonImg
                      style={{
                        maxWidth: "250px",
                        margin: "auto",
                        marginTop: "69px",
                      }}
                      src={
                        (searchText ?? "") === ""
                          ? "/assets/images/artboard007.png"
                          : "/assets/images/artboard008.png"
                      }
                    />
                    <p>
                      <strong>
                        <IonText
                          color="dark"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          {(searchText ?? "") === ""
                            ? ""
                            : " No Search Results"}
                        </IonText>
                      </strong>
                    </p>
                    <p
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <IonText
                        color="medium"
                        style={{
                          fontSize: "16px",
                        }}
                      ></IonText>
                    </p>
                  </IonLabel>
                </IonItem>
              )}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default StandardResponsesPage;
