import { gql, useQuery } from "@apollo/client";
import { IonLabel, IonSpinner, IonText, useIonViewDidEnter } from "@ionic/react";
import { ResponsivePie } from "@nivo/pie";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { SessionContext } from "../../../../../session";
import { hashCode } from "../../../../../utils/Helpers";

const GRAPHQL_QUERY = gql`
  query Query {
    workerProfiles {
      user {
        id
      }
      name
      stats {
        activeWorkItemsCount
      }
    }
  }
`;

interface Query {
  workerProfiles: WorkerProfile[];
}

interface WorkerProfile {
  user: {
    id: string;
  };
  name: string;
  stats: {
    activeWorkItemsCount: number;
  };
}

const CenteredMetric = (props: {
  dataWithArc: any[];
  centerX: string | number | undefined;
  centerY: string | number | undefined;
}) => {
  let total = 0;
  props.dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <text
      x={props.centerX}
      y={props.centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "52px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};

const AssignedWorkerProfileChart = observer(() => {
  const { loading, error, data, refetch } = useQuery<Query>(GRAPHQL_QUERY);
  
  useIonViewDidEnter(
    () => {
      refetch();
    }
  );

  const session = useContext(SessionContext);
  if (!session) {
    return <></>;
  }

  if (error) {
    console.error("Dashboard Error (ASSIGNED CONVERSATIONS): ", error)
  }

  return (
    <div style={{ height: "400px" }}>
      <IonLabel>
        <IonText color="dark">
          <strong>ASSIGNED CONVERSATIONS</strong>
        </IonText>
      </IonLabel>
      {loading ? (
        <IonLabel>
          <p>
            <IonSpinner />
          </p>
        </IonLabel>
      ) : error ? (
        <IonLabel>Failed to load.</IonLabel>
      ) : !data ? (
        <></>
      ) : (
        <>
          {(session.windowSize === "small" || session.windowDimensions.width < 1000) ? (
            <ResponsivePie
              data={data.workerProfiles
                .filter((item) => item.user?.id)
                .map((item) => ({
                  id:
                    item.name
                      ?.split(" ")
                      .map(function (str) {
                        return str ? str[0].toUpperCase() : "";
                      })
                      .join("")
                      .substring(0, 2) ?? "",
                  label: item.name,
                  value: item.stats.activeWorkItemsCount,
                  color: `hsl(${hashCode(item.user?.id || "") % 360
                    }, 90%, 70%)`,
                }))}
              colors={{ datum: "data.color" }}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={5}
              activeOuterRadiusOffset={8}
              //borderWidth={1}
              //borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              legends={[]}
              animate={false}
              tooltip={(e) => {
                var t = e.datum;
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "inherit",
                      fontSize: "inherit",
                      borderTopLeftRadius: "2px",
                      borderTopRightRadius: "2px",
                      borderBottomRightRadius: "2px",
                      borderBottomLeftRadius: "2px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                      padding: "5px 9px",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "pre",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          width: "12px",
                          height: "12px",
                          background: t.color,
                          marginRight: "7px",
                        }}
                      ></span>
                      <span>
                        {t.label}: <strong>{t.value}</strong>
                      </span>
                    </div>
                  </div>
                );
              }}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
            />
          ) : (
            <ResponsivePie
              data={data.workerProfiles
                .filter((item) => item.user?.id)
                .map((item) => ({
                  id: item.name,
                  label: item.name,
                  value: item.stats.activeWorkItemsCount,
                  color: `hsl(${hashCode(item.user?.id || "") % 360
                    }, 90%, 70%)`,
                }))}
              colors={{ datum: "data.color" }}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={5}
              activeOuterRadiusOffset={8}
              //borderWidth={1}
              //borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              legends={[]}
              animate={false}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              tooltip={(e) => {
                var t = e.datum;
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "inherit",
                      fontSize: "inherit",
                      borderTopLeftRadius: "2px",
                      borderTopRightRadius: "2px",
                      borderBottomRightRadius: "2px",
                      borderBottomLeftRadius: "2px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                      padding: "5px 9px",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "pre",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          width: "12px",
                          height: "12px",
                          background: t.color,
                          marginRight: "7px",
                        }}
                      ></span>
                      <span>
                        {t.label}: <strong>{t.value}</strong>
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          )}
        </>
      )}
    </div>
  );
});

export default AssignedWorkerProfileChart;
