import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonProgressBar,
  IonToolbar,
} from "@ionic/react";
import LoadingContainer from "../../components/LoadingContainer";

const LoadingPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="">
          <IonButtons slot="start"></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingContainer
          title="VoyagerNetz Engage"
          message="Loading..."
          progress={0}
        />
      </IonContent>
    </IonPage>
  );
};

export default LoadingPage;
