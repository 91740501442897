import firebase from "firebase/app";
import { makeAutoObservable } from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { StandardResponseData, standardResponseDataConverter, standardResponseDefaultValue } from "./StandardResponseData";

const __cache: Record<string, StandardResponse> = {};

export class StandardResponse {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: StandardResponseData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;

  constructor(session: Session, path: string, data?: StandardResponseData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable(this);

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? standardResponseDefaultValue : undefined);
    this.mSubscriptionCount = 0;
  }

  static createCacheInstance(session: Session, path: string, data?: StandardResponseData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new StandardResponse(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: StandardResponse) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: StandardResponseData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        .firestore()
        .doc(this.path)
        .withConverter(standardResponseDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        .firestore()
        .doc(this.path)
        .withConverter(standardResponseDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<StandardResponseData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get category(): string | null | undefined {
    if (this.data) return this.data.category;
    return undefined;
  }
  public set category(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.category = newValue;
    }
  }

  public get isDeleted(): boolean | undefined {
    if (this.data) return this.data.isDeleted;
    return undefined;
  }
  public set isDeleted(newValue: boolean | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.isDeleted = newValue;
    }
  }

  public get name(): string | null | undefined {
    if (this.data) return this.data.name;
    return undefined;
  }
  public set name(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.name = newValue;
    }
  }

  public get tag(): string | null | undefined {
    if (this.data) return this.data.tag;
    return undefined;
  }
  public set tag(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.tag = newValue;
    }
  }

  public get text(): string | null | undefined {
    if (this.data) return this.data.text;
    return undefined;
  }
  public set text(newValue: string | null | undefined) {
    if (newValue !== undefined && this.data) {
      this.data.text = newValue;
    }
  }

  public save = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.data) {
        if (this.id == null) {
          this.session.firebase
            .firestore()
            .collection(this.mPath)
            .withConverter(standardResponseDataConverter(this.session))
            .add(this.data)
            .then((doc) => {
              this.path = doc.path;
              StandardResponse.addCacheInstance(this);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          this.session.firebase
            .firestore()
            .doc(this.mPath)
            .withConverter(standardResponseDataConverter(this.session))
            .set(this.data, { merge: true })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
        }
      } else {
        reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
      }
    });
  }
}