import firebase from "firebase/app";

import { action, IReactionDisposer, makeAutoObservable, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, when } from "mobx";

import { ConversationData, conversationDataConverter, conversationDefaultValue, ConversationRequestTransfer } from "./ConversationData";

import { ConversationHistoryEntries } from "../ConversationHistoryEntries";
import { Customer } from "../Customers";
import { InternalMessages } from "../InternalMessages";
import { Message, messageConverter, Messages } from "../Messages";
import { Notes } from "../Notes";
import { Participants } from "../Participants";
import { WorkerProfile } from "../WorkerProfiles";
import { WorkItem } from "../WorkItems";

import { ConversationOutcome, ConversationOutcomeData } from "../ConversationOutcomes";
import { Photo } from "@capacitor/camera";
import { CommunicationChannel, FileAttachment, MediaPayload } from "../common";
import { FileDatastore } from "../Files/FileDatastore";
import { getId, newId } from "../../utils/Helpers";
import { Session } from "../../session";
import { FacebookPage } from "../FacebookPages";
import { BroadcastMessages } from "../BroadcastMessages";
import { Group } from "../Groups";

const __cache: Record<string, Conversation> = {};

export class Conversation {
  private mSession: Session
  private mPath: string;
  private mId: string | null;
  private mData: ConversationData | undefined;
  private mSubscription: any;
  private mSubscriptionCount: number;
  private mConversationHistoryEntries: ConversationHistoryEntries | null | undefined;
  private mCustomer: Customer | null | undefined;
  private mCustomerDisposer: IReactionDisposer | null | undefined;
  private mOwner: WorkerProfile | null | undefined;
  private mOwnerDisposer: IReactionDisposer | null | undefined;
  private mGroup: Group | null | undefined;
  private mGroupDisposer: IReactionDisposer | null | undefined;
  private mTransferWorkerProfile: WorkerProfile | null | undefined;
  private mTransferWorkerProfileDisposer: IReactionDisposer | null | undefined;
  private mTransferGroup: Group | null | undefined;
  private mTransferGroupDisposer: IReactionDisposer | null | undefined;
  private mWorkItem: WorkItem | null | undefined;
  private mWorkItemDisposer: IReactionDisposer | null | undefined;
  private mMessages: Messages | null | undefined;
  private mInternalMessages: InternalMessages | null | undefined;
  private mBroadcastMessages: BroadcastMessages | null | undefined;
  private mNotes: Notes | null | undefined;
  private mFirstMessage: Message | null | undefined;
  private mFirstMessageSubscription: any;
  private mFirstMessageSubscriptionCount: number;
  private mLastMessage: Message | null | undefined;
  private mLastMessageSubscription: any;
  private mLastMessageSubscriptionCount: number;
  private mParticipants: Participants | null | undefined;
  private mCommunicationChannelFacebookPage: FacebookPage | null | undefined;
  private mCommunicationChannelFacebookPageDisposer: IReactionDisposer | null | undefined;

  constructor(session: Session, path: string, data?: ConversationData) {
    if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

    makeAutoObservable<Conversation, "mData">(
      this, {
      mData: observable,
      subscribeCustomer: false,
      unsubscribeCustomer: false,
      subscribeOwner: false,
      unsubscribeOwner: false,
      subscribeGroup: false,
      unsubscribeGroup: false,
      subscribeTransferGroup: false,
      unsubscribeTransferGroup: false,
      subscribeTransferWorkerProfile: false,
      unsubscribeTransferWorkerProfile: false,
      subscribeMessages: false,
      unsubscribeMessages: false,
      subscribeFirstMessage: false,
      unsubscribeFirstMessage: false,
      subscribeLastMessage: false,
      unsubscribeLastMessage: false,
    });

    this.mSession = session;
    this.mPath = path.trim();
    this.mId = getId(this.mPath);
    this.mData = data ? data : (this.mId == null ? conversationDefaultValue : undefined);
    this.mSubscriptionCount = 0;
    this.mFirstMessageSubscriptionCount = 0;
    this.mLastMessageSubscriptionCount = 0;

    onBecomeObserved(this, "conversationHistoryEntries", this.subscribeConversationHistoryEntries);
    onBecomeUnobserved(this, "conversationHistoryEntries", this.unsubscribeConversationHistoryEntries);
    onBecomeObserved(this, "customer", this.subscribeCustomer);
    onBecomeUnobserved(this, "customer", this.unsubscribeCustomer);
    onBecomeObserved(this, "owner", this.subscribeOwner);
    onBecomeUnobserved(this, "owner", this.unsubscribeOwner);
    onBecomeObserved(this, "group", this.subscribeGroup);
    onBecomeUnobserved(this, "group", this.unsubscribeGroup);
    onBecomeObserved(this, "transferGroup", this.subscribeTransferGroup);
    onBecomeUnobserved(this, "transferGroup", this.unsubscribeTransferGroup);
    onBecomeObserved(this, "transferWorkerProfile", this.subscribeTransferWorkerProfile);
    onBecomeUnobserved(this, "transferWorkerProfile", this.unsubscribeTransferWorkerProfile);
    onBecomeObserved(this, "workItem", this.subscribeWorkItem);
    onBecomeUnobserved(this, "workItem", this.unsubscribeWorkItem);
    onBecomeObserved(this, "messages", this.subscribeMessages);
    onBecomeUnobserved(this, "messages", this.unsubscribeMessages);
    onBecomeObserved(this, "internalMessages", this.subscribeInternalMessages);
    onBecomeUnobserved(this, "internalMessages", this.unsubscribeInternalMessages);
    onBecomeObserved(this, "notes", this.subscribeNotes);
    onBecomeUnobserved(this, "notes", this.unsubscribeNotes);
    onBecomeObserved(this, "firstMessage", this.subscribeFirstMessage);
    onBecomeUnobserved(this, "firstMessage", this.unsubscribeFirstMessage);
    onBecomeObserved(this, "lastMessage", this.subscribeLastMessage);
    onBecomeUnobserved(this, "lastMessage", this.unsubscribeLastMessage);
    onBecomeObserved(this, "participants", this.subscribeParticipants);
    onBecomeUnobserved(this, "participants", this.unsubscribeParticipants);
    onBecomeObserved(this, "communicationChannelFacebookPage", this.subscribeCommunicationChannelFacebookPage);
    onBecomeUnobserved(this, "communicationChannelFacebookPage", this.unsubscribeCommunicationChannelFacebookPage);
  }

  static createCacheInstance(session: Session, path: string, data?: ConversationData) {
    const cachePath = (path ?? '').trim();
    if (cachePath === '') throw new Error("Path cannot be empty");

    const id = getId(cachePath);
    if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

    let cacheInstance = __cache[cachePath];
    if (!cacheInstance) {
      cacheInstance = new Conversation(session, cachePath, data);
      __cache[cachePath] = cacheInstance;
    } else if (data !== undefined) {
      cacheInstance.data = data;
    }

    return cacheInstance;
  }

  static addCacheInstance(instance: Conversation) {
    if (instance.id == null) {
      throw new Error("Instance has not yet been saved to the datastore.");
    }

    let cacheInstance = __cache[instance.path];
    if (!cacheInstance) {
      __cache[instance.path] = instance;
    }
  }

  public get session() {
    return this.mSession;
  }

  public get id(): string | null {
    return this.mId;
  }
  private set id(newValue: string | null) {
    this.mId = newValue;
  }

  public get path() {
    return this.mPath;
  }
  private set path(newValue: string) {
    this.mPath = newValue.trim();
    this.id = getId(this.mPath);
  }

  public get data() {
    return this.mData;
  }
  protected set data(newValue: ConversationData | undefined) {
    this.mData = newValue;
  }

  public get loading() {
    return this.data === undefined;
  }

  public get loaded() {
    return this.data !== undefined;
  }

  public refetch() {
    if (!this.mSubscription) {
      this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationDataConverter(this.session))
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          this.data = data;
        });
    }
  }

  public subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.path}`);
      this.mSubscription = this.session.firebase
        ?.firestore()
        .doc(this.path)
        .withConverter(conversationDataConverter(this.session))
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<ConversationData>) => {
          const data = snapshot.data();
          this.data = data;
        }
        );
    }
  }

  public unsubscribe() {
    if (this.mSubscription && --this.mSubscriptionCount <= 0) {
      console.log(`unsubscribe from ${this.path}`);
      this.mSubscription();
      this.mSubscription = undefined;
      this.mSubscriptionCount = 0;
    }
  }

  public get createdOn(): Date | null | undefined {
    if (this.data) return this.data.createdOn?.toDate();
    return undefined;
  }

  public get externalReference(): string | null | undefined {
    if (this.data) return this.data.externalReference;
    return undefined;
  }

  public get status(): string | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  public get outcome(): ConversationOutcomeData | null | undefined {
    if (this.data) return this.data.outcome;
    return undefined;
  }

  public get requestTransfer(): ConversationRequestTransfer | null | undefined {
    if (this.data) return this.data.requestTransfer ? this.data.requestTransfer : null;
    return undefined;
  }

  public get serviceProvider(): CommunicationChannel | null | undefined {
    if (this.data) return this.data.communicationChannel;
    return undefined;
  }

  public get transferRequestedByWorkerProfileId(): string | null | undefined {
    if (this.data && this.data.requestTransfer) return this.data.requestTransfer.requestedBy;
    return undefined;
  }

  public get transferToWorkerProfileId(): string | null | undefined {
    if (this.data && this.data.requestTransfer) return this.data.requestTransfer.targetOwnerId;
    return undefined;
  }

  public get transferWorkerProfile(): WorkerProfile | null | undefined {
    return this.mTransferWorkerProfile;
  }

  public subscribeTransferWorkerProfile = () => {
    when(
      () => this.transferToWorkerProfileId !== undefined,
      () => {
        const initTransferWorkerProfile = () => {
          if (this.mTransferWorkerProfile && this.mTransferWorkerProfile.id !== this.transferToWorkerProfileId) {
            this.unsubscribeTransferWorkerProfile();
          }

          if (this.mTransferWorkerProfile === undefined && this.transferToWorkerProfileId !== undefined) {
            this.mTransferWorkerProfile = this.transferToWorkerProfileId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.transferToWorkerProfileId}`
              )
              : null;
            this.mTransferWorkerProfile?.subscribe();

            this.mTransferWorkerProfileDisposer = reaction(
              () => this.transferToWorkerProfileId,
              (transferToWorkerProfileId) => initTransferWorkerProfile()
            );
          }
        };

        initTransferWorkerProfile();
      }
    );
  };
  public unsubscribeTransferWorkerProfile = () => {
    if (this.mTransferWorkerProfileDisposer) this.mTransferWorkerProfileDisposer();
    this.mTransferWorkerProfile?.unsubscribe();
    this.mTransferWorkerProfile = undefined;
  };

  public get transferGroupId(): string | null | undefined {
    if (this.data && this.data.requestTransfer) return this.data.requestTransfer.targetGroupId;
    return undefined;
  }

  public get transferGroup(): Group | null | undefined {
    return this.mTransferGroup;
  }

  public subscribeTransferGroup = () => {
    when(
      () => this.transferGroupId !== undefined,
      () => {
        const initTransferGroup = () => {
          if (this.mTransferGroup && this.mTransferGroup.id !== this.transferGroupId) {
            this.unsubscribeTransferGroup();
          }

          if (this.mTransferGroup === undefined && this.transferGroupId !== undefined) {
            this.mTransferGroup = this.transferGroupId
              ? Group.createCacheInstance(
                this.session,
                `groups/${this.transferGroupId}`
              )
              : null;
            this.mTransferGroup?.subscribe();

            this.mTransferGroupDisposer = reaction(
              () => this.transferGroupId,
              (transferGroupId) => initTransferGroup()
            );
          }
        };

        initTransferGroup();
      }
    );
  };
  public unsubscribeTransferGroup = () => {
    if (this.mTransferGroupDisposer) this.mTransferGroupDisposer();
    this.mTransferGroup?.unsubscribe();
    this.mTransferGroup = undefined;
  };

  public get conversationHistoryEntries(): ConversationHistoryEntries | null | undefined {
    return this.mConversationHistoryEntries;
  }
  public subscribeConversationHistoryEntries = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mConversationHistoryEntries === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mConversationHistoryEntries = this.id
              ? new ConversationHistoryEntries(this.session, `conversations/${this.id}/history`)
              : null;
            if (this.mConversationHistoryEntries) {
              let query = this.mConversationHistoryEntries.collectionRef.orderBy("createdOn");
              this.mConversationHistoryEntries.query = query;
            }
            this.mConversationHistoryEntries?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeConversationHistoryEntries = () => {
    this.mConversationHistoryEntries?.unsubscribe();
    this.mConversationHistoryEntries = undefined;
  };

  public get customerId(): string | null | undefined {
    if (this.data) return this.data.customerId;
    return undefined;
  }

  public get customer(): Customer | null | undefined {
    return this.mCustomer;
  }
  public subscribeCustomer = () => {
    when(
      () => this.customerId !== undefined,
      () => {
        const initCustomer = () => {
          if (this.mCustomer && this.mCustomer.id != this.customerId) {
            this.unsubscribeCustomer();
          }

          if (this.mCustomer === undefined && this.customerId !== undefined) {
            this.mCustomer = this.customerId
              ? Customer.createCacheInstance(
                this.session,
                `customers/${this.customerId}`
              )
              : null;
            this.mCustomer?.subscribe();

            this.mCustomerDisposer = reaction(
              () => this.customerId,
              (customerId) => initCustomer()
            );
          }
        };

        initCustomer();
      }
    );
  };
  public unsubscribeCustomer = () => {
    if (this.mCustomerDisposer) this.mCustomerDisposer();
    this.mCustomer?.unsubscribe();
    this.mCustomer = undefined;
  };

  public get ownerId(): string | null | undefined {
    if (this.data) return this.data.ownerId;
    return undefined;
  }

  public get owner(): WorkerProfile | null | undefined {
    return this.mOwner;
  }

  public subscribeOwner = () => {
    when(
      () => this.ownerId != undefined,
      () => {
        const initOwner = () => {
          if (this.mOwner && this.mOwner.id != this.ownerId) {
            this.unsubscribeOwner();
          }

          if (this.mOwner == undefined && this.ownerId != undefined) {
            this.mOwner = this.ownerId
              ? WorkerProfile.createCacheInstance(
                this.session,
                `workerProfiles/${this.ownerId}`
              )
              : null;
            this.mOwner?.subscribe();

            this.mOwnerDisposer = reaction(
              () => this.ownerId,
              (ownerId) => initOwner()
            );
          }
        };

        initOwner();
      }
    );
  };
  public unsubscribeOwner = () => {
    if (this.mOwnerDisposer) this.mOwnerDisposer();
    this.mOwner?.unsubscribe();
    this.mOwner = undefined;
  };

  public get groupId(): string | null | undefined {
    if (this.data) return this.data.groupId;
    return undefined;
  }

  public get group(): Group | null | undefined {
    return this.mGroup;
  }

  public subscribeGroup = () => {
    when(
      () => this.groupId !== undefined,
      () => {
        const initGroup = () => {
          if (this.mGroup && this.mGroup.id !== this.groupId) {
            this.unsubscribeGroup();
          }

          if (this.mGroup === undefined && this.groupId !== undefined) {
            this.mGroup = this.groupId
              ? Group.createCacheInstance(
                this.session,
                `groups/${this.groupId}`
              )
              : null;
            this.mGroup?.subscribe();

            this.mGroupDisposer = reaction(
              () => this.groupId,
              (groupId) => initGroup()
            );
          }
        };

        initGroup();
      }
    );
  };
  public unsubscribeGroup = () => {
    if (this.mGroupDisposer) this.mGroupDisposer();
    this.mGroup?.unsubscribe();
    this.mGroup = undefined;
  };

  public get workItem(): WorkItem | null | undefined {
    return this.mWorkItem;
  }
  public subscribeWorkItem = () => {
    when(
      () => this.loaded && (this.session.currentUser?.workerProfile?.loaded ?? false),
      () => {
        const initWorkItem = () => {
          if (this.mWorkItem && (this.mWorkItem.conversationId !== this.id)) {
            this.unsubscribeWorkItem();
          }

          if (this.mWorkItem === undefined && this.id !== undefined) {
            this.mWorkItem = this.id
              ? WorkItem.createCacheInstance(
                this.session,
                `${this.session.currentUser?.workerProfile?.path}/${this.status === "active" ? "activeConversations" : "inactiveConversations"}/${this.id}`
              )
              : null;
            this.mWorkItem?.subscribe();

            this.mWorkItemDisposer = reaction(
              () => this.status,
              () => initWorkItem()
            );
          }
        };

        initWorkItem();
      }
    );
  };
  public unsubscribeWorkItem = () => {
    if (this.mWorkItemDisposer) this.mWorkItemDisposer();
    this.mWorkItem?.unsubscribe();
    this.mWorkItem = undefined;
  };

  public get isParticipant(): boolean | undefined {
    return (this.workItem === null || this.workItem === undefined) ? undefined : this.workItem.exists;
  }

  public get messages(): Messages | null | undefined {
    return this.mMessages;
  }
  public subscribeMessages = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mMessages === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mMessages = this.id
              ? new Messages(this.session, `conversations/${this.id}/messages`)
              : null;
            if (this.mMessages) {
              let query = this.mMessages.collectionRef.orderBy("createdOn");
              this.mMessages.query = query;
            }
            this.mMessages?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeMessages = () => {
    this.mMessages?.unsubscribe();
    this.mMessages = undefined;
  };

  public get internalMessages(): InternalMessages | null | undefined {
    return this.mInternalMessages;
  }
  public subscribeInternalMessages = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mInternalMessages === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mInternalMessages = new InternalMessages(
              this.session,
              `conversations/${this.id}/internalMessages`
            );

            let query = this.mInternalMessages.collectionRef.orderBy(
              "createdOn"
            );
            this.mInternalMessages.query = query;

            this.mInternalMessages?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeInternalMessages = () => {
    this.mInternalMessages?.unsubscribe();
    this.mInternalMessages = undefined;
  };

  public get broadcastMessages(): BroadcastMessages | null | undefined {
    when(
      () => this.customer?.loaded || false,
      () => {
        if (this.customer && this.customer.loaded && !this.mBroadcastMessages) {
          if (`${this.customer.mobileNumber ?? ""}`.trim() !== "") {
            this.broadcastMessages = new BroadcastMessages(this.session, this.customer.mobileNumber || "");
          } else {
            this.broadcastMessages = null;
          }
        }
      }
    )

    return this.mBroadcastMessages;
  }
  public set broadcastMessages(newValue: BroadcastMessages | null | undefined) {
    this.mBroadcastMessages = newValue;
  }

  public get notes(): Notes | null | undefined {
    return this.mNotes;
  }
  public subscribeNotes = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mNotes === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mNotes = new Notes(
              this.session,
              `conversations/${this.id}/notes`
            );

            let query = this.mNotes.collectionRef.orderBy(
              "createdOn"
            );
            this.mNotes.query = query;

            this.mNotes?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeNotes = () => {
    this.mNotes?.unsubscribe();
    this.mNotes = undefined;
  };

  public get firstMessage(): Message | null | undefined {
    return this.mFirstMessage;
  }
  public set firstMessage(newValue: Message | null | undefined) {
    this.mFirstMessage = newValue;
  }
  public subscribeFirstMessage = () => {
    when(
      () => this.id !== undefined,
      () => {
        ++this.mFirstMessageSubscriptionCount;
        if (this.firstMessage === undefined && this.id !== undefined) {
          console.log(
            `fetch conversations/${this.id}/messages/firstMessage`
          );

          this.session.firebase
            .firestore()
            .collection(`conversations/${this.id}/messages`)
            .orderBy("createdOn", "asc")
            .limit(1)
            .withConverter(messageConverter(this.session))
            .get()
            .then(
              action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
                const data = snapshot.docs.map((doc) => doc.data());
                this.firstMessage = data[0] ?? null;
              })
            );

          this.mFirstMessageSubscription = () => { };
        }
      }
    );
  };
  public unsubscribeFirstMessage = () => {
    runInAction(() => {
      if (
        this.mFirstMessageSubscription &&
        --this.mFirstMessageSubscriptionCount <= 0
      ) {
        console.log(
          `unsubscribe from conversations/${this.id}/messages/firstMessage`
        );
        this.mFirstMessageSubscription();
        //this.mFirstMessage = undefined;
        this.mFirstMessageSubscriptionCount = 0;
      }
    });
  };


  public get lastMessage(): Message | null | undefined {
    return this.mLastMessage;
  }
  public subscribeLastMessage = () => {
    when(
      () => this.id !== undefined,
      () => {
        ++this.mLastMessageSubscriptionCount;
        if (this.mLastMessage === undefined && this.id !== undefined) {
          console.log(
            `subscribe to conversations/${this.id}/messages/lastMessage`
          );
          this.mLastMessageSubscription = this.session.firebase
            .firestore()
            .collection(`conversations/${this.id}/messages`)
            .where("type", "in", ["agent", "customer"])
            .orderBy("createdOn", "desc")
            .limit(1)
            .withConverter(messageConverter(this.session))
            .onSnapshot(
              action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
                const data = snapshot.docs.map((doc) => doc.data());
                this.mLastMessage = data[0] ?? null;
              })
            );
        }
      }
    );
  };
  public unsubscribeLastMessage = () => {
    runInAction(() => {
      if (
        this.mLastMessageSubscription &&
        --this.mLastMessageSubscriptionCount <= 0
      ) {
        console.log(
          `unsubscribe from conversations/${this.id}/messages/lastMessage`
        );
        this.mLastMessageSubscription();
        this.mLastMessage = undefined;
        this.mLastMessageSubscriptionCount = 0;
      }
    });
  };

  public get participants(): Participants | null | undefined {
    return this.mParticipants;
  }
  public subscribeParticipants = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mParticipants === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mParticipants = this.id
              ? new Participants(this.session, `conversations/${this.id}/participants`)
              : null;
            /*
            if (this.mParticipants) {
              let query = this.mParticipants.collectionRef.orderBy("createdOn");
              this.mParticipants.query = query;
            }
            */
            this.mParticipants?.subscribe();
          });
        }
      }
    );
  };
  public unsubscribeParticipants = () => {
    this.mParticipants?.unsubscribe();
    this.mParticipants = undefined;
  };

  public get communicationChannelFacebookPage(): FacebookPage | null | undefined {
    return this.mCommunicationChannelFacebookPage;
  }
  private set communicationChannelFacebookPage(newValue: FacebookPage | null | undefined) {
    this.mCommunicationChannelFacebookPage = newValue;
  }
  public subscribeCommunicationChannelFacebookPage = () => {
    when(
      () => this.loaded && (typeof (this.data?.communicationChannel?.facebookId) === "string" ?? false),
      () => {
        const initCommunicationChannelFacebookPage = () => {
          if (this.communicationChannelFacebookPage && (this.communicationChannelFacebookPage.id !== this.data?.communicationChannel?.facebookId)) {
            this.unsubscribeCommunicationChannelFacebookPage();
          }


          if (this.communicationChannelFacebookPage === undefined && typeof (this.data?.communicationChannel?.facebookId) === "string") {
            this.communicationChannelFacebookPage = this.id
              ? FacebookPage.createCacheInstance(
                this.session,
                `communication/channels/facebookPages/${this.data?.communicationChannel?.facebookId}`
              )
              : null;
            this.communicationChannelFacebookPage?.subscribe();

            this.mCommunicationChannelFacebookPageDisposer = reaction(
              () => this.data?.communicationChannel?.facebookId,
              () => initCommunicationChannelFacebookPage()
            );
          }
        };

        initCommunicationChannelFacebookPage();
      }
    );
  };
  public unsubscribeCommunicationChannelFacebookPage = () => {
    if (this.mCommunicationChannelFacebookPageDisposer) this.mCommunicationChannelFacebookPageDisposer();
    this.mCommunicationChannelFacebookPage?.unsubscribe();
    this.mCommunicationChannelFacebookPage = undefined;
  };

  public update = (conversationData: { externalReference?: string }) => {
    return new Promise((resolve, reject) => {
      this.session.firebase
        .firestore()
        .doc(this.mPath)
        .update(conversationData)
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public acceptConversation() {
    return new Promise<void>((resolve, reject) => {
      when(
        () => (this.session.currentUser && this.session.currentUser?.loaded) ?? false,
        async () => {
          if (this.session.currentUser && this.session.currentUser.workerProfileId && (this.status === "new" || (this.status === "active" && this.requestTransfer !== null))) {
            try {
              await this.session.firebase.firestore()
                .doc(this.path)
                .update({ 
                  ownerId: this.session.currentUser.workerProfileId, 
                  status: 'active', 
                  requestTransfer: null,
                  ...(typeof(this.requestTransfer?.targetGroupId) === "string" ? { groupId: this.requestTransfer?.targetGroupId } : {}),
                });
              await this.session.firebase.firestore()
                .doc(`${this.path}/participants/${this.session.currentUser.workerProfileId}`)
                .set({}, { merge: true });
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        }
      );
    });
  }


  public closeConversation(outcome: ConversationOutcome) {
    return new Promise<void>((resolve, reject) => {
      this.session.firebase
        .firestore()
        .doc(this.mPath)
        .update({
          outcome: { id: outcome.id, ...outcome.data },
          status: 'inactive',
          requestTransfer: null
        })
        .then(() => resolve(undefined))
    });
  }

  public sendMessage(messageBody: string, attachmentName?: string, attachmentDataUrl?: string, attachmentType?: string) {
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.lastMessage?.loaded ?? false,
        () => {
          const media = [] as MediaPayload[];

          if (attachmentDataUrl && attachmentType) {
            let base64String = attachmentDataUrl.replace(/^data:(.*,)?/, "");
            if (base64String.length % 4 > 0) {
              base64String += "=".repeat(4 - (base64String.length % 4));
            }

            media.push({
              name: attachmentName ?? "attachment",
              type: attachmentType,
              payload: base64String
            });
          }

          const envelope = {
            conversationId: this.id,
            message: {
              body: messageBody,
              media: media,
            },
            provider: {
              channel: this.lastMessage?.communicationChannel?.channel,
              name: this.lastMessage?.communicationChannel?.provider,
              facebookId: this.lastMessage?.communicationChannel?.facebookId,
              mobileNumber: this.lastMessage?.communicationChannel?.mobileNumber,
            }
          };

          this.session.firebase
            .functions()
            .httpsCallable("sendMessage")(envelope)
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      );
    });
  }

  public sendInternalMessage(messageBody: string) {
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.lastMessage?.loaded ?? false,
        () => {
          const internalMessage = {
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            body: messageBody,
            senderId: this.session.currentUser!.workerProfileId!,
          };

          /*this.internalMessages?.data?.push(
            new InternalMessage("", {
              id: "",
              body: messageBody,
              createdOn: firebase.firestore.Timestamp.now(),
              status: "pending",
              senderId: session.currentUser!.workerProfileId!,
            })
          );*/

          this.session.firebase
            .firestore()
            .collection(`conversations/${this.id}/internalMessages`)
            .add(internalMessage)
            .then(() => resolve(undefined))
        }
      );
    });
  }

  public addNote(noteBody: string, attachments?: { photos?: Photo[], files?: FileAttachment[] }) {
    return new Promise<void>((resolve: () => void, reject: (error: any) => void) => {
      when(
        () => (this.id != null ?? false) && (this.session.currentUser?.loaded ?? false),
        async () => {
          try {
            const noteId = newId();
            if (this.session.currentUser && this.session.currentUser.loaded) {
              let media: Record<string, { fileName: string, contentType: string, fullPath: string }> = {};

              const photos = attachments?.photos;
              if (photos && photos.length > 0) {
                const photoResults = await Promise.all(photos.map((photo, index) =>
                  new Promise((resolvePhoto: (result: { fileName: string, contentType: string, fullPath: string }) => void, rejectPhoto: () => void) => {
                    const fileName = newId();
                    FileDatastore.create(
                      this.session,
                      `conversations/${this.id}/notes/${noteId}/${fileName}.${photo.format}`,
                      photo.dataUrl!,
                      `image/${photo.format}`
                    ).then((path) => resolvePhoto({
                      fileName: fileName,
                      contentType: `image/${photo.format}`,
                      fullPath: path
                    })).catch((error) => reject(error));
                  })
                ));
                photoResults.forEach((result) => media[result.fileName] = result);
              }

              const files = attachments?.files;
              if (files && files.length > 0) {
                const fileResults = await Promise.all(files.map((file, index) =>
                  new Promise((resolveFile: (result: { fileName: string, contentType: string, fullPath: string }) => void, rejectPhoto: () => void) => {
                    const fileName = file.file.name;
                    FileDatastore.create(
                      this.session,
                      `conversations/${this.id}/notes/${noteId}/${fileName}`,
                      file.dataUrl,
                      file.file.type
                    ).then((path) => resolveFile({
                      fileName: fileName,
                      contentType: file.file.type,
                      fullPath: path
                    })).catch((error) => reject(error));
                  })
                ));
                fileResults.forEach((result) => media[result.fileName] = result);
              }

              await this.session.firebase
                .firestore()
                .collection(`conversations/${this.id}/notes`)
                .doc(noteId)
                .set({
                  createdOn: firebase.firestore.FieldValue.serverTimestamp(),
                  body: noteBody,
                  workerProfileId: this.session.currentUser.workerProfileId,
                  media
                });

              resolve();
            }
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  }

  public addParticipant(workerProfileId: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.ownerId === this.session.currentUser?.workerProfileId || this.session.currentUser?.role === "admin") {
        this.session.firebase
          .firestore()
          .doc(`${this.mPath}/participants/${workerProfileId}`)
          .set({})
          .then(() => resolve(undefined))
      }
      else {
        reject("You are not the owner")
      }
    });
  }

  public removeParticipant(workerProfileId: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.ownerId === this.session.currentUser?.workerProfileId || this.session.currentUser?.role === "admin") {
        this.session.firebase
          .firestore()
          .doc(`conversations/${this.id}/participants/${workerProfileId}`)
          .delete()
          .then(() => resolve(undefined))
      }
      else {
        reject("You are not the owner")
      }
    });
  }

  public transferToParticipant(workerProfileId: string, reason: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.ownerId === this.session.currentUser?.workerProfileId || this.session.currentUser?.role === "admin") {
        const requestTransfer = {
          requestedBy: this.session.currentUser?.workerProfileId,
          requestedOn: firebase.firestore.FieldValue.serverTimestamp(),
          targetOwnerId: workerProfileId,
          description: reason,
          isDeclined: false
        };

        this.session.firebase
          .firestore()
          .doc(`conversations/${this.id}`)
          .update({ requestTransfer })
          .then(() => {
            resolve(undefined)
          })
          .catch((error) => reject(error))
      }
    });
  }

  public transferToGroup(groupId: string, reason: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.ownerId === this.session.currentUser?.workerProfileId || this.session.currentUser?.role === "admin") {
        const requestTransfer = {
          requestedBy: this.session.currentUser?.workerProfileId,
          requestedOn: firebase.firestore.FieldValue.serverTimestamp(),
          targetGroupId: groupId,
          description: reason,
          isDeclined: false
        };

        this.session.firebase
          .firestore()
          .doc(`conversations/${this.id}`)
          .update({ requestTransfer })
          .then(() => {
            resolve(undefined)
          })
          .catch((error) => reject(error))
      }
    });
  }

  public cancelTransfer() {
    return new Promise<void>((resolve, reject) => {
      if (this.ownerId === this.session.currentUser?.workerProfileId || this.session.currentUser?.role === "admin") {
        this.session.firebase
          .firestore()
          .doc(`conversations/${this.id}`)
          .update({ requestTransfer: null })
          .then(() => {
            resolve(undefined)
          })
          .catch((error) => reject(error))
      }
    });
  }

  public acceptIncomingTransfer() {
    return new Promise<void>((resolve, reject) => {
      if (
        this.requestTransfer 
        && (
          this.requestTransfer.targetOwnerId === this.session.currentUser?.workerProfileId 
          || (this.requestTransfer.targetGroupId && this.session.currentUser?.groupIds?.includes(this.requestTransfer.targetGroupId))
        )
      ) {
        const acceptIncomingTransfer = this.session.firebase
          .functions()
          .httpsCallable("acceptIncomingConversation");
        acceptIncomingTransfer({ conversationId: this.id })
          .then(() => resolve(undefined))
          .catch((error) => reject(error));
      }
    });
  }

  public declineIncomingTransfer() {
    return new Promise<void>((resolve, reject) => {
      if (this.requestTransfer && this.requestTransfer.targetOwnerId === this.session.currentUser?.workerProfileId) {
        this.session.firebase
          .firestore()
          .doc(`conversations/${this.id}`)
          .update('requestTransfer.isDeclined', true)
          .then(() => {
            resolve(undefined)
          })
          .catch((error) => reject(error));
      }
    });
  }
}