import firebase from "firebase/app";

import { makeAutoObservable } from "mobx";
import { Session } from "../../session";

import { UserGroup } from "./UserGroup";
import { userGroupConverter } from "./UserGroupData";

export class UserGroups {
    private mSession: Session;
    private mCollectionRef: firebase.firestore.CollectionReference<UserGroup>;
    private mQuery: firebase.firestore.Query<UserGroup> | null;
    private mPath: string;
    private mData: UserGroup[] | null | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;

    constructor(session: Session, path: string) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;

        this.mCollectionRef = this.session.firebase
            .firestore()
            .collection(path)
            .withConverter(userGroupConverter(session));

        this.mQuery = null;
        this.mPath = path;
        this.mSubscriptionCount = 0;
    }

    public get session() {
        return this.mSession;
    }

    public get collectionRef() {
        return this.mCollectionRef;
    }

    public get query() {
        return this.mQuery;
    }
    public set query(newValue: firebase.firestore.Query<UserGroup> | null) {
        this.mQuery = newValue;

        if (this.mSubscription) {
            this.mSubscription();
            this.mSubscription = (this.mQuery ?? this.collectionRef)
                .onSnapshot(
                    {
                        includeMetadataChanges: true
                    },
                    (snapshot: firebase.firestore.QuerySnapshot<UserGroup>) => {
                        if (!snapshot.metadata.fromCache && !snapshot.metadata.hasPendingWrites) {
                            const data = snapshot.docs.map((doc) => doc.data());
                            this.data = data;
                        }
                    }
                );
        }
    }

    public get path() {
        return this.mPath;
    }

    public get data() {
        //if (this.mData) return this.mData.filter((item) => item.data?.isDeleted !== true ?? true);
        return this.mData;
    }
    protected set data(newValue: UserGroup[] | null | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public refetch() {
        if (!this.mSubscription) {
            (this.query ?? this.collectionRef).get()
                .then((snapshot: firebase.firestore.QuerySnapshot<UserGroup>) => {
                    const data = snapshot.docs.map((doc) => doc.data());
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.mPath}`);
            this.mSubscription = (this.query ?? this.collectionRef)
                .onSnapshot(
                    {
                        includeMetadataChanges: true
                    },
                    (snapshot: firebase.firestore.QuerySnapshot<UserGroup>) => {
                        if (!snapshot.metadata.fromCache && !snapshot.metadata.hasPendingWrites) {
                            const data = snapshot.docs
                                .filter(doc => doc.get('isDeleted') !== true)
                                .map((doc) => doc.data());
                            this.data = data;
                        }
                    }
                );
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.mPath}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }
}
