import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  IonList,
  IonButton,
  IonInput,
  IonTitle,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
  IonText,
  IonChip,
  IonSpinner,
  IonNote,
} from "@ionic/react";

import {
  close,
  closeCircleOutline,
  checkmarkCircleOutline,
  checkmarkOutline,
  trashBin,
  closeOutline,
  trash,
  unlink,
  link,
} from "ionicons/icons";

import * as Models from "../../../models";
import { Session } from "../../../session";

interface FacebookPageDetailProps {
  session: Session;
  facebookPage: Models.FacebookPage;
  onDismiss: () => void;
}

const FacebookPageDetail: React.FC<FacebookPageDetailProps> = observer(
  ({ session, facebookPage, onDismiss }) => {
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    const { control, handleSubmit, formState, reset, errors } = useForm({
      mode: "onChange",
    });

    const groups = session.groups?.data;

    const showError = (_fieldName: string) => {
      let error = (errors as any)[_fieldName];
      return error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    const onDisconnect = () => {
      const disconnectFacebookPage = session.firebase
        .functions()
        .httpsCallable("disconnectFacebookPage");

      disconnectFacebookPage({
        pageId: facebookPage.pageId,
        instanceId: session.instanceId,
      })
        .then((response) => {
          if (response.data?.isError) {
            alert(`Failed to unlink Facebook Page.`);
          }
        })
        .catch((error) => {
          alert(`Failed to unlink Facebook Page.`);
        })
        .finally(() => setIsDisconnecting(false));
    };

    const onSubmit = async (data: any) => {
      if (formState.isValid && facebookPage) {
        facebookPage.groupId = data.groupId;

        try {
          await facebookPage.save();
          onDismiss();
        } catch (error) {
          alert("Unable to save Facebook Page.");
          console.log("Error saving Facebook Page.", error);
        }
      }
    };

    if (!facebookPage) {
      return <IonSpinner color="light" style={{ margin: "auto" }} />;
    }

    return (
      <IonPage>
        <IonHeader
          translucent
        //className="ion-no-border"
        >
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              <IonButton onClick={() => onDismiss()}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Facebook Page</IonTitle>
            <IonButtons slot="end">
              {isDisconnecting ? (
                <IonChip color="light" style={{ height: "40px" }}>
                  <IonIcon icon={unlink} title="Unlink" />
                  <IonButton
                    onClick={(_) => setIsDisconnecting(false)}
                    color="light"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={closeOutline} title="Cancel" />
                  </IonButton>
                  <IonButton
                    onClick={onDisconnect}
                    color="danger"
                    fill="solid"
                    shape="round"
                  >
                    <IonIcon icon={checkmarkOutline} title="Confirm" />
                  </IonButton>
                </IonChip>
              ) : (
                <>
                  {facebookPage.id ? (
                    <IonButton
                      onClick={(_) => setIsDisconnecting(true)}
                      color="light"
                      expand="block"
                      fill="solid"
                      shape="round"
                      size="large"
                      hidden={facebookPage.status === "Unlinked"}
                    >
                      <IonIcon slot="start" icon={unlink} title="Unlink" />
                      Unlink
                    </IonButton>
                  ) : (
                    <></>
                  )}
                  <IonButton
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !formState.isValid ||
                      formState.isSubmitting ||
                      !formState.isDirty
                    }
                    shape="round"
                    expand="block"
                    size="large"
                    fill="solid"
                    color="light"
                    hidden
                  >
                    <IonIcon hidden icon={link} />
                    {formState.isSubmitting ? "Relinking..." : "Relink"}
                  </IonButton>
                </>
              )}
              <IonButton
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !formState.isValid ||
                  formState.isSubmitting ||
                  !formState.isDirty
                }
                color="light"
                expand="block"
                fill="solid"
                shape="round"
                size="large"
              >
                <IonIcon hidden icon={checkmarkOutline} />
                {formState.isSubmitting ? "Saving..." : "Save"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen color="light">
          {facebookPage ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList lines="none" className="ion-no-padding">
                <IonListHeader
                  hidden
                  color="light"
                  style={{ paddingTop: "5px" }}
                >
                  BASIC DETAILS
                </IonListHeader>
                <IonItem key="name" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Name
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={facebookPage.name}
                    name="name"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                      validate: (data) => {
                        const searchString = data.toLowerCase().trim();
                        const found =
                          session.facebookPages?.data?.filter(
                            (existing) =>
                              existing.id !== facebookPage.id &&
                              existing.name?.toLowerCase().trim() ===
                              searchString
                          ) ?? [];
                        return found.length === 0
                          ? true
                          : "Name must be unique";
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        disabled
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Customer Reference"
                      ></IonInput>
                    )}
                  />

                  {showError("name")}
                </IonItem>
                <IonItem key="category" lines="none">
                  <IonLabel position="stacked" color="medium">
                    Category
                  </IonLabel>
                  <Controller
                    control={control}
                    defaultValue={facebookPage.category}
                    name="category"
                    rules={{
                      required: true,
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        className="input-field"
                        disabled
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                      //placeholder="Customer Reference"
                      ></IonInput>
                    )}
                  />

                  {showError("category")}
                </IonItem>
                <IonItem className="default-element-border" lines="none">
                  <IonLabel position="stacked" color="medium">Group</IonLabel>
                  <Controller
                    control={control}
                    defaultValue={facebookPage.groupId}
                    name="groupId"
                    rules={{
                      required: "A group for the Facebook Page is required.",
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonSelect
                        className="input-field"
                        value={value}
                        onIonChange={onChange}
                        onIonBlur={onBlur}
                        interfaceOptions={{
                          cssClass: "select-alert"
                        }}
                      >
                        {groups && groups.length > 0 && groups.map(group => {
                          return (
                            <IonSelectOption
                              key={group.id}
                              value={group.id}
                            >
                              {group.name}
                            </IonSelectOption>
                          )
                        })}
                      </IonSelect>
                    )}
                  />
                  {showError("group")}
                </IonItem>
                <IonItem
                  key="status"
                  lines="none"
                  style={{ marginTop: "15px" }}
                  className="ion-padding-bottom"
                >
                  <IonLabel position="stacked" color="medium">
                    Status
                  </IonLabel>
                  <IonInput
                    className="input-field"
                    disabled
                    value={facebookPage.status}
                  //placeholder="Customer Reference"
                  ></IonInput>
                  {showError("status")}
                </IonItem>
              </IonList>
            </form>
          ) : (
            <div>Facebook Page not found</div>
          )}
        </IonContent>
      </IonPage>
    );
  }
);

export default FacebookPageDetail;
