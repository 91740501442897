import { IonImg, IonProgressBar, IonTitle } from "@ionic/react";

interface ContainerProps {
  title: string;
  message: string;
  progress?: number;
}

const LoadingContainer: React.FC<ContainerProps> = ({
  title,
  message,
  progress,
}) => {
  return (
    <>
      <IonImg
        src="/apple-touch-icon.png"
        style={{
          minWidth: "80px",
          maxWidth: "80px",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      />
      <IonTitle style={{ textAlign: "center" }}>{title}</IonTitle>
      <p style={{ textAlign: "center" }}>{message}</p>
      {typeof progress !== "undefined" && (
        <IonProgressBar
          //color="light"
          style={{
            marginTop: "13px",
            width: "200px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          type={progress == 0 ? "indeterminate" : "determinate"}
          value={progress / 100}
        />
      )}
    </>
  );
};

export default LoadingContainer;
