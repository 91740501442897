import firebase from "firebase/app";

import {
    makeAutoObservable,
    action,
    onBecomeObserved,
    onBecomeUnobserved,
    when,
    runInAction,
    IReactionDisposer,
    reaction,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { Files } from "../Files";
import { WorkerProfile } from "../WorkerProfiles";
import { NoteData, noteDataConverter, noteDefaultValue } from "./NoteData";

const __cache: Record<string, Note> = {};

export class Note {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: NoteData | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;
    private mWorkerProfile: WorkerProfile | null | undefined;
    private mWorkerProfileDisposer: IReactionDisposer | null | undefined;
    private mFiles: Files | null | undefined;

    constructor(session: Session, path: string, data?: NoteData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = getId(this.mPath);
        this.mData = data ? data : (this.mId == null ? noteDefaultValue : undefined);
        this.mSubscriptionCount = 0;

        onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
        onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
        onBecomeObserved(this, "files", this.subscribeFiles);
        onBecomeUnobserved(this, "files", this.unsubscribeFiles);
    }

    static createCacheInstance(session: Session, path: string, data?: NoteData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        const id = getId(cachePath);
        if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new Note(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: Note) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = getId(this.mPath);
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: NoteData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public refetch() {
        if (!this.mSubscription) {
            this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(noteDataConverter(this.session))
                .get()
                .then((snapshot) => {
                    const data = snapshot.data();
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.path}`);
            this.mSubscription = this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(noteDataConverter(this.session))
                .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<NoteData>) => {
                    const data = snapshot.data();
                    this.data = data;
                }
                );
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.path}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    public save = () => {
        return new Promise<void>((resolve, reject) => {
            if (this.data) {
                if (this.id == null) {
                    this.session.firebase
                        ?.firestore()
                        .collection(this.mPath)
                        .withConverter(noteDataConverter(this.session))
                        .add(this.data).then((doc) => {
                            this.path = doc.path;
                            Note.addCacheInstance(this);
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                } else {
                    this.session.firebase
                        ?.firestore()
                        .doc(this.mPath)
                        .withConverter(noteDataConverter(this.session))
                        .set(this.data, { merge: true }).then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
            } else {
                reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
            }
        });
    }

    public get body(): string | null | undefined {
        if (this.data) return this.data.body;
        return undefined;
    }

    public get createdOn(): Date | null | undefined {
        if (this.data) return this.data.createdOn?.toDate();
        return undefined;
    }

    public get workerProfileId(): string | null | undefined {
        if (this.data) return this.data.workerProfileId;
        return undefined;
    }

    public get workerProfile(): WorkerProfile | null | undefined {
        return this.mWorkerProfile;
    }
    public subscribeWorkerProfile = () => {
        when(
            () => this.workerProfileId !== undefined,
            () => {
                const initWorkerProfile = () => {
                    if (this.mWorkerProfile && this.mWorkerProfile.id !== this.workerProfileId) {
                        this.mWorkerProfile?.unsubscribe();
                        this.mWorkerProfile = undefined;
                    }

                    if (this.mWorkerProfile === undefined && this.workerProfileId !== undefined) {
                        this.mWorkerProfile = this.workerProfileId
                            ? WorkerProfile.createCacheInstance(
                                this.session,
                                `workerProfiles/${this.workerProfileId}`
                            )
                            : null;
                        this.mWorkerProfile?.subscribe();

                        this.mWorkerProfileDisposer = reaction(
                            () => this.workerProfileId,
                            (workerProfileId) => initWorkerProfile()
                        );
                    }
                };

                initWorkerProfile();
            }
        );
    };
    public unsubscribeWorkerProfile = () => {
        if (this.mWorkerProfileDisposer) this.mWorkerProfileDisposer();
        this.mWorkerProfile?.unsubscribe();
        this.mWorkerProfile = undefined;
    };

    public get files(): Files | null | undefined {
        return this.mFiles;
    }
    public subscribeFiles = () => {
        when(
            () => this.id !== undefined,
            () => {
                if (this.mFiles === undefined && this.id !== undefined) {
                    runInAction(() => {
                        this.mFiles = this.id
                            ? new Files(this.session, `${this.path}`)
                            : null;
                        this.mFiles?.subscribe();
                    });
                }
            }
        );
    };
    public unsubscribeFiles = () => {
        this.mFiles?.unsubscribe();
        this.mFiles = undefined;
    };
}