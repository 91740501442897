import { useContext, useState } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  IonList,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";

import { Customer } from "../../models/Customers";
import { SessionContext } from "../../session";

const CustomerPage = observer(() => {
  const [customer, setCustomer] = useState<Customer>();
  const params = useParams<{ id: string }>();

  const session = useContext(SessionContext);

  useIonViewWillEnter(() => {
    if (session) {
      const customer = Customer.createCacheInstance(session, `customers/${params.id}`);
      customer.subscribe();
      setCustomer(customer);
    }
  });

  if (!session) {
    return <></>;
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons>
            <IonBackButton
              text="Customers"
              defaultHref="/customers"
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonItem lines="none">
            <IonLabel className="ion-text-center">
              <IonIcon
                style={{ height: "100px", width: "100px" }}
                icon={personCircle}
                color="primary"
              ></IonIcon>
              <h2>
                {customer?.displayName}
              </h2>
            </IonLabel>
          </IonItem>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {customer ? (
          <IonList lines="none">
            <IonItem key="firstName">
              <IonLabel className="ion-text-wrap">
                first name
                <h2>{customer?.firstName}</h2>
              </IonLabel>
            </IonItem>
            <IonItem key="lastName">
              <IonLabel className="ion-text-wrap">
                last name
                <h2>{customer?.lastName}</h2>
              </IonLabel>
            </IonItem>
            <IonItem key="mobile">
              <IonLabel className="ion-text-wrap">
                mobile
                <h2>{customer?.mobileNumber}</h2>
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          <div>Customer not found</div>
        )}
      </IonContent>
    </IonPage>
  );
});

export default CustomerPage;
