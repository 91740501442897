import React, { useContext } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

import {
  chatbubbles,
  personCircle,
  settings,
  statsChart,
} from "ionicons/icons";

import AdminPage from "./Admin/AdminPage";
import ConversationOutcomeCategoriesPage from "./ConversationOutcomeCategories/ConversationOutcomeCategoriesPage";
import ConversationOutcomesPage from "./ConversationOutcomes/ConversationOutcomesPage";
import ConversationPage from "./Conversation/ConversationPage";
import ConversationsPage from "./Conversations/ConversationsPage";
import ConversationsSettingsPage from "./ConversationsSettings/ConversationsSettingsPage";
import CustomerPage from "./Customer/CustomerPage";
import CustomersPage from "./Customers/CustomersPage";
import DashboardsPage from "./Dashboards/DashboardsPage";
import FacebookPagesPage from "./FacebookPages/FacebookPagesPage";
import InfocenterPage from "./Infocenter/InfocenterPage";
import ProfilePage from "./Profile/ProfilePage";
import StandardResponseCategoriesPage from "./StandardResponseCategories/StandardResponseCategoriesPage";
import StandardResponsesPage from "./StandardResponses/StandardResponsesPage";
import UsersPage from "./Users/UsersPage";
import GroupsPage from "./Groups/GroupsPage";
import PhoneNumbersPage from "./PhoneNumbers/PhoneNumbersPage";

import UnauthenticatedPage from "./Auth/Unauthenticated";
import { SessionContext } from "../session";
import ProtectedRoute from "../utils/ProtectedRoute";
import UsageDashboardPage from "./Dashboards/pages/UsageDashboard/UsageDashboardPage";
import WorkspaceDashboardPage from "./Dashboards/pages/WorkspaceDashboard/WorkspaceDashboardPage";
import LoadingPage from "./common/LoadingPage";
import {
  NoAdminSectionSelectedEmptyPage,
  NoConversationSelectedEmptyPage,
  NoDashboardSelectedEmptyPage,
  NoProfileDetailsEmptyPage,
} from "./common/EmptyPage";
import ExportCompletedConversationsPage from "./Export/ExportCompletedConversationsPage";

const Main: React.FC = observer(() => {
  const location = useLocation();

  const session = useContext(SessionContext);
  if (!session || !session.currentUser) {
    return <></>;
  }

  if (session.windowSize === "small") {
    return (
      <IonTabs>
        <IonRouterOutlet>
          <Redirect exact path="/" to="/conversations" />

          <Route exact path="/customers" component={CustomersPage} />
          <Route exact path="/customers/:id" component={CustomerPage} />
          <Route exact path="/conversations" component={ConversationsPage} />
          <Route exact path="/conversations/:id" component={ConversationPage} />
          <ProtectedRoute
            exact
            path="/dashboards"
            component={DashboardsPage}
            fallbackComponent={UnauthenticatedPage}
            roles={["admin"]}
            loadingComponent={LoadingPage}
          />
          <ProtectedRoute
            exact
            path="/dashboards/workspace"
            component={WorkspaceDashboardPage}
            fallbackComponent={UnauthenticatedPage}
            roles={["admin"]}
            loadingComponent={LoadingPage}
          />
          <ProtectedRoute
            exact
            path="/dashboards/usage"
            component={UsageDashboardPage}
            fallbackComponent={UnauthenticatedPage}
            roles={["admin"]}
          />
          <Route exact path="/infocenter" component={InfocenterPage} />
          <Route exact path="/profile" component={ProfilePage} />

          <ProtectedRoute
            exact
            path="/admin"
            component={AdminPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/settings"
            component={ConversationsSettingsPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/users"
            component={UsersPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/groups"
            component={GroupsPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/channels/phoneNumbers"
            component={PhoneNumbersPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/outcomes"
            component={ConversationOutcomesPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/outcomecategories"
            component={ConversationOutcomeCategoriesPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/responses"
            component={StandardResponsesPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/responsecategories"
            component={StandardResponseCategoriesPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/channels/facebookPages"
            component={FacebookPagesPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin/exportCompletedConversations"
            component={ExportCompletedConversationsPage}
            fallbackComponent={UnauthenticatedPage}
            loadingComponent={LoadingPage}
            roles={["admin"]}
          />
        </IonRouterOutlet>
        <IonTabBar
          color="primary"
          slot="bottom"
          hidden={
            !(
              location?.pathname.toLowerCase() === "/customers" ||
              location?.pathname.toLowerCase() === "/conversations" ||
              location?.pathname.toLowerCase() === "/dashboards" ||
              location?.pathname.toLowerCase() === "/infocenter" ||
              location?.pathname.toLowerCase() === "/profile" ||
              location?.pathname.toLowerCase() === "/admin"
            )
          }
        >
          <IonTabButton tab="conversations" href="/conversations">
            <IonIcon icon={chatbubbles} />
            <IonLabel>Conversations</IonLabel>
          </IonTabButton>
          {session.currentUser?.role === "admin" ? (
            <IonTabButton tab="dashboards" href="/dashboards">
              <IonIcon icon={statsChart} />
              <IonLabel>Dashboards</IonLabel>
            </IonTabButton>
          ) : (
            <></>
          )}
          {session.currentUser?.role === "admin" ? (
            <IonTabButton tab="admin" href="/admin">
              <IonIcon icon={settings} />
              <IonLabel>Admin</IonLabel>
            </IonTabButton>
          ) : (
            <></>
          )}
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={personCircle} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    );
  }

  return (
    <IonSplitPane when="xs" contentId="main">
      <IonMenu
        menuId="main-menu"
        type="overlay"
        contentId="main"
        color="tertiary"
        side="start"
      >
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonImg
              src="/logo-white-border.svg"
              //src="/apple-touch-icon.png"
              style={{
                minWidth: "42px",
                maxWidth: "42px",
                margin: "auto",
                backgroundColor: "white",
                border: "2px solid white"
              }}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent color="tertiary">
          <IonList className="ion-no-padding">
            <IonItem
              routerLink="/conversations"
              routerDirection="none"
              lines="none"
              detail={false}
              color="tertiary"
              title="Conversations"
            >
              <IonIcon
                icon={chatbubbles}
                color={
                  location?.pathname.toLowerCase().startsWith("/conversations")
                    ? ""
                    : "medium"
                }
              />
              <IonLabel hidden>Conversations</IonLabel>
            </IonItem>
            {session.currentUser?.role === "admin"
              ? (
                <IonItem
                  routerLink="/dashboards"
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  color="tertiary"
                  title="Dashboards"
                >
                  <IonIcon
                    icon={statsChart}
                    color={
                      location?.pathname.toLowerCase().startsWith("/dashboards")
                        ? ""
                        : "medium"
                    }
                  />
                  <IonLabel hidden>Dashboards</IonLabel>
                </IonItem>
              ) : (
                <></>
              )
            }
            {session.currentUser?.role === "admin" && (
              <IonItem
                routerLink="/admin"
                routerDirection="none"
                lines="none"
                detail={false}
                color="tertiary"
                title="Admin"
              >
                <IonIcon
                  icon={settings}
                  color={
                    location?.pathname.toLowerCase().startsWith("/admin")
                      ? ""
                      : "medium"
                  }
                />
                <IonLabel hidden>Admin</IonLabel>
              </IonItem>
            )}
            <IonItem
              routerLink="/profile"
              routerDirection="none"
              lines="none"
              detail={false}
              color="tertiary"
              title="Profile"
            >
              <IonIcon
                icon={personCircle}
                color={
                  location?.pathname.toLowerCase().startsWith("/profile")
                    ? ""
                    : "medium"
                }
              />
              <IonLabel hidden>Profile</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonList className="ion-no-padding" style={{ width: "60px" }}>
            <IonItem
              hidden
              routerLink="/conversations"
              routerDirection="none"
              lines="none"
              detail={false}
              color="primary"
            >
              <IonIcon icon={personCircle} />
              <IonLabel hidden>Profile</IonLabel>
            </IonItem>
          </IonList>
        </IonFooter>
      </IonMenu>

      <IonPage id="main">
        <IonGrid
          style={{ width: "100%", height: "100%" }}
          className="ion-no-padding ion-no-margin"
        >
          <IonRow style={{ width: "100%", height: "100%" }}>
            <IonCol
              style={{
                maxWidth:
                  location?.pathname.toLowerCase() === "/profile"
                    ? ""
                    : "400px",
                borderRight: "1px solid var(--ion-color-primary)",
              }}
            >
              <IonRouterOutlet id="nav">
                <Redirect exact path="/" to="/conversations" />

                <Route path="/conversations" component={ConversationsPage} />
                <Route path="/customers" component={CustomersPage} />

                <ProtectedRoute
                  path="/dashboards"
                  component={DashboardsPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                  loadingComponent={LoadingPage}
                />

                <Route path="/profile" component={ProfilePage} />

                <ProtectedRoute
                  path="/admin"
                  component={AdminPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                  loadingComponent={LoadingPage}
                />
              </IonRouterOutlet>
            </IonCol>
            <IonCol hidden={location?.pathname.toLowerCase() === "/profile"}>
              <IonRouterOutlet>
                <Route
                  exact
                  path="/conversations"
                  component={NoConversationSelectedEmptyPage}
                />
                <Route path="/conversations/:id" component={ConversationPage} />

                <Route exact path="/customers" />
                <Route path="/customers/:id" component={CustomerPage} />

                <Route
                  exact
                  path="/profile"
                  component={NoProfileDetailsEmptyPage}
                />

                <Route
                  exact
                  path="/dashboards"
                  component={NoDashboardSelectedEmptyPage}
                />
                <ProtectedRoute
                  path="/dashboards/workspace"
                  component={WorkspaceDashboardPage}
                  fallbackComponent={IonPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  exact
                  path="/dashboards/usage"
                  component={UsageDashboardPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />

                <Route
                  exact
                  path="/admin"
                  component={NoAdminSectionSelectedEmptyPage}
                />
                <ProtectedRoute
                  path="/admin/settings"
                  component={ConversationsSettingsPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/users"
                  component={UsersPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/groups"
                  component={GroupsPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/channels/phoneNumbers"
                  component={PhoneNumbersPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/outcomes"
                  component={ConversationOutcomesPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/outcomecategories"
                  component={ConversationOutcomeCategoriesPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/responses"
                  component={StandardResponsesPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/responsecategories"
                  component={StandardResponseCategoriesPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/channels/facebookPages"
                  component={FacebookPagesPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
                <ProtectedRoute
                  path="/admin/exportCompletedConversations"
                  component={ExportCompletedConversationsPage}
                  fallbackComponent={UnauthenticatedPage}
                  roles={["admin"]}
                />
              </IonRouterOutlet>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonPage>
    </IonSplitPane>
  );
});

export default Main;
