import { gql, useQuery } from "@apollo/client";
import { IonLabel, IonSpinner, IonText, useIonViewDidEnter } from "@ionic/react";
import { ResponsiveSunburst } from "@nivo/sunburst";
import { observer } from "mobx-react-lite";
import { hashCode } from "../../../../../utils/Helpers";

const GRAPHQL_QUERY = gql`
  query Query {
    conversationOutcomeCategories {
      name
      conversationOutcomes {
        name
        stats {
          conversationsCount
        }
      }
    }
  }
`;

interface Query {
  conversationOutcomeCategories: ConversationOutcomeCategory[];
}

interface ConversationOutcomeCategory {
  name: string;
  conversationOutcomes: ConversationOutcome[];
}

interface ConversationOutcome {
  name: string;
  stats: {
    conversationsCount: number;
  };
}

const CenteredMetric = (props: {
  nodes: any[];
  centerX: string | number | undefined;
  centerY: string | number | undefined;
}) => {
  const total = props.nodes.reduce(
    (total, datum) => total + (datum.depth == 1 ? datum.value : 0),
    0
  );
  return (
    <text
      x={props.centerX}
      y={props.centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "42px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};

const CompletedConversationsChart = observer(() => {
  const { loading, error, data, refetch } = useQuery<Query>(GRAPHQL_QUERY);

  useIonViewDidEnter(
    () => {
      refetch();
    }
  );

  if (error) {
    console.error("Dashboard Error (COMPLETED CONVERSATIONS): ", error)
  }

  return (
    <div style={{ height: "400px" }}>
      <IonLabel>
        <IonText color="dark">
          <strong>COMPLETED CONVERSATIONS</strong>
        </IonText>
      </IonLabel>
      {loading ? (
        <IonLabel>
          <p>
            <IonSpinner />
          </p>
        </IonLabel>
      ) : error ? (
        <IonLabel>Failed to load.</IonLabel>
      ) : !data ? (
        <></>
      ) : (
        <ResponsiveSunburst
          data={{
            id: "Engage",
            children: data.conversationOutcomeCategories.map((category) => ({
              id: category.name,
              label: category.name,
              children: category.conversationOutcomes.map((outcome) => ({
                id: outcome.name,
                color: `hsl(${hashCode(outcome.name || "") % 360}, 90%, 70%)`,
                value: outcome.stats.conversationsCount,
              })),
              color: `hsl(${hashCode(category.name || "") % 360}, 90%, 70%)`,
            })),
          }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          cornerRadius={5}
          //borderColor={{ theme: "background" }}
          colors={{ datum: "data.color" }}
          childColor={{ from: "color", modifiers: [["brighter", 0.2]] }}
          enableArcLabels={true}
          arcLabel="value"
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          animate={false}
          inheritColorFromParent={true}
          layers={["arcs", "arcLabels", CenteredMetric]}
        />
      )}
    </div>
  );
});

export default CompletedConversationsChart;
