import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ApolloProvider } from "@apollo/client";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { arrowBack } from "ionicons/icons";

import { SessionContext } from "../../../../session";
import MessageUsageChart from "./components/MessageUsageChart";

const UsageDashboardPage: React.FC = observer(() => {
  const session = useContext(SessionContext);

  if (!session || !session.apolloClient) {
    return <></>;
  }

  return (
    <ApolloProvider client={session.apolloClient}>
      <IonPage>
        <IonHeader translucent>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              {session.windowSize === "small" ? (
                <>
                  <IonButton routerLink="/dashboards">
                    <IonIcon icon={arrowBack} />
                  </IonButton>
                </>
              ) : (
                <></>
              )}
            </IonButtons>
            <IonTitle>Usage Dashboard</IonTitle>
            <IonButtons slot="end"></IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonList>
            <IonListHeader></IonListHeader>
          </IonList>

          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-text-center">
                <MessageUsageChart />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ApolloProvider>
  );
});

export default UsageDashboardPage;
