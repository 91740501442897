import firebase from "firebase/app";

export type ReachMessageStatus =
    | "accepted"
    | "queued"
    | "sending"
    | "sent"
    | "failed"
    | "delivered"
    | "undelivered"
    | "receiving"
    | "received"
    | "read";

export interface BroadcastMessageData {
    contactRef: string;
    broadcastMessageRef?: string;
    from: string;
    to: string;
    body: string | null;
    direction: "inbound" | "outbound";
    //status: "pending" | "sent" | "failed";
    createdOn: firebase.firestore.Timestamp;
    createdAt: firebase.firestore.Timestamp;
    createdByUserRef: string | null;
    delivery?: {
        startTime: firebase.firestore.Timestamp;
        endTime?: firebase.firestore.Timestamp;
        leaseExpireTime?: firebase.firestore.Timestamp;
        state: "PENDING" | "PROCESSING" | "SUCCESS" | "ERROR";
        errorCode?: number;
        errorMessage?: string;
        info?: {
            messageSid: string;
            status?: ReachMessageStatus;
            dateCreated?: firebase.firestore.Timestamp;
            dateSent?: firebase.firestore.Timestamp;
            dateUpdated?: firebase.firestore.Timestamp;
            messagingServiceSid?: string;
            numMedia?: string;
            numSegments?: string;
        };
    };
}

export const broadcastMessageDefaultValue = {
    body: null,
    createdOn: firebase.firestore.Timestamp.now(),
} as BroadcastMessageData;