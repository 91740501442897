import firebase from "firebase/app";

import { makeAutoObservable } from "mobx";
import { Session } from "../../session";

import { WorkItem } from "./WorkItem";
import { workItemConverter } from "./WorkItemData";

export class WorkItems {
    private mSession: Session;
    private mCollectionRef: firebase.firestore.CollectionReference<WorkItem> | firebase.firestore.Query<WorkItem>;
    private mQuery: firebase.firestore.Query<WorkItem> | null;
    private mLimit: number | undefined;
    private mPath: string;
    private mData: WorkItem[] | null | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;

    constructor(session: Session, path: string, limit?: number) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;

        this.mCollectionRef = path.trim().startsWith("*/")
            ? this.session.firebase
                .firestore()
                .collectionGroup(path.trim().substr(2))
                .withConverter(workItemConverter(session))
            : this.session.firebase
                .firestore()
                .collection(path)
                .withConverter(workItemConverter(session));

        this.mQuery = null;
        this.mLimit = limit;
        this.mPath = path;
        this.mSubscriptionCount = 0;
    }

    public get session() {
        return this.mSession;
    }

    public get collectionRef() {
        return this.mCollectionRef;
    }

    public get query() {
        return this.mQuery;
    }
    public set query(newValue: firebase.firestore.Query<WorkItem> | null) {
        this.mQuery = newValue;

        if (this.mSubscription) {
            this.mSubscription();
            this.refreshSubscription();
        }
    }

    public get limit() {
        return this.mLimit;
    }
    private set limit(newValue: number | undefined) {
        this.mLimit = newValue;
        this.refreshSubscription();
    }

    public get path() {
        return this.mPath;
    }

    public get data() {
        //if (this.mData) return this.mData.filter((item) => item.data?.isDeleted !== true ?? true);
        return this.mData;
    }
    protected set data(newValue: WorkItem[] | null | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    private refreshSubscription() {
        let query = (this.query ?? this.collectionRef)
        query = typeof this.limit !== "undefined" ? query.limit(this.limit) : query;
        this.mSubscription = query
            .onSnapshot(
                //{
                //    includeMetadataChanges: true
                //},
                (snapshot: firebase.firestore.QuerySnapshot<WorkItem>) => {
                    //if (!snapshot.metadata.fromCache && !snapshot.metadata.hasPendingWrites) {
                    const data = snapshot.docs.filter((doc, index, self) => self.findIndex((searchDoc) => searchDoc.id === doc.id) === index).map((doc) => doc.data());
                    this.data = data;
                    //}
                }
            );
    }

    public refetch() {
        if (!this.mSubscription) {
            (this.query ?? this.collectionRef).get()
                .then((snapshot: firebase.firestore.QuerySnapshot<WorkItem>) => {
                    const data = snapshot.docs.filter((doc, index, self) => self.findIndex((searchDoc) => searchDoc.id === doc.id) === index).map((doc) => doc.data());
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.mPath}`);
            this.refreshSubscription();
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.mPath}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    public loadMore(count: number = 50) {
        if (typeof this.limit !== "undefined") {
            this.limit = this.limit + count;
        }
    }
}
