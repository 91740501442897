import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ApolloProvider } from "@apollo/client";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

import { arrowBack } from "ionicons/icons";

import { SessionContext } from "../../../../session";

import AssignedWorkerProfileChart from "./components/AssignedWorkerProfileChart";
import CompletedConversationsChart from "./components/CompletedConversations";
import OpenConversationsChart from "./components/OpenConversationsChart";

const WorkspaceDashboardPage: React.FC = observer(() => {
  const session = useContext(SessionContext);

  return session && session.apolloClient ? (
    <ApolloProvider client={session.apolloClient}>
      <IonPage>
        <IonHeader
          translucent
          //className="ion-no-border"
        >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              {(session.windowSize === "small") ? (
                <>
                  {/*<IonBackButton defaultHref={`/conversations`}></IonBackButton>*/}
                  <IonButton routerLink="/dashboards">
                    <IonIcon icon={arrowBack} />
                  </IonButton>
                </>
              ) : (
                <></>
              )}
            </IonButtons>
            <IonTitle>Workspace Dashboard</IonTitle>
            <IonButtons slot="end"></IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonList>
            <IonListHeader></IonListHeader>
          </IonList>

          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-text-center">
                <OpenConversationsChart />
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "50px" }}>
              <IonCol size="12" className="ion-text-center">
                <AssignedWorkerProfileChart />
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "50px" }}>
              <IonCol size="12" className="ion-text-center">
                <CompletedConversationsChart />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ApolloProvider>
  ) : (
    <></>
  );
});

export default WorkspaceDashboardPage;
