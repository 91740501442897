import { createContext } from "react";

import {
  observable,
  action,
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
  IReactionDisposer,
  reaction,
} from "mobx";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import 'firebase/messaging';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { Keyboard } from "@capacitor/keyboard";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform } from "@ionic/react";

import FirebasePlugin from "./plugins/FirebasePlugin";

import { Customers } from "./models/Customers";
import { ConversationOutcomes } from "./models/ConversationOutcomes";
import { ConversationOutcomesSettings } from "./models/ConversationOutcomesSettings";
import { ConversationsSettings } from "./models/ConversationsSettings";
import { FacebookPages, Groups, PhoneNumbers } from "./models";
import { Formats } from "./models/Formats";
import { StandardResponses } from "./models/StandardResponses";
import { StandardResponsesSettings } from "./models/StandardResponsesSettings";
import { User, Users } from "./models/Users";
import { WorkerProfiles } from "./models/WorkerProfiles";
import { getWindowDimensions } from "./utils/Helpers";

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export class Session {
  private mFirebaseAuth: firebase.app.App | null | undefined;
  private mFirebase: firebase.app.App;
  private mApolloClient: ApolloClient<NormalizedCacheObject> | null | undefined;
  private mInstanceId: string;
  private mInstanceVersion: string | null;
  private mCurrentUserId: string | null | undefined;
  private mCurrentUser: User | null | undefined;
  private mCurrentUserDisposer: IReactionDisposer | null | undefined;
  private mCustomers: Customers | null | undefined;
  private mConversationsSettings: ConversationsSettings | null | undefined;
  private mConversationsSettingsDisposer: IReactionDisposer | null | undefined;
  private mConversationOutcomes: ConversationOutcomes | null | undefined;
  private mConversationOutcomesSettings: ConversationOutcomesSettings | null | undefined;
  private mConversationOutcomesSettingsDisposer: IReactionDisposer | null | undefined;
  private mFacebookPages: FacebookPages | null | undefined;
  private mFacebookPagesDisposer: IReactionDisposer | null | undefined;
  private mFormats: Formats | null | undefined;
  private mStandardResponses: StandardResponses | null | undefined;
  private mStandardResponsesSettings: StandardResponsesSettings | null | undefined;
  private mStandardResponsesSettingsDisposer: IReactionDisposer | null | undefined;
  private mWorkerProfiles: WorkerProfiles | null | undefined;
  private mPhoneNumbers: PhoneNumbers | null | undefined;
  private mGroups: Groups | null | undefined;
  private mUsers: Users | null | undefined;
  private mNotificationToken: string | null | undefined;
  private mLoaded: boolean;
  private mConnectedRef: firebase.database.Reference | null | undefined;
  private mConnectionRef: firebase.database.Reference | null | undefined;
  private mKeyboardInfo = { visible: false }

  mWindowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  };

  constructor(firebaseInstance: firebase.app.App) {
    makeAutoObservable(
      this,
      {
        mWindowDimensions: observable.struct
      }
    );

    this.mLoaded = false;

    //if (typeof (window) !== "undefined") {
    //  this.mWindowDimensions = {
    //    width: window.innerWidth,
    //    height: window.innerHeight
    //  }

    window.onresize = () => {
      runInAction(() => this.mWindowDimensions = getWindowDimensions());
    }
    //}

    onBecomeObserved(this, "currentUser", this.subscribeCurrentUser);
    onBecomeUnobserved(this, "currentUser", this.unsubscribeCurrentUser);
    onBecomeObserved(this, "customers", this.subscribeCustomers);
    onBecomeUnobserved(this, "customers", this.unsubscribeCustomers);
    onBecomeObserved(this, "conversationsSettings", this.subscribeConversationsSettings);
    onBecomeUnobserved(this, "conversationsSettings", this.unsubscribeConversationsSettings);
    onBecomeObserved(this, "conversationOutcomes", this.subscribeConversationOutcomes);
    onBecomeUnobserved(this, "conversationOutcomes", this.unsubscribeConversationOutcomes);
    onBecomeObserved(this, "conversationOutcomesSettings", this.subscribeConversationOutcomesSettings);
    onBecomeUnobserved(this, "conversationOutcomesSettings", this.unsubscribeConversationOutcomesSettings);
    onBecomeObserved(this, "facebookPages", this.subscribeFacebookPages);
    onBecomeUnobserved(this, "facebookPages", this.unsubscribeFacebookPages);
    onBecomeObserved(this, "formats", this.subscribeFormats);
    onBecomeUnobserved(this, "formats", this.unsubscribeFormats);
    onBecomeObserved(this, "standardResponses", this.subscribeStandardResponses);
    onBecomeUnobserved(this, "standardResponses", this.unsubscribeStandardResponses);
    onBecomeObserved(this, "standardResponsesSettings", this.subscribeStandardResponsesSettings);
    onBecomeUnobserved(this, "standardResponsesSettings", this.unsubscribeStandardResponsesSettings);
    onBecomeObserved(this, "workerProfiles", this.subscribeWorkerProfiles);
    onBecomeUnobserved(this, "workerProfiles", this.unsubscribeWorkerProfiles);
    onBecomeObserved(this, "phoneNumbers", this.subscribePhoneNumbers);
    onBecomeUnobserved(this, "phoneNumbers", this.unsubscribePhoneNumbers);
    onBecomeObserved(this, "groups", this.subscribeGroups);
    onBecomeUnobserved(this, "groups", this.unsubscribeGroups);
    onBecomeObserved(this, "users", this.subscribeUsers);
    onBecomeUnobserved(this, "users", this.unsubscribeUsers);

    this.mFirebaseAuth = null;
    this.mFirebase = firebaseInstance;
    this.mInstanceId = firebaseInstance.name;
    this.mInstanceVersion = null;
    this.mConnectedRef = null;
    this.mConnectionRef = null;
    this.mApolloClient = null;

    if (isPlatform("capacitor") && isPlatform("ios")) {
      GoogleAuth.initialize();
    }
  }

  static getFirebaseInstance(instanceId: string) {
    return new Promise<firebase.app.App>(async (resolve, reject) => {
      const platform = isPlatform("ios")
        ? "ios"
        : isPlatform("android")
          ? "android"
          : "web";

      const firebaseConfig = await fetch(`https://engage.${instanceId}.voyagernetz.us/__vrz-config/firebase/config.json?platform=all-platforms`)
        .then(async response => {
          const result = await response.json();
          return result || null;
        })
        .catch((error) => {
          reject(error)
        });

      if (firebaseConfig) {
        const firebaseApp = firebase.apps.find((app) => app.name === instanceId) ?? firebase.initializeApp(firebaseConfig["web"] ?? firebaseConfig, instanceId);

        if (isPlatform("capacitor") && firebaseConfig[platform]) {
          await FirebasePlugin.initialize(firebaseConfig[platform]);
          console.log(`Firebase - ${platform}: instanceID`, instanceId)
        }

        resolve(firebaseApp);
      } else {
        reject(new Error("Instance is currently unavailable."))
      }
    });
  }

  public init() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.initializeFirebase();
        await this.initializeInstanceInfo();
        await this.initializeKeyboardInfo();

        this.loaded = true;
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  private initializeFirebase() {
    return new Promise<void>(async (resolve, reject) => {
      this.firebase.auth().onAuthStateChanged(
        (user: firebase.User | null) => {
          when(
            () => this.firebase !== undefined,
            async () => {
              this.currentUserId = user ? user.uid : null;

              if (user) {
                await this.initializeUserPresence(user);
                await this.initializePushNotifications(user);
                await this.initializeApolloClient(user);

                when(
                  () => this.currentUser?.loaded ?? false,
                  () => {
                    localStorage.setItem(
                      "vrz--engage-instance-id",
                      this.instanceId
                    );
                    this.loaded = true;
                    resolve();
                  }
                )
              } else {
                resolve();
              }
            });
        }
      );

      resolve();
    });
  }

  private initializeUserPresence(user: firebase.User) {
    return new Promise<void>((resolve, reject) => {
      this.connectionRef = this.firebase.database().ref(`status/${user.uid}`);
      this.connectedRef = this.firebase.database().ref(".info/connected");

      this.connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          this.connectionRef?.onDisconnect().remove();

          this.connectionRef?.set({
            status: "online",
            modifiedOn: firebase.database.ServerValue.TIMESTAMP
          });

          this.connectionRef?.onDisconnect().set({
            status: "offline",
            modifiedOn: firebase.database.ServerValue.TIMESTAMP
          });

        }
      });

      resolve();
    });
  }

  private initializeInstanceInfo() {
    return new Promise<void>((resolve, reject) => {
      fetch(`https://engage.${this.instanceId}.voyagernetz.us/__vrz-config/info`)
        .then(async response => {
          const info = await response.json();
          if (info?.version) {
            runInAction(() => this.mInstanceVersion = info.version);
          } else {
            reject(new Error("Instance version is missing"));
          }

          resolve();
        })
        .catch((error) => reject(new Error("Unable to fetch Instance version")))
    });
  }

  private initializePushNotifications(user: firebase.User) {
    return new Promise<void>((resolve, reject) => {
      if (user) {
        if (isPlatform("capacitor")) {
          PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
              PushNotifications.register();
            }
          });

          PushNotifications.addListener('registration', (token: Token) => {
            if (this.firebase && token?.value) {
              runInAction(() => this.mNotificationToken = token?.value);

              const userProfile = this.firebase.firestore().collection('userProfiles').doc(user.uid);
              userProfile.collection("notificationTokens")
                .doc(token.value)
                .set({
                  lastSeen: firebase.firestore.FieldValue.serverTimestamp()
                }).catch((error) => console.error("Unable to register notification token", error));
            }
          });

          PushNotifications.addListener('registrationError', (error: any) => { });
          PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => { },);
          PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => { },);

        } else if (firebase.messaging.isSupported() && !isLocalhost) {
          if (!("Notification" in window)) {
            console.info("This browser does not support desktop notification");
          }
          else {
            const registerNotficationToken = (user: firebase.User) => {
              if (this.firebase) {
                this.firebase.firestore().doc("/settings/notifications").get().then((notificationSettingsDoc) => {
                  if (notificationSettingsDoc.exists) {
                    const notificationSettings = notificationSettingsDoc.data();
                    const messagingVapidKey = notificationSettings?.messagingVapidKey;
                    if (typeof (messagingVapidKey) == "string") {
                      navigator.serviceWorker.ready.then((registration) => {
                        if (this.firebase) {
                          this.firebase.messaging()
                            .getToken({ vapidKey: messagingVapidKey, serviceWorkerRegistration: registration })
                            .then((currentToken) => {
                              if (this.firebase && currentToken) {
                                runInAction(() => this.mNotificationToken = currentToken);

                                const userProfile = this.firebase.firestore().collection('userProfiles').doc(user.uid);
                                userProfile.collection("notificationTokens")
                                  .doc(currentToken)
                                  .set({
                                    lastSeen: firebase.firestore.FieldValue.serverTimestamp()
                                  }).catch((error) => console.error("Unable to register notification token", error));

                                const notificationSound = new Audio("/notification-sound.mp3");

                                this.firebase.messaging().onMessage((payload) => {
                                  const notificationTitle = payload.notification?.title ?? "VoyagerNetz Engage";
                                  const notificationOptions = {
                                    body: payload.notification?.body ?? "",
                                    icon: '/notification-icon.png'
                                  };

                                  notificationSound.play();
                                  registration.showNotification(notificationTitle, notificationOptions);
                                });
                              } else {
                                console.log('Token: No registration token available. Request permission to generate one.');
                              }
                            }).catch((err) => {
                              console.error('Token: An error occurred while retrieving token. ', err);
                            });
                        }
                      });
                    }
                  }
                }).catch((error) => console.error("Unable to fetch messaging vapidkey", error));
              }
            }

            if (Notification.permission === "granted") {
              registerNotficationToken(user);
            }
            else if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  registerNotficationToken(user);
                }
              });
            }
          }
        }
      }

      resolve();
    });
  }

  private initializeApolloClient(user: firebase.User) {
    return new Promise<void>((resolve, reject) => {
      //fetch(`https://engage.${this.instanceId}.voyagernetz.us/__vrz-config/graphql/config.json`)
      //.then(async response => {
      //const info = await response.json();
      //if (info?.url) {


      const httpLink = createHttpLink({
        uri: `https://us-central1-vrz--${this.instanceId}.cloudfunctions.net/query/graphql`,
      });

      const authLink = setContext(async (_, { headers }) => {
        const accessToken = await user.getIdToken(/* forceRefresh */ true)

        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          }
        }
      });

      this.mApolloClient = new ApolloClient({
        link: from([authLink, httpLink]),
        cache: new InMemoryCache()
      });

      //} else {
      //  reject(new Error("Instance version is missing"));
      // }

      resolve();
      //})
      //.catch((error) => reject(new Error("Unable to fetch Graphql endpoint info")))
    });
  }

  private initializeKeyboardInfo() {
    return new Promise<void>((resolve, reject) => {
      if (isPlatform("capacitor")) {
        if (isPlatform("ios")) {
          Keyboard.addListener("keyboardWillShow", (info) => {
            //this.keyboardInfo = { visible: true };
          });

          Keyboard.addListener("keyboardDidShow", (info) => {
            this.keyboardInfo = { visible: true };
          });

          Keyboard.addListener("keyboardWillHide", () => {
            //this.keyboardInfo = { visible: false };
          });

          Keyboard.addListener("keyboardDidHide", () => {
            this.keyboardInfo = { visible: false };
          });
        } else if (isPlatform("android")) {
        }
      } if (typeof (window as any) !== 'undefined') {
        window.addEventListener('ionKeyboardWillShow', (info: any) => {
          //this.keyboardInfo = { visible: true };
        });

        window.addEventListener('ionKeyboardDidShow', (info: any) => {
          this.keyboardInfo = { visible: true };
        });

        window.addEventListener('ionKeyboardWillHide', () => {
          //this.keyboardInfo = { visible: false };
        });

        window.addEventListener('ionKeyboardDidHide', () => {
          this.keyboardInfo = { visible: false };
        });
      }
      resolve();
    });
  }

  private get connectedRef() {
    return this.mConnectedRef;
  }
  private set connectedRef(newValue: firebase.database.Reference | null | undefined) {
    this.mConnectedRef = newValue;
  }

  private get connectionRef() {
    return this.mConnectionRef;
  }
  private set connectionRef(newValue: firebase.database.Reference | null | undefined) {
    this.mConnectionRef = newValue;
  }

  public get loading() {
    return !this.mLoaded;
  }

  public get loaded() {
    return this.mLoaded;
  }
  private set loaded(newValue: boolean) {
    this.mLoaded = newValue;
  }

  public get instanceId() {
    return this.mInstanceId;
  }

  public get instanceVersion() {
    return this.mInstanceVersion;
  }

  public get instanceVersionDisplayText() {
    if (this.instanceVersion) {
      const version = this.instanceVersion.toLowerCase();
      const [major, minor] = version.split(".");

      const minorText = version.includes("alpha")
        ? (
          `${minor} Alpha`
        ) : (
          version.includes("beta") ? `${minor} Beta` : `${minor}`
        );

      return `Version ${major}.${minorText}`.trim();
    }

    return this.instanceVersion;
  }

  public get firebase() {
    return this.mFirebase;
  }

  public get firebaseAuth() {
    if (!this.mFirebaseAuth) {
      const productionReachFirebaseConfig = {
        apiKey: "AIzaSyCK5CjwW9N42deUfTBaeZbbgObMNhmh0Fs",
        authDomain: "vrz-reach.firebaseapp.com",
        databaseURL: "https://vrz-reach-default-rtdb.firebaseio.com",
        projectId: "vrz-reach",
        storageBucket: "vrz-reach.appspot.com",
        messagingSenderId: "301789987244",
        appId: "1:301789987244:web:ad1de049818c946015fa09",
        measurementId: "G-8L4F2NQFXF"
      };
      const stagingReachFirebaseConfig = {
        apiKey: "AIzaSyD15ain9qU4BCrYilQ1weVj2R7rKuT7iKs",
        authDomain: "vrz-reach-staging.firebaseapp.com",
        databaseURL: "https://vrz-reach-staging-default-rtdb.firebaseio.com",
        projectId: "vrz-reach-staging",
        storageBucket: "vrz-reach-staging.appspot.com",
        messagingSenderId: "154817975141",
        appId: "1:154817975141:web:38364a72fadd07f3d6878c"
      };

      this.mFirebaseAuth = firebase.initializeApp(
        stagingReachFirebaseConfig,
        "auth"
      );
    }

    return this.mFirebaseAuth;
  }

  public get apolloClient() {
    return this.mApolloClient;
  }

  public get signedIn() {
    return typeof (this.currentUserId) == "string";
  }

  public get currentUserId() {
    return this.mCurrentUserId;
  }
  private set currentUserId(newValue: string | null | undefined) {
    this.mCurrentUserId = newValue;
  }
  public get currentUser() {
    return this.mCurrentUser;
  }
  private set currentUser(newValue: User | null | undefined) {
    this.mCurrentUser = newValue;
  }
  public subscribeCurrentUser = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        const initCurrentUser = () => {
          if (this.currentUser && this.currentUser.id !== this.currentUserId) {
            this.unsubscribeCurrentUser();
          }

          if (this.currentUser == undefined && this.currentUserId !== undefined) {
            this.currentUser = this.currentUserId
              ? User.createCacheInstance(
                this,
                `userProfiles/${this.currentUserId}`
              )
              : null;
            this.currentUser?.subscribe();

            this.mCurrentUserDisposer = reaction(
              () => this.currentUserId,
              (currentUserId) => initCurrentUser()
            );
          }
        }

        initCurrentUser();
      })
  };
  public unsubscribeCurrentUser = () => {
    if (this.mCurrentUserDisposer) this.mCurrentUserDisposer();
    this.currentUser?.unsubscribe();
    this.currentUser = undefined;
  };

  public get customers(): Customers | null | undefined {
    return this.mCustomers;
  }
  public subscribeCustomers = () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mCustomers === undefined) {
          this.mCustomers = new Customers(this, `customers`);

          let query = this.mCustomers.collectionRef.orderBy("firstName");
          this.mCustomers.query = query;

          this.mCustomers?.subscribe();
        }
      }
    );
  };
  public unsubscribeCustomers = () => {
    this.mCustomers?.unsubscribe();
    this.mCustomers = undefined;
  };

  public get conversationsSettings() {
    return this.mConversationsSettings;
  }
  public subscribeConversationsSettings = () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        const initConversationsSettings = () => {
          //if (this.mConversationsSettings && this.mConversationsSettings.id != this.mConversationsSettingsId) {
          //  this.unsubscribeConversationsSettings();
          //}

          if (this.mConversationsSettings === undefined) {
            this.mConversationsSettings = ConversationsSettings.createCacheInstance(this, `/settings/general`);
            this.mConversationsSettings?.subscribe();

            this.mConversationsSettingsDisposer = reaction(
              () => false,
              () => initConversationsSettings()
            );
          }
        }

        initConversationsSettings();
      })
  };
  public unsubscribeConversationsSettings = () => {
    if (this.mConversationsSettingsDisposer) this.mConversationsSettingsDisposer();
    this.mConversationsSettings?.unsubscribe();
    this.mConversationsSettings = undefined;
  };

  public get conversationOutcomes(): ConversationOutcomes | null | undefined {
    return this.mConversationOutcomes;
  }
  public subscribeConversationOutcomes = async () => {
    if (this.mConversationOutcomes === undefined) {
      runInAction(() => {
        this.mConversationOutcomes = new ConversationOutcomes(this, `templates/conversationOutcome/templateOptions`);

        let query = this.mConversationOutcomes.collectionRef.orderBy("name");
        this.mConversationOutcomes.query = query;

        this.mConversationOutcomes?.subscribe();
      });
    }
  };
  public unsubscribeConversationOutcomes = () => {
    this.mConversationOutcomes?.unsubscribe();
    this.mConversationOutcomes = undefined;
  };

  public get conversationOutcomesSettings() {
    return this.mConversationOutcomesSettings;
  }
  public subscribeConversationOutcomesSettings = () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        const initConversationOutcomesSettings = () => {
          //if (this.mConversationOutcomesSettings && this.mConversationOutcomesSettings.id != this.mConversationOutcomesSettingsId) {
          //  this.unsubscribeConversationOutcomesSettings();
          //}

          if (this.mConversationOutcomesSettings === undefined) {
            this.mConversationOutcomesSettings = ConversationOutcomesSettings.createCacheInstance(this, `/templates/conversationOutcome`);
            this.mConversationOutcomesSettings?.subscribe();

            this.mConversationOutcomesSettingsDisposer = reaction(
              () => false,
              () => initConversationOutcomesSettings()
            );
          }
        }

        initConversationOutcomesSettings();
      })
  };
  public unsubscribeConversationOutcomesSettings = () => {
    if (this.mConversationOutcomesSettingsDisposer) this.mConversationOutcomesSettingsDisposer();
    this.mConversationOutcomesSettings?.unsubscribe();
    this.mConversationOutcomesSettings = undefined;
  };


  public get facebookPages(): FacebookPages | null | undefined {
    return this.mFacebookPages;
  }
  public subscribeFacebookPages = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mFacebookPages === undefined) {
          runInAction(() => {
            this.mFacebookPages = new FacebookPages(this, `communication/channels/facebookPages`);

            let query = this.mFacebookPages.collectionRef.orderBy("name");
            this.mFacebookPages.query = query;

            this.mFacebookPages?.subscribe();
          });
        }
      });
  };
  public unsubscribeFacebookPages = () => {
    this.mFacebookPages?.unsubscribe();
    this.mFacebookPages = undefined;
  };

  public get formats(): Formats | null | undefined {
    return this.mFormats;
  }
  public subscribeFormats = () => {
    if (this.mFormats === undefined) {
      runInAction(() => {
        this.mFormats = new Formats(this, "formats");
        this.mFormats?.subscribe();
      });
    }
  };
  public unsubscribeFormats = () => {
    this.mFormats?.unsubscribe();
    this.mFormats = undefined;
  };

  public get standardResponses(): StandardResponses | null | undefined {
    return this.mStandardResponses;
  }
  public subscribeStandardResponses = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mStandardResponses === undefined) {
          runInAction(() => {
            this.mStandardResponses = new StandardResponses(this, `templates/standardResponse/templateOptions`);

            let query = this.mStandardResponses.collectionRef.orderBy("name");
            this.mStandardResponses.query = query;

            this.mStandardResponses?.subscribe();
          });
        }
      });
  };
  public unsubscribeStandardResponses = () => {
    this.mStandardResponses?.unsubscribe();
    this.mStandardResponses = undefined;
  };

  public get standardResponsesSettings() {
    return this.mStandardResponsesSettings;
  }
  public subscribeStandardResponsesSettings = () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        const initStandardResponsesSettings = () => {
          //if (this.mStandardResponsesSettings && this.mStandardResponsesSettings.id != this.mStandardResponsesSettingsId) {
          //  this.unsubscribeStandardResponsesSettings();
          //}

          if (this.mStandardResponsesSettings === undefined) {
            this.mStandardResponsesSettings = StandardResponsesSettings.createCacheInstance(this, `/templates/standardResponse`);
            this.mStandardResponsesSettings?.subscribe();

            this.mStandardResponsesSettingsDisposer = reaction(
              () => false,
              () => initStandardResponsesSettings()
            );
          }
        }

        initStandardResponsesSettings();
      })
  };
  public unsubscribeStandardResponsesSettings = () => {
    if (this.mStandardResponsesSettingsDisposer) this.mStandardResponsesSettingsDisposer();
    this.mStandardResponsesSettings?.unsubscribe();
    this.mStandardResponsesSettings = undefined;
  };

  public get workerProfiles(): WorkerProfiles | null | undefined {
    return this.mWorkerProfiles;
  }
  public subscribeWorkerProfiles = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mWorkerProfiles === undefined) {
          runInAction(() => {
            this.mWorkerProfiles = new WorkerProfiles(this, `workerProfiles`);

            let query = this.mWorkerProfiles.collectionRef.orderBy("name");
            this.mWorkerProfiles.query = query;

            this.mWorkerProfiles?.subscribe();
          });
        }
      });
  };
  public unsubscribeWorkerProfiles = () => {
    this.mWorkerProfiles?.unsubscribe();
    this.mWorkerProfiles = undefined;
  };

  public get phoneNumbers(): PhoneNumbers | null | undefined {
    return this.mPhoneNumbers;
  }
  public subscribePhoneNumbers = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mPhoneNumbers === undefined) {
          runInAction(() => {
            this.mPhoneNumbers = new PhoneNumbers(this, `communication/channels/phoneNumbers`);

            let query = this.mPhoneNumbers.collectionRef.orderBy("name");
            this.mPhoneNumbers.query = query;

            this.mPhoneNumbers.subscribe();
          });
        }
      });
  };
  public unsubscribePhoneNumbers = () => {
    this.mPhoneNumbers?.unsubscribe();
    this.mPhoneNumbers = undefined;
  };

  public get groups(): Groups | null | undefined {
    return this.mGroups;
  }
  public subscribeGroups = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mGroups === undefined) {
          runInAction(() => {
            this.mGroups = new Groups(this, `groups`);

            let query = this.mGroups.collectionRef.orderBy("name");
            this.mGroups.query = query;

            this.mGroups?.subscribe();
          });
        }
      });
  };
  public unsubscribeGroups = () => {
    this.mGroups?.unsubscribe();
    this.mGroups = undefined;
  };

  public get users(): Users | null | undefined {
    return this.mUsers;
  }
  public subscribeUsers = async () => {
    when(
      () => this.currentUserId !== undefined,
      () => {
        if (this.mUsers === undefined) {
          runInAction(() => {
            this.mUsers = new Users(this, `userProfiles`);

            let query = this.mUsers.collectionRef.orderBy("firstName");
            this.mUsers.query = query;

            this.mUsers?.subscribe();
          });
        }
      });
  };
  public unsubscribeUsers = () => {
    this.mUsers?.unsubscribe();
    this.mUsers = undefined;
  };

  public get notificationToken(): string | null | undefined {
    return this.mNotificationToken;
  }

  public get keyboardInfo(): {
    visible: boolean
  } {
    return this.mKeyboardInfo;
  }
  private set keyboardInfo(newValue: { visible: boolean }) {
    this.mKeyboardInfo = newValue;
  }


  public get windowDimensions(): {
    width: number,
    height: number
  } /*| null | undefined*/ {
    return this.mWindowDimensions;
  }

  public get windowSize(): "large" | "small" {
    return isPlatform("ipad")
      ? "large"
      : /*(isPlatform("mobile") || (!isPlatform("mobile") &&*/ this.windowDimensions.width < 768 /*))*/
        ? "small"
        : "large";
  }

  public signInWithIdentityService(email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const instanceResponse = await fetch(`${isLocalhost ? "https://reach-staging.voyagernetz.us" : "https://reach-staging.voyagernetz.us"}/api/getInstance?id=${this.instanceId.toLowerCase().trim()}`);
        if (!instanceResponse.ok) {
          throw new Error("Instance not found")
        }
        const instance = await instanceResponse.json() as { id: string; tenantId: string };

        const firebaseAuthApp = this.firebaseAuth;
        firebaseAuthApp.auth().tenantId = instance.tenantId;

        const authSignInResult = await firebaseAuthApp
          .auth()
          .signInWithEmailAndPassword(email, password);

        const token = await authSignInResult.user?.getIdToken();
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  }

  public signInWithEmailAndPassword(email: string, password: string, useIdentityService?: boolean) {
    if (useIdentityService) {
      return new Promise(async (resolve, reject) => {
        try {
          const token = await this.signInWithIdentityService(email, password);
          if (token) {
            const authResponse = await fetch(`${isLocalhost ? `https://engage.${this.instanceId}.voyagernetz.us` : ""}/api/auth?token=${token}`);
            if (authResponse.ok) {
              const authToken = await authResponse.json() as { token: string; };

              const signInResult = await this.firebase
                .auth()
                .signInWithCustomToken(authToken.token);

              if (signInResult.additionalUserInfo?.isNewUser) {
                this.signOut().finally(() => reject(new Error("User is disabled")));
              } else {
                resolve(signInResult)
              }
            } else {
              reject(new Error(await authResponse.text() || "Unable to sign in."));
            }
          } else {
            reject(new Error("Unable to sign in."))
          }
        }
        catch (error) {
          reject(error)
        }
      });
    } else {
      return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        this.firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            if (userCredential.additionalUserInfo?.isNewUser) {
              this.signOut().finally(() => reject(new Error("User is disabled")));
            } else {
              resolve(userCredential);
            }
          })
          .catch((error) => reject(error));
      });
    }
  }

  public reauthenticationWithEmailAndPassword(email: string, password: string, useIdentityService?: boolean) {
    return new Promise<void>((resolve, reject) => {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );

      this.firebase
        .auth()
        .currentUser?.reauthenticateWithCredential(credential)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  };

  public sendPasswordResetEmail(email: string, redirectUrl: string, useIdentityService?: boolean) {
    return new Promise<void>(async (resolve, reject) => {
      if (useIdentityService) {
        try {
          const instanceResponse = await fetch(`${isLocalhost ? "https://reach-staging.voyagernetz.us" : "https://reach-staging.voyagernetz.us"}/api/getInstance?id=${this.instanceId.toLowerCase().trim()}`);
          if (!instanceResponse.ok) {
            throw new Error("Instance not found")
          }
          const instance = await instanceResponse.json() as { id: string; tenantId: string };

          const firebaseAuthApp = this.firebaseAuth;
          firebaseAuthApp.auth().tenantId = instance.tenantId;

          await firebaseAuthApp
            .auth()
            .sendPasswordResetEmail(email);

          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        this.firebase
          .auth()
          .sendPasswordResetEmail(email /*, {
          url: redirectUrl,
          iOS: {
            bundleId: 'us.voyagernetz.engage'
          },
          android: {
            packageName: 'us.voyagernetz.engage',
            installApp: true
          }
        }*/)
          .then(() => resolve())
          .catch((error) => reject(error));
      }
    });
  }


  public signInWithGoogle() {
    return new Promise((resolve, reject) => {
      if (isPlatform("capacitor")) {
        GoogleAuth.signIn()
          .then((result) => {
            var credential = firebase.auth.GoogleAuthProvider.credential(result.authentication.idToken);
            this.firebase
              .auth()
              .signInWithCredential(credential)
              .then((result) => {
                if (result.additionalUserInfo?.isNewUser) {
                  this.signOut().finally(() => reject(new Error("User is disabled")));
                } else {
                  resolve(result)
                }
              })
              .catch((error) => { console.error("Google Sign In Error", error); reject(error) });
          });
      } else {
        const provider = new firebase.auth.GoogleAuthProvider();
        this.firebase
          .auth()
          //.signInWithRedirect(provider)
          .signInWithPopup(provider)
          .then((result) => {
            if (result.additionalUserInfo?.isNewUser) {
              this.signOut().finally(() => reject(new Error("User is disabled")));
            } else {
              resolve(result)
            }
          })
          .catch((error) => reject(error));
      }
    });
  }

  public signInWithFacebook() {
    return new Promise<firebase.auth.OAuthCredential>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("pages_messaging");
      provider.addScope("pages_show_list");
      provider.addScope("pages_manage_metadata");

      this.firebase
        .auth()
        //.signInWithRedirect(provider)
        .signInWithPopup(provider)
        .then((result) => {
          if (result.additionalUserInfo?.isNewUser) {
            this.signOut().finally(() => reject(new Error("User is disabled")));
          } else {
            const credential = result.credential as firebase.auth.OAuthCredential;
            resolve(credential)
          }
        })
    });
  }

  public linkWithFacebook() {
    return new Promise<firebase.auth.OAuthCredential>((resolve, reject) => {
      when(
        () => this.currentUserId !== undefined,
        () => {
          const provider = new firebase.auth.FacebookAuthProvider();
          provider.addScope("pages_messaging");
          provider.addScope("pages_show_list");
          provider.addScope("pages_manage_metadata");

          if (this.firebase) {
            this.firebase
              .auth()
              .currentUser?.linkWithPopup(provider)
              .then((result) => {
                const credential = result.credential as firebase.auth.OAuthCredential;
                resolve(credential);
              })
              .catch((error) => {
                alert(`Unable to connect to Facebook: ${error.message}`);
              });
          }
        });
    });
  }

  public signOut() {
    return new Promise<void>(async (resolve, reject: (reason?: any) => void) => {
      await this.connectionRef?.set({
        status: "offline",
        modifiedOn: firebase.database.ServerValue.TIMESTAMP
      });

      if (this.firebase && typeof (this.notificationToken) == "string" && typeof (this.currentUserId) == "string") {
        const userProfile = this.firebase.firestore().collection('userProfiles').doc(this.currentUserId);
        userProfile.collection("notificationTokens")
          .doc(this.notificationToken)
          .delete()
          .then(() => {
            runInAction(() => this.mNotificationToken = null);

            if (this.firebase && firebase.messaging.isSupported() && !isLocalhost) {
              this.firebase.messaging().deleteToken();
            }
          })
          .catch((error) => console.error("Unable to delete notification token", error))
          .finally(() => {
            if (this.firebase) {
              this.firebase.auth().signOut().then(() => {
                if (isPlatform("capacitor")) {
                  GoogleAuth.signOut().finally(() => { this.currentUserId = null; this.loaded = false; resolve(); });
                } else {
                  this.currentUserId = null;
                  this.loaded = false;
                  resolve();
                }
              }).catch((error) => reject(error));
            }
          })
          ;
      } else {
        if (this.firebase) {
          this.firebase.auth().signOut().then(() => {
            if (this.firebase) {
              this.firebase.delete();
            }
            this.currentUserId = null;
            this.loaded = false;
            resolve();
          }).catch((error) => reject(error));
        }
      }
    });
  }

  public signUp(instanceId: string, email: string, password: string, firstName: string, lastName: string) {
    return new Promise<void>((resolve, reject) => {
      this.firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          this.signOut().finally(() => resolve());
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  public async executeCommand(
    args: {
      commandId: string,
      commandArgs: any
    }
  ) {
    return new Promise<unknown>((resolve, reject) => {
      when(
        () => this.currentUser?.loaded ?? false,
        async () => {
          try {
            if (this.currentUser) {
              const executeCommand = this.firebase
                .functions()
                .httpsCallable("engage-executeCommand");

              const result = await executeCommand(args);
              resolve(result)
            } else {
              reject(new Error("Unable to determine currently logged in user."));
            }
          } catch (error: any) {
            reject(error);
          }
        }
      );
    });
  }
}

export const SessionContext = createContext<Session | null | undefined>(undefined);