import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import firebase from "firebase/app";

import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItemDivider,
  IonList,
  IonText,
  IonCheckbox,
  IonSpinner,
  isPlatform,
} from "@ionic/react";

import { close, checkmarkCircle, add, link } from "ionicons/icons";

import * as Models from "../../../models";
import { SessionContext } from "../../../session";
import { FacebookPageData } from "../../../models";
import { isArray } from "util";

interface ConnectFacebookPagesButtonProps {
  parent?: HTMLElement | null;
}

const ConnectFacebookPagesButton: React.FC<ConnectFacebookPagesButtonProps> =
  observer(({ parent }) => {
    const [showModal, set_showModal] = useState(false);
    const [facebookPages, setFacebookPages] = useState<
      Record<
        string,
        {
          data: FacebookPageData;
          isSelected: boolean;
          availability: string | undefined;
        }
      >
    >({});

    const session = useContext(SessionContext);
    if (!(session?.conversationsSettings?.loaded || false)) {
      return <></>;
    }

    return (
      <>
        <IonButton
          shape="round"
          expand="block"
          size="large"
          fill="solid"
          color="light"
          onClick={async () => {
            if (isPlatform("capacitor")) {
              alert("Facebook Pages cannot be linked using the VoyagerNetz Engage Mobile App. To link, please sign into the web version via a Desktop browser.")
            } else {
              const provider = new firebase.auth.FacebookAuthProvider();
              const linkedFacebookProfiles = session.firebase
                .auth()
                .currentUser?.providerData.filter(
                  (profile) => profile?.providerId === provider.providerId
                );

              let credential;
              if (linkedFacebookProfiles && linkedFacebookProfiles.length > 0) {
                credential = await session.signInWithFacebook();
              } else {
                credential = await session.linkWithFacebook();
              }

              if (credential) {
                const accessToken = credential.accessToken;

                fetch(
                  `https://graph.facebook.com/v2.6/me/accounts?access_token=${accessToken}`
                )
                  .then(async (response) => {
                    const data = await response.json();
                    if (data && data.data) {
                      let tempFacebookPages = {} as Record<
                        string,
                        {
                          data: FacebookPageData;
                          isSelected: boolean;
                          availability: string | undefined;
                        }
                      >;

                      data.data.forEach(
                        (page: any) =>
                        (tempFacebookPages[page.id] = {
                          data: {
                            pageId: page.id,
                            name: page.name,
                            category: page.category,
                            accessToken: page.access_token,
                            groupId: session?.conversationsSettings?.groupId ?? null,
                          } as Models.FacebookPageData,
                          isSelected: false,
                          availability: undefined,
                        })
                      );

                      setFacebookPages(tempFacebookPages);
                      set_showModal(true);

                      const checkFacebookPages = Object.keys(
                        tempFacebookPages
                      ).map((key) => tempFacebookPages[key].data.pageId);

                      const checkFacebookPagesAvailability = session.firebase
                        .functions()
                        .httpsCallable("checkFacebookPagesAvailability");

                      checkFacebookPagesAvailability({
                        instanceId: session.instanceId,
                        facebookPages: checkFacebookPages,
                        format: "string",
                      })
                        .then((response) => {
                          if (response.data?.isError) {
                            alert(
                              `Unable to query availability of Facebook Pages. Please try again later.`
                            );
                            set_showModal(false);
                          } else {
                            const checkedFacebookPages = response.data.facebookPages;
                            for (const checkedFacebookPageId in checkedFacebookPages) {
                              setFacebookPages(
                                (prev) =>
                                  prev && {
                                    ...prev,
                                    [checkedFacebookPageId]: {
                                      ...prev[checkedFacebookPageId],
                                      availability:
                                        checkedFacebookPages[
                                        checkedFacebookPageId
                                        ],
                                    },
                                  }
                              );
                            }
                          }
                        })
                        .catch((error) => {
                          alert(
                            `Unable to query availability of Facebook Pages. Please try again later.`
                          );
                          set_showModal(false);
                        });
                    }
                  })
                  .catch((error) => {
                    alert(
                      `Unable to query list of Facebook Pages. Please try again later.`
                    );
                    set_showModal(false);
                  });
              }
            }
          }}
        >
          <IonIcon icon={add} title="Link" />
        </IonButton>

        <IonModal animated isOpen={showModal} swipeToClose onDidDismiss={(e) => set_showModal(false)}>
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonButtons slot="start">
                <IonButton onClick={() => set_showModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>

              <IonTitle>Link Facebook Pages</IonTitle>

              <IonButtons slot="end">
                <IonButton
                  shape="round"
                  expand="block"
                  size="large"
                  fill="solid"
                  color="light"
                  onClick={(e) => {
                    if (facebookPages) {
                      Object.keys(facebookPages).forEach(async (key) => {
                        if (facebookPages[key].isSelected) {
                          const executeCommand = session.firebase
                            .functions()
                            .httpsCallable("engage-executeCommand");

                          const response = await executeCommand({
                            commandId: "facebookPage.create",
                            commandArgs: {
                              data: {
                                ...facebookPages[key].data,
                                instanceId: session.instanceId,
                              }
                            }
                          });

                          if (response.data?.isError) {
                            alert(
                              `Unable to link Facebook Page: ${facebookPages[key].data.name}`
                            );
                          }
                        }
                      });

                      set_showModal(false);
                    }
                  }}
                  strong
                >
                  <IonIcon slot="start" icon={link} title="Link" />
                  Link
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList lines="none" className="ion-no-padding">
              {facebookPages &&
                Object.keys(facebookPages).map((key) => (
                  <IonItem button key={key}>
                    <IonSpinner
                      hidden={facebookPages[key].availability !== undefined}
                      slot="start"
                      color="medium"
                    />
                    <IonCheckbox
                      hidden={facebookPages[key].availability === undefined}
                      disabled={
                        facebookPages[key].availability === "unavailable"
                      }
                      slot="start"
                      checked={facebookPages[key].isSelected}
                      onIonChange={(e) => {
                        setFacebookPages(
                          (prev) =>
                            prev && {
                              ...prev,
                              [key]: {
                                ...prev[key],
                                isSelected: e.detail.checked,
                              },
                            }
                        );
                      }}
                    />
                    <IonLabel
                      color={
                        facebookPages[key].availability === "unavailable"
                          ? "medium"
                          : ""
                      }
                    >
                      <h2>{facebookPages[key].data.name}</h2>
                      <p>
                        {facebookPages[key].availability === undefined
                          ? "Checking availability..."
                          : facebookPages[key].availability === "unavailable"
                            ? "Already linked to another instance"
                            : facebookPages[key].availability === "current"
                              ? "Linked to this instance"
                              : "Available to link to this instance"}
                      </p>
                    </IonLabel>
                  </IonItem>
                ))}
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  });

export default ConnectFacebookPagesButton;
