import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { gql, useQuery } from "@apollo/client";

import { hashCode } from "../../../../../utils/Helpers";

import {
  ResponsiveBar,
  BarLegendProps
} from "@nivo/bar";

import { 
  IonLabel,
  IonSpinner,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";

const GRAPHQL_QUERY = gql(`
query Query {
  usage_info: channelRouteStats {
    com_channel: channel
    direction
    period_month: period
    usage: segmentCount
  }
}
`);

interface Query {
  usage_info: UsageInfo[]
}

interface UsageInfo {
  com_channel: string,
  direction: string,
  period_month: string,
  usage: number
}

interface DashData {
  inbound: { keys: string[], data: any[] },
  outbound: { keys: string[], data: any[] }
};

const legends: BarLegendProps[] = [{
  dataFrom: "keys",
  anchor: "right",
  direction: "column",
  justify: false,
  translateX: 120,
  translateY: 0,
  itemsSpacing: 2,
  itemWidth: 100,
  itemHeight: 20,
  itemDirection: "left-to-right",
  itemOpacity: 0.85,
  symbolSize: 20,
  effects: [
    {
      on: "hover",
      style: {
        itemOpacity: 1
      }
    }
  ]
}];

const colors: Record<string, string> = {
  "messenger": "#00B2FF", //"var(--ion-color-success)",
  "sms": "#898F9C", //"var(--ion-color-primary)",
  "webchat": "#4267B2", //"var(--ion-color-warning)",
  "whatsapp": "#25D366" //"var(--ion-color-secondary)"
};

const MessageUsageChart: React.FC = observer(() => {
  const { loading, error, data, refetch } = useQuery<Query>(GRAPHQL_QUERY);
  const [dashData, setDashData] = useState<DashData | null>(null);

  useIonViewDidEnter(
    () => {
      refetch();
    }
  );

  useEffect(() => {
    if (data) {
      const processedData: DashData = data.usage_info.reduce(
        (grp: any, itm: UsageInfo) => {
          const prop = `${itm.direction}bound`;

          if (!grp[prop].keys.includes(itm.com_channel)) {
            grp[prop].keys.push(itm.com_channel);
          }

          const index = grp[prop].data.findIndex((d: UsageInfo) => d.period_month === itm.period_month);
          const datum = index === -1
            ? {
                period_month: itm.period_month
            }
            : grp[prop].data[index];

          if (!datum[itm.com_channel]) {
            datum[itm.com_channel] = 0;
          }
          datum[itm.com_channel] += itm.usage;

          if (index === -1) {
            grp[prop].data.push(datum);
          } else {
            grp[prop].data[index] = datum;
          }

          grp[prop].keys.sort().reverse();
          return grp;
        },
        {
          inbound: { keys: [], data: [] },
          outbound: { keys: [], data: [] }
        }
      );

      setDashData(processedData);
      console.info('processed data', processedData);
    }
  }, [data]);

  /// Render ---
  const renderDashboard = (group: "inbound" | "outbound") => {
    if (dashData) {
      if (dashData[group].data.length === 0) {
        return <div>No data available.</div>;
      }

      return <div style={{ height: "300px", textAlign: "center" }}>
        <ResponsiveBar
          data={dashData[group].data}
          keys={dashData[group].keys}
          indexBy="period_month"
          legends={legends}
          margin={{ top: 40, right: 120, bottom: 80, left: 40 }}
          colors={({ id }) => {
            const channel = `${id}`.toLowerCase();
            if (colors[channel]) {
              return colors[channel];
            }

            const hash = hashCode(channel);
            const color = (hash & 0x00FFFFFF)
              .toString(16)
              .toUpperCase();

            return `#${"00000".substring(0, 6 - color.length)}${color}`;
          }}
        />
      </div>;
    }

    return null;
  };

  if (error) {
    console.error("Dashboard Error (USAGE): ", error);
  }

  return (
    <div style={{ height: "700px" }}>
      <IonLabel hidden>
        <IonText color="dark">
          <strong>Usage</strong>
        </IonText>
      </IonLabel>
      {
        loading 
          ? (
            <IonLabel>
              <p>
                <IonSpinner /> 
              </p>
            </IonLabel>
          ) : (
            error 
              ? (
                <IonLabel>Failed to load.</IonLabel>
              ) : (
                !data || !dashData
                  ? (
                    null
                  ) : (
                    <div>
                      <div style={{ marginBottom: "50px" }}>
                        <IonLabel>
                          <IonText color="dark">
                            <strong>INBOUND</strong>
                          </IonText>
                        </IonLabel>
                        { renderDashboard("inbound") }
                      </div>
                      <div style={{ marginBottom: "50px" }}>
                        <IonLabel>
                          <IonText color="dark">
                            <strong>OUTBOUND</strong>
                          </IonText>
                        </IonLabel>
                        { renderDashboard("outbound") }
                      </div>
                    </div>
                  )
              )
          )
      }
    </div>
  );
  /// --- render
});
export default MessageUsageChart;
