import firebase from "firebase/app";

import {
    makeAutoObservable,
    onBecomeObserved,
    onBecomeUnobserved,
    when,
    IReactionDisposer,
    reaction,
} from "mobx";
import { Session } from "../../session";
import { getId } from "../../utils/Helpers";

import { WorkerProfile } from "../WorkerProfiles";
import { InternalMessageData, internalMessageDataConverter, internalMessageDefaultValue } from "./InternalMessageData";

const __cache: Record<string, InternalMessage> = {};

export class InternalMessage {
    private mSession: Session
    private mPath: string;
    private mId: string | null;
    private mData: InternalMessageData | undefined;
    private mSubscription: any;
    private mSubscriptionCount: number;
    private mSender: WorkerProfile | null | undefined;
    private mSenderDisposer: IReactionDisposer | null | undefined;

    constructor(session: Session, path: string, data?: InternalMessageData) {
        if ((path ?? '').trim() === '') throw new Error("Path cannot be empty");

        makeAutoObservable(this);

        this.mSession = session;
        this.mPath = path.trim();
        this.mId = getId(this.mPath);
        this.mData = data ? data : (this.mId == null ? internalMessageDefaultValue : undefined);
        this.mSubscriptionCount = 0;

        onBecomeObserved(this, "sender", this.subscribeSender);
        onBecomeUnobserved(this, "sender", this.unsubscribeSender);
    }

    static createCacheInstance(session: Session, path: string, data?: InternalMessageData) {
        const cachePath = (path ?? '').trim();
        if (cachePath === '') throw new Error("Path cannot be empty");

        const id = getId(cachePath);
        if (id == null) throw new Error("Instance has not yet been saved to the datastore.")

        let cacheInstance = __cache[cachePath];
        if (!cacheInstance) {
            cacheInstance = new InternalMessage(session, cachePath, data);
            __cache[cachePath] = cacheInstance;
        } else if (data !== undefined) {
            cacheInstance.data = data;
        }

        return cacheInstance;
    }

    static addCacheInstance(instance: InternalMessage) {
        if (instance.id == null) {
            throw new Error("Instance has not yet been saved to the datastore.");
        }

        let cacheInstance = __cache[instance.path];
        if (!cacheInstance) {
            __cache[instance.path] = instance;
        }
    }

    public get session() {
        return this.mSession;
    }

    public get id(): string | null {
        return this.mId;
    }
    private set id(newValue: string | null) {
        this.mId = newValue;
    }

    public get path() {
        return this.mPath;
    }
    private set path(newValue: string) {
        this.mPath = newValue.trim();
        this.id = getId(this.mPath);
    }

    public get data() {
        return this.mData;
    }
    protected set data(newValue: InternalMessageData | undefined) {
        this.mData = newValue;
    }

    public get loading() {
        return this.data === undefined;
    }

    public get loaded() {
        return this.data !== undefined;
    }

    public refetch() {
        if (!this.mSubscription) {
            this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(internalMessageDataConverter(this.session))
                .get()
                .then((snapshot) => {
                    const data = snapshot.data();
                    this.data = data;
                });
        }
    }

    public subscribe() {
        ++this.mSubscriptionCount;
        if (this.mSubscription === undefined) {
            console.log(`subscribe to ${this.path}`);
            this.mSubscription = this.session.firebase
                ?.firestore()
                .doc(this.path)
                .withConverter(internalMessageDataConverter(this.session))
                .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<InternalMessageData>) => {
                    const data = snapshot.data();
                    this.data = data;
                }
                );
        }
    }

    public unsubscribe() {
        if (this.mSubscription && --this.mSubscriptionCount <= 0) {
            console.log(`unsubscribe from ${this.path}`);
            this.mSubscription();
            this.mSubscription = undefined;
            this.mSubscriptionCount = 0;
        }
    }

    public save = () => {
        return new Promise<void>((resolve, reject) => {
            if (this.data) {
                if (this.id == null) {
                    this.session.firebase
                        ?.firestore()
                        .collection(this.mPath)
                        .withConverter(internalMessageDataConverter(this.session))
                        .add(this.data).then((doc) => {
                            this.path = doc.path;
                            InternalMessage.addCacheInstance(this);
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                } else {
                    this.session.firebase
                        ?.firestore()
                        .doc(this.mPath)
                        .withConverter(internalMessageDataConverter(this.session))
                        .set(this.data, { merge: true }).then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
            } else {
                reject(new Error(this.loading ? "Data still loading" : "Invalid Data"));
            }
        });
    }

    public get body(): string | null | undefined {
        if (this.data) return this.data.body;
        return undefined;
    }

    public get createdOn(): Date | null | undefined {
        if (this.data) return this.data.createdOn?.toDate();
        return undefined;
    }

    public get senderId(): string | null | undefined {
        if (this.data) return this.data.senderId;
        return undefined;
    }

    public get status(): string | null | undefined {
        if (this.data) return this.data.status;
        return undefined;
    }

    public get sender(): WorkerProfile | null | undefined {
        return this.mSender;
    }
    public subscribeSender = () => {
        when(
            () => this.senderId !== undefined,
            () => {
                const initSender = () => {
                    if (this.mSender && this.mSender.id !== this.senderId) {
                        this.mSender?.unsubscribe();
                        this.mSender = undefined;
                    }

                    if (this.mSender === undefined && this.senderId !== undefined) {
                        this.mSender = this.senderId
                            ? WorkerProfile.createCacheInstance(
                                this.session,
                                `workerProfiles/${this.senderId}`
                            )
                            : null;
                        this.mSender?.subscribe();

                        this.mSenderDisposer = reaction(
                            () => this.senderId,
                            (senderId) => initSender()
                        );
                    }
                };

                initSender();
            }
        );
    };
    public unsubscribeSender = () => {
        if (this.mSenderDisposer) this.mSenderDisposer();
        this.mSender?.unsubscribe();
        this.mSender = undefined;
    };
}