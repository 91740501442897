import React, { useState, useEffect } from "react";
import { CreateAnimation } from "@ionic/react";

interface AnimatedImgProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

const AnimatedImg: React.FC<AnimatedImgProps> = (props) => {
  const [visible, set_visible] = useState(false);

  return (
    <>
      {props.src !== undefined && (
        <CreateAnimation
          duration={1000}
          play={visible}
          iterations={1}
          fromTo={[{ property: "opacity", fromValue: "0", toValue: "1" }]}
        >
          <img
            alt=""
            onLoad={() => set_visible(true)}
            {...{
              ...props,
              style: { ...props.style, opacity: visible ? "1" : "0" },
            }}
          />
        </CreateAnimation>
      )}
    </>
  );
};

export default AnimatedImg;
