import firebase from "firebase/app";
import { Session } from "../../session";

import { ConversationsSettings } from "./ConversationsSettings";

export interface ConversationsSettingsData {
  groupId?: string | null,
}

export const conversationsSettingsDefaultValue = {
  
} as ConversationsSettingsData;

export const conversationsSettingsDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: ConversationsSettingsData) {
      return {
        groupId: data.groupId,
      }
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationsSettingsData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        groupId: data.groupId ?? null,
      } as ConversationsSettingsData;
    },
  }
};

export const conversationsSettingsConverter = (session: Session) => {
  return {
    toFirestore: function (conversationsSettings: ConversationsSettings) {
      return conversationsSettings.data
        ? {
          groupId: conversationsSettings.data.groupId,
        } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationsSettingsData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return ConversationsSettings.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          groupId: data.groupId ?? null,
        }
      );
    },
  }
};