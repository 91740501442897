import firebase from "firebase/app";
import { Session } from "../../session";

import { CommunicationChannel } from "../common";
import { Conversation } from "./Conversation";
import { ConversationOutcomeData } from "../ConversationOutcomes";

export type ConversationRequestTransfer = {
  requestedBy: string | null;
  targetOwnerId?: string | null;
  targetGroupId?: string | null;
  requestedOn: firebase.firestore.Timestamp | null;
  description: string | null;
  isDeclined: boolean | null;
};

export interface ConversationData {
  createdOn: firebase.firestore.Timestamp | null;
  customerId: string | null;
  externalReference: string | null;
  status: string | null;
  ownerId: string | null;
  requestTransfer: ConversationRequestTransfer | null;
  communicationChannel: CommunicationChannel | null;
  outcome: ConversationOutcomeData | null;
  groupId: string | null;
}

export const conversationDefaultValue = {
  createdOn: firebase.firestore.FieldValue.serverTimestamp(),
  customerId: null,
  externalReference: null,
  status: "new",
  ownerId: null,
  requestTransfer: null,
  communicationChannel: null,
  outcome: null,
  groupId: null,
} as ConversationData;

export const conversationDataConverter = (session: Session) => {
  return {
    toFirestore: function (data: ConversationData) {
      return {
        communicationChannel: data.communicationChannel,
        createdOn: data.createdOn,
        customerId: data.customerId,
        externalReference: data.externalReference,
        status: data.status,
        ownerId: data.ownerId,
        outcome: data.outcome,
        requestTransfer: data.requestTransfer,
        groupId: data.groupId,
      };
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return {
        communicationChannel: data.communicationChannel,
        createdOn: data.createdOn ?? null,
        customerId: data.customerId ?? null,
        externalReference: data.externalReference ?? null,
        status: data.status ?? null,
        ownerId: data.ownerId ?? null,
        outcome: data.outcome,
        requestTransfer: data.requestTransfer && !data.requestTransfer.isDeclined ? (data.requestTransfer ?? null) : null,
        groupId: data.groupId ?? null,
      } as ConversationData;
    },
  }
};

export const conversationConverter = (session: Session) => {
  return {
    toFirestore: function (conversation: Conversation) {
      return conversation.data ? {
        communicationChannel: conversation.data.communicationChannel,
        createdOn: conversation.data.createdOn,
        customerId: conversation.data.customerId,
        externalReference: conversation.data.externalReference,
        status: conversation.data.status,
        ownerId: conversation.data.ownerId,
        outcome: conversation.data.outcome,
        requestTransfer: conversation.data.requestTransfer,
        groupId: conversation.data.groupId,
      } : {};
    },
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationData>,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);
      return Conversation.createCacheInstance(
        session,
        snapshot.ref.path,
        {
          communicationChannel: data.communicationChannel,
          createdOn: data.createdOn ?? null,
          customerId: data.customerId ?? null,
          externalReference: data.externalReference ?? null,
          status: data.status ?? null,
          ownerId: data.ownerId ?? null,
          outcome: data.outcome,
          requestTransfer: data.requestTransfer && !data.requestTransfer.isDeclined ? (data.requestTransfer ?? null) : null,
          groupId: data.groupId ?? null,
        });
    },
  }
};