import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";

import * as Models from "../../../models";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { checkmarkOutline, close } from "ionicons/icons";
import { Session } from "../../../session";

import firebase from "firebase/app";

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface ForgotPasswordProps {
  session: Session;
  onSuccess: () => void;
  onCancel: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = observer(
  ({ session, onSuccess, onCancel }) => {
    const [submitted, setSubmitted] = useState(false);

    const { control, handleSubmit, formState, reset, errors, setValue, watch } =
      useForm({
        mode: "onChange",
      });

    const onSubmit = async (data: any) => {
      setSubmitted(true);

      if (formState.isValid) {
        try {
          const useIdentityService = true;
          await session.sendPasswordResetEmail(
            data.email,
            window.location.href,
            useIdentityService
          );
          onSuccess();
        } catch (error: any) {
          alert((
            error.code === "auth/invalid-email"
              ? "Invalid Email Address"
              : error.code === "auth/user-disabled"
                ? "User is disabled"
                : error.code === "auth/user-not-found" || error.code === "auth/wrong-password"
                  ? "Invalid user email address or password"
                  : error.message
          ));
          return;
        }
      }
    };

    /// Render ---
    const renderError = (_fieldName: string) => {
      const error = (errors as any)[_fieldName];
      return submitted && error ? (
        <IonNote color="danger" mode="md">
          {error.message || "Field Is Required"}
        </IonNote>
      ) : null;
    };

    return (
      <IonPage>
        <IonHeader translucent className="ion-no-border">
          <IonToolbar color="">
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="">
            <IonTitle style={{ textAlign: "center" }}>
              Forgot your password?
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 18 }}>
            <IonGrid fixed style={{ maxWidth: "340px" }}>
              <IonRow>
                <IonCol>
                  <IonItem
                    lines="none"
                    style={{
                      border: "1px solid var(--ion-color-light)",
                      borderRadius: "10px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="instanceId"
                      defaultValue={session.instanceId}
                      rules={{
                        required: true,
                      }}
                      render={({ onBlur, onChange, value }) => (
                        <IonInput
                          disabled
                          onIonInput={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="instance"
                        />
                      )}
                    />
                    <IonText color="medium">.voyagernetz.us</IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol>
                  <IonItem
                    lines="none"
                    style={{
                      border: "1px solid var(--ion-color-light)",
                      borderRadius: "10px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ onBlur, onChange, value }) => (
                        <IonInput
                          type="email"
                          onIonInput={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Email"
                          onKeyUp={(e) => {
                            if (formState.isValid && e.key === "Enter")
                              handleSubmit(onSubmit)();
                          }}
                        />
                      )}
                    />
                    {renderError("email")}
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow style={{ paddingTop: "15px" }}>
                <IonCol>
                  <IonButton
                    shape="round"
                    type="submit"
                    disabled={!formState.isValid || formState.isSubmitting}
                    expand="block"
                  >
                    Send Reset Password Email
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonPage>
    );
  }
);

export default ForgotPassword;
